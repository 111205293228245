import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import { setResultFilter, setEnableResultFilter, setLatestResultFilter } from '../../../store/mainSlice';
import { TSearchTemplate } from '../../../store/Interfaces';
import { Accordion, TAccordionItemInst } from "../../Accordion";
import { Filter } from '../Filter';
import { FilterBadges } from '../FilterBadges';

import {
    BASE_CLASS, NonBaseFilterClasses, TFilterClass, TFilterEnabled,
    TFilterDefinitions, TMatchType, TValueType, typeToMatches
} from "../FilterGroup";

export type TFilterFragment = { propName: string, value: TValueType, matches: TMatchType };


export interface IExtendedFiltersProps {
    filtersActive: () => Record<TFilterClass, TFilterEnabled>;
    filterEnabled: (filterClass: TFilterClass, item: string) => boolean;
    filterDefinitions: TFilterDefinitions;
    setFilterDefinitions: (filterDefinitions: TFilterDefinitions) => void;
    setFilterActive: (filterClass: TFilterClass, key: string, enabled: boolean) => void;
    filterChange: (filterClass: TFilterClass, propName: string, value: TValueType, match?: TMatchType) => void;
    getSearchTemplates: () => Array<TSearchTemplate>;

}

export const ExtendedFilters: React.FunctionComponent<IExtendedFiltersProps> = ({ filtersActive, filterEnabled, filterDefinitions, setFilterDefinitions, setFilterActive, filterChange, getSearchTemplates }) => {
    const dispatch = useDispatch();
    const { resultFilter, enableResultFilter, filterTemplate, latestResultFilter, tableEndPoints } = useSelector(store => store.main);

    const selectReportType: React.ChangeEventHandler<HTMLSelectElement> = e => dispatch(setResultFilter(tableEndPoints.find(te => te.name === e.target.value)));

    const [accordionItems, setAccordionItems] = useState<Array<TAccordionItemInst>>([]);

    const propReportFilter = (sp: TSearchTemplate) =>
        NonBaseFilterClasses.every(filterClass => !sp.propName.startsWith(`${filterClass}.`));


    const getFilterClassFields = (filterClass: TFilterClass) => {
        const sp = getSearchTemplates();
        if (filterClass === BASE_CLASS) {
            return sp.filter(propReportFilter)
        }
        else {
            return sp.filter(spx => spx.propName.startsWith(`${filterClass}.`)).map(spx => ({ ...spx, propName: spx.propName.split('.')[1] }));
        }
    }

    useEffect(() => {
        const possibleAccordions: TFilterClass[] = ['asset', 'result', 'report', 'site', 'subSite', 'company'];
        const accNameMap = { asset: 'Asset', result: 'Result', report: 'Report', site: 'Site', subSite: 'Sub Site', company: 'Company' }

        // const nameKeyMap = { Asset: 'asset', Result: 'result', Report: 'report', Site: 'site', 'Sub Site': 'subSite', Company: 'company' };

        const localAccordionItems: Array<TAccordionItemInst> =
            possibleAccordions.filter((acc) => undefined !== filterDefinitions[acc]).map((acc) => {
                const name = accNameMap[acc];
                return {
                    initiallyExpanded: false,
                    title: name,
                    children: <Filter title={acc}
                        searchTerms={filterDefinitions[acc]}
                        filterFields={getFilterClassFields(acc)}
                        setFilterActive={(key, enabled) => setFilterActive(acc, key, enabled)}
                        filterActive={(item: string) => filtersActive()[acc][item]}
                        filterEnabled={(item: string) => filterEnabled(acc, item)}
                        typeToMatches={typeToMatches}
                        filterChange={(key, value, match) => filterChange(acc, key, value, match)}
                    />
                }
            })

        setAccordionItems(localAccordionItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDefinitions, filtersActive]);

    return <div>
        <div className='inline-block w-full mx-2' >
            {
                tableEndPoints.length === 0 ? <div className='inline-block'><span className='mr-2 font-bold' >Loading...</span><FontAwesomeIcon className='fa-spin text-hvpd-red-400' icon={solid('spinner')} /></div> :
                    <>
                        <input type='checkbox' id='lastest-result-filter' checked={latestResultFilter} onChange={() => dispatch(setLatestResultFilter(!latestResultFilter))}
                            className='w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'></input>
                        <label htmlFor="lastest-result-filter" className="mx-1">Show only latest results</label>

                        <input type='checkbox' id='result-type-filter' checked={enableResultFilter} onChange={() => dispatch(setEnableResultFilter(!enableResultFilter))}
                            className='w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'></input>
                        <label htmlFor="result-type-filter" className="mx-1">Filter by result type</label>
                        <select id='selectReportTypeFilter' name='selectReportTypeFilter' aria-label='Select report type filter' disabled={!enableResultFilter} className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border-1' value={resultFilter?.name} onChange={selectReportType}>
                            {!resultFilter ? <option>{`${tableEndPoints.length === 0 ? 'Loading...' : 'Please select...'} `}</option> : null}
                            {tableEndPoints.map(endPoint => <option key={endPoint.name} value={endPoint.name}>{endPoint.name}</option>)}
                        </select>
                    </>
            }
            <div className='overflow-y-auto h-[93vh] mt-2 mr-2' >
                {filterTemplate ?
                    <FilterBadges filtersActive={filtersActive} searchTemplates={getSearchTemplates()} filterDefinitions={filterDefinitions} deleteFilter={(filterClass, propName) => setFilterActive(filterClass, propName, false)} /> : <p className='text-center text-s text-gray-600'>Please select a report from the right click menu on the report list</p>}
                <Accordion items={accordionItems} alwaysOpen={true} options={{ minPadding: true }} id='filters' />
            </div >
        </div >
    </div >

}