import { getApiEndpoint, fetchWithAuthRedirect } from './api';

import { store } from "../store/store";
import { addError } from "../store/mainSlice";
import {
    IObservation,
    TResultType,
    TResultObservations
} from "../store/Interfaces"


const { dispatch } = store;

const rmap: Record<TResultType, string> = {
    assets: "Asset",
    companies: "Company",
    sites: "Site",
    results: "Result",
    "sub-sites": "SubSite",
    users: "Asset",
    "one-report": "OneReport",
};

export const getObservations = async (
    token: string,
    assetType: TResultType,
    uuid: string,
    abortController: AbortController,
): Promise<Array<IObservation> | string> => {
    if (0 === uuid.length) {
        // We have Results with no Report, which leads to the observations being called for an empty uuid, just ignoring it...
        return "";
    }

    const url = `${getApiEndpoint()}/${rmap[assetType]}Observation/parent/${uuid}`;
    const p = new Promise<Array<IObservation>>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal,
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else if (resp.status === 404) {
                    reject("Not found");
                } else {
                    throw new Error(`Error getting the observation ${uuid} [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(json as Array<IObservation>);
            })
            .catch((e) => {
                if (e.name !== "AbortError") {
                    dispatch(addError(e.message));
                }
                reject(e.message);
            });
    });
    return p;
};

export const updateObservation = async (
    token: string,
    assetType: keyof TResultObservations,
    observation: IObservation,
): Promise<IObservation> => {
    const translation = assetType === 'Subsite' ? 'SubSite' : assetType;
    const url = `${getApiEndpoint()}/${translation}Observation`;

    const p = new Promise<IObservation>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },

            method: "PUT",
            body: JSON.stringify(observation),
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else if (resp.status === 404) {
                    reject("Not found");
                } else {
                    throw new Error(`Error updating the observation ${observation.uuid} [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(json as IObservation);
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject(e.message);
            });
    });
    return p;
};


export const addObservation = async (
    token: string,
    observation: IObservation,
    assetType: keyof TResultObservations,
): Promise<IObservation> => {
    const translation = assetType === 'Subsite' ? 'SubSite' : assetType;
    const url = `${getApiEndpoint()}/${translation}Observation`;
    const p = new Promise<IObservation>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },

            method: "POST",
            body: JSON.stringify(observation),
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else if (resp.status === 404) {
                    reject("Not found");
                } else {
                    throw new Error(`Error adding the observation ${observation.uuid} [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(json as IObservation);
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject(e.message);
            });
    });
    return p;
};


export const deleteObservation = async (
    token: string,
    uuid: string,
    assetType: keyof TResultObservations,
): Promise<boolean> => {
    const translation = assetType === 'Subsite' ? 'SubSite' : assetType;
    const url = `${getApiEndpoint()}/${translation}Observation/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "DELETE",
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else if (resp.status === 404) {
                    reject("Not found");
                } else {
                    throw new Error(`Error deleting the observation ${uuid} [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(true);
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject(e.message);
            });
    });
    return p;
};
