import React, { useEffect, useState } from 'react';
import { useTimeout } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { useAppSelector as useSelector } from '../../hooks';
import { getRunningConditions, testGetRunningConditions } from '../../api';
import { TResultRow, IRunningConditions, IResultHistory } from "../../store/Interfaces";
import { ReportHistorySelect } from '../Report/ReportHistorySelect';
import { RunningConditionsGraph } from './RunningConditionsGraph';

import { jsonToCsv, downloadCSV } from '../ResultsTable/utils';

const AUTO_CLOSE_ERROR = 5000;

export type TDataType = 'deg' | 'n' | 'kV' | 'pc' | 's';

interface IElementRowProps {
    title: string;
    value: number | string;
    type: TDataType;
    index: number;
}

const ElementRow: React.FunctionComponent<IElementRowProps> = ({ title, value, type, index }) => {
    return (<><div className={`font-semibold text-right pr-2 border-r-2 ${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:hover:bg-slate-200`}>{title}</div>
        <div className={`${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:bg-slate-200`} >{value}{type === 'deg' ? ' deg' : type === 'pc' ? '%' : null}</div></>)
}

export interface IRunningConditionsProps {
    resultRow: TResultRow | undefined;
    resultHistory: { uuid: string, history: Array<IResultHistory> };
    resultHistoryIndex: number;
    setResultHistoryIndex: (index: number) => void;
    abortController: AbortController;
}

export const RunningConditions: React.FunctionComponent<IRunningConditionsProps> = ({ resultRow, resultHistory, resultHistoryIndex, setResultHistoryIndex, abortController }) => {
    const { token } = useSelector(store => {
        return store.main;
    });
    const [runningConditionsError, setRunningConditionsError] = useState('');
    const [loading, setLoading] = useState(false);
    const [runningConditionsIndex, setRunningConditionIndex] = useState<string | undefined>();
    const [runningConditions, setRunningConditions] = useState<Record<string, IRunningConditions>>({});
    const [autoCloseTimeout, setAutoCloseTimeout] = useState<number | null>(null);
    const [resultHistoryValid, setResultHistoryValid] = useState<Record<string, string>>({});

    const keyTranslate: Record<string, [string, TDataType]> = {
        windingTemp1DegC: ['Winding temp', 'deg'],
        windingTempB1DegC: ['Winding temp b1', 'deg'],
        windingTemp2DegC: ['Winding temp 2', 'deg'],
        windingTemp2BDegC: ['Winding temp 2b', 'deg'],
        windingTemp3DegC: ['Winding temp 3', 'deg'],
        windingTemp3BDegC: ['Winding temp 3b', 'deg'],
        auxTempDegC: ['Aux temp', 'deg'],
        load_kW: ['Load (kW)', 'n'],
        runningSpeed_rpm: ['Running speed (rpm)', 'n'],
        ambientTemp_DegC: ['Ambient temp', 'deg'],
        ambientHumidity_PC: ['Ambient humidity', 'pc'],
        voltage_kV: ['Voltage (kV)', 'n'],
        current_Amps: ['Current amps', 'n'],
        currentP1_Amps: ['Current p1', 'n'],
        currentP2_Amps: ['Current p2', 'n'],
        currentP3_Amps: ['Current p3', 'n'],
        coolantTemp_DegC: ['Coolant temp', 'deg'],
        energisationState: ['Energisation state', 's'],
    }
    useEffect(() => {
        setResultHistoryValid({});
        setRunningConditionIndex(undefined);
        setRunningConditions({});
        setRunningConditionsError('');
        setLoading(false);
    }, [resultRow]);

    useEffect(() => {
        if (token && resultHistory && resultHistory.history.length > 0 && !abortController.signal.aborted) {
            const history = resultHistory.history;
            const localValid: Record<string, string> = history.reduce((acc, r) => ({ ...acc, [r.uuid]: '' }), {});
            setResultHistoryValid(localValid);
            history.filter(r => r.runningConditionUuid != null)
                .filter(r => ('' === localValid[r.uuid]))
                .forEach(async (r) => {
                    const valid = await testGetRunningConditions(token, r.runningConditionUuid, abortController);
                    setResultHistoryValid((prev: Record<string, string>) => ({ ...prev, [r.uuid]: valid ? r.runningConditionUuid : '' }));
                });
        }
    }, [resultHistory, token, abortController]);

    const closeRunningConditionsError = () => setRunningConditionsError('');
    useTimeout(closeRunningConditionsError, autoCloseTimeout);

    useEffect(() => {
        const localGetRunningConditions = async (token: string, runningConditionUuid: string) => {
            setLoading(true);
            try {
                const rc = await getRunningConditions(token, runningConditionUuid, true, abortController);
                setRunningConditions(runningConditions => ({ ...runningConditions, [runningConditionUuid]: rc as IRunningConditions }));
                setRunningConditionIndex(runningConditionUuid);
                setLoading(false);
            } catch (e) {
                setRunningConditionsError(e as string);
                setAutoCloseTimeout(AUTO_CLOSE_ERROR);
                setLoading(false);
            }
        }
        if (resultRow && token && resultRow.runningConditionUuid && !abortController.signal.aborted) {
            localGetRunningConditions(token, resultRow.runningConditionUuid);
        } else if (resultHistory && resultHistory.history.length > 0) {
            const sorted = resultSorter(resultHistory.history as IResultHistory[]);
            selectHistoryPage(sorted[sorted.length - 1].uuid);
        } else {
            setRunningConditions({});
            setResultHistoryIndex(0);
        }
    }, [token, resultHistory, abortController]);

    const resultSorter = (resultHistory: IResultHistory[]) => [...resultHistory ?? []].sort((a, b) => b.endTime - a.endTime);

    const selectHistoryPage = async (historyUuid: string) => {
        if (resultHistory && !abortController.signal.aborted) {
            const sorted = resultSorter(resultHistory.history as IResultHistory[]);
            const index = sorted.findIndex(r => r.uuid === historyUuid);
            setResultHistoryIndex(index);
            setLoading(true);
            try {
                if (null == sorted[index].runningConditionUuid) {
                    setRunningConditionsError('No running condition for this result');
                    setRunningConditionIndex(undefined);
                    setAutoCloseTimeout(AUTO_CLOSE_ERROR);
                    setLoading(false);
                } else {
                    let rc: IRunningConditions | string;
                    if (runningConditions && runningConditions[sorted[index].runningConditionUuid]) {
                        rc = runningConditions[sorted[index].runningConditionUuid] as IRunningConditions;
                    } else {
                        rc = await getRunningConditions(token, sorted[index].runningConditionUuid, true, abortController);
                    }
                    if (typeof rc !== 'string') {
                        setRunningConditionsError('');
                        setRunningConditions(runningConditions => ({ ...runningConditions, [sorted[index].runningConditionUuid]: rc as IRunningConditions }));
                        setRunningConditionIndex(sorted[index].runningConditionUuid);
                    }
                } setLoading(false);
            } catch (e) {
                setRunningConditionsError(e as string);
                setRunningConditionIndex(undefined);
                setAutoCloseTimeout(AUTO_CLOSE_ERROR);
                setLoading(false);
            }
        }
    }

    const getAllRunningConditions = async () => {
        setLoading(true);
        if (!abortController.signal.aborted) {
            const toFetch = Object.values(resultHistoryValid)
                .filter(runningConditionsUuid => runningConditionsUuid !== '')
                .filter(runningConditionsUuid => null == runningConditions[runningConditionsUuid]);
            const promises: Array<Promise<boolean>> = toFetch.map(async runningConditionUuid => {
                const p = new Promise<boolean>(async (resolve, reject) => {
                    try {
                        const rc = await getRunningConditions(token, runningConditionUuid, true, abortController);
                        setRunningConditions(runningConditions => ({ ...runningConditions, [runningConditionUuid]: rc as IRunningConditions }));
                        resolve(true);
                    } catch (e) {
                        setRunningConditionsError(e as string);
                        setAutoCloseTimeout(AUTO_CLOSE_ERROR);
                        reject(e);
                    }
                });
                return p;
            });
            Promise.all(promises).then(() => {
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            });
        }
    }

    const onExport = () => {
        if (runningConditionsIndex && runningConditions?.[runningConditionsIndex]) {
            const csv = Object.keys(keyTranslate).map(key => {
                const type = keyTranslate[key][1];
                const typeS = type === 'deg' ? ' deg' : type === 'pc' ? '%' : '';
                return [keyTranslate[key][0], (runningConditions?.[runningConditionsIndex]?.[key as keyof IRunningConditions].toString() ?? '') + typeS];
            });
            csv.splice(0, 0, ['Entity', 'Value']);
            const csvString = jsonToCsv(csv);
            downloadCSV(csvString, `Running conditions export - ${new Date().toLocaleString()}.csv`);
        }
    }

    const getRunningConditionsUUIDs = () => {
        const s = new Set<string>(Object.values(resultHistoryValid).filter(v => v !== ''));
        if (resultRow?.runningConditionUuid) {
            s.add(resultRow?.runningConditionUuid);
        }
        return Array.from(s);
    }

    return <div className="overflow-y-auto h-full">
        <div className='h-full bg-gray-50'>
            {((Object.values(resultHistoryValid).filter(v => v).length === 0) && null == resultRow?.runningConditionUuid) ? <div className='container mt-20 text-center text-lg'>No valid running conditions</div> : null}
            {(resultHistory.history.filter(rh => resultHistoryValid[rh.uuid]).length > 1) ?
                <div>
                    <ReportHistorySelect resultHistory={resultHistory.history.filter(r => resultHistoryValid[r.uuid]) as IResultHistory[]} historySorter={resultSorter} resultHistoryIndex={resultHistoryIndex} selectHistoryPage={(e) => selectHistoryPage(e.target.value)} />
                </div> : null}
            {loading ? <div className='container mt-20 text-center'>
                <FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> :
                <div className='px-8 py-4 rounded-lg break-all'>
                    {
                        runningConditionsError ?
                            <div className='min-w-80 px-7 py-4 mx-2 block bg-red-200 border-red-300 rounded-md'>{runningConditionsError}
                                <button className='float-right text-red-700 hover:text-red-800 hover:border-red-900' onClick={() => setRunningConditionsError('')}><FontAwesomeIcon className='' icon={solid('xmark')} /></button>
                            </div> : null
                    }
                    {runningConditionsIndex && runningConditions?.[runningConditionsIndex] ? <>
                        <div className='px-2 text-sky-800 text-center'>
                            <span className='font-bold'>Running conditions</span>
                            <button onClick={onExport} className='float-right text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 text-sm py-1 px-2 border-solid border-1 font-medium'>Export</button>
                        </div>

                        <div className='grid grid-cols-[2fr_3fr] gap-x-1 text-sm m-2'>  {
                            Object.keys(keyTranslate).map((key, ix) =>
                                (<ElementRow key={`A_${key}_${ix}`} title={keyTranslate[key][0]} value={runningConditions[runningConditionsIndex] ? (runningConditions[runningConditionsIndex][key as keyof IRunningConditions] ?? '') : ''} type={keyTranslate[key][1]} index={ix} />)
                            )
                        }
                        </div></> : null}
                </div>}
            {/*<div className='grid grid-cols-2 text-xs'>
                {!resultRow?.runningConditionUuid ? <><div className='bg-slate-400'>No running condition</div><div></div></> : null}
                {resultRow?.runningConditionUuid ? <React.Fragment><div className='bg-slate-400'>{resultRow?.runningConditionUuid}</div><div>{runningConditions[resultRow?.runningConditionUuid ?? ''] ? 'Yes' : 'No'}</div></React.Fragment> : null}
                {Object.entries(resultHistoryValid)
                    .filter(([resultHistoryUuid, runningConditionsUuid]) => runningConditionsUuid !== '')
                    .map(([resultHistoryUuid, runningConditionsUuid]) => <React.Fragment key={resultHistoryUuid}><div>{runningConditionsUuid}</div><div>{runningConditions[runningConditionsUuid] ? 'Yes {x}' : 'No'}</div></React.Fragment>)}
            </div>
            <div><pre>{Object.keys(runningConditions)}</pre></div>*/}
            {getRunningConditionsUUIDs().length > 1 ? <div>
                <RunningConditionsGraph runningConditionsUuids={getRunningConditionsUUIDs()} resultHistory={resultSorter(resultHistory.history.filter(rh => resultHistoryValid[rh.uuid]))} additionalRunningConditionUuid={resultRow?.runningConditionUuid ?? ''} runningConditions={runningConditions} fetchRunningConditions={getAllRunningConditions} loading={loading} keyTranslate={keyTranslate} />
            </div> : null}
        </div>
    </div >
}