import React, { useEffect, useState, ReactElement } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { Busy } from "../../Busy";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../hooks';
import { keyToTitle, sanitise } from '../../../utils';
import { setSubSites } from "../../../store/mainSlice";
import { ISubSite, ICompany, ISite } from "../../../store/Interfaces";
import { getSubSites } from "../../../api";

export interface ISubSiteProps {
    uuid: string;
    detailsOnly?: boolean;
}

const embargoedSubSiteKeys: Array<keyof ISubSite> = ['uuid'];

interface ISubSiteBlockProps {
    subSite: ISubSite;
    sites: Array<ISite>;
    companies: Array<ICompany>;
}

const SubSiteBlock: React.FunctionComponent<ISubSiteBlockProps> = ({ subSite, sites, companies }) => {

    const getCellValue = (subSite: ISubSite, key: keyof ISubSite): React.ReactElement | string => {
        if (key === 'companyUuid') {
            const company = companies.find(c => c.uuid === subSite[key]);
            if (company) {
                return company.name ?? '';
            }
            return <FontAwesomeIcon className='fa-spin text-hvpd-grey-400' icon={solid('spinner')} />
        } else if (key === 'siteUuid') {
            const site = sites.find(c => c.uuid === subSite[key]);
            if (site) {
                return site.name ?? '';
            }
            return <FontAwesomeIcon className='fa-spin text-hvpd-grey-400' icon={solid('spinner')} />
        } else if (key === 'logoURL') {
            return <img alt={subSite.name} src={subSite[key]} />
        }
        return sanitise(subSite[key]?.toString());
    }
    return <div className='px-5 overflow-y-auto max-h-[80vh]'>
        <div className='grid grid-cols-[2fr_3fr] gap-x-1 text-sm m-2'>{
            Object.keys(subSite).filter((key) => !embargoedSubSiteKeys.includes(key as keyof ISubSite)).sort().map((key, ix) =>
                (<ElementRow key={`A_${key}_${ix}`} title={keyToTitle(key, keysToTitle)} value={getCellValue(subSite, key as keyof ISubSite)} index={ix} />))
        }
        </div>
    </div>
}


interface IElementRowProps {
    title: string;
    value: string | ReactElement;
    index: number;
}

const ElementRow: React.FunctionComponent<IElementRowProps> = ({ title, value, index }) => (
    <><div className={`font-semibold text-right pr-2 border-r-2 ${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:hover:bg-slate-200`}>{title}</div><div className={`${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:bg-slate-200`}>{value}</div></>
)

const keysToTitle: Record<string, string> = {
    'address': 'Address',
    'companyUuid': 'Company',
    'ratedPower_kW': 'Rated Power kW', 'voltage_kV': 'Voltage kV', 'ratedSpeedRPM': 'Rated Speed RPM', 'kva': 'kVA',
    'siteUuid': 'Site',
    addressZIP: 'Zip Code', logoURL: 'Logo', 'taxReferenceID': 'Tax Ref'
};

export const SubSiteDetails: React.FunctionComponent<ISubSiteProps> = ({ uuid, detailsOnly }) => {
    const dispatch = useDispatch();
    const { token, companies, sites, subSites } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (token && subSites.length === 0) {
            setLoading(true);
            // currently getting _all_ subsites, not just the one we want           
            getSubSites(token).then((res) => {
                if (res instanceof Array) {
                    dispatch(setSubSites(res));
                }
                setLoading(false);
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });

        }
    }, [token, subSites, dispatch]);

    const subSite = subSites.find((s) => s.uuid === uuid);

    return (
        <div className={`overflow-y-auto  ${detailsOnly ? 'h-[92%]' : 'mt-16'}`}>
            {loading ? <Busy /> :
                <>{
                    subSite ?
                        detailsOnly ? <SubSiteBlock subSite={subSite} sites={sites} companies={companies} /> :
                            <div className='bg-white border border-gray-200 py-4 px-5'>
                                <h2 className='mb-0'>
                                    <span className='relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none focus:outline-none'>SubSite</span>
                                </h2>
                                <div className='px-5 overflow-y-auto max-h-[80vh]'>
                                    <SubSiteBlock subSite={subSite} sites={sites} companies={companies} />
                                </div>
                            </div>
                        : <div>No SubSite found</div>
                }
                </>
            }
        </div>
    )
}