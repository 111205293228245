import { createContext } from 'react';

import { TEntity } from './FormTemplate';

export interface IFormContext {
    wipEntity: TEntity;
    setWipEntity: (value: TEntity) => void;
    confirmOverwrite: boolean;
    setConfirmOverwrite: (value: boolean) => void;
    confirmEndEdit: boolean;
    setConfirmEndEdit: (value: boolean) => void;
    confirmDelete: boolean;
    setConfirmDelete: (value: boolean) => void;
    confirmClear: boolean;
    setConfirmClear: (value: boolean) => void;
}

export const FormContext = createContext<IFormContext>({ wipEntity: [], setWipEntity: () => { }, confirmOverwrite: false, setConfirmOverwrite: () => { }, confirmEndEdit: false, setConfirmEndEdit: () => { }, confirmDelete: false, setConfirmDelete: () => { }, confirmClear: false, setConfirmClear: () => { } });
