import { getApiEndpoint, fetchWithAuthRedirect } from "./api";

import {
    TEntityType, TDocumentDescriptor, TUploadParameters,
    TChunkedUploadId, TChunkedUploadResult, TChunkedUploadCompletionResult,
    TChunkMergePayload, TStorageDetails
} from "../store/Interfaces";

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;

export type TFileType = 'Image' | 'Doc' | 'Zip';
export type TUploadType = 'image' | 'doc' | 'zip';

// currently a DUP of the function in 'api.ts'
export const getEntityFileList = async (token: string, uuid: string, entityType: TEntityType, fileType: TFileType, abortController: AbortController): Promise<Array<TDocumentDescriptor>> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/${fileType}List`;
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            signal: abortController.signal,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the ${entityType} ${fileType} list [${resp.status}]`));
            }
        }).then(json => {
            resolve(json as Array<TDocumentDescriptor>);
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}

export const getUploadParameters = async (token: string): Promise<TUploadParameters> => {
    const url = `${getApiEndpoint()}/Storage/UploadParams`;
    const p = new Promise<TUploadParameters>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting file upload parameters [${resp.status}]`));
            }
        }).then(json => {
            resolve(json as TUploadParameters);
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error getting upload parameters');
        });
    })
    return p;
}

export const getEntityStorage = async (token: string, uuid: string, entityType: TEntityType): Promise<TStorageDetails> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/StorageList`;
    const p = new Promise<TStorageDetails>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'GET',

            headers: {
                Authorization: `Bearer ${token}`,
            },

        }).then(async (response) => {
            if (response.ok) {
                const j = await response.json();
                resolve(j as TStorageDetails);
            }
            reject('Error getting storage details');
        }).catch((error) => {
            dispatch(addError(error.message));
            console.error(error);
            reject('Error getting storage details');
        });
    });
    return p;

}

export const uploadFile = async (token: string, uuid: string, file: File, entityType: TEntityType, fileType: TFileType, abortController: AbortController): Promise<boolean> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/${fileType}Upload`;

    const p = new Promise<boolean>((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('storagepath', file.name);
        //      formData.append('storagepath', `${entityType}`);

        fetchWithAuthRedirect(url, {
            method: 'POST',
            signal: abortController.signal,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData

        }).then((response) => {
            if (response.ok) {
                resolve(true);
            } else {
                console.error('Error uploading file');
                resolve(false);
            }
        }).catch((error) => {
            dispatch(addError(error.message));
            console.error(error);
            resolve(false);
        });
    });
    return p;
}

export const getChunkUploadID = (token: string, entityType: TEntityType, uuid: string, mediaType: string, storagePath: string, size: number): Promise<TChunkedUploadId> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/ChunkedUploadCreate`;
    const p = new Promise<TChunkedUploadId>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                path: storagePath,
                totalSizeBytes: size,
                storageDirectory: mediaType,
            })
        }).then((response) => {
            if (response.ok) {
                response.json().then((json) => {
                    resolve(json as TChunkedUploadId);
                });
            }
            else {
                console.error('Error getting chunk upload ID');
                reject(false)
            }
        }).catch((error) => {
            dispatch(addError(error.message));
            console.error(error);
            reject(false);
        });
    });
    return p;
}

export const chunkUpload = (token: string, uuid: string, rangeStart: number, rangeEnd: number, file: File, chunkIndex: number): Promise<TChunkedUploadResult> => {
    const chunk = file.slice(rangeStart, rangeEnd);

    const url = `${getApiEndpoint()}/ChunkedUpload/${uuid}/chunk/${chunkIndex}`;

    const formData = new FormData();
    formData.append('file', chunk, file.name);

    const p = new Promise<TChunkedUploadResult>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                //            'Content-Type': 'multipart/form-data',
                'Content-Range': `bytes ${rangeStart}-${rangeEnd - 1}/${file.size}`,
            },
            body: formData
        }).then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    resolve(json as TChunkedUploadResult);
                })
            } else {
                console.error('Error uploading chunk');
                reject(false);
            }
        }).catch(error => {
            dispatch(addError(error.message));
            console.error(error);
            reject(false);
        });
    })
    return p;
}

export const completeChunkUpload = (token: string, uuid: string, mergeDetails: TChunkMergePayload): Promise<TChunkedUploadCompletionResult> => {
    const url = `${getApiEndpoint()}/ChunkedUpload/${uuid}/Finish`;
    const p = new Promise<TChunkedUploadCompletionResult>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(mergeDetails)
        }).then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    resolve(json as TChunkedUploadCompletionResult);
                })
            } else {
                console.error('Error completing chunk upload');
                reject(false);
            }
        }).catch(error => {
            dispatch(addError('Error completing chunk upload'));
            console.error(error);
            reject(false);
        });
    })
    return p;
}

/*
export const uploadChunk = (token: string, file: File, uuid: string, entityType: TEntityType, fileType: TFileType, start: number, chunkIndex: number, chunks: number, end: number, abortController: AbortController): Promise<boolean> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/${fileType}Upload`;

    const p = new Promise<boolean>((resolve, reject) => {
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append('file', chunk, `${file.name}.part${chunkIndex}`);
        formData.append('chunks', String(chunks));
        formData.append('chunkIndex', String(chunkIndex));

        fetchWithAuthRedirect(url, {
            method: 'POST',
            signal: abortController.signal,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        }).then((response) => {
            if (response.ok) {
                resolve(true);
            }
            else {
                console.error('Error uploading file');
                reject(false);
            }
        }).catch((error) => {
            console.error(error);
            reject(false);
        });
    });
    return p;
}
*/
export const downloadFile = async (token: string, uuid: string, entityType: TEntityType, fileType: TFileType, fileName: string, abortController: AbortController): Promise<number> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/${fileType}/${fileName}`;
    const p = new Promise<number>((resolve, reject) => {
        const pResult = new Promise<number>(resolveResult => {
            const p = new Promise<Blob>((resolve, reject) => {
                fetchWithAuthRedirect(url, {
                    signal: abortController.signal,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                    .then(resp => {
                        if (resp.ok) {
                            const fn = resp.headers.get('content-disposition');
                            if (fn) {
                                fileName = fn.split('filename=')[1].split(';')[0];
                                fileName = fileName.substring(1, fileName.length - 1);
                            }
                            resolve(resp.blob());
                        } else {
                            reject(resp.status);
                        }
                    }).catch(e => {
                        reject(500);
                    })
            });
            p.then(blob => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode?.removeChild(link);
                resolveResult(200);
            }).catch((e: number) => {
                resolveResult(e);
            })
        })
        pResult.then(result => {
            if (result) {
                resolve(result);
            } else {
                resolve(500);
            }
        });
    });
    return p;
}

/*
Upload
Entity/$uuid/ImageUpload

Entity/$uuid/DocUpload

Entity/$uuid/ZipUpload
Expects Request body as formdata – file=the file to be uploaded, path=the storage path to be uploaded to [ sufficient to be the filename but can upload to a subdir ie dir/doc.pdf ]
Response is the ListItem of the uploaded file


List
Entity/$uuid/ImageList

Entity/$uuid/DocList

Entity/$uuid/ZipList
Responds with the listing of the requested directory

Download
Entity/$uuid/Image/$imageName

Entity/$uuid/Doc/$docName

Entity/$uuid/Zip/$ZipName
*/

