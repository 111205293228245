import { TEntityType, TImageDescriptorList } from "../store/Interfaces";

import { getApiEndpoint, sanitise, tidyCache, fetchWithAuthRedirect } from "./api";

import { store } from "../store/store";
import { addError } from "../store/mainSlice";

const { dispatch } = store;

export const getEntityImageList = (
    token: string,
    entityType: TEntityType,
    uuid: string,
    abortController: AbortController,
): Promise<TImageDescriptorList> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/ImageList`;
    const p = new Promise<TImageDescriptorList>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            signal: abortController.signal,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error("Error fetching image descriptor list");
                }
            })
            .then((json) => {
                resolve(sanitise(json));
            })
            .catch((e) => {
                if (e.name !== "AbortError") {
                    dispatch(addError(e.message));
                }
                reject(e.message);
            });
    });
    return p;
};

export const getEntityImage = (
    token: string,
    entityType: TEntityType,
    uuid: string,
    name: string,
    seconds: number,
    abortController: AbortController,
) => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/${name}`;

    const p = new Promise<Blob>(async (resolve, reject) => {
        try {
            const cache = await caches.open("Images");
            // this section deletes cache entries > 1h old
            await tidyCache(cache, 3600 * 1000);

            // -- end of cache tidying
            cache.match(url).then((resp) => {
                if (resp !== undefined) {
                    resp.blob().then((imageBlob) => {
                        if (imageBlob) {
                            resolve(imageBlob);
                        } else {
                            dispatch(addError(`Error converting image from cache`));
                        }
                    });
                } else {
                    fetchWithAuthRedirect(url, {
                        cache: "no-cache",
                        signal: abortController.signal,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                        .then((resp) => {
                            if (resp.ok && resp.status === 200) {
                                const cloneResp = resp.clone();
                                try {
                                    cache.put(url, resp);
                                } catch (e) {
                                    console.log("Error caching image");
                                }
                                return cloneResp.blob();
                            } else {
                                reject("Internal server fetching image");
                            }
                        })
                        .then((imageBlob) => {
                            if (imageBlob) {
                                resolve(imageBlob);
                            } else {
                                dispatch(addError(`Error converting image ${url}`));
                                reject("Error converting image");
                            }
                        })
                        .catch((e) => {
                            if (e.name !== "AbortError") {
                                dispatch(addError(e.message));
                            }
                            reject(e);
                        });
                }
            });
        } catch (e) {
            reject("Error fetching image");
        }
    });
    return p;
};
