import React, { useEffect, useState } from "react";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../hooks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { getEntityNames } from "../../../api";
import { addEntityDisplayNames } from "../../../store/mainSlice";
import { TEntityDisplayName } from "../../../store/Interfaces";
export interface ISubSiteNameProps {
    uuids: string[];
}

export const SubSiteName: React.FunctionComponent<ISubSiteNameProps> = ({ uuids }) => {
    const dispatch = useDispatch();
    const { token, entityDisplayNames } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);
    const [unknownUUIDs, setUnknownUUIDs] = useState<string[]>([]);
    const [ac, setAc] = useState(new AbortController());

    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        setLoading(false);
        return () => {
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        setUnknownUUIDs(uuids.filter(uuid => entityDisplayNames.SubSite.find(p => p.uuid === uuid) === undefined));
    }, [uuids, entityDisplayNames]);

    useEffect(() => {
        if (token && ac && uuids.length > 0 && unknownUUIDs.length > 0 && !loading) {
            setLoading(true);
            getEntityNames(token, ac, unknownUUIDs, 'SubSite').then((res) => {
                if (res instanceof Object) {
                    const names: Array<TEntityDisplayName> = Object.entries(res).map(([uuid, name]) => ({ uuid, name }));
                    dispatch(addEntityDisplayNames({ entityType: 'SubSite', names }));
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
        }
    }, [token, uuids, ac, dispatch, unknownUUIDs, loading]);

    return (
        <>{uuids.some(uuid => entityDisplayNames.SubSite.find(p => p.uuid === uuid) === undefined) ?
            loading ? <FontAwesomeIcon className='fa-spin text-hvpd-grey-400' icon={solid('spinner')} /> : <FontAwesomeIcon className=' text-hvpd-grey-400' icon={solid('exclamation')} /> :
            uuids.map(uuid => entityDisplayNames.SubSite.find(p => p.uuid === uuid)).filter(p => p !== undefined).map((p => <span key={p?.uuid} className="mr-1">{p?.name} </span>))}
        </>
    );
};
