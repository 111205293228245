import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import CopyToClipboard from "react-copy-to-clipboard";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../hooks';
import {
    addToast,
    setTableDefinitionComplete,
} from "../../../store/mainSlice";
import {
    IColumnHeader, TResultRow, ISortColumn, TReportEntityGroup,
    TTableResultEndpoint, ITableDefinitionResult, ILatLng, TSlideOver
} from "../../../store/Interfaces";
import { getTableDefinition, getApiEndpoint } from "../../../api";
import { ResultsTableHeader } from "../ResultsTableHeader";
import { ResultsTableRow } from "../ResultsTableRow";
import { ContextMenu } from "../../ContextMenu";
import { getFilteredSortedResults, jsonToCsv, downloadCSV } from "../utils";
import { TBounds } from "../../../Grid/Grid";

export interface ISiteTableProps {
    tableEndPoint: TTableResultEndpoint | undefined;
    bounds: TBounds | null;
    boundsFiltered: 'map' | 'sld' | '';
    allowedAssets: string[];
    onClickRow: (e: React.MouseEvent, row?: TResultRow) => void;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    resultFilterText: string;
    setHighlightLatLng: (ll: ILatLng | undefined) => void;
    setSlideOver: (slideOver: TSlideOver) => void;
    selectedEntity: TReportEntityGroup | undefined;
    refresh: number;
}

const TABLENAME = 'TableSite';

export const SiteTable: React.FunctionComponent<ISiteTableProps> = ({ onClickTableColumn, bounds, boundsFiltered, onClickRow, resultFilterText, sortColumn, setHighlightLatLng, selectedEntity }) => {
    const dispatch = useDispatch();
    const { token, tableDefinitionsComplete } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);
    const [menuShown, setMenuShown] = useState(false);
    const [contextPos, setContextPos] = useState({ x: 0, y: 0 });
    const [contextRow, setContextRow] = useState<TResultRow | undefined>(undefined);
    const [expandedHeaders, setExpandedHeaders] = useState<Record<string, boolean>>({});

    const tableResults: ITableDefinitionResult = tableDefinitionsComplete[TABLENAME] ? tableDefinitionsComplete[TABLENAME] : { columnHeaders: [], rows: [], searchParams: [] };

    useEffect(() => {
        const myAbortController = new AbortController();

        const getTable = async () => {
            setLoading(true);
            getTableDefinition(token, { name: TABLENAME, endpoint: `${getApiEndpoint()}/${TABLENAME}` }, [], true, myAbortController).then((res) => {
                dispatch(setTableDefinitionComplete([TABLENAME, res]));
                setLoading(false);
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });
        };
        if (token && !tableDefinitionsComplete[TABLENAME]) {
            getTable();
        }
        return () => {
            myAbortController.abort();
        }
    }, [token, tableDefinitionsComplete, dispatch]);


    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] })
    }

    const handleContextMenu = (e: MouseEvent, row: TResultRow) => {
        e.preventDefault();
        setContextPos({ x: e.pageX, y: e.pageY });
        setContextRow(row);
        setMenuShown(true);
    };

    const onExport = (selection: 'all' | 'selected') => {
        setMenuShown(false);
        let filteredSortedResults: TResultRow[] = (undefined === tableResults.rows || 0 === tableResults.rows.length) ? [] :
            getFilteredSortedResults(tableResults.rows, bounds, boundsFiltered, resultFilterText, [], sortColumn, tableResults.columnHeaders);

        let columns: string[][] = [];
        columns.push(tableResults.columnHeaders.map(ch => ch.title));
        columns = columns.concat(filteredSortedResults.map(row =>
            tableResults.columnHeaders.map((ch, idx) => {
                if (row[ch.propDisplay] === undefined) {
                    console.log(`Site table - Missing ${ch.propDisplay} in row ${idx}`)
                }
                return (row[ch.propDisplay] ?? '').toString();
            })
        ));
        const csvString = jsonToCsv(columns);
        downloadCSV(csvString, `Site export - ${new Date().toLocaleString()}.csv`);
    }

    const onCopyToClipboard = () => {
        setMenuShown(false);
        dispatch(addToast(`Site GUID ${contextRow?.uuid.toString() ?? 'Error'} copied to clipboard`));
    }

    const filteredSortedResults: TResultRow[] = (undefined === tableResults || 0 === tableResults.rows.length) ? [] :
        getFilteredSortedResults(tableResults.rows, bounds, boundsFiltered, resultFilterText, [], sortColumn, tableResults.columnHeaders);

    return <>
        {loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> :
            <div className='resultsTableClipper relative overflow-y-auto bg-hvpd-grey-50'>
                {filteredSortedResults.length > 0 ? <table className='table-auto min-w-full border-slate-400 border' >
                    <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                        <tr>
                            {tableResults.columnHeaders.map(colHeader => (
                                <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} expandedHeaders={expandedHeaders} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                            ))}
                        </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                        {filteredSortedResults.map((resultRow, idx) => (
                            <ResultsTableRow key={`row-${idx}`} columnHeaders={tableResults.columnHeaders} row={resultRow} handleContextMenu={handleContextMenu} onClickRow={onClickRow} expandedHeaders={expandedHeaders} setHighlightLatLng={setHighlightLatLng} selected={selectedEntity?.type === 'sites' && selectedEntity?.uuid === resultRow.uuid} />
                        ))}
                    </tbody>
                </table> :
                    <div className='w-full flex mt-20'>
                        <div className='flex-grow text-center'><span>No results to display</span>
                            <button onClick={onClickRow} className='ms-2 flex-grow h-7 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md'>Show panel</button>
                        </div>
                    </div>}
            </div>
        }
        <ContextMenu pos={contextPos} menuShown={menuShown} closeMenu={() => setMenuShown(false)} >
            <li className='w-full'><button onClick={() => onExport('all')} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>Export to CSV</button></li>
            <li className='w-full'><CopyToClipboard text={contextRow?.uuid.toString() ?? 'Error'} ><button onClick={() => onCopyToClipboard()} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>Copy site GUID to clipboard</button></CopyToClipboard></li>
        </ContextMenu >
    </>
}