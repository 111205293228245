import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import {
    faCircle1
} from "@fortawesome/pro-duotone-svg-icons";
import { Popover, Tooltip, initTE } from "tw-elements";

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../hooks';
import { TextFilter } from './TextFilter';
import {
    TTestType,
} from '../../store/Interfaces';

import {
    setAssetsForReporting,
    setResultFilterText,
    setResultFilter,
    setReportsDisabled,
    setMapPanelExpanded,
    setBoundsFiltered,
    setSlideOver,
    setIncludeNoTests,
    setFilterTemplate,
} from "../../store/mainSlice";


const FILTER_DEBOUNCE_TIMEOUT = 300;

const popOverTemplate = `<div class="opacity-0 transition-opacity duration-150 ease-in-out absolute top-0 left-0 z-[1070] block max-w-[267px] break-words bg-white bg-clip-padding border border-neutral-100 rounded-lg shadow-2 text-sm not-italic font-normal text-left no-underline underline-offset-auto normal-case leading-6 tracking-normal break-normal whitespace-normal dark:border-white/10 dark:bg-surface-dark dark:text-white data-[popper-reference-hidden]:hidden" role="tooltip">` +
    `<div><h3 data-twe-popover-header-ref class="py-2 px-4 mb-0 border-b-2 border-neutral-100 rounded-t-lg font-medium empty:hidden dark:border-white/10"></h3><input type='checkbox'/></div>` +
    `<div data-twe-popover-body-ref class="p-4 text-surface dark:text-white"></div> </div> `;


export interface IControlPanelProps {
    bumpRefresh: () => void;
    quickFilterColours: string;
    filterColours: string;
}

interface IExtendedFilterButtonProps {
    filterColours: string;
    showText: boolean;
    size?: 'xs';
}

const ExtendedFilterButton: React.FC<IExtendedFilterButtonProps> = ({ filterColours, showText, size }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { extendedFilter, selectedAssets,
        viewBundle: { selectedResultRow, slideOver
        } } = useSelector(store => store.main);

    useEffect(() => {
        if (!selectedResultRow && selectedAssets.length === 0) {
            dispatch(setSlideOver(''));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAssets, selectedResultRow]);

    const toggleFilterSildeOver = () => {
        if (slideOver === 'filters') {
            dispatch(setSlideOver(''));
        }
        else if (selectedResultRow) {
            dispatch(setSlideOver('filters'));
            dispatch(setFilterTemplate(selectedResultRow));
        }
    }

    return (<button type='button' title={t('Extended Filters')} onClick={toggleFilterSildeOver}
        disabled={extendedFilter.length === 0 && (selectedAssets.length === 0 || !selectedResultRow)}
        className={`text-white disabled:text-hvpd-grey-200 ${filterColours} ${size === 'xs' ? 'text-xs py-0' : 'text-sm py-1'} px-2 border-solid border-1 font-medium`}><FontAwesomeIcon icon={solid('sliders')} />
        {showText ? <span className='ml-1 hidden 2xl:inline'>{t('Filters')}</span> : null}
    </button>);
}


const MapPanelExpanderButton: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { mapPanelExpanded } = useSelector(store => store.main.viewBundle);

    const toggleMapPanelExpanded = () => dispatch(setMapPanelExpanded(!mapPanelExpanded));

    return <button type='button' title={t('Expand / contract')} onClick={toggleMapPanelExpanded}
        className='text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 border-solid border-1 text-sm px-2 py-0'><FontAwesomeIcon icon={mapPanelExpanded ? solid('caret-right') : solid('caret-left')} /></button>
}

const FilterText: React.FunctionComponent<{ boundsFiltered: boolean, mapView: string }> = ({ boundsFiltered, mapView }) => {
    const { t } = useTranslation();
    let s = ''

    if (mapView === 'map') {
        if (boundsFiltered) {
            s = t('Showing all filtered by map bounds');
        } else {
            s = t('Showing all unfiltered by map bounds');
        }

    } else {
        if (boundsFiltered) {
            s = t('Showing all filtered by connection');
        } else {
            s = t('Showing all unfiltered by connection');
        }
    }
    return <>{s}</>;
}


export const ControlPanel: React.FunctionComponent<IControlPanelProps> = ({ bumpRefresh, quickFilterColours,
    filterColours,
}) => {
    // companies and currentUser are only used to extract the current user's company logo
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [popover, setPopover] = useState<typeof Popover>(null); // [1
    const { assetsForReporting, resultFilter, tableEndPoints, selectedAssets, tableResults,
        viewBundle: { selectedResultTable,
            mapView,
            mapPanelExpanded,
            resultFilterText,
            reportsDisabled,
            boundsFiltered, includeNoTests, slideOver } } = useSelector(store => store.main);

    const [localResultFilterText, setLocalResultFilterText] = useState(resultFilterText);

    const selectReportType: React.ChangeEventHandler<HTMLSelectElement> = e => dispatch(setResultFilter(tableEndPoints.find(te => te.name === e.target.value)));

    useEffect(() => {
        initTE({ Popover, Tooltip });

        return () => {
            if (popover) {
                popover.dispose();
            }
        }
    }, []);

    useEffect(() => {
        const localTimeout = setTimeout(() => {
            dispatch(setResultFilterText(localResultFilterText));
        }, FILTER_DEBOUNCE_TIMEOUT);
        return () => {
            clearTimeout(localTimeout);
        }
    }, [localResultFilterText, dispatch]);


    const toggleBoundsFilter = () => dispatch(setBoundsFiltered(!boundsFiltered));
    const toggleIncludeNoTests = () => dispatch(setIncludeNoTests(!includeNoTests));

    const disableReports = () => {
        dispatch(setReportsDisabled(!reportsDisabled));
        if (!reportsDisabled && slideOver === 'report') {
            dispatch(setSlideOver(''));
        }
        else if (reportsDisabled && selectedAssets.length > 0) {
            dispatch(setSlideOver('report'));
        }
    }

    const hasMapFilter = !['one-report', 'users'].includes(selectedResultTable);

    const markSelectedForReporting = () => {
        if (assetsForReporting.length > 0 && selectedAssets.length === 0) {
            dispatch(setAssetsForReporting([]));
        } else if (selectedAssets.length > 0) {
            const results = tableResults[resultFilter?.name as TTestType]?.rows ?? { rows: [] };
            let newAssetsForReporting = results.filter(row => selectedAssets.includes(row.assetUuid)).map(r => ({ assetUuid: r!.assetUuid, resultUuid: (r!.resultUuid ?? '').toString() }));
            let assetsForUnReporting = assetsForReporting.filter(row => selectedAssets.includes(row.assetUuid));
            newAssetsForReporting = newAssetsForReporting.filter(na => assetsForReporting.every(ar => ar.assetUuid !== na.assetUuid));
            const filteredAssetsForReporting = assetsForReporting.filter(na => assetsForUnReporting.every(ar => ar.assetUuid !== na.assetUuid));
            dispatch(setAssetsForReporting([...newAssetsForReporting, ...filteredAssetsForReporting]));
        }
    }


    return (<div className={`sticky top-0 bg-colour-header ${hasMapFilter ? 'grid grid-rows-[32px_32px]' : ''} `}>
        <div className="block w-full whitespace-nowrap">
            {tableEndPoints.length === 0 ?
                <div className='inline-block'><span className='mr-2 font-bold' >{t('Loading...')}</span><FontAwesomeIcon className='fa-spin text-hvpd-red-400' icon={solid('spinner')} /></div> :
                <div className='my-1 pl-1'>
                    {selectedResultTable === 'results' ?
                        <><label htmlFor='selectReportType' className='font-semibold mr-2 hidden xl:inline'>{t('Results filter')}</label>
                            <select id='selectReportType' name='selectReportType' className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border-1' value={resultFilter?.name} onChange={selectReportType}>
                                {!resultFilter ? <option>{tableEndPoints.length === 0 ? t('Loading...') : t('Please select...')}</option> : null}
                                {tableEndPoints.map(endPoint => <option key={endPoint.name} value={endPoint.name}>{endPoint.name}</option>)}
                            </select></> : null}
                    <button type='button' title={t('Refresh')} onClick={bumpRefresh} className='text-white disabled:text-hvpd-grey-700 bg-hvpd-jungle-green-500 border-hvpd-jungle-green-200/40 hover:bg-hvpd-jungle-green-600 px-2 py-1 border-solid border-1 border-l-0 text-sm font-medium'><FontAwesomeIcon icon={solid('rotate-right')} /></button>

                    <div className='inline-block ml-1'>
                        <button type='button' className='text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium' onClick={disableReports}>
                            {reportsDisabled ? t('Enable') : t('Disable')} <span className='hidden xl:inline'>{t('report pane')}</span>
                        </button>
                    </div>
                    {selectedResultTable === 'results' ?
                        <div className='inline-block ml-1'>
                            <button type='button' title={t('Add selected to One Report')} className='text - white disabled:text-hvpd-grey-200 bg-hvpd-jungle-green-500 border-hvpd-jungle-green-200/40 hover:bg-hvpd-jungle-green-600 px-2 py-1 border-solid border-1 border-l-0 text-sm font-medium' onClick={markSelectedForReporting}>
                                <FontAwesomeIcon icon={faCircle1} />
                            </button>
                        </div> : null}


                    {selectedResultTable === 'users' ? <div className='inline-block float-right mr-2'>
                        <MapPanelExpanderButton />
                    </div> : null}
                    <div className='inline-block float-right mr-2'>
                        <TextFilter quickFilterColours={quickFilterColours} filterText={localResultFilterText} setFilterText={setLocalResultFilterText} />

                    </div>
                    {selectedResultTable === 'results' && !mapPanelExpanded ?
                        <div className='inline-block float-right mr-2'>
                            <ExtendedFilterButton filterColours={filterColours} showText={!mapPanelExpanded} />
                        </div> : null}
                </div>
            }
        </div>
        {
            hasMapFilter ? <div className="w-100 grid grid-cols-[1fr_90px]">
                <div className="filter-map form-check form-switch mx-2">
                    <input defaultChecked={boundsFiltered} type="checkbox" role="switch" value={boundsFiltered ? 'true' : 'false'} onChange={toggleBoundsFilter}
                        className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-hvpd-pickled-bluewood-200 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:hvpd-pickled-bluewood-300 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_3px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:hvpd-pickled-bluewood-500 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-pickled-bluewood-400 dark:checked:after:bg-pickled-bluewood-400 dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]" id="flexSwitchCheckDefault" />
                    <label className="form-check-label inline-block text-gray-800" htmlFor="flexSwitchCheckDefault"><FilterText boundsFiltered={boundsFiltered} mapView={mapView} /></label>
                    {selectedResultTable === 'results' ? <span className='ms-1'><input defaultChecked={!includeNoTests} type="checkbox" role="switch" value={includeNoTests ? 'true' : 'false'} onChange={toggleIncludeNoTests}
                        className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-hvpd-pickled-bluewood-200 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:hvpd-pickled-bluewood-300 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_3px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:hvpd-pickled-bluewood-500 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-pickled-bluewood-400 dark:checked:after:bg-pickled-bluewood-400 dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]" id="hideZeroTests" />
                        <label className="form-check-label inline-block text-gray-800" htmlFor="hideZeroTests">{includeNoTests ? t('Showing results with no tests') : t('Hiding results with no tests')}</label></span> : null}

                </div>
                <div className='inline-block'>
                    <div className='float-right m-1 mr-2'>
                        {selectedResultTable === 'results' && mapPanelExpanded ?
                            <div className='inline-block mr-1'><ExtendedFilterButton size='xs' filterColours={filterColours} showText={!mapPanelExpanded} /></div>
                            : null}
                        <div className='inline-block'><MapPanelExpanderButton /></div>
                    </div>
                </div>

            </div> : null
        }
    </div >)
}
