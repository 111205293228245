import {
    TEntityType, TDocumentDescriptor
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;

export const getEntityDocuments = async (token: string, uuid: string, entityType: TEntityType): Promise<Array<TDocumentDescriptor>> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/DocList`;
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error('Error fetching asset results'));
            }
        }).then(json => {
            resolve(sanitise(json));
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.message);
        });
    });
    return p;
}

export const getEntityDocument = async (token: string, uuid: string, fileName: string, entityType: TEntityType, abortController: AbortController, etagIn: string): Promise<{ report: Uint8Array, etag: string }> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/Doc/${fileName}`;
    let etag = '';
    const headers: HeadersInit = {
        Authorization: `Bearer ${token}`,
    };
    if (etagIn) {
        headers['If-None-Match'] = etagIn;
    }
    const p = new Promise<{ report: Uint8Array, etag: string }>(async (resolve, reject) => {
        const cache = await caches.open('Documents');
        let promise = cache.match(url);

        promise.then(async resp => {
            if (resp === undefined) {
                resp = await fetchWithAuthRedirect(url, {
                    cache: "no-cache",
                    headers,
                    signal: abortController.signal
                })
            }
            if (resp.ok) {
                const cloneResp = resp.clone();
                try {
                    cache.put(url, resp);
                } catch (e) {
                    console.log('Error caching document')
                }
                etag = cloneResp.headers.get('etag') || '';
                return cloneResp.arrayBuffer();
            } else if (resp.status === 304) {
                etag = resp.headers.get('etag') || '';
                resolve({ report: new Uint8Array([]), etag })
            } else {
                throw (new Error('Error in report download'));
            }
        }).then(text => {
            if (text) {
                resolve({ report: new Uint8Array(text), etag });
            } else {
                reject('No data');
            }
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.message)
        });
    });
    return p;
}

export const downloadEntityDocument = (token: string, uuid: string, fileName: string, entityType: TEntityType, abortController: AbortController): Promise<Boolean> => {
    const url = `${getApiEndpoint()}/${entityType}/${uuid}/Doc/${fileName}`;
    const pResult = new Promise<Boolean>(resolveResult => {
        const p = new Promise<Blob>((resolve, reject) => {
            fetchWithAuthRedirect(url, {
                cache: "no-cache",
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        resolve(resp.blob());
                    } else {
                        reject();
                    }
                }).catch(e => {
                    dispatch(addError(e.message));
                    reject();
                })
        });
        p.then(blob => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            //const siteName = sites.find(site => site.uuid === asset?.siteUuid)?.name ?? '';
            //const companyName = companies.find(company => company.uuid === asset?.companyUuid)?.name ?? '';
            const extension = (blob.type === 'application/zip') ?
                'zip'
                : 'pdf'
            //const fileName = asset ? `${companyName} - ${siteName} - ${asset?.name} - ${d}.${extension}` : `Report.${extension}`;
            link.setAttribute(
                'download',
                fileName + '.' + extension,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
            resolveResult(true);
        }).catch(e => {
            dispatch(addError(e.message));
            resolveResult(false);
            //  setReportError('Failed to collect report for this item');
        })
    })
    return pResult;
}

