/*import {
    TTestType
} from '../store/Interfaces'; */

import { getApiEndpoint, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;

export const getOneReport = async (token: string, testResultType: string, assetUuids: Array<string>, attach: boolean): Promise<{ report: Uint8Array, etag: string }> => {
    const url = `${getApiEndpoint()}/CustomReport/AssetHistory${attach ? '?attach=true' : ''}`;
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            cache: "no-cache",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                testResultType, assetUuids
            })
        }).then(async resp => {
            if (resp.ok) {
                const text = await resp.arrayBuffer();
                return { text, etag: resp.headers.get('etag') };
            } else {
                throw (new Error(`Error genering report [${resp.status}]`));
            }
        }).then(({ text, etag }) => {
            resolve({ report: new Uint8Array(text), etag });
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}


export const downloadOneReport = async (token: string, testResultType: string, assetUuids: Array<string>, fileName: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/CustomReport/AssetHistory?attach=true`;
    const pResult = new Promise<boolean>(resolveResult => {
        const p = new Promise<Blob>((resolve, reject) => {
            fetchWithAuthRedirect(url, {
                method: 'POST',
                cache: "no-cache",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    testResultType, assetUuids
                })
            })
                .then(resp => {
                    if (resp.ok) {
                        //                      etag = resp.headers.get('etag') ?? '';
                        resolve(resp.blob());
                    } else {
                        reject();
                    }
                }).catch(e => {
                    dispatch(addError(e.message));
                    reject();
                })
        });
        p.then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            //const siteName = sites.find(site => site.uuid === asset?.siteUuid)?.name ?? '';
            //const companyName = companies.find(company => company.uuid === asset?.companyUuid)?.name ?? '';
            const extension = (blob.type === 'application/zip') ?
                'zip'
                : 'pdf'
            //const fileName = asset ? `${companyName} - ${siteName} - ${asset?.name} - ${d}.${extension}` : `Report.${extension}`;
            link.setAttribute(
                'download',
                fileName + '.' + extension,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
            resolveResult(true);
        }).catch(e => {
            resolveResult(false);
            //  setReportError('Failed to collect report for this item');
        })
    })
    return pResult;
}