import React from "react";
import { LoginButton } from '../LoginButton';

export const LoginCard: React.FunctionComponent = () => (
    <div className="bg-center bg-no-repeat bg-auto w-screen h-screen" style={{ backgroundImage: 'url("./login-background.svg")', backgroundColor: '#060606' }}>
        <div className="fixed bottom-[18vh] left-[40vw]">
            <LoginButton />
        </div>
    </div>
);

