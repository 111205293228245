import {
    ISite
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;

export const getSites = async (token: string): Promise<Array<ISite> | string> => {
    const url = `${getApiEndpoint()}/Site`;
    const p = new Promise<ISite[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`Error getting the site list [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(sanitise(json) as Array<ISite>);
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject("Error fetching...");
            });
    });
    return p;
};

export const createSite = async (token: string, site: ISite): Promise<ISite> => {
    const url = `${getApiEndpoint()}/Site`;
    const p = new Promise<ISite>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...site, uuid: undefined })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error creating site [${resp.status}]`));
            }
        }).then(json => {
            const siteReturned: ISite = json as ISite;
            resolve(sanitise(siteReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error creating');
        });
    })
    return p;
}

export const updateSite = async (token: string, site: ISite): Promise<ISite> => {
    const url = `${getApiEndpoint()}/Site`;
    const p = new Promise<ISite>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(site)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error updating site [${resp.status}]`));
            }
        }).then(json => {
            const siteReturned: ISite = json as ISite;
            resolve(sanitise(siteReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error updating');
        });
    })
    return p;
}


export const deleteSite = async (token: string, uuid: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Site/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Error deleting site [${resp.status}]`));
            }
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error deleting.');
        });
    })
    return p;
}