import React from 'react';
import { Busy } from "../Busy";

import { TTabId, TTabName } from "../../store/Interfaces";

export type TTab = {
    id: TTabId, name: TTabName, loading: boolean, disabled: boolean
}

export interface ITabsProps {
    tabList: Array<TTab>;
    currentTab: TTabId;
    setCurrentTab: (currentTab: TTabId) => void;
}

export const Tabs: React.FunctionComponent<ITabsProps> = ({ tabList, currentTab, setCurrentTab }) => {
    return (<>
        <div className="inline-flex rounded-lg mt-1 ml-1 bg-gray-100 bg-opacity-30 mx-auto">
            {tabList.map((tab, index) =>
                <button key={tab.id} onClick={() => setCurrentTab(tab.id)}
                    className={`${index === tabList.length - 1 ? 'rounded-tr-lg ' : ''} ${(index === 0) ? 'rounded-tl-lg ' : ''}  ${(tab.id === currentTab) ? 'border-stone-300 bg-white text-hvpd-pickled-bluewood-400 border-b-0' : ' border-stone-200 bg-stone-100 text-neutral-600'} ${tab.disabled ? 'text-neutral-400' : ''} py-[10px] sm:py-2 px-[8px] sm:px-3 inline-flex items-center justify-center font-medium border text-center  hover:text-hvpd-pickled-bluewood-800 hover:underline text-sm sm:text-base capitalize `} >
                    {tab.name}
                    {tab.loading ? <span className='ml-1'><Busy size='xs' /></span> : null}
                </button>
            )}
        </div>
    </>)
}