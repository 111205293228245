import React from "react";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta } from 'react-select';

import { TFilterClass, TFilterFragment, TMatchType, TValueType, typeToMatches } from './FilterGroup';
import type { TSearchTemplate, TValueName } from '../../store/Interfaces';

type OptionType = Record<string, any>
type OptionsType = ReadonlyArray<OptionType>

interface IElementRowProps {
    searchTemplate: TSearchTemplate;
    title: string;
    value: TValueType;
    matches: TMatchType;
    typeToMatches: Record<TValueName, Array<TMatchType>>;
    index: number;
    enableFilter: () => void;
    filterActive: (item: string) => boolean;
    filterEnabled: (item: string) => boolean;
    filterChange: (value: TValueType, match?: TMatchType) => void;
    getSelectOptions: (value: TValueType | undefined) => OptionsType;
}

const ElementRow: React.FunctionComponent<IElementRowProps> = ({ searchTemplate, title, value, matches, index, enableFilter, filterActive, filterEnabled, filterChange, getSelectOptions }) => {
    //   const [useRange, setUseRange] = useState(false);
    // const filterIsNumber = () => ['int', 'long', 'double', 'float'].includes(searchTemplate.type);

    const onSelectChange = (newValue: OptionsType, actionMeta: ActionMeta<OptionType>, matches: TMatchType): void => {
        if (actionMeta.action === "select-option" || actionMeta.action === "remove-value" || actionMeta.action === "clear" || actionMeta.action === "create-option") {
            filterChange(newValue.map(nv => nv.value) as TValueType, matches);
        }
    }

    const localFilterChange = (value: string | [string, string]) => {
        if (Array.isArray(value) && (parseFloat(value[0]) > parseFloat(value[1]))) {
            value[1] = value[0];
        }
        filterChange(value as TValueType);
    }

    const localMatchesChange = (match: TMatchType): void => {
        if (match === 'minmax') {
            if (Array.isArray(value)) {
                filterChange(value, match)
            } else {
                filterChange([value as number, value as number], match);
            }
        } else if (matches === 'minmax') {
            if (Array.isArray(value)) {
                filterChange((value[0] as TValueType), match)
            } else {
                filterChange([value as number, value as number], match);
            }
        } else {
            filterChange(value, match)
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            padding: '0rem',
            minHeight: '1rem',
            width: '94%',

        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            fontSize: '0.875rem',
            lineHeight: '1rem',
            padding: '0.1rem'
        }),
        menuList: (provided: any, state: any) => {
            return { ...provided, fontSize: '0.75rem' }
        },
        dropdownIndicator: (provided: any, state: any) => {
            return { ...provided }
        },
        listBox: (provided: any, state: any) => {
            return { ...provided, backgroundColor: 'red', position: 'absolute' }
        }
    };

    const enumsToSelectOptions = (searchTemplate: TSearchTemplate, value: string[] | undefined): OptionsType => {
        if (searchTemplate.propName === 'assetSubType') {
            return getSelectOptions(value);
        }
        if (Array.isArray(value)) {
            return value.map(v => ({ value: v, label: v.toString() }));
        } else {
            return [];
        }
    }

    const valueToSelectOptions = (value: TValueType): OptionsType => {
        if (searchTemplate.propName === 'assetSubType') {
            return getSelectOptions(value);
        }
        if (Array.isArray(value)) {
            return value.map(v => ({ value: v, label: v.toString() }));
        } else {
            return [];
        }
    }
    const fActive = filterActive(searchTemplate.propName);
    const fEnabled = filterEnabled(searchTemplate.propName) && fActive;

    return <div className={`grid grid-cols-[1fr_6fr_3fr_8fr] ${index & 1 ? ' bg-hvpd-grey-100' : 'bg-hvpd-grey-50'} hover:hover:bg-hvpd-grey-200`} >
        <input type="checkbox" id={`checkbox-${title}-${searchTemplate.propName}`} onChange={enableFilter} checked={fActive} className='w-3 h-3 m-2  text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
        <label htmlFor={`checkbox-${title}-${searchTemplate.propName}`} className={`font-semibold text-right text-sm pr-2 mt-1 border-r-2 `}>{searchTemplate.title}</label>
        <div className="text-left pr-1">
            {typeToMatches[searchTemplate.type].length === 1 ? <span className='w-24 m-2 text-sm'>{searchTemplate.type === 'enum' ? 'is one of' : typeToMatches[searchTemplate.type][0]}</span> :
                <select id={`type-selector-${title}-${searchTemplate.propName}`} className='w-24 p-1 text-sm rounded-sm border-slate-300 border-1' value={matches} onChange={e => localMatchesChange(e.target.value as TMatchType)} disabled={!fEnabled} >
                    {
                        typeToMatches[searchTemplate.type].map(key => <option key={key} value={key}>{key}</option>)
                    }
                </select>}
        </div>
        <div>
            {searchTemplate.type === 'string' ? <>
                {matches === 'contains' ? <input className='w-48 p-1 text-sm rounded-sm border-slate-300 border-1' type='text' id={`input-${title}-${searchTemplate.propName}`} disabled={!fEnabled} value={value.toString()} onChange={e => localFilterChange(e.target.value)} />
                    : <CreatableSelect options={valueToSelectOptions(value)}
                        styles={customStyles}
                        isMulti
                        isDisabled={!fEnabled}
                        id={`createableSelect-${title}-${searchTemplate.propName}`}
                        onChange={(newValue, actionMeta) => onSelectChange(newValue, actionMeta, matches)}
                        value={valueToSelectOptions(value)} />}
            </> : null}
            {searchTemplate.type === 'boolean' ?
                <input type='checkbox' id={`boolean-${title}-${searchTemplate.propName}`} checked={value as boolean} disabled={!fEnabled} onChange={() => filterChange(!value)} className='w-3 h-3 m-2 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
                : null}
            {searchTemplate.type === 'enum' ?
                <><Select
                    options={enumsToSelectOptions(searchTemplate, searchTemplate.enumConstants)}
                    id={`select-${title}-${searchTemplate.propName}`}
                    styles={customStyles}
                    isMulti
                    isDisabled={!fEnabled}
                    onChange={(newValue, actionMeta) => onSelectChange(newValue, actionMeta, matches)}
                    value={valueToSelectOptions(value)} />
                </> : null}
            {searchTemplate.type === 'int' || searchTemplate.type === 'long' || searchTemplate.type === 'double' || searchTemplate.type === 'float' ?
                <div>
                    {matches === 'minmax' ?
                        <div>
                            <input id={`input-min-${title}-${searchTemplate.propName}`} className='inline w-24 p-1 text-sm rounded-sm border-slate-300 border-1' type='number' disabled={!fEnabled} value={(value as [number, number])[0]} onChange={e => localFilterChange([e.target.value, (value as [number, number])[1].toString()])} />
                            <input id={`input-max-${title}-${searchTemplate.propName}`} className='inline w-24 p-1 ml-1 text-sm rounded-sm border-slate-300 border-1' type='number' disabled={!fEnabled} value={(value as [number, number])[1]} onChange={e => localFilterChange([(value as [number, number])[0].toString(), e.target.value])} />
                        </div> :
                        <input className='w-48 p-1 text-sm rounded-sm border-slate-300 border-1' type='number' id={`input-${title}-${searchTemplate.propName}`} disabled={!fEnabled} value={value.toString()} onChange={e => localFilterChange(e.target.value)} />
                    }
                </div>
                : null}
        </div>
    </div >
}

interface IFilterProps {
    title: TFilterClass;
    searchTerms: Array<TFilterFragment>;
    filterFields: Array<TSearchTemplate>;
    setFilterActive: (filterName: string, enabled: boolean) => void;
    filterActive: (item: string) => boolean;
    filterEnabled: (item: string) => boolean;
    typeToMatches: Record<TValueName, Array<TMatchType>>;
    filterChange: (key: string, value: TValueType, match?: TMatchType) => void;
    //    matchesChange: (key: string, value: TMatchType) => void;
}

export const Filter: React.FunctionComponent<IFilterProps> = ({ title, searchTerms, filterFields, filterEnabled, filterActive, setFilterActive, typeToMatches, filterChange }) => {
    const flipFilter = (key: string) => {
        setFilterActive(key, !(filterActive(key) ?? false));
    }

    const getSelectOptions = (value: TValueType | undefined): OptionsType => {
        if (Array.isArray(value)) {
            let selectedAssetTypes: Array<string> = []
            const definition = searchTerms.find(fd => fd.propName === 'assetType');
            if (definition) {
                selectedAssetTypes = definition.value as Array<string>;
            } else {
                return [];
            }
            return (value as Array<string>).filter(v => selectedAssetTypes.includes(v.toString().split('_')[0]))
                .map(v => ({ value: v, label: v.toString().split('_')[1] }));
        } else {
            return [];
        }
    }
    const localFilterChange = (key: string, value: TValueType, match?: TMatchType) => {
        if (key === 'assetType') {
            const selectedAssetTypes = value as Array<string>;
            const assetSubType = searchTerms.find(st => st.propName === 'assetSubType');
            if (assetSubType) {
                const newAssetSubType = (assetSubType.value as Array<string>).filter(v => selectedAssetTypes.includes(v.toString().split('_')[0]));
                filterChange('assetSubType', newAssetSubType, match)
            }
        }
        filterChange(key, value, match);
    }

    return <div className=' bg-gray-50 mt-2 py-0 rounded-lg'>
        {
            filterFields.map((f, ix) => {
                const searchTerm = searchTerms.find(s => s.propName === f.propName);
                return <ElementRow key={`${f.propName}_${ix}`}
                    title={title}
                    searchTemplate={f}
                    index={ix}
                    value={(searchTerm?.value ?? '') as TValueType}
                    matches={searchTerm?.matches ?? typeToMatches[f.type][0]}
                    typeToMatches={typeToMatches}
                    filterActive={(item: string) => filterActive(item) ?? false}
                    filterEnabled={(item: string) => filterEnabled(item) ?? false}
                    enableFilter={() => flipFilter(f.propName)}
                    getSelectOptions={getSelectOptions}
                    filterChange={(v, m) => localFilterChange(f.propName, v, m)} />
            })
        }
    </div>

}