import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { getEntityDocuments } from '../../api'
import { TEntityType, TDocumentDescriptor } from "../../store/Interfaces";
import { Busy } from "../Busy";

interface IReportCountProps {
    token: string;
    uuid: string;
    assetType: TEntityType;
    assetName: string;
    active: boolean;
    setReportCount: (count: number) => void;
    onClickShowReport: (documents: TDocumentDescriptor[]) => void;
}

export const ReportCount: React.FunctionComponent<IReportCountProps> = ({ token, uuid, assetType, assetName, active, setReportCount, onClickShowReport }) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [documents, setDocuments] = useState<TDocumentDescriptor[]>([]);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setBusy(true);
        const ac = new AbortController();
        setDocuments([]);
        getEntityDocuments(token, uuid, assetType).then(documents => {
            setDocuments(documents);
            if (documents.length > 0) {
                setReportCount(documents.length);
            }
            setBusy(false);
        }).catch(e => {
            setError(true);
            setBusy(false);
        });
        return () => {
            ac?.abort();
        }
    }, [token, uuid, assetType, assetName]);

    const getEntityLabel = (assetType: string) => assetType !== 'SubSite' ? assetType : 'Substation';

    return (
        documents.length > 0 ? (
            <tr className="text-sm outline outline-1 outline-sky-300 bg-sky-200">
                <td className='font-semibold'>{getEntityLabel(assetType)}</td>
                <td>{assetName}</td>
                <td className="text-right">
                    {busy ? <Busy size='xs' /> :
                        error ? <span>Error</span> :
                            <button className='text-gray-700 text-left text-sm hover:text-hvpd-grey-400 disabled:text-hvpd-grey-400 border-solid border-1 font-medium' onClick={() => onClickShowReport(documents)}>
                                <span className='mr-2'>{documents.length}</span>
                                {active ?
                                    <FontAwesomeIcon className=' text-hvpd-pickled-bluewood-200' icon={solid('circle-arrow-down')} /> :
                                    <FontAwesomeIcon className=' text-hvpd-pickled-bluewood-400' icon={solid('circle-arrow-right')} />
                                }
                            </button>
                    }
                </td>
            </tr>
        ) : null
    );
}