
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { IColumnHeader, ISortColumn } from "../../store/Interfaces";
import { ColumnControlButton } from '../';

const COMPRESSABLE_WIDTH = 100;

const SortIcon: React.FunctionComponent<{ direction: 1 | -1 }> = ({ direction }) => {
    return direction === 1 ? <FontAwesomeIcon icon={solid('sort-down')} /> : <FontAwesomeIcon icon={solid('sort-up')} />;
}

interface IResultsTableHeaderProps {
    colHeader: IColumnHeader,
    onClick: (colHeader: IColumnHeader) => void;
    headerWidth?: number;
    isExpanded: boolean;
    expandedHeaders?: Record<string, boolean>;
    sortColumn: ISortColumn | null;
    flipExpandedHeader?: (e: React.MouseEvent, title: string) => void;
}

export const ResultsTableHeader: React.FunctionComponent<IResultsTableHeaderProps> = ({ colHeader, onClick, headerWidth, isExpanded, sortColumn, flipExpandedHeader }) => {
    //const [width, setWidth] = useState(0);
    const chRef = useRef<HTMLTableCellElement>(null);
    const [width, setWidth] = useState(COMPRESSABLE_WIDTH);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (chRef.current) {
            const w = chRef.current?.getBoundingClientRect().width;
            if (w !== width) {
                setWidth(chRef.current?.getBoundingClientRect().width);
            }
        }
    }, [headerWidth, isExpanded, width]);

    return <th key={colHeader.title} ref={chRef} onClick={() => onClick(colHeader)} style={{ width: headerWidth && isExpanded ? `${headerWidth}px` : undefined }} className={`text-sm center font-medium text-white px-1 py-0 text-left ${!isExpanded ? 'max-w-[2rem] w-[2rem] overflow-hidden text-ellipsis' : ''}`}>
        <div className="grid grid-cols-[1fr_30px]">
            <div className={`${isExpanded === false ? 'max-w-[1.5rem] overflow-hidden text-ellipsis' : ''}  whitespace-nowrap`}>{colHeader.title}{sortColumn && sortColumn.title === colHeader.title ? <span className="ml-1"><SortIcon direction={sortColumn.direction} /></span> : null}</div>
            {flipExpandedHeader ? <div>{width >= COMPRESSABLE_WIDTH || !isExpanded ? <span className="float-right"><ColumnControlButton isExpanded={isExpanded} onClick={(e: React.MouseEvent) => flipExpandedHeader(e, colHeader.title)} /></span> : null}</div> : null}
        </div>
    </th>
}
