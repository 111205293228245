import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

//import Markdown from "react-markdown";
import { Viewer } from "@toast-ui/react-editor";

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import {
    addToast,
    setClipBoardObservation,
    setClipBoardRecommendation,
} from "../../../store/mainSlice";

import { deleteObservation, deleteRecommendation } from "../../../api";
import { ConfirmModal } from "../ConfirmModal";
import { EditModal } from "../EditModal";
import { EditRecommendationModal } from "../EditRecommendationModal";

import { TRecommendationRow } from "../Recommendations";
import { IObservation, TResultObservations, IResultRecommendation } from "../../../store/Interfaces";

interface IRecommendationProps {
    type: string;
    isRecommendation: boolean;
    recommendations: Array<TRecommendationRow>;
    recommendationsRaw?: Array<IResultRecommendation>;
    canEdit?: boolean;
    canDelete?: boolean;
    canCreate?: boolean;
    observationType?: keyof TResultObservations
    updateObservation?: (uuid: string, observation: IObservation) => void;
    updateRecommendation?: (recommendation: IResultRecommendation) => void;
    observationDeleted?: (uuid: string) => void;
    observations?: IObservation[];
}

export const Recommendation: React.FunctionComponent<IRecommendationProps> = ({ type, isRecommendation, recommendations, recommendationsRaw, canCreate, canEdit, canDelete, observationType,
    updateObservation, updateRecommendation, observationDeleted, observations }) => {

    const dispatch = useDispatch();
    const { token } = useSelector(state => state.main);

    const [recommendationRow, setRecommendationRow] = useState<TRecommendationRow | null>(null);
    const [deleteUUid, setDeleteUuid] = useState<string | null>(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [blink, setBlink] = useState(false);

    const editRow = (r: TRecommendationRow) => {
        setRecommendationRow(r);
    }

    const doDelete = async () => {
        setShowConfirm(false);
        if (deleteUUid && (observationType || isRecommendation) && observationDeleted) {
            try {
                if (isRecommendation) {
                    await deleteRecommendation(token, deleteUUid, type === 'assets' ? 'Asset' : 'Result');
                    dispatch(addToast('Recommendation deleted successfully'));

                } else if (observationType) {
                    await deleteObservation(token, deleteUUid, observationType);
                    dispatch(addToast('Observation deleted successfully'));
                }
                observationDeleted(deleteUUid);
            } catch (e) {
                console.error(e);
                dispatch(addToast('Failed to delete observation'));
            }
        }
    };

    const deleteRow = (r: TRecommendationRow) => {
        setDeleteUuid(r.uuid);
        setShowConfirm(true);
    }

    const updateObservationText = (observation: IObservation) => {
        if (recommendationRow && updateObservation !== undefined) {
            updateObservation(recommendationRow.uuid, observation);
            setRecommendationRow(null);
            setBlink(true);
            setTimeout(() => setBlink(false), 1);
        }
    }

    const updateRecommendationText = (recommendation: IResultRecommendation) => {
        if (recommendationRow && updateRecommendation !== undefined) {
            updateRecommendation(recommendation);
            setRecommendationRow(null);
            setBlink(true);
            setTimeout(() => setBlink(false), 1);
        }
    }

    const copyToClipBoard = (r: TRecommendationRow) => {
        if (isRecommendation) {
            const recommendation = recommendationsRaw && recommendationsRaw.length > 0 && recommendationsRaw.find(rr => rr.uuid === r.uuid);
            if (recommendation) {
                dispatch(addToast('Recommendation copied to clipboard'));
                dispatch(setClipBoardRecommendation({
                    type: (type === 'assets' ? 'Asset' : 'Result'), recommendation: recommendation
                }));
            }
        }
        else {
            const observation = observations && observations.length > 0 && observations.find(o => o.uuid === r.uuid);
            if (observation) {
                dispatch(addToast(`${observationType} observation copied to clipboard`));
                dispatch(setClipBoardObservation({ type: observationType === 'Asset' ? observationType : 'Result', observation }));
            }
        }
    }

    const cols: number = (canCreate ? 1 : 0) + (canDelete ? 1 : 0) + (
        canEdit ? 1 : 0);
    const canCopyToClipBoard = () => {
        return canCreate && (isRecommendation || (observationType === 'Result' || observationType === 'Asset'));
    }


    return recommendations.length > 0 ? <div className='bg-grey-50 mb-1 border-b-hvpd-grey-600'>
        {/*type ? <div className='bg-slate-100'><h3 className="font-semibold px-2 py-2 text-lg text-hvpd-pickled-bluewood-300">{type}</h3></div> : null */}
        {recommendations.map((r, idx) => <div key={`${r.name}-${idx}`} className='border-t-2'>
            <div className={`${(cols === 0) ? 'grid-cols-1' : `grid grid-cols-[1fr,_repeat(4,auto)]`} mr-2 bg-slate-100`}>
                <h3 className="font-semibold px-2 py-2 text-lg text-hvpd-pickled-bluewood-300">{r.name}
                    {r.createdBy && <span className='pl-1 text-sm text-hvpd-grey-800'>({r.createdBy})</span>}
                    {r.createdDate && <span className='pl-1 text-sm text-hvpd-grey-600'>{r.createdDate}</span>}
                    {r.updatedDate ? (
                        <><span className='text-base text-hvpd-grey-600'>; Updated</span>
                            {r.updatedDate && <span className='pl-1 text-sm text-hvpd-grey-600'>{r.updatedDate}</span>}
                            {(r.updatedBy && r.updatedDate) ? <span className='pl-1 text-sm text-hvpd-grey-800'>({r.updatedBy})</span> : null}
                        </>) : null}
                </h3>
                {canCopyToClipBoard() ? <button className="float-right rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 my-2 mr-1 border-solid border-1 text-sm h-6" onClick={() => copyToClipBoard(r)}><FontAwesomeIcon className=' text-hvpd-malibu-300' icon={solid('clipboard')} /></button> : null}
                {canEdit ? <button className="float-right rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 my-2 mr-1 border-solid border-1 text-sm h-6" onClick={() => editRow(r)}>Edit</button> : null}
                {canDelete ? <button className="float-right rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 my-2 mr-2 border-solid border-1 text-sm h-6" onClick={() => deleteRow(r)}>Delete</button> : null}
            </div>
            <div className="px-2 py-2 bg-slate-50 border-b-2">{blink === false ? <Viewer initialValue={r.value} /> : null}</div>

        </div>)
        }
        {
            recommendationRow && observationType && observations ? <EditModal assetType={observationType} observation={(observations ?? []).find(o => o.uuid === recommendationRow.uuid)} onCancel={() => setRecommendationRow(null)} onSave={updateObservationText} /> :
                recommendationRow && isRecommendation ? <EditRecommendationModal assetType={type === 'assets' ? 'Asset' : 'Result'} recommendation={(recommendationsRaw ?? []).find(r => r.uuid === recommendationRow.uuid)} onCancel={() => setRecommendationRow(null)} onSave={updateRecommendationText} /> : null

        }
        {showConfirm ? <ConfirmModal onConfirm={doDelete} onCancel={() => setShowConfirm(false)} type={isRecommendation ? 'recommendation' : 'observation'} /> : null}
    </div > : null
}
