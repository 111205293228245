import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReportGroupContext } from '../../ReportGroup/ReportGroupContext';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import {
    addToast,
} from "../../../store/mainSlice";
import { Busy } from '../../Busy';
import { ImagePicker } from '../ImagePicker';
import { ImageUpload } from '../../Images/ImageUpload';
import { updateRecommendation } from '../../../api';
import { TAssetType } from '../../../api/recommendationApi'
import { TEntityType, TSeverity, TResultObservations, IResultRecommendation, TTestType } from "../../../store/Interfaces";

interface IEditRecommendationModalProps {
    onCancel: () => void;
    onSave: (value: IResultRecommendation) => void;
    recommendation: IResultRecommendation | undefined;
    assetType: TAssetType;
}


export const EditRecommendationModal: React.FunctionComponent<IEditRecommendationModalProps> = ({ onCancel, onSave, recommendation, assetType }) => {
    const dispatch = useDispatch();
    const { resultFilter, tableResults } = useSelector(state => state.main);
    const { imageListAsset, imageBlobs, refreshImageList } = useContext(ReportGroupContext);
    const [showImageUpload, setShowImageUpload] = useState(false)
    const editorRef = useRef<Editor>(null);
    const [busy, setBusy] = useState(false);
    const [recommendationEdit, setRecommendationEdit] = useState<
        { name: string; severity: TSeverity, description: string }>({ name: '', severity: 'Low', description: ' ' });

    const { token } = useSelector(state => state.main);


    const getAssetUuid = () => {
        if (assetType === 'Result') {
            const r = tableResults?.[resultFilter?.name as TTestType];
            if (r) {
                const resultRow = r.rows.find(r => r.resultUuid === recommendation?.parentUuid);
                if (resultRow) {
                    return resultRow.assetUuid;
                }
            }
        }
        return recommendation?.parentUuid ?? '';
    }

    useEffect(() => {
        if (recommendation) {
            setRecommendationEdit({ ...recommendation });
        }
    }, [recommendation]);

    const entityTypeToAssetType: Record<keyof TResultObservations, TEntityType> = {
        'Result': 'Asset',
        'Asset': 'Asset',
        'Company': 'Company',
        'Site': 'Site',
        'Subsite': 'SubSite',
    }

    const save = async () => {
        setBusy(true);
        try {
            const payload: IResultRecommendation = { ...recommendation, ...recommendationEdit } as unknown as IResultRecommendation;
            const newRecommendation: IResultRecommendation = await updateRecommendation(token, assetType, payload);
            dispatch(addToast(`Updated recommendation ${newRecommendation.name}`));
            setBusy(false);
            onSave(newRecommendation);
        } catch (e) {
            console.error(e);
            setBusy(false);
            onCancel();
        }
    }

    const onClickImage = (base64: string, metadata: string) => {
        const markdown = editorRef.current?.getInstance().getMarkdown();
        editorRef.current?.getInstance().setMarkdown(`${markdown} ![${metadata}](${base64})`);
    }

    const imageUploaded = () => {
        refreshImageList();
        dispatch(addToast('Image upload successful'));
    }

    const recommendationIsIncomplete = recommendationEdit.name === '' || recommendationEdit.description === '';
    const hasImages = imageListAsset.images.some(image => (imageBlobs[image.name] ?? '').length > 0);

    return (<><div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-[3000] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-5xl">
                    <div className="flex flex-shrink-0 items-center justify-between rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                            Edit {assetType.toLowerCase()} recommendation
                        </h5>
                        <button type="button" onClick={onCancel} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label="Close">
                            <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                        </button>
                    </div>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 py-1">
                        {busy ? <div className='mb-20 mx-[12rem]'><Busy /></div> :
                            <div className={`grid ${hasImages ? 'grid-cols-[580px_380px]' : 'grid-cols-1'}`}>
                                <div>
                                    <div className='grid grid-cols-2 mb-3'>
                                        <div className='inline-flex'>
                                            <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="recommendationName">Name</label>
                                            <input type="text" id='recommendationName' name='recommendationName' className="w-40 p-1 text-sm rounded-sm border-hvpd-grey-700 focus:hvpd-grey-500 active:hvpd-grey-500 border inline" value={recommendationEdit.name} onChange={e => setRecommendationEdit({ ...recommendationEdit, name: e.target.value })} />
                                        </div>
                                        <div className='inline-flex'>
                                            <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="recommendationSeverity">Severity</label>
                                            <select id='recommendationSeverity' name='recommendationSeverity' className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border' value={recommendationEdit.severity} onChange={e => setRecommendationEdit({ ...recommendationEdit, severity: e.target.value as TSeverity })}>
                                                <option>Low</option><option>Medium</option><option>High</option>
                                            </select>
                                        </div>

                                    </div>

                                    <label className='block font-bold mb-1 text-lg text-hvpd-pickled-bluewood-400' htmlFor="recommendationDetails">Recommendation details</label>
                                    <Editor
                                        initialValue={recommendation?.description ?? ''}
                                        placeholder='Enter recommendation details here...'
                                        previewStyle="vertical"
                                        height="460px"
                                        onChange={(e) => {
                                            const markDown = editorRef.current?.getInstance().getMarkdown();
                                            setRecommendationEdit(markDown ? { ...recommendationEdit, description: markDown } : { ...recommendationEdit, description: '' });
                                        }}
                                        ref={editorRef}
                                        initialEditType="wysiwyg"
                                        useCommandShortcut={true}
                                    />
                                </div>
                                {hasImages ? <div>
                                    <ImagePicker onClickImage={onClickImage} />
                                    <button className='float-right mr-2 mb-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' onClick={() => setShowImageUpload(true)}>Upload image</button>
                                </div> : null}
                            </div>}
                    </div>

                    <div className="rounded-md bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse">
                        <button type="button" onClick={save} disabled={recommendationIsIncomplete || busy} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2">Save</button>
                        <button type="button" onClick={onCancel} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div >
        <ImageUpload showModal={showImageUpload} onClose={() => setShowImageUpload(false)} assetUuid={getAssetUuid()} entityType={entityTypeToAssetType[assetType]} onError={(error) => dispatch(addToast(error))} onSuccessfulUpload={imageUploaded} />
    </>)

}
