import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector } from "../../../hooks";

import { IAsset } from "../../../store/Interfaces";
import { multiAssetEditForm, mapEnumsToForm } from "../../../templates/formTemplates";
import { FormTemplate, IFormField, TEntity, FormContext } from "../FormTemplate";

const TABLENAME = 'TableAsset';

interface IAssetSelectorProps {
    onClose: (assets: IAsset[]) => void;
    assetUuids: string[];
}

export const MultiAssetEdit: React.FunctionComponent<IAssetSelectorProps> = ({ onClose, assetUuids }) => {
    const { t } = useTranslation();
    const { assets, tableDefinitionsComplete } = useSelector(store => store.main);
    const [wipAsset, setWipAsset] = useState<IAsset>();
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(multiAssetEditForm);
    const [dependantFields, setDependantFields] = useState<Array<{ name: string, value: string }>>([]);
    const [entityChanged, setEntityChanged] = useState(false);
    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        if (assetUuids.length > 0 && assets.length > 0) {
            const s1 = assets.find(s => s.uuid === assetUuids[0]);
            if (s1) {
                setWipAsset({ ...s1, name: '' });
                const constrained: Array<{ name: string, value: string }> = [];

                setDependantFields(constrained);
            }
        }
    }, [assetUuids, assets])

    const setUpdatedAssets = (entity: TEntity) => {
        const updateAssets = assetUuids.map(uuid => {
            const asset = assets.find(a => a.uuid === uuid);
            if (asset && wipAsset) {

                const partEntity = formWithOptions.reduce((acc, field) => {
                    if (Array.isArray(field.propValue)) {
                        return { ...acc, [field.propValue[0]]: entity[field.propValue[0]], [field.propValue[1]]: entity[field.propValue[1]] };
                    } else return { ...acc, [field.propValue]: entity[field.propValue] }
                }, {});
                return { ...asset, ...partEntity };
            } else {
                return null;
            }
        }).filter(a => a !== null) as IAsset[];
        onClose(updateAssets);
    }

    const onFieldChange = (field: string, value: string) => {
        if (field === 'companyUuid' || field === 'siteUuid') {
            setDependantFields([...dependantFields.filter(df => df.name !== field), { name: field, value: value }]);
        }
        if (field === 'assetType') {
            setDependantFields([...dependantFields.filter(df => df.name !== field), { name: field, value: value }]);
            return [{ name: 'assetSubType', value: '' }];
        }
        if (field === 'companyUuid') {
            return [{ name: 'siteUuid', value: '' }, { name: 'subSiteUuid', value: '' }];
        }
        if (field === 'siteUuid') {
            return [{ name: 'subSiteUuid', value: '' }];
        }
        return [];
    }

    useEffect(() => {
        if (tableDefinitionsComplete[TABLENAME]?.searchParams) {
            let localFormWithOptions = mapEnumsToForm(multiAssetEditForm, tableDefinitionsComplete[TABLENAME]?.searchParams)
                .map(field => {
                    if (field.propValue === 'supplyFrequency') {
                        return { ...field, options: (field?.options ?? []).map(option => ({ name: option.name.startsWith('E') ? option.name.substring(1) : option.name, value: option.value.toString() })) };
                    } else {
                        return field;
                    }
                });


            setFormWithOptions(localFormWithOptions);
        }
    }, [dependantFields, tableDefinitionsComplete])


    const setForm = (form: Array<IFormField>) => {
        setFormWithOptions(form);
    }

    return (
        <div>
            {formWithOptions ? <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                <FormTemplate entityName={t('Multi asset')} form={formWithOptions} setForm={setForm} entity={wipAsset as TEntity} babysitFieldChange={onFieldChange} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={setUpdatedAssets} canCreate={false} />
            </FormContext.Provider> : null}
        </div>
    );
}