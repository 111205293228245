import React, { useRef } from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    ChartData, ChartOptions,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement,
    ScatterController, PieController
} from 'chart.js';
import type { InteractionItem } from 'chart.js';

import {
    Line, getDatasetAtEvent,
    getElementAtEvent,
    getElementsAtEvent
} from 'react-chartjs-2';


ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ScatterController, PieController
);

interface ILineChartProps {
    chart: ChartData<"line">,
    options: ChartOptions<"line">
    onClick?: (index: number) => void;
}

export const LineChart: React.FunctionComponent<ILineChartProps> = ({ chart, options, onClick }) => {
    const chartRef = useRef(null);

    const printDatasetAtEvent = (dataset: InteractionItem[]) => {
        if (!dataset.length) return;

        const datasetIndex = dataset[0].datasetIndex;

        console.log(chart.datasets[datasetIndex].label);
    };

    const printElementAtEvent = (element: InteractionItem[]) => {
        if (!element.length) return;
        const { datasetIndex, index } = element[0];

        console.log(chart.labels ? chart.labels[index] : '', chart.datasets[datasetIndex].data[index]);
    };

    const printElementsAtEvent = (elements: InteractionItem[]) => {
        if (!elements.length) return;

        console.log(elements.length);
    };


    const onClickChart = (event: React.MouseEvent<HTMLCanvasElement>) => {
        const { current: chart } = chartRef;

        if (!chart) {
            return;
        }
        const ds = getElementAtEvent(chart, event);
        if (!ds.length) return;

        printDatasetAtEvent(getDatasetAtEvent(chart, event));
        printElementAtEvent(getElementAtEvent(chart, event));
        printElementsAtEvent(getElementsAtEvent(chart, event));
        if (onClick) {
            onClick(ds[0].index);
        }
    }


    return <Line ref={chartRef} data={chart as ChartData<'line'>} onClick={(e) => onClickChart(e)} options={options} />
}