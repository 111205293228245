import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const initI18n = () => {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            // the translations
            // (tip move them in a JSON file and import them,
            // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
            resources: {
                en: {
                    translation: {
                        'Access Error': 'Access Error',
                        'Add one new asset': 'Add one new asset',
                        'Add count new assets': 'Add {{count}} new assets',
                        'Added observation': 'Added observation',
                        'Add selected to One Report': 'Add selected to One Report',
                        'Apply selection': 'Apply selection',
                        'Are you sure you want to delete this thing': 'Are you sure you want to delete this {{thing}}?',
                        'Assets': 'Assets',
                        'Asset created': 'Asset created',
                        'Asset deleted': 'Asset deleted',
                        'Asset name': 'Asset name',
                        'Asset template operations': 'Asset template operations',
                        'Asset updated': 'Asset updated',
                        'Cancel': 'Cancel',
                        'Clear and create new': 'Clear and create new',
                        'Clear template': 'Clear template',
                        'Click to change to SLD view': 'Click to change to SLD view',
                        'Close': 'Close',
                        'Confirm': 'Confirm',
                        'Copy as template': 'Copy as template',
                        'create': 'create',
                        'Create asset observation': 'Create asset observation',
                        'Create company observation': 'Create company observation',
                        'Create observation': 'Create observation',
                        'Create site observation': 'Create site observation',
                        'Create subsite observation': 'Create subsite observation',
                        'Companies': 'Companies',
                        'Company': 'Company',
                        'Company created': 'Company created',
                        'Company deleted': 'Company deleted',
                        'Company updated': 'Company updated',
                        'Confirm edits to the following assets': 'Confirm edits to the following assets',
                        'Constrain by company': 'Constrain by company',
                        'Create assets': 'Create assets',
                        'Delete': 'Delete',
                        'Disable': 'Disable',
                        'edit': 'edit',
                        'Enable': 'Enable',
                        'Enter observation details here...': 'Enter observation details here...',
                        'Expand / contract': 'Expand / contract map panel',
                        'Extended Filters': 'Extended Filters',
                        'Failed to get users': 'Failed to get users',
                        'file name': 'file name',
                        'Filters': 'Filters',
                        'Hiding results with no tests': 'Hiding results with no tests',
                        'High': 'High',
                        'Image upload successful': 'Image upload successful',
                        'Lat, Lng copied to clipboard': 'Lat, Lng copied to clipboard',
                        'Loading...': 'Loading...',
                        'Log In': 'Log In',
                        'Log Out': 'Log Out',
                        'Low': 'Low',
                        'Medium': 'Medium',
                        'Multi asset': 'Multi asset',
                        'n assets could not be added': '{{count}} assets could not be added',
                        'n assets have been added': '{{count}} assets have been added',
                        'Name': 'Name',
                        'Names of assets': 'Names of assets',
                        'Next': 'Next',
                        'No GPS data found in image': 'No GPS data found in image',
                        'Number of new assets': 'Number of new assets',
                        'Observation details': 'Observation details',
                        'One asset could not be added': 'One asset could not be added',
                        'One asset has been added': 'One asset has been added',
                        'One report': 'One report',
                        'Please select...': 'Please select...',
                        'Prev': 'Prev',
                        'Projects': 'Projects',
                        'report pane': 'report pane',
                        "Results filter": "Results filter",
                        'Refresh': 'Refresh',
                        'Results': 'Results',
                        'Save': 'Save',
                        'Select': 'Select',
                        'Select assets for edit': 'Select assets for edit',
                        'Select multiple assets': 'Select multiple assets',
                        'Severity': 'Severity',
                        'Showing all filtered by connection': 'Showing all filtered by connection',
                        'Showing all filtered by map bounds': 'Showing all filtered by map bounds',
                        'Showing all unfiltered by connection': 'Showing all unfiltered by connection',
                        'Showing all unfiltered by map bounds': 'Showing all unfiltered by map bounds',
                        'Showing results with no tests': 'Showing results with no tests',
                        'Sites': 'Sites',
                        'Sorry, you are not authorised': 'Sorry, you are not authorised',
                        'Sub sites': 'Sub sites',
                        'Submit': 'Submit',
                        'The following names are already in use:': 'The following names are already in use:',
                        'The number of names does not match the number of assets': 'The number of names does not match the number of assets',
                        'Undo': 'Undo',
                        'Upload image': 'Upload image',
                        'User groups': 'User groups',
                        'Users': 'Users',
                        'View recommendation': 'View recommendation',
                        'Warning: This observation will be permenantly removed': 'Warning: This observation will be permenantly removed',
                        'Warning: This recommendation will be permenantly removed': 'Warning: This recommendation will be permenantly removed',
                        'You have unsaved changes. Are you sure you want to discard them?': 'You have unsaved changes. Are you sure you want to discard them?'
                    }
                }
            },
            lng: "en", // if you're using a language detector, do not define the lng option
            fallbackLng: "en",

            interpolation: {
                escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            }
        });
}