import React, { useEffect, useState, ReactElement } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { Busy } from "../../Busy";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../hooks';
import { keyToTitle, sanitise } from '../../../utils';
import { setAssets } from "../../../store/mainSlice";
import { IAsset, ICompany, ISubSite, ISite } from "../../../store/Interfaces";
import { getAssets } from "../../../api";
import { ProjectName } from "../ProjectName";
import { SubSiteName } from "../SubSiteName";

export interface IAssetDetailsProps {
    uuid: string;
    detailsOnly?: boolean;
}

const embargoedAssetKeys: Array<keyof IAsset> = ['assetDiagrams', 'uuid'];

interface IAssetBlockProps {
    asset: IAsset;
    companies: Array<ICompany>;
    sites: Array<ISite>;
    subSites: Array<ISubSite>;
}

const AssetBlock: React.FunctionComponent<IAssetBlockProps> = ({ asset, companies, sites, subSites }) => {
    const getCellValue = (asset: IAsset, key: keyof IAsset): React.ReactElement | string => {
        if (key === 'companyUuid') {
            const company = companies.find(c => c.uuid === asset[key]);
            if (company) {
                return company.name ?? '';
            }
            return <FontAwesomeIcon className='fa-spin text-hvpd-grey-400' icon={solid('spinner')} />
        } else if (key === 'siteUuid') {
            const site = sites.find(s => s.uuid === asset[key]);
            if (site) {
                return site.name ?? '';
            }
            return <FontAwesomeIcon className='fa-spin text-hvpd-grey-400' icon={solid('spinner')} />
        } else if (key === 'subSiteUuid') {
            const subSite = subSites.find(s => s.uuid === asset.subSiteUuid);
            if (subSite) {
                return subSite.name;
            } else if (asset.subSiteUuid) {
                return <SubSiteName uuids={[asset.subSiteUuid]} />
            }
            return '';
        } else if (key === 'projects') {
            return <ProjectName uuids={asset[key] ?? []} />;
        }
        return sanitise(asset[key]?.toString());
    }

    return <div className='grid grid-cols-[2fr_3fr] gap-x-1 text-sm m-2'>{
        Object.keys(asset).filter((key) => !embargoedAssetKeys.includes(key as keyof IAsset)).sort().map((key, ix) =>
            (<ElementRow key={`A_${key}_${ix}`} title={keyToTitle(key, keysToTitle)} value={getCellValue(asset, key as keyof IAsset)} index={ix} />))
    }
    </div>
}
interface IElementRowProps {
    title: string;
    value: string | ReactElement;
    index: number;
}

const ElementRow: React.FunctionComponent<IElementRowProps> = ({ title, value, index }) => (
    <><div className={`font-semibold text-right pr-2 border-r-2 ${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:hover:bg-slate-200`}>{title}</div><div className={`${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:bg-slate-200`}>{value}</div></>
)

const keysToTitle: Record<string, string> = {
    'address': 'Address',
    'companyUuid': 'Company',
    'ratedPower_kW': 'Rated Power kW', 'voltage_kV': 'Voltage kV', 'ratedSpeedRPM': 'Rated Speed RPM', 'kva': 'kVA',
    'siteUuid': 'Site',
    'subSiteUuid': 'Sub Site',
    addressZIP: 'Zip Code', logoURL: 'Logo', 'taxReferenceID': 'Tax Ref'
};

export const AssetDetails: React.FunctionComponent<IAssetDetailsProps> = ({ uuid, detailsOnly }) => {
    const dispatch = useDispatch();
    const { token, assets, companies, sites, subSites } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (token && assets.length === 0) {
            setLoading(true);
            // currently getting _all_ assets, not just the one we want           
            getAssets(token).then((res) => {
                if (res instanceof Array) {
                    dispatch(setAssets(res));
                }
                setLoading(false);
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });

        }
    }, [token, assets, dispatch]);

    const asset = assets.find((s) => s.uuid === uuid);

    return (
        <div className={`overflow-y-auto ${detailsOnly ? 'h-[92%]' : 'h-full mt-16'}`}>
            {loading ? <Busy /> : null}

            {!loading ?
                <>{asset ?
                    detailsOnly ? <AssetBlock asset={asset} companies={companies} sites={sites} subSites={subSites} /> :
                        <div className='bg-white border border-gray-200 py-4 px-5' >
                            <h2 className='mb-0'>
                                <span className='relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none focus:outline-none'>Asset</span>
                            </h2>
                            <div className='px-5 overflow-y-auto max-h-[80vh]'>
                                <AssetBlock asset={asset} companies={companies} sites={sites} subSites={subSites} />
                            </div>
                        </div>
                    :
                    <div>No Asset found</div>
                }
                </>
                : null
            }
        </div>
    );
}