import React, { useEffect, useRef, useState } from "react";
import {
    useTimeout,
    useAppSelector as useSelector,
    useAppDispatch as useDispatch,
} from "../../hooks";
import { setSelectedAssets } from "../../store/mainSlice";

import {
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
    InteractionItem,
} from "chart.js";
import { Pie, Bar, Line, getElementsAtEvent } from "react-chartjs-2";

import { TTableResultEndpoint, TResultRow } from "./../../store/Interfaces";

ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale);

export interface IResultsTableProps {
    tableEndPoint: TTableResultEndpoint | undefined;
    expanded: boolean;
}

export const Dashboard: React.FunctionComponent<IResultsTableProps> = ({
    tableEndPoint,
    expanded,
}) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(true);
    const [showTimer, setShowTimer] = useState<number | null>(null);
    const [resizeTimeout, setResizeTimeout] = useState<number | null>(null);
    const { selectedAssets, oneReportResults } = useSelector(
        (state) => state.main,
    );
    const assetChartRef = useRef(null);
    //const testChartRef = useRef(null);
    const assetChartData = useRef<ChartData<"pie", number[], string>>({
        labels: [],
        datasets: [],
    });

    const [chartType, setChartType] = useState<"pie" | "bar" | "line">("pie");

    useTimeout(() => {
        setResizeTimeout(null);
        setShowTimer(1);
        setShow(false);
    }, resizeTimeout);

    useTimeout(() => {
        setShow(true);
        setShowTimer(null);
    }, showTimer);

    useEffect(() => {
        setShowTimer(1);
        setShow(false);
    }, [expanded]);

    const flipSelectedAssets = (newSelectedAssets: string[]) => {
        if (
            newSelectedAssets.length === selectedAssets.length &&
            newSelectedAssets.every(
                (value, index) => value === selectedAssets[index],
            )
        ) {
            dispatch(setSelectedAssets([]));
        } else {
            dispatch(setSelectedAssets(newSelectedAssets));
        }
    };

    let rows: TResultRow[] = oneReportResults?.rows ?? [];
    //'color' - sheesh
    const backgroundColorOrig = [
        "rgba(10, 192, 10, 0.9)",
        "rgba(232, 165, 3, 0.9)",
        "rgba(230, 2230, 22, 0.9)",
        "rgba(227, 53, 53, 0.9)",
        "rgba(40, 40, 40, 0.8)",
    ];
    const borderColorOrig = [
        "rgba(10, 192, 10, 1)",
        "rgba(232, 165, 3, 1)",
        "rgba(230, 2230, 22, 1)",
        "rgba(227, 53, 53, 1)",
        "rgba(40, 40, 40, 1)",
    ];

    let backgroundColor: string[] = [...backgroundColorOrig];
    let borderColor: string[] = [...borderColorOrig];

    if (selectedAssets.length > 0) {
        //   backgroundColor = backgroundColor.filter((_, idx) => selectedAssets.includes(rows[idx]['assetUuid']));
        //   borderColor = backgroundColor.filter((_, idx) => selectedAssets.includes(rows[idx]['assetUuid']));
        rows = rows.filter((row) => selectedAssets.includes(row["assetUuid"]));
    }

    return (
        <>
            <div className="bg-hvpd-grey-50 w-[calc(100%-32px)] p-4">
                Dashboard {tableEndPoint ? tableEndPoint.name : "no endpoint"}
            </div>
            <div className="absolute h-full w-full">
                {oneReportResults.rows.length > 0 ? (
                    <div className="mb-2 h-[calc(100%-80px)] w-full">
                        <div className="h-full overflow-y-auto ">
                            <div className="grid grid-cols-2">
                                <div>
                                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                        <input
                                            className="checked:border-primary checked:after:border-primary checked:after:bg-primary checked:focus:border-primary dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:checked:focus:border-primary relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="radioPie"
                                            checked={chartType === "pie"}
                                            onClick={() => setChartType("pie")}
                                        />
                                        <label
                                            className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                            htmlFor="radioPie"
                                        >
                                            Pie charts
                                        </label>
                                    </div>
                                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                        <input
                                            className="checked:border-primary checked:after:border-primary checked:after:bg-primary checked:focus:border-primary dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:checked:focus:border-primary relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="radioBar"
                                            checked={chartType === "bar"}
                                            onClick={() => setChartType("bar")}
                                        />
                                        <label
                                            className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                            htmlFor="radioBar"
                                        >
                                            Bar charts
                                        </label>
                                    </div>
                                    {/*<div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                        <input
                                            className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="radioLine"
                                            checked={chartType === 'line'}
                                            onClick={() => setChartType('line')} />
                                        <label
                                            className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                            htmlFor="radioLine">
                                            Line charts
                                        </label>
                </div>*/}
                                </div>
                                <div>
                                    <button
                                        onClick={() =>
                                            dispatch(setSelectedAssets([]))
                                        }
                                        disabled={selectedAssets.length === 0}
                                        type="button"
                                        className="disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 border-1 border-solid px-2 py-1 text-sm font-medium text-white"
                                    >
                                        Deselect all
                                    </button>
                                </div>
                            </div>
                            {show ? (
                                <div className={`grid grid-cols-2`}>
                                    <>
                                        <div className="mx-8">
                                            <TestChart
                                                chartType={chartType}
                                                rows={rows}
                                                flipSelectedAssets={
                                                    flipSelectedAssets
                                                }
                                            />
                                        </div>
                                        <div className="mx-8">
                                            <AssetChart
                                                chartType={chartType}
                                                rows={rows}
                                                flipSelectedAssets={
                                                    flipSelectedAssets
                                                }
                                            />
                                        </div>
                                    </>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}{" "}
            </div>
        </>
    );
};

interface IPieChartProps {
    onClickChart: (elements: InteractionItem[]) => void;
    options: ChartOptions<"pie">;
    data: ChartData<"pie", number[], string>;
}

const PieChart: React.FunctionComponent<IPieChartProps> = ({
    onClickChart,
    options,
    data,
}) => {
    const pieChart = useRef(null);
    const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (pieChart.current) {
            const elements = getElementsAtEvent(pieChart.current, event);
            onClickChart(elements);
        }
    };
    return (
        <Pie ref={pieChart} data={data} options={options} onClick={onClick} />
    );
};
interface IBarChartProps {
    onClickChart: (elements: InteractionItem[]) => void;
    options: ChartOptions<"bar">;
    data: ChartData<"bar", number[], string>;
}

const BarChart: React.FunctionComponent<IBarChartProps> = ({
    onClickChart,
    options,
    data,
}) => {
    const barChart = useRef(null);

    const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (barChart.current) {
            const elements = getElementsAtEvent(barChart.current, event);
            onClickChart(elements);
        }
    };

    return (
        <Bar ref={barChart} data={data} options={options} onClick={onClick} />
    );
};

interface LineChartProps {
    onClickChart: (elements: InteractionItem[]) => void;
    options: ChartOptions<"line">;
    data: ChartData<"line", number[], string>;
}

const LineChart: React.FunctionComponent<LineChartProps> = ({
    onClickChart,
    options,
    data,
}) => {
    const lineChart = useRef(null);

    const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (lineChart.current) {
            const elements = getElementsAtEvent(lineChart.current, event);
            onClickChart(elements);
        }
    };
    return (
        <Line ref={lineChart} data={data} options={options} onClick={onClick} />
    );
};

interface ITestChartProps {
    chartType: "pie" | "bar" | "line";
    rows: TResultRow[];
    flipSelectedAssets: (rows: string[]) => void;
}

const TestChart: React.FunctionComponent<ITestChartProps> = ({
    chartType,
    rows,
    flipSelectedAssets,
}) => {
    const testChartData = useRef<ChartData<"pie", number[], string>>({
        labels: [],
        datasets: [],
    });

    const backgroundColor = [
        "rgba(10, 192, 10, 0.9)",
        "rgba(232, 165, 3, 0.9)",
        "rgba(230, 2230, 22, 0.9)",
        "rgba(227, 53, 53, 0.9)",
        "rgba(40, 40, 40, 0.8)",
    ];
    const borderColor = [
        "rgba(10, 192, 10, 1)",
        "rgba(232, 165, 3, 1)",
        "rgba(230, 2230, 22, 1)",
        "rgba(227, 53, 53, 1)",
        "rgba(40, 40, 40, 1)",
    ];
    const conditions = rows.reduce(
        (acc, row) => {
            if (undefined !== row["phaseMaxCondition"]) {
                const phaseMaxCondition: string =
                    row["phaseMaxCondition"].toString();
                undefined === acc[phaseMaxCondition]
                    ? (acc[phaseMaxCondition] = 1)
                    : acc[phaseMaxCondition]++;
            }
            return acc;
        },
        { Green: 0, Amber: 0, Yellow: 0, Red: 0 } as Record<string, number>,
    );

    const data = {
        labels: Object.keys(conditions),
        datasets: [
            {
                label: "Severity",
                data: Object.values(conditions),
                backgroundColor,
                borderColor,
                borderWidth: 1,
            },
        ],
    };

    testChartData.current = data;

    const onClickTestChart = (elements: InteractionItem[]) => {
        const assetConditions: string[] = [];
        elements.forEach((element, idx) => {
            const { index } = element;
            if ((testChartData.current?.labels ?? [])[index]) {
                assetConditions.push(
                    (testChartData.current?.labels ?? [])[index],
                );
            }
        });

        const newSelectedAssets: string[] = rows
            .filter((row) => undefined !== row["phaseMaxCondition"])
            .filter((row) =>
                assetConditions.includes(row["phaseMaxCondition"].toString()),
            )
            .map((row) => row["assetUuid"]);

        flipSelectedAssets(newSelectedAssets);
    };

    const testOptions: ChartOptions<"pie"> = {
        plugins: {
            legend: {
                align: "start",
                position: "bottom",
                labels: {
                    boxWidth: 10,
                    font: {
                        size: 14,
                    },
                },
            },
            title: {
                display: true,
                text: "Asset Severity Count",
                font: {
                    size: 20,
                },
            },
        },
        scales:
            chartType === "pie"
                ? undefined
                : {
                      x: {
                          ticks: {
                              font: {
                                  size: 20,
                              },
                              color: "rgb(10, 10, 10)",
                          },
                      },
                  },
    };

    return chartType === "pie" ? (
        <PieChart
            data={data}
            options={testOptions}
            onClickChart={onClickTestChart}
        />
    ) : chartType === "bar" ? (
        <BarChart
            data={data}
            options={testOptions as ChartOptions<"bar">}
            onClickChart={onClickTestChart}
        />
    ) : chartType === "line" ? (
        <LineChart
            data={data}
            options={testOptions as ChartOptions<"line">}
            onClickChart={onClickTestChart}
        />
    ) : null;
};

interface IAssetChartProps {
    chartType: "pie" | "bar" | "line";
    rows: TResultRow[];
    flipSelectedAssets: (rows: string[]) => void;
}

const AssetChart: React.FunctionComponent<IAssetChartProps> = ({
    chartType,
    rows,
    flipSelectedAssets,
}) => {
    const assetChartData = useRef<ChartData<"pie", number[], string>>({
        labels: [],
        datasets: [],
    });

    const backgroundColor = [
        "rgba(10, 192, 10, 0.9)",
        "rgba(232, 165, 3, 0.9)",
        "rgba(230, 2230, 22, 0.9)",
        "rgba(227, 53, 53, 0.9)",
        "rgba(40, 40, 40, 0.8)",
    ];
    const borderColor = [
        "rgba(10, 192, 10, 1)",
        "rgba(232, 165, 3, 1)",
        "rgba(230, 2230, 22, 1)",
        "rgba(227, 53, 53, 1)",
        "rgba(40, 40, 40, 1)",
    ];
    const assetTypes = rows.reduce(
        (acc, row) => {
            const assetType: string = row["assetType"].toString();
            undefined === acc[assetType]
                ? (acc[assetType] = 1)
                : acc[assetType]++;
            return acc;
        },
        {} as Record<string, number>,
    );

    const data = {
        labels: Object.keys(assetTypes),
        datasets: [
            {
                label: "Assets of this type",
                data: Object.values(assetTypes),
                backgroundColor,
                borderColor,
                borderWidth: 1,
            },
        ],
    };

    assetChartData.current = data;

    const chartOptions: ChartOptions<"pie"> = {
        plugins: {
            legend: {
                align: "start",
                position: "bottom",
                labels: {
                    boxWidth: 10,
                    font: {
                        size: 14,
                    },
                },
            },
            title: {
                display: true,
                font: {
                    size: 20,
                },
                text: "Asset Types",
            },
        },
        scales:
            chartType === "pie"
                ? undefined
                : {
                      x: {
                          ticks: {
                              font: {
                                  size: 20,
                              },
                              color: "rgb(10, 10, 10)",
                          },
                      },
                  },
    };

    const onClickAssetChart = (elements: InteractionItem[]) => {
        const assetTypes: string[] = [];

        if (assetChartData.current) {
            elements.forEach((element, idx) => {
                const { index } = element;
                if ((assetChartData.current?.labels ?? [])[index]) {
                    assetTypes.push(
                        (assetChartData.current?.labels ?? [])[index],
                    );
                }
            });
        }
        const newSelectedAssets = rows
            .filter((row) => assetTypes.includes(row["assetType"].toString()))
            .map((row) => row["assetUuid"]);
        flipSelectedAssets(newSelectedAssets);
    };

    return chartType === "pie" ? (
        <PieChart
            data={data}
            options={chartOptions}
            onClickChart={onClickAssetChart}
        />
    ) : chartType === "bar" ? (
        <BarChart
            data={data}
            options={chartOptions as ChartOptions<"bar">}
            onClickChart={onClickAssetChart}
        />
    ) : chartType === "line" ? (
        <LineChart
            data={data}
            options={chartOptions as ChartOptions<"line">}
            onClickChart={onClickAssetChart}
        />
    ) : null;
};
