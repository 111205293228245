import React, { useEffect, useState } from "react";
import { useTimeout, useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import {
    Chart as ChartJS, ChartData, ChartOptions, RadialLinearScale, TimeScale
} from 'chart.js';
import 'chartjs-adapter-luxon';

import { LineChart } from "../LineChart";

import { setReportTab, } from "../../store/mainSlice";
import { TTrendData, TTrendField, TTestType, IResultHistory, TDocumentDescriptor } from "../../store/Interfaces";

import { Accordion } from "../Accordion";
import { getTrendDataPoints } from '../../api';

export interface ITrendGraphProps {
    containerElement: HTMLElement | null;
    assetUuid: string;
    expanded: boolean;
    resultHistory: { uuid: string, history: Array<IResultHistory> | Array<TDocumentDescriptor> };
    setResultHistoryIndex: (index: number) => void;
}

ChartJS.register(
    RadialLinearScale,
    TimeScale
);

const RESIZE_DELAY = 200;

export const darken = (hex: string, amount: number) => {
    return '#' + hex.replace(/^#/, '').replace(/../g, color => {
        const s = `0${Math.min(255, Math.max(0, Math.floor(parseInt(color, 16) * amount))).toString(16)}`;
        return s.substring(s.length - 2);
    });
}

export const TrendGraph: React.FunctionComponent<ITrendGraphProps> = ({ containerElement, assetUuid, expanded, resultHistory, setResultHistoryIndex }) => {
    const dispatch = useDispatch();
    const { token, resultFilter } = useSelector(store => store.main);

    const [trendData, setTrendData] = useState<TTrendData>();
    const [loading, setLoading] = useState(false);
    const [charts, setCharts] = useState<Array<{ options: ChartOptions<"line">, chart: ChartData<"line"> }>>([]);
    const [zeroBased, setZeroBased] = useState<Record<string, boolean>>({});
    const [last3Months, setLast3Months] = useState<Record<string, boolean>>({});
    const [show, setShow] = useState(true);
    const [showTimer, setShowTimer] = useState<number | null>(null);
    const [resizeTimeout, setResizeTimeout] = useState<number | null>(null);
    const [startOffset, setStartOffset] = useState<Record<string, number>>({});
    const [endOffset, setEndOffset] = useState<Record<string, number>>({});

    useTimeout(() => {
        setResizeTimeout(null);
        setShowTimer(1);
        setShow(false);
    }, resizeTimeout);

    useTimeout(() => {
        setShow(true);
        setShowTimer(null);
    }, showTimer);

    useEffect(() => {
        if (expanded) {
            setShowTimer(1);
        }
    }, [expanded]);

    useEffect(() => {
        // const trendsContainerDiv = document.getElementById('trendsContainer');
        let observer: ResizeObserver;
        if (containerElement) {
            observer = new ResizeObserver(
                (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
                    setResizeTimeout(RESIZE_DELAY);
                });
            observer.observe(containerElement);
        }
        return () => {
            if (containerElement && observer) {
                observer.unobserve(containerElement);
            }
        }
    }, [containerElement]);

    const zeroBase = (itemTitle: string) => {
        setZeroBased({ ...zeroBased, [itemTitle]: !zeroBased[itemTitle] });

        const index = trendData?.trendFields.findIndex(t => t.rangeLabel === itemTitle);

        setCharts(charts.map((c, i) => {
            return (i === index) ?
                {
                    chart: c.chart,
                    options: {
                        ...c.options, scales: { ...c.options.scales }, y: { ...c.options.scales?.y ?? {}, suggestedMin: !zeroBased[itemTitle] ? 0 : undefined }
                    }//, suggestedMin: !zeroBased[itemTitle] } } }
                }
                : c
        }));
    }

    const onSetLast3Months = (itemTitle: string) => {
        setLast3Months({ ...last3Months, [itemTitle]: !last3Months[itemTitle] });
    }

    const updateStartOffset = (itemTitle: string, value: number) => {
        const eOffset = endOffset[itemTitle] ?? 100;
        if (value < eOffset && value < 100) {
            setStartOffset({ ...startOffset, [itemTitle]: value });
        }
    }

    const updateEndOffset = (itemTitle: string, value: number) => {
        const sOffset = startOffset[itemTitle] ?? 0;
        if (value > sOffset && value > 0) {
            setEndOffset({ ...endOffset, [itemTitle]: value });
        }
    }

    const goToReport = (chartIndex: number, dataIndex: number) => {
        if (trendData) {
            const trendPoint = trendData.trendFields[chartIndex].sensorTrends[0].trendPoints[dataIndex];
            if (resultHistory.history.length > 0) {
                let index = -1
                if (undefined !== (resultHistory.history as IResultHistory[])[0].endTime) {
                    const sortedHistory = [...resultHistory.history].sort((a, b) => (b as IResultHistory).endTime - (a as IResultHistory).endTime);
                    index = sortedHistory.findIndex(ix => (ix as IResultHistory).endTime === trendPoint.timestamp)
                } else {
                    const sortedHistory = [...resultHistory.history].sort((a, b) => (b as TDocumentDescriptor).lastModifiedTimestamp - (a as TDocumentDescriptor).lastModifiedTimestamp);
                    index = sortedHistory.findIndex(ix => (ix as TDocumentDescriptor).lastModifiedTimestamp === trendPoint.timestamp)
                }
                if (index !== -1) {
                    setResultHistoryIndex(index);
                }
            }
            dispatch(setReportTab('report'));
        }
    }

    useEffect(() => {
        const getTrendData = async () => {
            try {
                setLoading(true);
                const rf = (resultFilter?.name ?? 'OnlinePD');
                const dataPoints = await getTrendDataPoints(token, assetUuid, rf as TTestType);
                if (undefined === dataPoints.trendFields) {
                    setTrendData({ ...dataPoints, trendFields: [] })
                } else {
                    setTrendData(dataPoints);
                }
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
            }
        }
        getTrendData();

    }, [assetUuid, token, resultFilter]);




    const createChartOptions = (td: TTrendField, suggestedMin: number | undefined): ChartOptions<"line"> => {
        return {
            animation: false,
            responsive: true,
            layout: {
                padding: 10
            },
            elements: {
                point: {
                    radius: 1
                },
                line: {
                    borderWidth: 2,
                }
            }, scales: {
                y: {
                    suggestedMin,
                    title: { text: td.baseUnits, display: !!td.baseUnits },
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                            if (typeof value === 'string') {
                                return value;
                            } else if (Math.abs(value) < 1e-4 || Math.abs(value) >= 1e5) {
                                return value.toPrecision(2);
                            }
                            return Math.floor(value * 10000) / 10000;
                        }
                    }
                },
                x: {
                    type: 'time',
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 15,

                    }
                },
            },
            plugins: {
                legend: {
                    position: 'bottom' as const,
                    labels: { color: 'rgb(255, 43, 132)' },
                },
                title: {
                    display: false,
                    position: 'top',
                    align: 'start',
                    font: {
                        size: 18
                    },
                    text: td.rangeLabel,
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return `${context.dataset.label}: ${context.parsed.y}`
                        }
                    }
                }
            },
        }
    }

    type TDataSet = {
        label: string;
        type: "line";
        data: (number | null | { x: number | string, y: number })[];
        backgroundColor: string;
        borderColor: string;
    }[];

    type TAllCharts = {
        options: ChartOptions<"line">;
        chart: ChartData<"line">;
    }[];

    const createDatasets = (td: TTrendField, allTimePoints: number[]) => td.sensorTrends.map(sensorTrend => ({
        label: sensorTrend.sensorName,
        type: 'line' as const,
        data: (allTimePoints ?? []).map(timePoint => {
            return { x: new Date(timePoint * 1000).toISOString(), y: (sensorTrend.trendPoints.find(tp => tp.timestamp === timePoint) ?? { scaledValue: 0 }).scaledValue };
        }),


        backgroundColor: sensorTrend.hexColor,
        borderColor: darken(sensorTrend.hexColor, 0.8)
    }));

    const createChartData = (allTimePoints: number[], datasets: TDataSet) => ({
        // labels: allTimePoints.map(trendPoint => `${new Date(trendPoint * 1000).toLocaleString('default', { day: 'numeric' })} ${new Date(trendPoint * 1000).toLocaleString('default', { month: 'short', })} ${new Date(trendPoint * 1000).toLocaleString('default', { year: 'numeric' }).substring(2)}`),
        datasets,
    });

    useEffect(() => {
        const setZeroBasedGraphs = (allCharts: TAllCharts, trendData: TTrendData) => {
            const allLabels: Array<string> = allCharts.reduce((acc, c, idx) => {
                if (trendData.trendFields[idx]?.rangeLabel) {
                    acc.push(trendData.trendFields[idx]?.rangeLabel);
                } return acc
            }, [] as Array<string>);

            const zb = allLabels.reduce((acc, label) => ({ ...acc, [label]: false }), {} as Record<string, boolean>);
            const so = allLabels.reduce((acc, label) => ({ ...acc, [label]: 0 }), {} as Record<string, number>);
            const eo = allLabels.reduce((acc, label) => ({ ...acc, [label]: 100 }), {} as Record<string, number>);

            setZeroBased(zb);
            setLast3Months({ ...zb });
            setStartOffset(so);
            setEndOffset(eo);
        }
        if (charts.length > 0 && trendData) {
            setZeroBasedGraphs(charts, trendData);
        }
    }, [trendData]);

    useEffect(() => {
        const handlScaling = (td: TTrendField) => {
            const scales: Array<[number, string]> = [[1e-18, 'a'], [1e-15, 'f'], [1e-12, 'p'], [1e-9, 'n'], [1e-6, 'μ'], [1e-3, 'm'], [1, '']];
            const tdScaled: TTrendField = { ...td };

            if (td.baseUnits === 'C') {
                let scale = 1;
                const max = Math.max(...td.sensorTrends.map(st => Math.max(...st.trendPoints.map(tp => tp.scaledValue))));
                const maxF = max / 1000;
                const scaleInst = scales.find(s => maxF < s[0]);
                if (scaleInst) {
                    tdScaled.baseUnits = `${scaleInst[1]}C`;
                    scale = 1 / scaleInst[0];
                    tdScaled.sensorTrends = td.sensorTrends.map(st => ({ ...st, trendPoints: st.trendPoints.map(tp => ({ ...tp, scaledValue: tp.scaledValue * scale })) }));
                }
            }
            return tdScaled;
        }

        const generateCharts = (trendData: TTrendData) => {

            const allCharts: Array<{ options: ChartOptions<"line">, chart: ChartData<"line"> }> = trendData.trendFields.map(td => {
                let allTimePoints = Array.from(new Set(td.sensorTrends.reduce((acc: number[], st) => acc.concat(st.trendPoints.map(tp => tp.timestamp)), []))).sort();

                const tdScaled: TTrendField = handlScaling(td);

                const startOffs = startOffset[td.rangeLabel] ?? 0;
                const endOffs = endOffset[td.rangeLabel] ?? 100;

                const origLength = allTimePoints.length;
                allTimePoints.splice(Math.ceil(origLength * (endOffs / 100)));
                allTimePoints.splice(0, Math.floor(origLength * (startOffs / 100)));

                if (last3Months[td.rangeLabel]) {
                    const cutOffDate = new Date();
                    cutOffDate.setMonth(cutOffDate.getMonth() - 3);
                    const cutOffDateSeconds = cutOffDate.getTime() / 1000;
                    allTimePoints = allTimePoints.filter(tp => tp > cutOffDateSeconds);
                }

                const options: ChartOptions<"line"> = createChartOptions(tdScaled, zeroBased?.[tdScaled.rangeLabel] ? 0 : undefined);
                const datasets = createDatasets(tdScaled, allTimePoints);

                const chart: ChartData<"line"> = createChartData(allTimePoints, datasets) as ChartData<"line">;
                return { options, chart };
            });
            setCharts(allCharts);
        }

        if (trendData) {
            setCharts([]);
            generateCharts(trendData);
        }
    }, [trendData, startOffset, endOffset, last3Months]);

    /*   const getChart = (index: number, chart: ChartData<"line">, options: ChartOptions<"line">, show: boolean) => {
           const rangeLabel = trendData?.trendFields[index]?.rangeLabel ?? '';
           return (trendData && trendData.trendFields[index] ? (<div key={`chart-${index}`}>
               {(show) ?
                   <TrendChartWithControls rangeLabel={rangeLabel} goToReport={(v) => goToReport(index, v)} options={options} chart={chart}
                       startOffset={startOffset[rangeLabel]} endOffset={endOffset[rangeLabel]}
                       updateStartOffset={(value) => updateStartOffset(rangeLabel, value)} updateEndOffset={(value) => updateEndOffset(rangeLabel, value)}
                       zeroBased={zeroBased[rangeLabel]} setZeroBased={(v) => setZeroBased(rangeLabel, v)} />
                   : null}</div>) : null)
               ;
       }*/

    const getChartItems = () => (
        trendData ? charts.map(({ chart, options }, index) => ({
            title: trendData.trendFields[index]?.rangeLabel ?? '', initiallyExpanded: true, children:
                <TrendChartAccordionItem trendData={trendData} index={index} show={show} goToReport={goToReport} options={options}
                    chart={chart} startOffset={startOffset} endOffset={endOffset} updateStartOffset={updateStartOffset}
                    updateEndOffset={updateEndOffset} zeroBased={zeroBased} zeroBase={zeroBase} last3Months={last3Months} setLast3Months={onSetLast3Months} />
        })) : []);


    return (<>
        {loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> : null}
        {!loading && trendData ? <div className="grid grid-cols-1 max-h-full">
            {charts.length === 0 ? <div className="mt-8 text-large font-bold text-center">No data found</div> :
                <Accordion id='charts-accordion' options={{ minPadding: true }} alwaysOpen={true} items={getChartItems()} />}
        </div> : null}
    </>);
}

interface ITrendChartAccordionItemProps {
    trendData: TTrendData | undefined;
    index: number;
    show: boolean
    goToReport: (dataIndex: number, itemIndex: number) => void;
    options: ChartOptions<"line">;
    chart: ChartData<"line">;
    startOffset: Record<string, number>;
    endOffset: Record<string, number>;
    updateStartOffset: (itemTitle: string, value: number) => void;
    updateEndOffset: (itemTitle: string, value: number) => void;
    zeroBased: Record<string, boolean>;
    zeroBase: (itemTitle: string) => void;
    last3Months: Record<string, boolean>;
    setLast3Months: (itemTitle: string) => void;
}

const TrendChartAccordionItem: React.FunctionComponent<ITrendChartAccordionItemProps> = ({ trendData, index, show, goToReport, options, chart,
    startOffset, endOffset, updateStartOffset, updateEndOffset, zeroBased, zeroBase, last3Months, setLast3Months }) => {
    const rangeLabel = trendData?.trendFields[index]?.rangeLabel ?? '';
    return (trendData && trendData.trendFields[index] ? (<div key={`chart-${index}`}>
        {(show) ? <TrendChartWithControls rangeLabel={rangeLabel} goToReport={(v) => goToReport(index, v)} options={options} chart={chart}
            startOffset={startOffset[rangeLabel]} endOffset={endOffset[rangeLabel]}
            updateStartOffset={(value) => updateStartOffset(rangeLabel, value)} updateEndOffset={(value) => updateEndOffset(rangeLabel, value)}
            zeroBased={zeroBased[rangeLabel]} zeroBase={() => zeroBase(rangeLabel)} last3Months={last3Months[rangeLabel]} setLast3Months={() => setLast3Months(rangeLabel)} />
            : null}</div>) : null);
}


interface ITrendChartWithControlsProps {
    rangeLabel: string;
    goToReport: (dataIndex: number) => void;
    options: ChartOptions<"line">;
    chart: ChartData<"line">;
    startOffset: number;
    endOffset: number;
    updateStartOffset: (value: number) => void;
    updateEndOffset: (value: number) => void;
    zeroBased: boolean;
    zeroBase: () => void;
    last3Months: boolean;
    setLast3Months: () => void;
}

const TrendChartWithControls: React.FunctionComponent<ITrendChartWithControlsProps> = ({ rangeLabel, goToReport, options, chart,
    startOffset, endOffset, updateStartOffset, updateEndOffset, zeroBased, zeroBase, last3Months, setLast3Months }) => {
    return (<><div className='w-full grid grid-flow-col grid-cols-[auto_auto_auto]'>
        <div>
            <input id={`range0-${rangeLabel}`} className='h-1 mb-6 w-24 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700' type='range' min='0' max='100' value={startOffset ?? 0} onChange={(e) => updateStartOffset(parseFloat(e.target.value))} />
            <label htmlFor={`range0-${rangeLabel}`} className={`text-right pr-2 mt-1 text-sm ps-1`}>Start</label>
        </div>
        <div>
            <input id={`range1-${rangeLabel}`} className='h-1 mb-6 w-24 bg-gray-200 rounded-lg appearance-none cursor-pointer  dark:bg-gray-700' type='range' min='0' max='100' value={endOffset ?? 100} onChange={(e) => updateEndOffset(parseFloat(e.target.value))} />
            <label htmlFor={`range1-${rangeLabel}`} className={`text-right pr-2 mt-1 text-sm ps-1`}>End</label>
        </div>
        <div className='justify-self-end'>
            <input id={`input3months-${rangeLabel}`} type="checkbox" onChange={setLast3Months} checked={last3Months ?? false} className='w-2 h-3 m-2  text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
            <label htmlFor={`input3months-${rangeLabel}`} className={`text-right pr-2 mt-1 text-sm`}>Last 3 months</label>

            <input id={`inputZero-${rangeLabel}`} type="checkbox" onChange={zeroBase} checked={zeroBased ?? false} className='w-2 h-3 m-2  text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
            <label htmlFor={`inputZero-${rangeLabel}`} className={`text-right pr-2 mt-1 text-sm`}>Zero based</label>
        </div>
    </div>
        <LineChart options={options} chart={chart} onClick={(dataIndex) => goToReport(dataIndex)} />
    </>)
}
