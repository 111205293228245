import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

interface ILogoutButtonProps {
    unAuthorised: boolean;
}

export const LogoutButton: React.FunctionComponent<ILogoutButtonProps> = ({ unAuthorised }) => {
    const { logout } = useAuth0();
    const { t } = useTranslation();
    return (
        <button className='text-white disabled:text-hvpd-grey-700 bg-hvpd-red-500 border-hvpd-red-400 hover:bg-hvpd-red-300 text-xs rounded-md px-2 py-1 ' onClick={() => logout({ returnTo: window.location.origin })}>
            {t('Log Out')}
        </button>
    );
};
