import React, { CSSProperties, useEffect, useState } from "react";

import { ResultsTableHeader } from "../ResultsTableHeader";

import {
    IColumnHeader, IUser,
} from "../../../store/Interfaces";


export interface IUserSelectTableProps {
    users: IUser[];
    selectedUsers: string[];
    setSelectedUsers: (selectedUsers: string[]) => void;
}


export const UserSelectTable: React.FunctionComponent<IUserSelectTableProps> = ({ users, selectedUsers, setSelectedUsers }) => {
    const [sorting, setSorting] = useState<{
        column: keyof IUser | '', direction: -1 | 1
    }>({ column: '', direction: 1 });


    const onClickTableColumn = (colHeader: IColumnHeader) => {
        if (sorting.column === colHeader.propValue && sorting.direction === -1) {
            setSorting({ column: '', direction: 1 });
        }
        else {
            setSorting({ column: colHeader.propValue as keyof IUser, direction: (sorting?.column !== colHeader.propValue ? 1 : sorting.direction * -1) as -1 | 1 });
        }
    };

    const colSort = (users: IUser[]) => {
        if (sorting.column) {
            return [...users].sort((a, b) =>
                (a[sorting.column as keyof IUser] > b[sorting.column as keyof IUser] ? 1 : -1) * sorting.direction)
        }
        return users;
    }

    const columnHeaders: IColumnHeader[] = [
        {
            title: 'First', type: 'String', propValue: 'firstName', propDisplay: 'firstName'
        }, {
            title: 'Last', type: 'String', propValue: 'lastName', propDisplay: 'lastName'
        }, {
            title: 'Email', type: 'String', propValue: 'emailAddress', propDisplay: 'emailAddress'
        }];

    const onClickRow = (user: IUser) => {
        if (selectedUsers.includes(user.uuid)) {
            setSelectedUsers(selectedUsers.filter(u => u !== user.uuid))
        } else {
            setSelectedUsers([...selectedUsers, user.uuid]);
        }
    }


    return (<div className='max-h-64 block overflow-y-auto'>
        <table className='table-auto w-full border-slate-400 border'>
            <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                <tr>

                    {columnHeaders.filter(ch => !ch.isCheckbox).map((colHeader, idx) => (
                        <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} isExpanded={true} colHeader={colHeader} sortColumn={null} />
                    ))}
                </tr>
            </thead>
            <tbody className="overflow-y-auto">
                {colSort(users ?? []).filter(u => !selectedUsers.includes(u.uuid)).map(row => (
                    <tr tabIndex={0} key={row['uuid'].toString()} role='button' className="hover:bg-hvpd-grey-200 bg-hvpd-grey-50 text-sm">
                        {columnHeaders.map((colHeader, idx) => {
                            let style: CSSProperties = {};

                            return (<td key={`col-${idx} `} tabIndex={1} onClick={() => onClickRow(row)} className={`text-sm overflow-clip text-gray-900 font-light px-1 py-0 text-left border border-grey-900 `} style={style}>
                                {row[colHeader.propValue as keyof IUser] ?? ''}
                            </td>)
                        })
                        }
                    </tr>))}
            </tbody>
        </table>
    </div>)
}