import React, { useEffect, useRef, useState } from 'react';

export interface IContextMenuProps {
    pos: { x: number, y: number };
    menuShown: boolean;
    closeMenu: () => void;
    children: React.ReactNode;
}

export const ContextMenu: React.FunctionComponent<IContextMenuProps> = ({ pos, menuShown, closeMenu, children }) => {
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [offsetY, setOffsetY] = useState(0);
    const handleClick = () => {
        setOffsetY(0);
        closeMenu();
    }
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (menuShown) {
            setTimeout(() => {
                const menuBase = ((menuRef.current?.clientHeight ?? 0) + (menuRef.current?.offsetTop ?? 0));
                if (menuBase > window.innerHeight) {
                    setOffsetY(window.innerHeight - menuBase - 10);
                }
            }, 5);
        } else {
            setOffsetY(0);
        }

    }, [menuShown, pos.y]);


    return <>{menuShown ? <div ref={menuRef} className='z-[2002] bg-white border-0 shadow-slate-700 rounded divide-y divide-gray-100 shadow-xl dark:bg-gray-700 fixed' style={{
        top: (pos.y + offsetY),
        left: pos.x,
    }}><ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
            {children}
        </ul>
    </div> : null}
    </>
}