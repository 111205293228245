

import {
    IAsset, ICompany, ISite, TTestType, TTrendData
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;


export const getTrendReport = async (token: string, uuid: string, testType: string, abortController: AbortController): Promise<Uint8Array> => {
    const url = `${getApiEndpoint()}/Asset/${uuid}/TrendReport/${testType}`;
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'GET',
            signal: abortController.signal,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.arrayBuffer();
            } else {
                //                       dispatch(addError(`Error getting the latest asset report [${resp.status}]`));
                throw (new Error('Error in report download'));
            }
        }).then(text => {
            if (text) {
                resolve(new Uint8Array(text));
            } else {
                throw (new Error('No data in report download'));
            }
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            console.error(e.message);
        })
    })
    return p;
}


export const getTrendDataPoints = (token: string, assetUuid: string, testType: TTestType): Promise<TTrendData> => {
    const url = `${getApiEndpoint()}/Asset/${assetUuid}/TrendData/${testType}`;
    const p = new Promise<TTrendData>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error('Error fetching trend data'));
            }
        }).then(json => {
            resolve(sanitise(json));
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.message);
        });
    });
    return p;
}

export const downloadTrendReport = (token: string, asset: IAsset, sites: ISite[], companies: ICompany[], type: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Asset/${asset.uuid}/TrendReport/${type}?attach=true`;
    const pResult = new Promise<boolean>(resolveResult => {

        const p = new Promise<Blob>((resolve, reject) => {
            fetchWithAuthRedirect(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(resp => {
                if (resp.ok) {
                    resolve(resp.blob());
                } else {
                    throw (new Error('Error downloading trend reports'));
                }
            }).catch(e => {
                dispatch(addError(e.message));
                reject();
            })
        });
        p.then(blob => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            const d = new Date().toDateString();
            const siteName = sites.find(site => site.uuid === asset?.siteUuid)?.name ?? '';
            const companyName = companies.find(company => company.uuid === asset?.companyUuid)?.name ?? '';
            const fileName = asset ? `Trend - ${companyName} - ${siteName} - ${asset?.name} - ${d}.pdf` : 'TrendReport.pdf';
            link.setAttribute(
                'download',
                fileName
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
            resolveResult(true);
        }).catch(e => {
            resolveResult(false);
            //  setReportError('Failed to collect report for this item');
        })
    })
    return pResult;
}
