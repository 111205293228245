import { createContext } from "react";

import { TImageListAsset } from "../Images/Images";

interface IReportGroupContext {
    imageListAsset: TImageListAsset;
    loadingImages: boolean;
    imageBlobs: Record<string, string>;
    refreshImageList: () => void;
}

export const ReportGroupContext = createContext<IReportGroupContext>({ imageListAsset: { asset: '', images: [] }, loadingImages: false, imageBlobs: {}, refreshImageList: () => { } });
