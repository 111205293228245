import React, { useEffect, useState } from "react";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setSubSites, setTableDefinitionComplete } from "../../../store/mainSlice";
import { ICompany, ISite, ISubSite } from "../../../store/Interfaces";
import { FormTemplate, TEntity, IFormField, FormContext } from "../FormTemplate";
import { createSubSite, updateSubSite, deleteSubSite } from "../../../api";
import { Busy } from "../../Busy";
import { subSiteEditForm, mapEnumsToForm } from "../../../templates/formTemplates";
const TABLENAME = 'TableSubSite';

interface ISubSiteEditProps {
    subSiteUuid?: string;
    endEdit: () => void;
    clearFocus: () => void;
}

export const SubSiteEdit: React.FunctionComponent<ISubSiteEditProps> = ({ subSiteUuid, endEdit, clearFocus }) => {
    const dispatch = useDispatch();
    const { sites, companies, subSites, token, tableDefinitionsComplete } = useSelector(store => store.main);
    const [wipSubSite, setWipSubSite] = useState<ISubSite>();
    const [busy, setBusy] = useState(false);
    const [entityChanged, setEntityChanged] = useState(false);
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(subSiteEditForm);
    const [dependantFields, setDependantFields] = useState<Array<{ name: string, value: string }>>([]);
    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);


    useEffect(() => {
        if (subSiteUuid && subSites) {
            //const tx = tableDefinitionsComplete.TableSubSite.rows.find(r => r.uuid === subSiteUuid);;
            //const s = subSites.find(s => s.name === tx?.name);
            const s1 = subSites.find(ss => ss.uuid === subSiteUuid);
            if (s1) {
                setWipSubSite(s1);
                const constrained: Array<{ name: string, value: string }> = [];
                if (s1.companyUuid) {
                    constrained.push({ name: 'companyUuid', value: s1.companyUuid });
                }
                setDependantFields(constrained);
            }
        }
    }, [subSiteUuid])

    const siteSort = (a: ISite, b: ISite) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }
    const companySort = (a: ICompany, b: ICompany) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }
    useEffect(() => {
        if (companies && sites && tableDefinitionsComplete[TABLENAME]?.searchParams) {
            let localFormWithOptions = mapEnumsToForm(subSiteEditForm, tableDefinitionsComplete[TABLENAME]?.searchParams);
            let availableSites = sites;
            const dfCompany = dependantFields.find(df => df.name === 'companyUuid');
            if (dfCompany) {
                availableSites = sites.filter(s => s.companyUuid === dfCompany.value);
            }
            localFormWithOptions = localFormWithOptions.map(field => {
                if (field.propValue === 'siteUuid') {
                    return { ...field, options: ([...availableSites] ?? []).sort(siteSort).map(site => ({ name: site?.name ?? '', value: site.uuid })) }
                } else if (field.propValue === 'companyUuid') {
                    return { ...field, options: ([...companies] ?? []).sort(companySort).map(company => ({ name: company?.name ?? '', value: company.uuid })) }
                } else {
                    return field
                }
            })
            setFormWithOptions(localFormWithOptions);
        }
    }, [sites, companies, dependantFields, tableDefinitionsComplete])

    const onFieldChange = (field: string, value: string) => {
        if (field === 'companyUuid') {
            setDependantFields([...dependantFields.filter(df => df.name !== field), { name: field, value: value }]);
            return [{ name: 'siteUuid', value: '' }];
        }
        return [];
    }

    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createSubSite(token, entity as ISubSite).then((subSite) => {
                dispatch(addToast('SubSite created'));
                setBusy(false);
                setWipSubSite(subSite);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
                dispatch(setSubSites([...subSites, subSite as ISubSite]));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            })
        } else {
            setBusy(true);
            updateSubSite(token, entity as ISubSite).then((subSite) => {
                dispatch(addToast('SubSite updated'));
                setBusy(false);
                setWipSubSite(subSite);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
                dispatch(setSubSites(subSites.map(ss => ss.uuid === subSite.uuid ? subSite as ISubSite : ss)));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            });
        }
    }

    const onDelete = () => {
        setBusy(true);
        deleteSubSite(token, wipSubSite?.uuid as string).then(() => {
            dispatch(setSubSites(subSites.filter(c => c.uuid !== wipSubSite?.uuid)));
            dispatch(setTableDefinitionComplete([TABLENAME, null]))
            clearFocus();
            dispatch(addToast('SubSite deleted'));
            setWipSubSite(undefined);
            setBusy(false);
        }).catch((err) => {
            setBusy(false);
            dispatch(addError(err.message));
            console.error(err);
        });
    }

    return (
        <div className='m-2'>
            <div className="relative z-30  w-full h-full">
                {busy ? <Busy /> :
                    <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                        <FormTemplate entityName='Sub site' form={formWithOptions} entity={wipSubSite as TEntity} babysitFieldChange={onFieldChange} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={onDelete} onEndEdit={endEdit} />
                    </FormContext.Provider>}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}

        </div>)
}