
import {
    ISubSite
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;


export const getSubSite = async (token: string, uuid: string): Promise<ISubSite | string> => {
    const url = `${getApiEndpoint()}/SubSite/${uuid}`;
    const p = new Promise<ISubSite>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the sub-site [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as ISubSite);
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        })
    });
    return p;
}

export const getSubSites = async (token: string): Promise<Array<ISubSite> | string> => {
    const url = `${getApiEndpoint()}/SubSite`;
    const p = new Promise<ISubSite[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the sub-site list [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as Array<ISubSite>);
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        })
    });
    return p;
}

export const searchSubSites = async (token: string, uuids: string[], abortController: AbortController): Promise<ISubSite[] | string> => {
    const url = `${getApiEndpoint()}/SubSite/search`;
    const p = new Promise<ISubSite[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal,
            method: 'POST',
            body: JSON.stringify({
                propName: "uuidStr",
                matches: uuids
            })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error getting the users [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as ISubSite[]);
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.name);
        });
    });
    return p;
}


export const createSubSite = async (token: string, subSite: ISubSite): Promise<ISubSite> => {
    const url = `${getApiEndpoint()}/SubSite`;
    const p = new Promise<ISubSite>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...subSite, uuid: undefined })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error creating subsite [${resp.status}]`));
            }
        }).then(json => {
            const siteReturned: ISubSite = json as ISubSite;
            resolve(sanitise(siteReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error creating');
        });
    })
    return p;
}

export const updateSubSite = async (token: string, subSite: ISubSite): Promise<ISubSite> => {
    const url = `${getApiEndpoint()}/SubSite`;
    const p = new Promise<ISubSite>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(subSite)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error updating subsite [${resp.status}]`));
            }
        }).then(json => {
            const siteReturned: ISubSite = json as ISubSite;
            resolve(sanitise(siteReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error updating');
        });
    })
    return p;
}


export const deleteSubSite = async (token: string, uuid: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/SubSite/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Error deleting subsite [${resp.status}]`));
            }
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error deleting.');
        });
    })
    return p;
}