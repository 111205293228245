import React, { useContext, useEffect, useState, FormEventHandler } from "react";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { ActionMeta } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { SelectPair } from "../../SelectPair";
import { UserSelect } from "../../ResultsTable/UserSelect";
import { ConfirmationModal } from "../../ConfirmationModal";
import { LocationModal } from "./LocationModal";
import { IUser } from "../../../store/Interfaces";
import { FormButtonGroup } from "./FormButtonGroup";
import { IFormContext, FormContext } from "./FormContext";
import { hasChanged, createEntity } from "./formUtils";
import { deepEqual } from '../../../utils';

import "./FormTemplate.css";


export type TEntity = Record<string, any>;

export type TOptions = Array<{ name: string, value: string | number, entityValue?: IUser }>;

type OptionType = Record<string, any>;
type OptionsType = ReadonlyArray<OptionType>;

export interface IFormField {
    type: 'String' | 'Checkbox' | 'Date' | 'Select' | 'MultiSelect' | 'MultiSelectPair' | 'Number' | 'NumberPair' | 'UserSelect'
    propValue: string | [string, string];
    propDisplay: string;
    propLabels?: [string, string];
    required?: boolean;
    options?: TOptions;
}
export interface IFormTemplateProps {
    form: IFormField[];
    setForm?: (form: IFormField[]) => void;
    entity: TEntity;
    entityName: string;
    babysitFieldChange?: (name: string, value: string) => Array<{ name: string, value: string }>;
    entityChanged?: (changed: boolean) => void;
    onEndEdit?: () => void;
    onSubmit?: (entity: TEntity) => void;
    onDelete?: () => void;
    canCreate?: boolean;
    children?: React.ReactNode;
}
const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        padding: '0rem',
        minHeight: '1rem',
        width: '94%',

    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        fontSize: '0.875rem',
        lineHeight: '1rem',
        padding: '0.1rem'
    }),
    menuList: (provided: any, state: any) => {
        return { ...provided, fontSize: '0.75rem' }
    },
    dropdownIndicator: (provided: any, state: any) => {
        return { ...provided }
    },
    menu: (provided: any, state: any) => {
        return { ...provided, }
    },
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    listBox: (provided: any, state: any) => {
        return { ...provided, backgroundColor: 'red', position: 'absolute' }
    }
};

export const FormTemplate: React.FunctionComponent<IFormTemplateProps> = ({ form, setForm, entity, entityName, entityChanged, babysitFieldChange, onSubmit, onDelete, onEndEdit, canCreate, children }) => {
    const { t } = useTranslation();
    const { wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmClear, setConfirmClear, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete } = useContext<IFormContext>(FormContext);
    const [locationField, setLocationField] = useState<IFormField | null>(null);

    const [entityStack, setEntityStack] = useState<Array<TEntity>>([]);

    useEffect(() => {
        if (entityStack.length === 0 || !deepEqual(entityStack[entityStack.length - 1], wipEntity)) {
            const newStack = [...entityStack, { ...wipEntity }];
            if (newStack.length > 20) {
                setEntityStack(newStack.slice(1));
            }
            else {
                setEntityStack(newStack);
            }
        }
    }, [wipEntity]);

    const onUndo = () => {
        setWipEntity(entityStack[entityStack.length - 2]);
        setEntityStack(entityStack.slice(0, entityStack.length - 1));
    }

    useEffect(() => {
        if (entity) {
            setWipEntity({ ...entity });
        }
        else {
            setWipEntity(createEntity(form));
        }
        setEntityStack([]);
    }, [entity])

    const overWriteEntity = () => {
        setConfirmOverwrite(false);
        setWipEntity({ ...entity });
    }

    const deleteEntity = () => {
        setConfirmDelete(false);
        onDelete?.();
    }

    const clearForm = () => {
        if (hasChanged(entity, form, wipEntity)) {
            setConfirmClear(true);
        } else {
            const entity = createEntity(form);
            setWipEntity(entity);
        }
    }

    const clearEntity = () => {
        setConfirmClear(false);
        const entity = createEntity(form);
        setWipEntity(entity);
    }

    const endEdit = () => {
        if (hasChanged(entity, form, wipEntity)) {
            setConfirmEndEdit(true);
        }
        else {
            setWipEntity({ ...entity });
        }
    }
    const doEndEdit = () => {
        setConfirmEndEdit(false);
        setWipEntity({ ...entity });
    }

    useEffect(() => {
        const changed = hasChanged(entity, form, wipEntity);
        entityChanged?.(changed);
    }, [wipEntity])

    const updateTextField = (field: IFormField, value: string, index?: number) => {
        if (Array.isArray(field.propValue)) {
            setWipEntity((p: TEntity) => (p ? { ...p, [field.propValue[index ?? 0]]: value } : p))
        }
        else setWipEntity((p: TEntity) => (p ? { ...p, [field.propValue as string]: value } : p))
    }

    const updateBooleanField = (field: IFormField, value: boolean) => {
        setWipEntity((p: TEntity) => (p ? { ...p, [field.propValue as string]: value } : p))
    }

    const updateDateField = (field: IFormField, value: string) => {
        const d = new Date(value);
        setWipEntity((p: TEntity) => (p ? { ...p, [field.propValue as string]: d.getTime() / 1000 } : p))
    }

    const updateSelectField = (field: IFormField, value: string) => {
        setWipEntity((p: TEntity) => (p ? { ...p, [field.propValue as string]: value } : p))
    }

    const getDateFromField = (field: IFormField): string => {
        const value = wipEntity?.[field.propValue as string]?.toString() ?? ''
        const d = new Date(parseInt(value, 10) * 1000);
        return d.toISOString().split('T')[0];
    }
    /* const getSelectOptions1 = (values: TOptions) => {
         return values.map(value => ({
             label: value.name,
             value: value.value
         }))
     }
    */
    const getSelectOptions = (values: TOptions) => {
        return values.map(value => ({ label: value.name, value: value.value }));
    }

    const getSingleSelectValue = (value: string | number, options: TOptions) => {
        const option = options.find(o => o.value === value) ?? { name: '', value: '' };
        return { label: option.name, value: option.value };
    }
    const getMultiSelectValue = (value: Array<string | number>, options: TOptions): OptionsType => {
        const optionsOut = (options.filter((o => (value ?? []).includes(o.value))) ?? [{ name: '', value: '' }]).map(o => ({ label: o.name, value: o.value }));
        return optionsOut
    }

    const onSelectChange = (newValue: OptionsType, actionMeta: ActionMeta<OptionType>, field: IFormField): void => {
        if (actionMeta.action === "select-option" || actionMeta.action === "remove-value" || actionMeta.action === "clear" || actionMeta.action === "create-option") {
            //          console.log(newValue, actionMeta);
            setWipEntity((p: TEntity) => (p ? { ...p, [field.propValue.toString()]: newValue.map(v => v.value) } : p))
            // filterChange(newValue.map(nv => nv.value) as TValueType, matches);
        }
    }
    const onSingleSelectChange = (newValue: { label: string, value: string | number } | null, actionMeta: ActionMeta<{ label: string, value: string | number }>, field: IFormField): void => {
        if (actionMeta.action === "select-option" || actionMeta.action === "remove-value" || actionMeta.action === "clear" || actionMeta.action === "create-option") {
            const updatedEntity = { ...wipEntity }
            if (undefined !== babysitFieldChange) {
                const updatedDependancies = babysitFieldChange?.(field.propValue.toString(), newValue?.value.toString() ?? '');
                updatedDependancies?.forEach(dep => {
                    updatedEntity[dep.name] = dep.value;
                });
            }
            updatedEntity[field.propValue.toString()] = newValue?.value ?? '';
            setWipEntity(updatedEntity);
        }
    }
    const onUserSelectChange = (newValue: string[], field: IFormField) => {
        setWipEntity((p: TEntity) => (p ? { ...p, [field.propValue.toString()]: newValue } : p));
    }

    const onSelectPairChange = (newValue: Array<string | number>, field: IFormField) => {
        setWipEntity((p: TEntity) => (p ? { ...p, [field.propValue.toString()]: newValue } : p));
    }

    const formSubmit: FormEventHandler<HTMLFormElement> | undefined = (e) => {
        e.preventDefault();
        if (wipEntity) {
            onSubmit?.(wipEntity);
        }
    }
    const onNPPaste = (e: React.ClipboardEvent<HTMLInputElement>, field: IFormField) => {
        const data = e.clipboardData.getData('Text').split(',').map(v => v.trim());
        if (data.length >= 2) {
            e.preventDefault();
            updateTextField(field, data[0], 0);
            updateTextField(field, data[1], 1);
        }
    }
    const invokeLocationModal = (field: IFormField) => {
        console.log('invokeLocationModal', field);
        setLocationField(field);
    }
    const closeLocationModal = () => {
        setLocationField(null);
    }
    const updateLocationField = (location: { x: number, y: number }) => {
        if (locationField) {
            setWipEntity((p: TEntity) => (p ? { ...p, [locationField.propValue[0]]: location.x, [locationField.propValue[1]]: location.y } : p))
        }
        setLocationField(null);
    }
    const flipMulti = (field: IFormField) => () => {
        setForm?.(form.map(f =>
            f.propValue !== field.propValue ? f : ({ ...f, type: f.type === 'MultiSelectPair' ? 'MultiSelect' : 'MultiSelectPair' })
        ))
    }

    return (<> {confirmOverwrite ? <ConfirmationModal show={confirmOverwrite} confirmationText={t('You have unsaved changes. Are you sure you want to discard them?')} onCancel={() => setConfirmOverwrite(false)} onConfirm={overWriteEntity} /> : null}
        {confirmClear ? <ConfirmationModal show={confirmClear} confirmationText={t('You have unsaved changes. Are you sure you want to discard them?')} onCancel={() => setConfirmClear(false)} onConfirm={clearEntity} /> : null}
        {confirmEndEdit ? <ConfirmationModal show={confirmEndEdit} confirmationText={t('You have unsaved changes. Are you sure you want to abandon this edit?')} onCancel={() => setConfirmEndEdit(false)} onConfirm={doEndEdit} /> : null}
        {confirmDelete ? <ConfirmationModal show={confirmDelete} confirmationText={t('Are you sure you want to delete this thing', { thing: entityName.toLowerCase() })} onCancel={() => setConfirmDelete(false)} onConfirm={deleteEntity} /> : null}
        <form className="grid grid-rows-[min-content_min-content_max-content_2.5em] h-full overflow-y-auto" onSubmit={formSubmit}>
            <div>
                <h1 className="font-semibold px-2 py-2 text-xxl text-hvpd-pickled-bluewood-300">{entityName} {wipEntity?.uuid ? t('edit') : t('create')}</h1>
                <hr />
            </div>
            {wipEntity?.name ? <div><h2 className="font-semibold px-2 py-2 text-lg text-hvpd-pickled-bluewood-300">{wipEntity?.name}</h2><hr /></div> : <span />}
            <div className="grid grid-cols-[1fr_3fr] gap-1 overflow-hidden mt-2">
                {form.map((field) => {
                    const pv = field.propValue.toString();
                    return <React.Fragment key={pv}>
                        {field.type === 'Date' && <>
                            <label className={`mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2 ${field.required ? 'required' : ''}`} htmlFor={pv}>{field.propDisplay}</label>
                            {wipEntity?.[pv] !== undefined ? <input type='date' id={pv} value={getDateFromField(field)} onChange={e => updateDateField(field, e.target.value)} className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500' /> : null}</>}
                        {field.type === 'Checkbox' && <><label htmlFor={pv} className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2">{field.propDisplay}</label>
                            <input id={pv} type='checkbox' checked={wipEntity?.[pv] as boolean ?? false} onChange={e => updateBooleanField(field, e.target.checked)} className='w-3 h-3 m-2  text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
                        </>}
                        {field.type === 'String' && (
                            <>
                                <label className={`mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2 ${field.required ? 'required' : ''}`} htmlFor={pv}>{field.propDisplay}</label>
                                <input className='px-1 w-64 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                    id={pv} type='text' required={field.required ?? undefined} value={(wipEntity?.[pv] ?? '').toString() ?? ''} onChange={e => updateTextField(field, e.target.value)} />
                            </>)
                        }
                        {field.type === 'Number' && (
                            <>
                                <label className={`mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2 ${field.required ? 'required' : ''}`} htmlFor={pv}>{field.propDisplay}</label>
                                <input className='px-1 w-32 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                    id={pv} type='number' required={field.required ?? undefined} value={(wipEntity?.[pv] ?? '').toString() ?? ''} onChange={e => updateTextField(field, e.target.value)} />
                            </>)
                        }
                        {field.type === 'NumberPair' && (
                            <>
                                <span className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2" >{field.propDisplay}</span>
                                <div className='flex'>
                                    <label className={`whitespace-nowrap font-semibold text-right text-sm pr-2 mt-1 ${field.required ? 'required' : ''}`} htmlFor={(field.propLabels ?? [])?.[0] ?? 'np0'}>{(field.propLabels ?? [])?.[0] ?? 'np0'}</label>
                                    <input className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                        id={(field.propLabels ?? [])?.[0] ?? 'np0'} type='number' onPaste={e => onNPPaste(e, field)} required={field.required ?? undefined} value={(wipEntity?.[field.propValue[0]] ?? '').toString() ?? ''} onChange={e => updateTextField(field, e.target.value, 0)} />
                                    <label className={`ml-1 whitespace-nowrap font-semibold text-right text-sm pr-2 mt-1 ${field.required ? 'required' : ''}`} htmlFor={(field.propLabels ?? [])?.[0] ?? 'np0'}>{(field.propLabels ?? [])?.[1] ?? 'np1'}</label>

                                    <input className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                        id={(field.propLabels ?? [])?.[1] ?? 'np1'} type='number' onPaste={e => onNPPaste(e, field)} required={field.required ?? undefined} value={(wipEntity?.[field.propValue[1]] ?? '').toString() ?? ''} onChange={e => updateTextField(field, e.target.value, 1)} />

                                    <button type='button' className='h-7 ml-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' onClick={() => invokeLocationModal(field)}><FontAwesomeIcon icon={solid('globe')} /> </button>
                                </div>
                            </>)
                        }
                        {(field.type === 'Select') ?
                            <>
                                <label className={`mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2 ${field.required ? 'required' : ''}`} htmlFor={`select-${pv}}`}>{field.propDisplay}</label>
                                <Select
                                    options={getSelectOptions(field.options ?? [])}
                                    id={`select-${pv}`}
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    menuPlacement='auto'
                                    styles={customStyles}
                                    required={field.required ?? undefined}
                                    onChange={(newValue, actionMeta) => onSingleSelectChange(newValue, actionMeta, field)}
                                    value={getSingleSelectValue((wipEntity?.[pv]) ?? null, field?.options ?? [])} />
                            </> : null}
                        {(field.type === 'MultiSelect' && (field.options ?? []).length) ?
                            (<>
                                <div>
                                    <div className='text-right'>
                                        {setForm ? <button type='button' className='text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-1 py-0 border-solid border-1 rounded-sm text-xs font-medium inline' onClick={flipMulti(field)}>&#x2016;</button> : null}
                                        <label className={`mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2 ${field.required ? 'required' : ''}`} htmlFor={`multiselect-${pv}`} >{field.propDisplay}</label>
                                    </div>
                                </div>
                                <Select
                                    options={getSelectOptions(field.options ?? [])}
                                    id={`multiselect-${pv}`}
                                    styles={customStyles}
                                    required={field.required ?? undefined}
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    menuPlacement='auto'
                                    isMulti
                                    onChange={(newValue, actionMeta) => onSelectChange(newValue, actionMeta, field)}
                                    value={getMultiSelectValue((wipEntity?.[pv]) ?? null, field?.options ?? [])} />
                            </>) : null}
                        {field.type === 'MultiSelectPair' && (field.options ?? []).length ?
                            <>
                                <div>
                                    <div className='text-right'>
                                        {setForm ? <button type='button' className='text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-1 py-0 border-solid border-1 rounded-sm text-xs font-medium inline' onClick={flipMulti(field)}>-</button> : null}
                                        <label className={`mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2 ${field.required ? 'required' : ''}`} htmlFor={`multiselect-${pv}`} >{field.propDisplay}</label>
                                    </div>
                                </div>
                                <SelectPair name={pv} onChange={p => onSelectPairChange(p, field)} availablePairs={field.options ?? []} existingValues={(wipEntity?.[pv] ?? [])} />
                            </> : null}
                        {field.type === 'UserSelect' && (<>
                            <label className={`mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2 ${field.required ? 'required' : ''}`} htmlFor={`select-${pv}}`}>{field.propDisplay}</label>
                            <UserSelect name='Users' users={(field.options ?? []).map(u => u.entityValue) as IUser[]} selectedUsers={(wipEntity?.[pv] ?? [])} setSelectedUsers={(v) => onUserSelectChange(v, field)} /></>)}

                    </React.Fragment>
                }
                )}
            </div>
            {children ? children :
                <FormButtonGroup wipEntity={wipEntity} canCreate={canCreate} clearForm={clearForm} onEndEdit={onEndEdit ? endEdit : undefined} onDelete={onDelete ? () => setConfirmDelete(true) : undefined} setConfirmDelete={setConfirmDelete} undo={entityStack.length > 1 ? onUndo : undefined} />
            }
        </form >
        {
            locationField ? <LocationModal close={closeLocationModal} save={updateLocationField} locationField={locationField} original={{ x: parseFloat(entity?.[locationField.propValue[0]]), y: parseFloat(entity?.[locationField.propValue[1]]) }} current={{ x: parseFloat(wipEntity?.[locationField.propValue[0]]), y: parseFloat(wipEntity?.[locationField.propValue[1]]) }
            } /> : null
        }
    </>)
}