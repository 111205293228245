
import {
    IUser, IUserGroup
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;

export const createUser = async (token: string, userIn: IUser): Promise<IUser> => {
    const url = `${getApiEndpoint()}/CreateNewUser`;
    const user = sanitise({
        firstName: userIn.firstName,
        lastName: userIn.lastName,
        emailAddress: userIn.emailAddress,
        phoneNumber: userIn.phoneNumber,
        userRoles: userIn.userRoles,
        userPermissions: userIn.userPermissions,
        userGroups: userIn.userGroups,
        companies: userIn.companies,
        projects: userIn.projects,
    });
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error creating user [${resp.status}]`));
            }
        }).then(json => {
            const userReturned: IUser = json as IUser;
            resolve(sanitise(userReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}

export const updateUser = async (token: string, userIn: IUser): Promise<IUser> => {
    const url = `${getApiEndpoint()}/User`;
    const user = sanitise({
        firstName: userIn.firstName,
        lastName: userIn.lastName,
        emailAddress: userIn.emailAddress,
        phoneNumber: userIn.phoneNumber,
        userRoles: userIn.userRoles,
        userPermissions: userIn.userPermissions,
        userGroups: userIn.userGroups,
        companies: userIn.companies,
        projects: userIn.projects,
        uuid: userIn.uuid
    });
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error updating user [${resp.status}]`));
            }
        }).then(json => {
            const userReturned: IUser = json as IUser;
            resolve(sanitise(userReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}

export const getUser = async (token: string, uuid: string, abortController: AbortController): Promise<IUser | string> => {
    const url = `${getApiEndpoint()}/User/${uuid}`;
    const p = new Promise<IUser>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting user [${resp.status}]`));
            }
        }).then(json => {
            const user: IUser = json;
            resolve(sanitise(user));
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject('Error fetching...');
        });
    });
    return p;
}

export const getUsers = async (token: string): Promise<Array<IUser> | string> => {
    const url = `${getApiEndpoint()}/User`;
    const p = new Promise<IUser[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the user list [${resp.status}]`));
            }
        }).then(json => {
            const users: Array<IUser> = json;
            resolve(sanitise(users));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    });
    return p;
}

export const getCurrentUser = async (token: string): Promise<IUser> => {
    const url = `${getApiEndpoint()}/CurrentUser`;
    const p = new Promise<IUser>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the current user [${resp.status}]`));
            }
        }).then(json => {
            const user: IUser = json;
            resolve(sanitise(user));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}

export const searchUsers = async (token: string, uuids: string[], abortController: AbortController): Promise<IUser[] | string> => {
    const url = `${getApiEndpoint()}/User/search`;
    const p = new Promise<IUser[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal,
            method: 'POST',
            body: JSON.stringify({
                propName: "uuidStr",
                matches: uuids
            })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error getting the users [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IUser[]);
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.name));
            }
            reject(e.name);
        });
    });
    return p;
}


export const getUserGroups = async (token: string): Promise<Array<IUserGroup>> => {
    const url = `${getApiEndpoint()}/UserGroup`;
    const p = new Promise<IUserGroup[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the user list [${resp.status}]`));
            }
        }).then(json => {
            const users: Array<IUserGroup> = json;
            resolve(sanitise(users));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    });
    return p;
}

export const createUserGroup = async (token: string, userGroup: IUserGroup): Promise<IUserGroup> => {
    const url = `${getApiEndpoint()}/UserGroup`;
    const p = new Promise<IUserGroup>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...userGroup, uuid: undefined })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error creating user group [${resp.status}]`));
            }
        }).then(json => {
            const userGroupReturned: IUserGroup = json as IUserGroup;
            resolve(sanitise(userGroupReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}

export const updateUserGroup = async (token: string, userGroup: IUserGroup): Promise<IUserGroup> => {
    const url = `${getApiEndpoint()}/UserGroup`;
    const p = new Promise<IUserGroup>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userGroup)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error updating user group [${resp.status}]`));
            }
        }).then(json => {
            const userGroupReturned: IUserGroup = json as IUserGroup;
            resolve(sanitise(userGroupReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}


export const deleteUserGroup = async (token: string, uuid: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/UserGroup/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Error deleting user group [${resp.status}]`));
            }
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error deleting.');
        });
    })
    return p;
}