import React, { useEffect, useRef, useState, useMemo } from "react";

import { TDocumentDescriptor, TResultType, TTestType } from '../../store/Interfaces';
import { feToEt } from '../ReportGroup';

import { Report } from './Report';

export interface INestedReportProps {
    token: string;
    uuid: string;
    resultEndpointName: TTestType;
    entityType: TResultType;
    reportHistory: Array<TDocumentDescriptor>;
}

export const NestedReport: React.FunctionComponent<INestedReportProps> = (props) => {
    const { token, uuid, resultEndpointName, entityType, reportHistory } = props;
    const reportGroupRef = useRef<HTMLDivElement>(null);
    const [resultHistoryIndex, setResultHistoryIndex] = useState<number>(-1);
    const [loadingReport, setLoadingReport] = useState<boolean>(false);

    useEffect(() => {
        setResultHistoryIndex(0);
        setLoadingReport(false);
    }, []);

    useEffect(() => {
        setResultHistoryIndex(0);
    }, [token, uuid, resultEndpointName, entityType]);

    const memoisedReportHistory = useMemo(() => ({
        uuid,
        history: reportHistory
    }), [uuid, reportHistory]);

    return <div ref={reportGroupRef} className="h-[90vh]">
        <Report container={reportGroupRef.current} visible={true} uuid={uuid} entityType={feToEt[entityType]} resultEndpointName={resultEndpointName} resultHistory={memoisedReportHistory} resultHistoryIndex={resultHistoryIndex} setResultHistoryIndex={setResultHistoryIndex} loadingHistory={false} loading={loadingReport} setLoading={setLoadingReport} />
    </div>
};