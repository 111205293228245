import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector } from "../../../hooks";

import { SelectPair } from "../../SelectPair";
import { TOptions } from "../FormTemplate";
import { IAsset } from "../../../store/Interfaces";

interface IAssetSelectorProps {
    assetUuid: string;
    selectedAssets: string[];
    setSelectedAssets: (assets: string[]) => void;
    onSelectionComplete: () => void;
}

export const AssetSelector: React.FunctionComponent<IAssetSelectorProps> = ({ assetUuid, selectedAssets, setSelectedAssets, onSelectionComplete }) => {
    const { t } = useTranslation();
    const { assets, sites, subSites } = useSelector(store => store.main);
    const [availableAssets, setAvailableAssets] = useState<TOptions>([]);
    const [constrainBySameCompany, setConstrainBySameCompany] = useState(true);

    const existing = assets.find(a => a.uuid === assetUuid);
    const siteName = (asset: IAsset) =>
        sites.find(s => s.uuid === asset.siteUuid)?.name ?? '';
    const subSiteName = (asset: IAsset) =>
        subSites.find(s => s.uuid === asset.siteUuid)?.name ?? '';

    const getAssetName = (asset: IAsset) => {
        const site = siteName(asset);
        const subSite = subSiteName(asset);
        if (site && subSite) {
            return `${asset.name} - (${site} - ${subSite})`;
        } else if (site) {
            return `${asset.name} - (${site})`;
        }
        return asset.name;
    }

    useEffect(() => {
        if (assets && assetUuid) {
            if (existing) {
                setAvailableAssets(assets.filter(a => a.uuid !== assetUuid && (!constrainBySameCompany || a.companyUuid === existing.companyUuid)).map(a => ({ name: getAssetName(a), value: a.uuid })).sort((a, b) => a.name.localeCompare(b.name)));
            }
        }
    }, [assetUuid, assets, constrainBySameCompany]);


    const onSelectPairChange = (newValue: Array<string | number>) => {
        setSelectedAssets(newValue.map(v => v.toString()));
    }


    return (<><div className="flex flex-shrink-0 items-center justify-between rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
        <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
            {t('Select assets for edit')}
        </h5>
    </div>
        <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 py-1">
            <div className={`grid  grid-cols-1`}>
                <div>
                    <div className='grid grid-cols-[2fr_1fr] mb-3'>
                        <div className='inline-flex'>
                            <span className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400'>{t('Name')}</span>
                            <span id='recommendationName' className="w-80 text-lg" >{existing?.name}</span>
                        </div>

                    </div>
                    <input defaultChecked={constrainBySameCompany} type="checkbox" role="switch" value={constrainBySameCompany ? 'true' : 'false'} onChange={() => setConstrainBySameCompany(!constrainBySameCompany)}
                        className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-hvpd-pickled-bluewood-200 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:hvpd-pickled-bluewood-300 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_3px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:hvpd-pickled-bluewood-500 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-pickled-bluewood-400 dark:checked:after:bg-pickled-bluewood-400 dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]" id="flexSwitchCheckDefault" />
                    <label className="form-check-label inline-block text-gray-800" htmlFor="flexSwitchCheckDefault">{t('Constrain by company')}</label>

                    <SelectPair name='assetSelect' labelSelected='Selected' onChange={p => onSelectPairChange(p)} availablePairs={availableAssets} existingValues={selectedAssets} />
                    <button type="button" onClick={onSelectionComplete} disabled={selectedAssets.length === 0} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{t('Apply selection')}</button>
                </div>
            </div>
        </div></>);


}