import React, { useEffect, useMemo, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import CopyToClipboard from "react-copy-to-clipboard";

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../hooks';
import {
    addToast,
    setTableDefinitionComplete,
} from "../../../store/mainSlice";
import {
    IColumnHeader, TResultRow, ISortColumn, TReportEntityGroup,
    TTableResultEndpoint, ITableDefinitionResult, ILatLng, TExtendedFilter, TSlideOver
} from "../../../store/Interfaces";
import { getTableDefinition, getApiEndpoint } from "../../../api";
import { ResultsTableHeader } from "../ResultsTableHeader";
import { ResultsTableRow } from "../ResultsTableRow";
import { ContextMenu } from "../../ContextMenu";
import { getFilteredSortedResults, jsonToCsv, downloadCSV } from "../utils";
import { TBounds } from "../../../Grid/Grid";

export interface IAssetTableProps {
    tableEndPoint: TTableResultEndpoint | undefined;
    bounds: TBounds | null;
    boundsFiltered: 'map' | 'sld' | '';
    allowedAssets: string[];
    onClickRow: (e: React.MouseEvent, row?: TResultRow) => void;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    resultFilterText: string;
    setHighlightLatLng: (ll: ILatLng | undefined) => void;
    setSlideOver: (slideOver: TSlideOver) => void;
    selectedEntity: TReportEntityGroup | undefined;
    refresh: number;
}

const TABLENAME = 'TableAsset';

export const AssetTable: React.FunctionComponent<IAssetTableProps> = ({ allowedAssets, onClickTableColumn, bounds, boundsFiltered, onClickRow, resultFilterText, sortColumn, setHighlightLatLng, selectedEntity, refresh }) => {
    const dispatch = useDispatch();
    const tableHeader = useRef<HTMLTableRowElement>(null);

    const { token, tableDefinitionsComplete } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);
    const [menuShown, setMenuShown] = useState(false);
    const [contextPos, setContextPos] = useState({ x: 0, y: 0 });
    const [contextRow, setContextRow] = useState<TResultRow | undefined>(undefined);
    const [expandedHeaders, setExpandedHeaders] = useState<Record<string, boolean>>({});

    const tableResults: ITableDefinitionResult = useMemo(() => tableDefinitionsComplete[TABLENAME] ? tableDefinitionsComplete[TABLENAME] : { columnHeaders: [], rows: [], searchParams: [] }, [tableDefinitionsComplete]);
    const [filteredSortedResults, setFilteredSortedResults] = useState<TResultRow[]>([]);

    const [ac, setAc] = useState<AbortController>();


    /* the following are variables associated with table virtualisation */
    const [itemRowHeight, setItemRowHeight] = useState(22); // same height as each row
    const [headerWidths, setHeaderWidths] = useState<number[]>([]);
    const [displayStart, setDisplayStart] = useState(0);
    const [displayEnd, setDisplayEnd] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, [allowedAssets]);

    useEffect(() => {
        const getTable = async (ac: AbortController) => {
            const filter: TExtendedFilter[] = [];

            if (allowedAssets.length > 0) {
                filter.push({ propName: "asset.uuidStr", matches: allowedAssets });
            }

            setLoading(true);
            getTableDefinition(token, { name: TABLENAME, endpoint: `${getApiEndpoint()}/${TABLENAME}` }, filter, true, ac).then((res) => {
                dispatch(setTableDefinitionComplete([TABLENAME, res]));
                setLoading(false);
                if (tableResults.columnHeaders.length > 0) {
                    setExpandedHeaders(tableResults.columnHeaders.reduce((acc: Record<string, boolean>, ch) => {
                        acc[ch.title] = true;
                        return acc;
                    }, {}));
                }
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });
        };

        if (token && ac && !tableDefinitionsComplete[TABLENAME]) {
            getTable(ac);
        }
    }, [allowedAssets, token, tableDefinitionsComplete, ac, dispatch]);

    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] });
    }

    useEffect(() => {
        setHeaderWidths([]);
    }, [filteredSortedResults, expandedHeaders, resultFilterText]);

    const handleContextMenu = (e: MouseEvent, row: TResultRow) => {
        e.preventDefault();
        setContextPos({ x: e.pageX, y: e.pageY });
        setContextRow(row);
        setMenuShown(true);
    };

    const onExport = (selection: 'all' | 'selected') => {
        setMenuShown(false);
        let filteredSortedResults: TResultRow[] = (undefined === tableResults.rows || 0 === tableResults.rows.length) ? [] :
            getFilteredSortedResults(tableResults.rows, bounds, boundsFiltered, resultFilterText, allowedAssets, sortColumn, tableResults.columnHeaders);

        let columns: string[][] = [];
        columns.push(tableResults.columnHeaders.map(ch => ch.title));
        columns = columns.concat(filteredSortedResults.map(row =>
            tableResults.columnHeaders.map((ch, idx) => {
                if (row[ch.propDisplay] === undefined) {
                    console.log(`Assets table - Missing ${ch.propDisplay} in row ${idx}`)
                }
                return (row[ch.propDisplay] ?? '').toString();
            })
        ));
        const csvString = jsonToCsv(columns);
        downloadCSV(csvString, `Asset export - ${new Date().toLocaleString()}.csv`);
    }

    const onCopyToClipboard = () => {
        setMenuShown(false);
        dispatch(addToast(`Asset GUID ${contextRow?.uuid.toString() ?? 'Error'} copied to clipboard`));
    }

    useEffect(() => {
        const localFilteredSortedResults: TResultRow[] = (undefined === tableResults || 0 === tableResults.rows.length) ? [] :
            getFilteredSortedResults(tableResults.rows, bounds, boundsFiltered, resultFilterText, [], sortColumn, tableResults.columnHeaders);
        if (localFilteredSortedResults.length !== filteredSortedResults.length || localFilteredSortedResults.some((r, idx) => r.uuid !== filteredSortedResults[idx].uuid)) {
            setFilteredSortedResults(localFilteredSortedResults);
        } else if (tableResults.rows.length === 0 && filteredSortedResults.length > 0) {
            setFilteredSortedResults([]);
        }
    }, [tableResults, bounds, boundsFiltered, resultFilterText, sortColumn]);


    /******* TABLE VIRTUALISATION */

    const screenHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
    ); // get the height of the screen
    const offset = screenHeight; // We want to render more than we see, or else we will see nothing when scrolling fast
    const rowsToRender = Math.floor((screenHeight + offset) / itemRowHeight);

    /*******************/

    const setDisplayPositions = React.useCallback(
        (scroll: number) => {
            // we want to start rendering a bit above the visible screen
            const scrollWithOffset = Math.floor(scroll - rowsToRender - offset / 2);
            // start position should never be less than 0
            const displayStartPosition = Math.round(
                Math.max(0, Math.floor(scrollWithOffset / itemRowHeight))
            );

            // end position should never be larger than our data array
            const displayEndPosition = Math.round(
                Math.min(displayStartPosition + rowsToRender, filteredSortedResults.length)
            );

            setDisplayStart(displayStartPosition);
            setDisplayEnd(displayEndPosition);
        },
        [filteredSortedResults.length, offset, rowsToRender, itemRowHeight]
    );

    const onTableScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (filteredSortedResults.length !== 0) {
            setScrollPosition(e.currentTarget.scrollTop);
            setDisplayPositions(e.currentTarget.scrollTop);
        }
    }

    // We want to set the display positions on rendering
    useEffect(() => {
        setDisplayPositions(scrollPosition);
    }, [scrollPosition, setDisplayPositions]);

    /*************************************/



    // add a filler row at the top. The further down we scroll the taller this will be

    let rows: React.JSX.Element[];
    if (headerWidths.length > 0) {
        rows =
            [<tr
                key="startRowFiller"
                style={{ height: displayStart * itemRowHeight }}
            ></tr>];

        // add the rows to actually render
        for (let i = displayStart; i < displayEnd; ++i) {
            const resultRow = filteredSortedResults[i];
            if (resultRow !== undefined) {
                rows.push(
                    <ResultsTableRow key={`row-${i}`} columnHeaders={tableResults.columnHeaders} row={resultRow} handleContextMenu={handleContextMenu} onClickRow={onClickRow} expandedHeaders={expandedHeaders} setHighlightLatLng={setHighlightLatLng} selected={selectedEntity?.type === 'assets' && selectedEntity.uuid === resultRow.uuid} />
                );
            }
        }

        // add a filler row at the end. The further up we scroll the taller this will be
        rows.push(
            <tr
                key="endRowFiller"
                style={{ height: (filteredSortedResults.length - displayEnd) * itemRowHeight }}
            ></tr>
        );
    } else {
        // Create small sample table consisting of the widest rows from each column and use that to calculate the widths
        rows = [];
        if (filteredSortedResults.length > 0) {
            let localExpandedHeaders = { ...expandedHeaders };
            if (Object.keys(expandedHeaders).length === 0) {
                localExpandedHeaders = tableResults.columnHeaders.reduce((acc: Record<string, boolean>, ch) => {
                    acc[ch.title] = true;
                    return acc;
                }, {});
                setExpandedHeaders(localExpandedHeaders);
            }
            const initialRow = { ...filteredSortedResults[0] };
            tableResults.columnHeaders.forEach((colHeader => initialRow[colHeader.propDisplay] = colHeader.title));
            filteredSortedResults.forEach((row, i) => {
                tableResults.columnHeaders.forEach(colHeader => {
                    const text = row[colHeader.propDisplay]?.toString() ?? '';
                    if (text.length > initialRow[colHeader.propDisplay].toString().length) {
                        initialRow[colHeader.propDisplay] = text;
                    }
                });
            });
            rows = [initialRow].map((resultRow, idx) =>
                <ResultsTableRow key={`row-${idx}`} columnHeaders={tableResults.columnHeaders} row={resultRow} handleContextMenu={handleContextMenu} onClickRow={onClickRow} expandedHeaders={localExpandedHeaders} setHighlightLatLng={setHighlightLatLng} selected={false} />);
        }

        /* rows = filteredSortedResults.map((resultRow, i) => <ResultsTableRow key={`row-${i}`} columnHeaders={definitions.columnHeaders} row={resultRow} showModal={(row) => setShowModal(row)}
             handleContextMenu={handleContextMenu}
             onClickRow={(e) => onClickRow(e, resultRow)} onMouseOver={onMouseOver} expandedHeaders={expandedHeaders} setHighlightLatLng={setHighlightLatLng}
             selected={selectedAssets.some(sa => sa === resultRow.assetUuid)} forReporting={assetsForReporting.some(a4r => a4r.assetUuid === resultRow.assetUuid)} />) */
    }

    // recalculate the header widths when the table contentents are changed

    const WIDTH_PADDING = 8;

    useEffect(() => {
        if (tableHeader.current && headerWidths.length === 0 && filteredSortedResults.length > 0) {
            const headerWidthsLocal = Array.from(tableHeader.current.childNodes as NodeListOf<HTMLTableCellElement>).map(tableHead => tableHead.getBoundingClientRect());
            if (headerWidthsLocal.length) {
                setItemRowHeight(headerWidthsLocal[0].height);
                setHeaderWidths(headerWidthsLocal.map(h => h.width + WIDTH_PADDING));
            }
        };
    }, [tableHeader, headerWidths, filteredSortedResults]);

    return <>
        {loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> :
            <div className='resultsTableClipper relative overflow-y-auto bg-hvpd-grey-50' onScroll={onTableScroll}>
                {rows.length > 0 ? <table className={`${headerWidths.length === 0 ? 'table-auto' : 'table-fixed'} min-w-full w-full border-slate-400 border`}>
                    <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                        <tr ref={tableHeader}>
                            {tableResults.columnHeaders.map((colHeader, i) => (
                                <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} headerWidth={headerWidths.length > 0 ? headerWidths[i] : undefined} expandedHeaders={expandedHeaders} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                            ))}
                        </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                        {rows}
                    </tbody>
                </table> :
                    <div className='w-full flex mt-20'>
                        <div className='flex-grow text-center'><span>No results to display</span>
                            <button onClick={onClickRow} className='ms-2 flex-grow h-7 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md'>Show panel</button>
                        </div>
                    </div>}
            </div>
        }
        <ContextMenu pos={contextPos} menuShown={menuShown} closeMenu={() => setMenuShown(false)} >
            <li className='w-full'><button onClick={() => onExport('all')} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>Export table to CSV</button></li>
            <li className='w-full'><CopyToClipboard text={contextRow?.uuid.toString() ?? 'Error'} ><button onClick={() => onCopyToClipboard()} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>Copy asset GUID to clipboard</button></CopyToClipboard></li>
        </ContextMenu >
    </>
}