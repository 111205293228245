import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { addToast, addError, setAssets, setTableDefinitionComplete } from "../../../store/mainSlice";
import { createAsset } from "../../../api";
import { Busy } from "../../Busy";

import { IAsset } from "../../../store/Interfaces";
const TABLENAME = 'TableAsset';

interface ITemplateOperationsModalProps {
    asset: IAsset;
    close: () => void;
}

export const TemplateOperationsModal: React.FunctionComponent<ITemplateOperationsModalProps> = ({ asset, close }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { assets, token } = useSelector(store => store.main);
    const [busy, setBusy] = useState(false);
    const [templateCount, setTemplateCount] = useState<number>(1);
    const [templateNames, setTemplateNames] = useState<string>(asset.name);
    const [templateName, setTemplateName] = useState<string>(asset.name);
    const [badNames, setBadNames] = useState<string[]>([]);

    const generateTemplateNames = (count: number, name: string) => {
        const names = Array.from({ length: count }, (_, i) => `${name} (${i + 1})`).join('\n');
        return names;
    }

    useEffect(() => {
        if (templateCount > 0 && templateCount < 51) {
            setTemplateNames(generateTemplateNames(templateCount, templateName));
        } else if (templateCount < 0) {
            setTemplateCount(1);
        } else if (templateCount > 50) {
            setTemplateCount(50);
        }
    }, [templateCount, templateName])

    useEffect(() => {
        const nameList = templateNames.split('\n').map(n => n.trim());
        setBadNames(Array.from(new Set(nameList.map((n, idx, nameList) => {
            if (nameList.some((nlx, nlxIdx) => ((idx !== nlxIdx) && (nlx.toLowerCase() === n.trim().toLowerCase())))) {
                return n;
            }
            if (assets.some(asset => asset.name.toLowerCase() === n.trim().toLowerCase())) {
                return n;
            }
            return '';
        }).filter(n => n !== ''))))
    }, [templateNames, assets, templateName])

    const save = () => {
        setBusy(true);
        const nameList = templateNames.split('\n');
        const newAssets = nameList.map(n => ({ ...asset, name: n.trim(), uuid: undefined }));
        const successes: Array<IAsset> = [];
        const failures: Array<boolean> = [];
        const allUpdates = newAssets.map(asset => {
            return createAsset(token, asset as unknown as IAsset).then((asset) => {
                successes.push(asset);
            }).catch((err) => {
                dispatch(addError(err.message));
                failures.push(true);
                setBusy(false);
                console.error(err);
            })
        });
        Promise.all(allUpdates).then(() => {
            setBusy(false);
            if (successes.length > 0) {
                dispatch(setAssets([...assets, ...successes]));
                dispatch(addToast(successes.length === 1 ? t('One asset has been added') : t('n assets have been added', { count: successes.length })));
            } if (failures.length > 0) {
                dispatch(addError(failures.length === 1 ? t('One asset could not be added') : t('n assets could not be added', { count: failures.length })));
            }
            dispatch(setTableDefinitionComplete([TABLENAME, null]))
            close()
        })
    }

    const nameMismatch = templateCount !== templateNames.split('\n').map(t => t.trim()).filter(t => t.length).length;
    return (
        <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

            <div className="fixed inset-0 z-[3000] overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-5xl">
                        <div className="flex flex-shrink-0 items-center justify-between rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                            <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                                {t('Asset template operations')}
                            </h5>
                            <button type="button" onClick={close} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label={t('Close')}>
                                <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                            </button>
                        </div>
                        {busy ? <Busy /> :
                            <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 py-1">
                                <div className='grid grid-cols-[1fr_3fr] gap-1 overflow-auto mt-2'>
                                    <label htmlFor='templateName' className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2">{t('Asset name')}</label>
                                    <input name='templateName' id='templateName' value={templateName} onChange={e => setTemplateName(e.target.value)} className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'/>
                                    <label htmlFor='templateNumber' className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2">{t('Number of new assets')}</label>
                                    <input name='templateNumber' id='templateNumber' type='number' min={1} max={50} value={templateCount} onChange={e => setTemplateCount(parseInt(e.target.value, 10))} className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'/>
                                    <label htmlFor='templateNames' className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2">{t('Names of assets')}</label>
                                    <textarea name="templateNames" id="templateNames" cols={40} rows={5} value={templateNames} onChange={e => setTemplateNames(e.target.value)} className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'></textarea>
                                </div>
                                {badNames.length > 0 ? <div className='text-red-500'>{t('The following names are already in use:')} {badNames.join(', ')}</div> : null}
                                {nameMismatch ? <div className='text-red-500'>{t('The number of names does not match the number of assets')}</div> : null}
                            </div>}
                        <div className="rounded-md bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse">
                            <button type="button" onClick={save} disabled={badNames.length > 0 || busy || nameMismatch} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2">{(templateCount === 1) ? t('Add one new asset') : t('Add count new assets', { count: templateCount })}</button>
                            <button type="button" onClick={close} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{t('Cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}