import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReportGroupContext } from '../../ReportGroup/ReportGroupContext';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import {
    addToast,
} from "../../../store/mainSlice";
import { Busy } from '../../Busy';
import { ImagePicker } from '../ImagePicker';
import { ImageUpload } from '../../Images/ImageUpload';
import { addRecommendation, searchRecommendationTemplates } from '../../../api';
import { TAssetType } from '../../../api/recommendationApi'
import { TEntityType, TSeverity, TResultObservations, IResultRecommendation, IRecommendationTemplate, TTestType } from "../../../store/Interfaces";

interface ICreateRecommendationModalProps {
    show: boolean;
    onCancel: () => void;
    onCreate: (value: IResultRecommendation) => void;
    parentUuid: string;
    assetType: TAssetType;
}

export const CreateRecommendationModal: React.FunctionComponent<ICreateRecommendationModalProps> = ({ show, onCancel, onCreate, parentUuid, assetType }) => {
    const dispatch = useDispatch();
    const { resultFilter, tableResults } = useSelector(state => state.main);

    const { imageListAsset, imageBlobs, refreshImageList } = useContext(ReportGroupContext);
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [showImageUpload, setShowImageUpload] = useState(false)
    const [recommedationTemplates, setRecommendationTemplates] = useState<IRecommendationTemplate[]>([]);
    const editorRef = useRef<Editor>(null);
    const [templateSelected, setTemplateSelected] = useState<boolean>(false);
    const [recommendationTemplateUuid, setRecommendationTemplateUuid] = useState<string | undefined>('');
    const [busy, setBusy] = useState(false);

    const [recommendation, setRecommendation] = useState<
        { name: string; severity: TSeverity, description: string }>({ name: '', severity: 'Low', description: ' ' });

    const { token, assets } = useSelector(state => state.main);

    const getAssetUuid = () => {
        if (assetType === 'Result') {
            const r = tableResults?.[resultFilter?.name as TTestType];
            if (r) {
                const resultRow = r.rows.find(r => r.resultUuid === parentUuid);
                if (resultRow) {
                    return resultRow.assetUuid;
                }
            }
        }
        return parentUuid;
    }

    useEffect(() => {
        const ac = new AbortController();
        editorRef.current?.getInstance().focus();
        let assetUuid = getAssetUuid();
        if (show && assetUuid) {
            setRecommendationTemplateUuid('');
            setTemplateSelected(false);
            setLoadingTemplates(true);
            const asset = assets.find(a => a.uuid === assetUuid);
            if (asset && asset.assetType) {
                searchRecommendationTemplates(token, asset.assetType, ac)
                    .then((localRecommendations) => {
                        setRecommendationTemplates(localRecommendations.sort((a, b) => (a.name + a.description).localeCompare(b.name + b.description)));
                        setLoadingTemplates(false);
                    }).catch(e => {
                        if (ac.signal.aborted) {
                            console.log('Aborted getting recommendation templates')
                        }
                        setLoadingTemplates(false);
                        if (!ac.signal.aborted) {
                            console.error('Failed to get recommendation templates', e);
                            setTemplateSelected(true);
                        }
                    });
            }
        } else if (show && parentUuid) {
            setTemplateSelected(true);
        }
        return () => {
            ac.abort();
        }
    }, [show]);


    const entityTypeToAssetType: Record<keyof TResultObservations, TEntityType> = {
        'Result': 'Asset',
        'Asset': 'Asset',
        'Company': 'Company',
        'Site': 'Site',
        'Subsite': 'SubSite',
    }
    const selectTemplate = (uuid: string) => {
        setRecommendationTemplateUuid(uuid);
        const template = recommedationTemplates.find(t => t.uuid === uuid);
        if (template) {
            setRecommendation({ name: template.name, severity: template.severity, description: template.description });
        }
    }

    const save = async () => {
        setBusy(true);
        try {
            const payload: IResultRecommendation = { ...recommendation, parentUuid, recommendationDate: Math.floor(new Date().getTime() / 1000) } as unknown as IResultRecommendation;
            const newRecommendation: IResultRecommendation = await addRecommendation(token, assetType, payload);
            dispatch(addToast(`Added recommendation ${newRecommendation.name}`));
            setBusy(false);
            onCreate(newRecommendation);
        } catch (e) {
            console.error(e);
            setBusy(false);
            onCancel();
        }
    }

    const onClickImage = (base64: string, metadata: string) => {
        const markdown = editorRef.current?.getInstance().getMarkdown();
        editorRef.current?.getInstance().setMarkdown(`${markdown} ![${metadata}](${base64})`);
    }

    const imageUploaded = () => {
        refreshImageList();
        dispatch(addToast('Image upload successful'));
    }

    const recommendationIsIncomplete = recommendation.name === '' || recommendation.description === '';
    const hasImages = imageListAsset.images.some(image => (imageBlobs[image.name] ?? '').length > 0);

    return (show ? <><div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-[3000] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-5xl">
                    <div className="flex flex-shrink-0 items-center justify-between rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                            Create {assetType.toLowerCase()} recommendation
                        </h5>
                        <button type="button" onClick={onCancel} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label="Close">
                            <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                        </button>
                    </div>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 py-1">
                        {loadingTemplates ? <div className='mb-20 mx-[12rem]'><div>Loading templates</div><Busy /></div> : null}
                        {busy && !loadingTemplates ? <div className='mb-20 mx-[12rem]'><Busy /></div> : null}
                        {!busy && !templateSelected ?
                            <div>
                                <div className='inline-flex'>
                                    <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="recommendationSeverity">Select a template</label>
                                    <select id='recommendationTemplate' name='recommendationTemplate' className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border' value={recommendationTemplateUuid} onChange={e => selectTemplate(e.target.value)}>
                                        <option disabled value=''>Select a template</option>
                                        {recommedationTemplates.map(template => (<option key={template.uuid} value={template.uuid}>{`${template.name} : ${template.description}`.substring(0, 80)}</option>))}
                                    </select>
                                </div>
                                <button className='float-right ml-2 mb-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' disabled={!recommendationTemplateUuid} onClick={() => setTemplateSelected(true)}>Select{loadingTemplates ? <span className='ms-1'><Busy size='xs' /></span> : null}</button>
                            </div> : null}
                        {!busy && !loadingTemplates && templateSelected ? <div className={`grid ${hasImages ? 'grid-cols-[580px_380px]' : 'grid-cols-1'}`}>
                            <div>
                                <div className='grid grid-cols-2 mb-3'>
                                    <div className='inline-flex'>
                                        <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="recommendationName">Name</label>
                                        <input type="text" id='recommendationName' name='recommendationName' className="w-40 p-1 text-sm rounded-sm border-hvpd-grey-700 focus:hvpd-grey-500 active:hvpd-grey-500 border inline" value={recommendation.name} onChange={e => setRecommendation({ ...recommendation, name: e.target.value })} />
                                    </div>
                                    <div className='inline-flex'>
                                        <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="recommendationSeverity">Severity</label>
                                        <select id='recommendationSeverity' name='recommendationSeverity' className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border' value={recommendation.severity} onChange={e => setRecommendation({ ...recommendation, severity: e.target.value as TSeverity })}>
                                            <option>Low</option><option>Medium</option><option>High</option>
                                        </select>
                                    </div>

                                </div>

                                <label className='block font-bold mb-1 text-lg text-hvpd-pickled-bluewood-400' htmlFor="recommendationDetails">Recommendation details</label>
                                <Editor
                                    initialValue={recommendation.description}
                                    placeholder='Enter recommendation details here...'
                                    previewStyle="vertical"
                                    height="460px"
                                    onChange={(e) => {
                                        const markDown = editorRef.current?.getInstance().getMarkdown();
                                        setRecommendation(markDown ? { ...recommendation, description: markDown } : { ...recommendation, description: '' });
                                    }}
                                    ref={editorRef}
                                    initialEditType="wysiwyg"
                                    useCommandShortcut={true}
                                />
                            </div>
                            {hasImages ? <div>
                                <ImagePicker onClickImage={onClickImage} />
                                <div className='w-full flex-row-reverse'>
                                    <button className='mx-1 mb-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' onClick={() => setShowImageUpload(true)}>Upload image</button>
                                </div>
                            </div> : null}

                        </div> : null}
                    </div>

                    <div className="rounded-md bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse">
                        <button type="button" onClick={save} disabled={recommendationIsIncomplete || busy || !templateSelected} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2">Save</button>
                        <button type="button" onClick={onCancel} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">Cancel</button>
                        {templateSelected ? <button className=' mr-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' onClick={() => setTemplateSelected(false)}>Select a different template</button> : null}
                    </div>
                </div>
            </div>
        </div>
    </div>
        <ImageUpload showModal={showImageUpload} onClose={() => setShowImageUpload(false)} assetUuid={getAssetUuid()} entityType={entityTypeToAssetType[assetType]} onError={(error) => dispatch(addToast(error))} onSuccessfulUpload={imageUploaded} />
    </> : null)

}
