import React, { useEffect, useState, ReactElement } from "react";
import { Busy } from "../../Busy";
import { useAppSelector as useSelector } from '../../../hooks';
import { keyToTitle, sanitise } from '../../../utils';
import {
    IUser
} from "../../../store/Interfaces";
import { ProjectName } from "../ProjectName";
import { getUser } from "../../../api";
export interface IUserProps {
    uuid: string;
}

const embargoedUserKeys: Array<keyof IUser> = ['uuid'];

interface IElementRowProps {
    title: string;
    value: string | ReactElement;
    index: number;
}

const ElementRow: React.FunctionComponent<IElementRowProps> = ({ title, value, index }) => (
    <><div className={`font-semibold text-right pr-2 border-r-2 ${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:hover:bg-slate-200`}>{title}</div><div className={`${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:bg-slate-200`}>{value}</div></>
)

const keysToTitle: Record<string, string> = {
    'address': 'Address',
    'ratedPower_kW': 'Rated Power kW', 'voltage_kV': 'Voltage kV', 'ratedSpeedRPM': 'Rated Speed RPM', 'kva': 'kVA',
    addressZIP: 'Zip Code', logoURL: 'Logo', 'taxReferenceID': 'Tax Ref'
};

const UserPermissions: React.FunctionComponent<{ permissions: string[] }> = ({ permissions }) => (
    <div className="flex flex-wrap">
        {permissions.map((p, ix) => <div key={`${p}_${ix}`} className="bg-slate-100 text-slate-800 rounded-md px-3 py-1 text-sm font-semibold mr-2 mb-2">{p}</div>)}
    </div>
)

export const UserDetails: React.FunctionComponent<IUserProps> = ({ uuid }) => {
    const { token, companies } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<IUser | undefined>(undefined);

    useEffect(() => {
        const myAbortController = new AbortController();
        if (token && uuid) {
            setLoading(true);
            // currently getting _all_ subUsers, not just the one we want           
            getUser(token, uuid, myAbortController).then((res) => {
                if (res instanceof Object) {
                    setUser(res);
                }
                setLoading(false);
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });

        }
        return () => {
            myAbortController.abort();
        }
    }, [token, uuid]);

    const getCellValue = (user: IUser, key: keyof IUser): ReactElement | string => {
        if (key === 'projects') {
            return <ProjectName uuids={user[key]} />;
        } else if (key === 'companies') {
            return <>{(user[key] ?? []).map((u, ix) => {
                const company = companies.find(c => c.uuid === u);
                return <span className='mr-1' key={`${company?.uuid}_${ix}`}>{company?.name ?? ''}</span>
            })}</>;

        } else if (key === 'userPermissions') {
            return <UserPermissions permissions={user[key] ?? []} />;
        }
        return sanitise(user[key]?.toString());
    }

    return (
        <div className='overflow-y-auto h-[92%]'>
            {loading ? <Busy /> :
                user ?
                    <div className='grid grid-cols-[2fr_3fr] gap-x-1 text-sm m-2'>{
                        Object.keys(user).filter((key) => !embargoedUserKeys.includes(key as keyof IUser)).sort().map((key, ix) =>
                            (<ElementRow key={`A_${key}_${ix}`} title={keyToTitle(key, keysToTitle)} value={getCellValue(user, key as keyof IUser)} index={ix} />))
                    }
                    </div>
                    : <div>No User found</div>
            }
        </div>
    )
}