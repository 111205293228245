
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAppDispatch as useDispatch } from '../../../hooks';

import { addToast } from "../../../store/mainSlice";

import { TResultRow, TApprovalCount, TApprovalStatus, approveStates } from "../../../store/Interfaces";

import { translateField } from '../utils';

export type IProblemReportModalProps = {
    show: boolean;
    row: TResultRow | undefined;
    onCancel: () => void;
}


export const ProblemReportModal: React.FunctionComponent<IProblemReportModalProps> = ({ show, row, onCancel }) => {
    const dispatch = useDispatch();

    const getReportText = (row: TResultRow | undefined): string => {
        const reportStateText = ['reportStateCounts', 'resultStateCounts'].map((reportGroup, reportIdx) => {
            let text = `${!reportIdx ? 'Report' : 'Result'} state\n`;
            const currentRow: TApprovalCount = row?.[reportGroup] as TApprovalCount;
            text += currentRow ? (
                Object.keys(approveStates).map((key, idx) => {
                    const value = currentRow[key as TApprovalStatus];
                    return value ? `${approveStates[key as TApprovalStatus]}: ${currentRow[key as TApprovalStatus]}\n` : ''
                }).join('')
            ) : '';
            return text;
        }).join('\n');

        return `Company name: ${row?.['companyName']}\nAsset name: ${row?.['assetName']}\nSite name: ${row?.['siteName']}\nTest result type: ${row?.['testResultType']}\n\n${reportStateText}\n${row?.['missingAssetFields'] ? `Missing fields: ${row?.['missingAssetFields']?.map(translateField).join(', ')}\n` : ``}`;
    }

    return (<>{show ? <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div >
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className='grid grid-cols-2 gap-1'>
                            <div className="text-right font-bold">Company name</div>
                            <div>{row?.['companyName']}</div>
                            <div className="text-right font-bold">Asset name</div>
                            <div>{row?.['assetName']}</div>
                            <div className="text-right font-bold">Site name</div>
                            <div>{row?.['siteName']}</div>
                            {row?.['testResultType'] ? <><div className="text-right font-bold">Test result type</div>
                                <div>{row?.['testResultType']}</div></> : null}


                            {(row?.['resultStateCounts'] || row?.['resultStateCounts']) ?
                                <>
                                    {['reportStateCounts', 'resultStateCounts'].map((reportGroup, reportIdx) => {
                                        const currentRow: TApprovalCount = row?.[reportGroup] as TApprovalCount;
                                        return currentRow ? (
                                            <React.Fragment key={reportGroup} >
                                                <div className='col-span-2 text-center font-bold my-1'>{!reportIdx ? 'Report' : 'Result'} state</div>
                                                {Object.keys(approveStates).map((key, idx) => {
                                                    const value = currentRow[key as TApprovalStatus];
                                                    return value ? (<React.Fragment key={`${key} -${idx} `}><div className='text-right'>{approveStates[key as TApprovalStatus]}</div>
                                                        <div className='ml-1 text-left'>{currentRow[key as TApprovalStatus]}</div></React.Fragment>) : null
                                                })
                                                }
                                            </React.Fragment>
                                        ) : null
                                    })} </> : null}
                            {
                                (row?.['missingAssetFields'] ?? []).length > 0 ? <>
                                    <div className='text-right font-bold my-1'>Missing fields</div>
                                    <div className='ml-1 text-left whitespace-break-spaces my-1'>
                                        {
                                            row?.['missingAssetFields']?.map(translateField).join(', ')
                                        }
                                    </div>
                                </> : null
                            }


                        </div>
                    </div>
                    <div className="rounded-md bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" onClick={onCancel} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2">Ok</button>
                        <CopyToClipboard text={getReportText(row)}
                            onCopy={() => dispatch(addToast('Report text copied to clipboard'))}>
                            <span className='cursor-pointer rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2' title='Click to copy to clipboard'>Copy to clipboard</span>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </div>
    </div > : null
    }</>)
}
