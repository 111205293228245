import React, { useEffect, useState } from 'react';

import { useTimeout } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
export interface ISlideOverProps {
    active: boolean;
    onClose: () => void;
    expanded: boolean;
    children?: React.ReactElement;
    rightOffset?: boolean;
    disableClose: boolean;
}

export const SlideOver: React.FunctionComponent<ISlideOverProps> = ({ active, onClose, expanded, children, rightOffset, disableClose }) => {

    // this code block enables smooth transformations despite the z-index fiddling
    const [activeZ, setActiveZ] = useState(active);
    const [activeTimer, setActiveTimer] = useState<number | null>(null);

    //  const [activeTimer, setActiveTimer] = useState<NodeJS.Timeout>();

    const setActiveZOnTimeout = () => setActiveZ(active);

    useTimeout(setActiveZOnTimeout, activeTimer);

    useEffect(() => {
        setActiveTimer(null);
        if (active) {
            setActiveZ(active);
        }
        else {
            setActiveTimer(100);
        }
        return () => {
            setActiveTimer(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    const panelWidth = expanded && !rightOffset ? 'w-7/12' : 'w-5/12';
    const right = rightOffset ? expanded ? 'right-[54%]' : 'right-[41%]' : 'right-0';

    return (
        <div className={`w-screen h-screen flex max-h-0 ${!activeZ ? 'z-[-1]' : 'z-[2001]'}`}>
            <div className={`w-full h-full fixed inset-0 pointer-events-none ${!active ? 'invisible' : ''}`}>
                <div className={`${panelWidth} h-full absolute ${right} duration-100 ease-out transition-all pointer-events-auto bg-stone-50 ${!active ? 'translate-x-full ' : 'shadow-[0px_0px_36px_-8px_rgba(0,30,74,1)]'}`}>
                    {!disableClose ? <button onClick={onClose} className="transform transition duration-500 hover:scale-125 absolute cursor-pointer text-gray-600 top-0 w-8 h-8 flex items-center justify-center right-0">
                        <FontAwesomeIcon icon={solid('xmark')} />
                    </button> : null}
                    {children}
                </div>
            </div>
        </div >
    )
}