import React, { useState } from "react"

interface ICheckboxDropdownProps {
    name: string;
    items: Array<[string, boolean]>;
    onChange: (items: Array<[string, boolean]>) => void;
}

export const CheckboxDropdown: React.FunctionComponent<ICheckboxDropdownProps> = ({ name, items, onChange }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return (<><button id="dropdownSearchButton" onClick={() => setShowDropdown(!showDropdown)} className="text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 text-sm py-1 px-2 border-solid border-1 font-medium inline-flex items-center" type="button">
        {name} <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
        </svg></button >

        <div id="dropdownSearch" className={`z-10 ${!showDropdown ? 'hidden' : ''} bg-white rounded-lg shadow w-60 dark:bg-gray-700`}>
            <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
                {items.map((item, index) => (
                    <li key={item[0]}>
                        <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                            <input id={`checkbox-item-${index}`} checked={item[1]} onChange={() => onChange(items.map(ix => item[0] === ix[0] ? [ix[0], !ix[1]] : ix))} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                            <label htmlFor={`checkbox-item-${index}`} className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{item}</label>
                        </div>
                    </li>
                ))}
            </ul>
        </div></>)
}