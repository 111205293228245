
import {
    TEntityType, IResultHistory
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, tidyCache, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;

export const getResult = async (token: string, uuid: string, abortController: AbortController): Promise<IResultHistory | string> => {
    const url = `${getApiEndpoint()}/Result/${uuid}`;
    const p = new Promise<IResultHistory>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error getting the result ${uuid} [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IResultHistory);
        }).catch(e => {
            if (e !== 'Component unmounted' && e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.message);
        });
    });
    return p;
}

export const getResults = async (token: string): Promise<Array<IResultHistory> | string> => {
    const url = `${getApiEndpoint()}/Result`;
    const p = new Promise<IResultHistory[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the result list [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as Array<IResultHistory>);
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    });
    return p;
}


export const getReportDocument = async (token: string, uuid: string, attach: boolean, abortController: AbortController, etagIn: string): Promise<{ report: Uint8Array, etag: string }> => {
    const url = `${getApiEndpoint()}/Result/${uuid}/Report${attach ? '?attach=true' : ''}`;
    let etag = '';
    const headers: HeadersInit = {
        Authorization: `Bearer ${token}`,
    };
    if (etagIn) {
        headers['If-None-Match'] = etagIn;
    }
    const cache = await caches.open('Reports');
    await tidyCache(cache, 3600 * 1000);
    const p = new Promise<{
        report: Uint8Array, etag: string
    }>((resolve, reject) => {
        cache.match(url).then(resp => {
            if (resp !== undefined) {
                resp.blob().then(imageBlob => {
                    if (imageBlob) {
                        etag = resp.headers.get('etag') || '';
                        return ({ report: new Uint8Array([]), etag });
                    } else {
                        dispatch(addError(`Error converting image from cache`));
                    }
                })
            } else {
                fetchWithAuthRedirect(url, {
                    cache: "no-cache",
                    headers,
                    signal: abortController.signal
                }).then(resp => {
                    if (resp.ok) {
                        etag = resp.headers.get('etag') || '';
                        return resp.arrayBuffer();
                    } else if (resp.status === 304) {
                        etag = resp.headers.get('etag') || '';
                        resolve({ report: new Uint8Array([]), etag })
                    }
                    else {
                        throw (new Error('Error in report download'));
                    }
                }).then(text => {
                    if (text) {
                        const report = new Uint8Array(text);
                        resolve({ report, etag });
                    } else {
                        reject('No data');
                    }
                }).catch(e => {
                    if (e.name !== 'AbortError') {
                        dispatch(addError(e.message));
                    }
                    reject(e.message)
                });
            }
        })
    });
    return p;
}


export const getAssetResults = (token: string, uuid: string, testType: string, entityType: TEntityType, abortController: AbortController): Promise<IResultHistory[]> => {
    let url = '';
    let method = 'GET';
    let body: Array<Record<string, string>> = [];
    if (entityType === 'Asset') {
        method = 'POST';
        body = [
            { "matches": uuid, "propName": "asset.uuidStr" },
            {
                "matches": testType, "propName": "testResultType"
            }
        ];
        url = `${getApiEndpoint()}/Result/search`;
    } else {
        url = `${getApiEndpoint()}/Result/company/${uuid}`;
    }
    const p = new Promise<any>((resolve, reject) => {
        const fetchParams: Record<string, unknown> = {
            method,
            cache: "no-cache",
            signal: abortController.signal,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        };
        if (body.length > 0) {
            fetchParams.body = JSON.stringify(body);
        }
        fetchWithAuthRedirect(url, fetchParams)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw (new Error('Error fetching asset results'));
                }
            }).then(json => {
                resolve(sanitise(json));
            }).catch(e => {
                if (e.name !== 'AbortError') {
                    dispatch(addError(e.message));
                }
                reject(e.message);
            });
    });
    return p;
}


// report and data downloads ---

export const downloadReport = (token: string, resultUuid: string, fileName: string, type: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Result/${resultUuid}/Report?attach=true`;
    // let etag = '';
    let derivedFileName = '';
    const pResult = new Promise<boolean>(resolveResult => {
        const p = new Promise<Blob>((resolve, reject) => {
            fetchWithAuthRedirect(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(resp => {
                    /*const headerObj = {};
                    const keys = resp.headers.keys();
                    let header = keys.next();
                    while (header.value) {
                        // @ts-ignore
                        headerObj[header.value] = resp.headers.get(header.value);
                        header = keys.next();
                    }
                    debugger; */
                    if (resp.ok) {
                        const cd = resp.headers.get('content-disposition') ?? '';
                        const cdFrags = cd.split('; ');
                        if (cdFrags.length > 1 && cdFrags[1].startsWith('filename=')) {
                            derivedFileName = cdFrags[1].split('=')[1];
                            if (derivedFileName.startsWith('"')) {
                                derivedFileName = derivedFileName.substring(1, derivedFileName.length - 1);
                            }
                        }
                        resolve(resp.blob());
                    } else {
                        reject();
                    }
                }).catch(e => {
                    dispatch(addError(e.message));
                    reject();
                })
        });
        p.then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            //const siteName = sites.find(site => site.uuid === asset?.siteUuid)?.name ?? '';
            //const companyName = companies.find(company => company.uuid === asset?.companyUuid)?.name ?? '';
            if (derivedFileName.length > 0) {
                link.setAttribute(
                    'download',
                    derivedFileName);
            }
            else {
                const extension = (blob.type === 'application/zip') ?
                    'zip'
                    : 'pdf'
                //const fileName = asset ? `${companyName} - ${siteName} - ${asset?.name} - ${d}.${extension}` : `Report.${extension}`;
                link.setAttribute(
                    'download',
                    fileName + '.' + extension,
                );
            }
            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
            resolveResult(true);
        }).catch(e => {
            resolveResult(false);
            //  setReportError('Failed to collect report for this item');
        })
    })
    return pResult;
}

export const downloadReportData = (token: string, resultUuid: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Result/${resultUuid}/Data`;
    let fileName = '';
    const pResult = new Promise<boolean>(resolveResult => {

        const p = new Promise<Blob>((resolve, reject) => {
            fetchWithAuthRedirect(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        const fn = resp.headers.get('content-disposition');
                        if (fn) {
                            fileName = fn.split('filename=')[1].split(';')[0];
                            fileName = fileName.substring(1, fileName.length - 1);
                        }
                        resolve(resp.blob());
                    } else {
                        reject();
                    }
                }).catch(e => {
                    dispatch(addError(e.message));
                    reject();
                })
        });
        p.then(blob => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
            resolveResult(true);
        }).catch(e => {
            resolveResult(false);
        })
    })
    return pResult;
}



export const changeResultApprovalStatus = async (token: string, resultUuid: string, status: 'Approve' | 'Disapprove'): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Result/${resultUuid}/${status}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Failed to ${status} report`));
            }
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    });
    return p;
}