import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

interface IBusyProps {
    size?: 'xs'
}

export const Busy: React.FunctionComponent<IBusyProps> = ({ size }) => (
    size === 'xs' ? <FontAwesomeIcon className='fa-spin  text-hvpd-red-400' icon={solid('spinner')} />
        :
        <div className='container my-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div>
);