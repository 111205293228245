import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useAppDispatch as useDispatch } from "../../hooks";
import { setTokenRejected } from "../../store/mainSlice";

export const TokenRejected: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { logout } = useAuth0();
    const tryAgain = () => {
        dispatch(setTokenRejected(false));
    }
    return (
        <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="flex flex-shrink-0 items-center justify-between rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                            <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                                Authentication Error
                            </h5>
                        </div>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div>It looks like your session has expired, you may need to log out and log back in again, or if you think an error may have occurred just try again.</div>
                        </div>
                        <div className="rounded-md bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                            <button className='text-white disabled:text-hvpd-grey-700 bg-hvpd-red-500 border-hvpd-red-400 hover:bg-hvpd-red-300 text-sm rounded-md px-2 py-1 ' onClick={() => logout({ returnTo: window.location.origin })}>
                                Log Out
                            </button>
                            <button className='text-white disabled:text-hvpd-grey-700  bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 text-sm rounded-md px-2 py-1 me-2' onClick={tryAgain}>
                                Try again
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};