import React from "react";
import { useTranslation } from "react-i18next";

import { TEntity } from "./FormTemplate";

interface IFormButtonGroupProps {
    wipEntity?: TEntity;
    canCreate?: boolean;
    clearForm: () => void;
    onEndEdit?: () => void;
    onDelete?: () => void;
    setConfirmDelete: (value: boolean) => void;
    undo?: () => void;
}

export const FormButtonGroup: React.FunctionComponent<IFormButtonGroupProps> = ({ wipEntity, canCreate, clearForm, onEndEdit, onDelete, setConfirmDelete, undo }) => {
    const { t } = useTranslation();
    return (
        <div className='flex mt-2 mx-2'>
            <div className='flex-grow'>
                {wipEntity?.uuid && canCreate !== false ? <button type='button' onClick={clearForm} className='justify-self-start h-7 ml-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Clear and create new')}</button> : null}
            </div>
            {undo && <button type='button' onClick={undo} className='h-7 mr-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Undo')}</button>}
            {onEndEdit && <button type='button' onClick={onEndEdit} className='h-7 mr-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Cancel')}</button>}
            {onDelete && wipEntity?.uuid ? <button type='button' onClick={onDelete} className='h-7 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Delete')}</button> : null}
            <button type='submit' className='h-7 ml-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Submit')}</button>
        </div>
    );
}