import React, { useEffect } from 'react';
import { setAssetAccordion } from "../../store/mainSlice";
import { IAsset } from '../../store/Interfaces';
import { Accordion, TAccordionItemInst } from "../Accordion";
import { AssetDetails, CompanyDetails, SiteDetails } from '../EntityDetails';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../hooks';
import './Asset.css';
export interface IAssetProps {
    asset: IAsset
}

export const Asset: React.FunctionComponent<IAssetProps> = ({ asset }) => {
    const dispatch = useDispatch();
    const { companies, sites, assetAccordion } = useSelector(store => store.main);
    const company = companies.find(c => c.uuid === asset.companyUuid);
    const site = sites.find(s => s.uuid === asset.siteUuid);

    useEffect(() => {
        dispatch(setAssetAccordion({ Asset: true, Site: false, Company: false }));
    }, [dispatch]);

    const accordionItems: TAccordionItemInst[] = [{
        initiallyExpanded: assetAccordion.Asset,
        title: 'Asset', children: <AssetDetails uuid={asset.uuid} detailsOnly={true} />
    }];
    if (site) {
        accordionItems.push({
            initiallyExpanded: assetAccordion.Site,
            title: 'Site', children: <SiteDetails uuid={asset.siteUuid} detailsOnly={true} />
        });
    }
    if (company) {
        accordionItems.push({
            initiallyExpanded: assetAccordion.Company,
            title: 'Company', children: <CompanyDetails uuid={asset.companyUuid} detailsOnly={true} />
        })
    }

    return <div className="overflow-y-auto h-full">
        <div className='m-4 mb-16'>
            <Accordion items={accordionItems} id='asset' alwaysOpen={true} />
        </div>
    </div>
}
