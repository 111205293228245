import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setAssets, setTableDefinitionComplete } from "../../../store/mainSlice";

import { AssetSelector } from "./AssetSelector";
import { MultiAssetEdit } from "./MultiAssetEdit";
import { updateAsset } from "../../../api";

import { Busy } from "../../Busy";

import { IAsset } from "../../../store/Interfaces";

const TABLENAME = 'TableAsset';

interface IAssetSelectorProps {
    onClose: () => void;
    assetUuid: string;
}

export const MultiAssetModal: React.FunctionComponent<IAssetSelectorProps> = ({ onClose, assetUuid }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { assets, token } = useSelector(store => store.main);
    const [busy, setBusy] = useState(false);
    const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
    const [showForm, setShowForm] = useState<'select' | 'edit' | 'confirm'>('select');
    const [updatedAssets, setUpdatedAssets] = useState<IAsset[]>([]);

    const onSubmit = () => {
        setBusy(true);
        const p = Promise.all(
            updatedAssets.map((asset) => updateAsset(token, asset).then((asset) => {
                dispatch(addToast(`Asset ${asset.name} updated`));
                dispatch(setAssets(assets.map(a => a.uuid === asset.uuid ? asset as IAsset : a)));
            }).catch((err) => {
                dispatch(addError("Error updating asset"));
                console.error(err);
            })
            ));
        p.then(() => {
            setBusy(false);
            dispatch(setTableDefinitionComplete([TABLENAME, null]))
            onClose();
        }).catch(() => {
            setBusy(false);
        });
    }

    const onConfirm = (assets: IAsset[]) => {
        setUpdatedAssets(assets);
        setShowForm('confirm');
    }

    return <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-[3000] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-8xl">
                    {showForm === 'select' ?
                        (
                            <AssetSelector assetUuid={assetUuid} selectedAssets={selectedAssets} setSelectedAssets={setSelectedAssets} onSelectionComplete={() => setShowForm('edit')} />
                        ) : null}
                    {showForm === 'edit' ?
                        <MultiAssetEdit assetUuids={selectedAssets} onClose={onConfirm} />
                        : null}
                    {showForm === 'confirm' ?
                        <div>
                            <div>
                                <h1 className="font-semibold px-2 py-2 text-xxl text-hvpd-pickled-bluewood-300"> {t('Confirm edits to the following assets')}</h1>
                                <hr />
                            </div>
                            <div className='m-2'>
                                {updatedAssets.map((asset, index) => <div key={index}>{asset.name}</div>)}
                            </div>
                            {busy ? <Busy /> : <div className="flex flex-row-reverse w-full p-2">
                                <button type="button" onClick={onSubmit} className="flex-end rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{t('Confirm')}</button>
                            </div>}
                        </div>
                        : null}
                    <div className="rounded-md bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse">
                        <button type="button" onClick={onClose} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{t('Close')}</button>
                    </div>
                </div>
            </div>
        </div>
    </div >
}