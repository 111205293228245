import * as DOMPurify from "dompurify";
import Cookies from 'universal-cookie';

import {
    IGeoPoint,
    IBuildInfo,
    IRunningConditions,
    TTableTestResultList,
    TTableResultEndpoint,
    ITableDefinitionResult,
    TDetailedAssetDiagram,
    TEntityType,
    TExtendedFilter,
} from "../store/Interfaces";

import { store } from "../store/store";
import { addError, setTokenRejected } from "../store/mainSlice";

const { dispatch } = store;

const DOMAIN_AZURE_REACT = "-webapp-react.azurewebsites.net";
const DOMAIN_AZURE_API = "-webapp-api.azurewebsites.net";
const DOMAIN_HVPD_ATLAS = "hvpd-atlas.com";

export const isProductionSite = () => {
    const hostname = window.location.hostname.toLowerCase();
    return hostname.endsWith(DOMAIN_AZURE_REACT) || hostname.endsWith(DOMAIN_HVPD_ATLAS);
};

export const fetchWithAuthRedirect = (url: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> => {
    try {
        return fetch(url, init).then((resp) => {
            if (resp.status === 401) {
                dispatch(setTokenRejected(true));
            }
            return resp;
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

const getEndpoint = () => {
    const hostname = window.location.hostname.toLowerCase();
    const cookies = new Cookies();
    const api = cookies.get('api');
    if (hostname.endsWith(DOMAIN_AZURE_REACT)) {
        return window.location.protocol + "//" + hostname.replace(DOMAIN_AZURE_REACT, DOMAIN_AZURE_API) + "/";
    } else if (hostname.endsWith(DOMAIN_HVPD_ATLAS)) {
        if (DOMAIN_HVPD_ATLAS === hostname || "www." + DOMAIN_HVPD_ATLAS === hostname) {
            return window.location.protocol + "//api." + DOMAIN_HVPD_ATLAS;
        } else {
            return window.location.protocol + "//" + hostname.replace("." + DOMAIN_HVPD_ATLAS, "-api." + DOMAIN_HVPD_ATLAS) + "/";
        }
    } else if ("localhost" === hostname || "atlas.yosser.uk" === hostname) {
        // best to use values defined in the environment file - SH
        if (!api) {
            return process.env.REACT_APP_API_ENDPOINT;
        }
        switch (api) {
            case 'beta':
                return process.env.REACT_APP_BETA_API_ENDPOINT;
            case 'live':
                return process.env.REACT_APP_LIVE_API_ENDPOINT;
            case 'demo':
                return process.env.REACT_APP_DEMO_API_ENDPOINT ?? 'https://atlas-demo-webapp-api.azurewebsites.net/';
            case 'staging':
                return process.env.REACT_APP_STAGING_API_ENDPOINT ?? 'https://atlas-staging-webapp-api.azurewebsites.net/';
            default:
                return process.env.REACT_APP_DEV_API_ENDPOINT;
        }

        //return 'https://atlas-live-webapp-api.azurewebsites.net/'
        // return 'https://www.monitra-atlas.net:8443/global-kronos-framework-webapp/';
        // return 'http://localhost:8080/global-kronos-framework-webapp/';
    } else {
        //return process.env.REACT_APP_API_ENDPOINT;
        return window.location.protocol + "//" + window.location.hostname + ":8443/global-kronos-framework-webapp/";
        /*
        // Default to the original functionality for now...
        const { main } = store.getState();
        const database = main.database.version;
        if ((process.env.NODE_ENV === 'production')
            && (database === 'beta')) {
            // console.log(process.env.REACT_APP_BETA_API_ENDPOINT);
            return process.env.REACT_APP_BETA_API_ENDPOINT;
        }
        // console.log(process.env.REACT_APP_API_ENDPOINT);
        return process.env.REACT_APP_API_ENDPOINT;
        */
    }
};

export const ENDPOINT_PREFIX = "api/v2";

export const getApiEndpoint = () => {
    return `${getEndpoint()}${ENDPOINT_PREFIX}`;
};

export const tidyCache = async (cache: Cache, delay: number): Promise<boolean> => {
    const p: Promise<boolean> = new Promise(async (resolve) => {
        const keys = await cache.matchAll();
        keys.forEach(async (resp) => {
            if (resp) {
                const respTime = Date.parse(resp.headers.get("date") ?? "");
                if (Date.now() > respTime + delay) {
                    cache.delete(resp.url);
                }
            }
        });
        resolve(true);
    });
    return p;
};

export const sanitise = <T,>(s: T): T => {
    if (typeof s === "string") {
        return DOMPurify.sanitize(s) as unknown as T;
    } else if (Array.isArray(s)) {
        return s.map(sanitise) as unknown as T;
    } else if (typeof s === "object") {
        const o = s as unknown as { [key: string]: unknown };
        const r: { [key: string]: unknown } = {};
        Object.keys(o).forEach((k) => {
            r[k] = sanitise(o[k]);
        });
        return r as unknown as T;
    }
    return s;
};

type TAUTH0_PARAMS = {
    AUTH0_DOMAIN: string;
    AUTH0_CLIENT_ID: string;
};

export const getAuth0Config = async (): Promise<TAUTH0_PARAMS> => {
    /*
    if (process.env.NODE_ENV === 'development' || true) {
        return Promise.resolve({ AUTH0_DOMAIN: process.env.REACT_APP_DOMAIN as string, AUTH0_CLIENT_ID: process.env.REACT_APP_CLIENT_ID as string });
    }
    const url = getEndpoint() 'https://atlas-live-webapp-api.azurewebsites.net/api/Config'
    */

    const url = `${getApiEndpoint()}/Config`;

    // console.log(`getAuth0Config : ep = ${ep}`);
    // console.log(`getAuth0Config : url = ${url}`);

    const p = new Promise<TAUTH0_PARAMS>((resolve, reject) => {
        fetch(url, {
            headers: {
                Accept: "application/json",
            },
        })
            .then((resp) => {
                // console.log(`getAuth0Config : resp = `, resp);
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`Error getting the auth0 config [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(json as TAUTH0_PARAMS);
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject("Error getting auth config");
            });
    });
    return p;
};


export const getGeoPoints = async (token: string): Promise<Array<IGeoPoint>> => {
    const url = `${getApiEndpoint()}/GeoPoints`;
    const p = new Promise<IGeoPoint[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`Error getting the geopoint list [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(sanitise(json) as Array<IGeoPoint>);
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject("Error fetching...");
            });
    });
    return p;
};

export const testGetRunningConditions = async (token: string, uuid: string, ac: AbortController): Promise<boolean> => {
    const url = `${getApiEndpoint()}/RunningConditions/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: "HEAD",
            cache: "no-cache",
            signal: ac.signal,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    resolve(true)
                } else {
                    resolve(false);
                }
            })
            .catch((e) => {
                resolve(false);
            });
    });
    return p;
}

export const getRunningConditions = async (token: string, uuid: string, suppressError = false, ac: AbortController): Promise<IRunningConditions | string> => {
    const url = `${getApiEndpoint()}/RunningConditions/${uuid}`;
    const p = new Promise<IRunningConditions | string>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            signal: ac.signal,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`No running condition data found [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(sanitise(json));
            })
            .catch((e) => {
                if (!suppressError && e.name !== "AbortError") {
                    dispatch(addError(e.message));
                    reject(e.message);
                }
                resolve('');
            });
    });
    return p;
};

// export const getLatestAssetReport = async (token: string, uuid: string, type: string, attach: boolean, abortController: AbortController): Promise<Uint8Array> => {
//
//     const url = `${getApiEndpoint()}/Asset/${uuid}/LatestReport?testType=${type}${attach ? '&attach=true' : ''}`;
//     const p = new Promise<Uint8Array>((resolve, reject) => {
//         fetch(url, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//             signal: abortController.signal
//         }).then(resp => {
//             if (resp.ok) {
//                 return resp.arrayBuffer();
//             } else {
//                 throw (new Error('Error in report download'));
//             }
//         }).then(text => {
//             if (text) {
//                 const u8 = new Uint8Array(text);
//                 console.log(`Resoliving with length ${u8.length}`)
//                 resolve(u8);
//             } else {
//                 reject('No data');
//             }
//         }).catch(e => {
//             if (e.name !== 'AbortError') {
//                 dispatch(addError(e.message));
//             }
//             reject(e.message)
//         });
//     });
//     return p;
// }

/*
export const getAssetRunningConditions = (token: string, uuid: string) => {
    const url = `${getApiEndpoint()}/RunningConditions/asset/${uuid}`;
    const p = new Promise<any>((resolve, reject) => {
        fetch(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error('Error fetching asset running conditions'));
            }
        }).then(json => {
            resolve(json);
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.message);
        });
    });
    return p;
}
*/
// export const getLatestAssetData = async (token: string, uuid: string, attach: boolean): Promise<Uint8Array> => {
//
//     const url = `${getApiEndpoint()}/Asset/${uuid}/LatestData`;
//     const p = new Promise<Uint8Array>((resolve, reject) => {
//         fetch(url, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             }
//         }).then(resp => {
//             if (resp.ok) {
//                 return resp.arrayBuffer();
//             } else {
//                 throw (new Error(`Error getting the latest asset data [${resp.status}]`));
//             }
//         }).then(text => {
//             if (text) {
//                 resolve(new Uint8Array(text));
//             }
//             reject('No data');
//         }).catch(e => {
//             dispatch(addError(e.message));
//             reject('Error fetching...');
//         });
//     })
//     return p;
// }

export const getVersion = async (token: string): Promise<IBuildInfo> => {
    const url = `${getApiEndpoint()}/Health`;
    const p = new Promise<IBuildInfo>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`Error getting the application version`);
                }
            })
            .then((json) => {
                resolve(sanitise(json));
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject("Error fetching...");
            });
    });
    return p;
};

export const getTableDefinition = (
    token: string,
    tableEndPoint: TTableResultEndpoint,
    extendedFilter: TExtendedFilter[],
    includeNoTests: boolean,
    abortController: AbortController,
): Promise<ITableDefinitionResult> => {
    let url = "";
    if (["OnlinePD", "OfflinePD", "MCSAOnline"].includes(tableEndPoint.name)) {
        url = `${tableEndPoint.endpoint}?includeNoTests=${includeNoTests ? "true" : "false"}`;
    } else {
        url = tableEndPoint.endpoint;
    }
    if (!url.startsWith("http")) {
        url = `${getEndpoint()}${url}`;
    }
    const p = new Promise<ITableDefinitionResult>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: extendedFilter.length > 0 ? "POST" : "GET",
            signal: abortController.signal,
            cache: extendedFilter.length === 0 ? "no-cache" : undefined,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: extendedFilter.length > 0 ? JSON.stringify(extendedFilter) : undefined,
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`Error fetching table definition [${tableEndPoint.endpoint}]`);
                }
            })
            .then((json) => {
                const td: ITableDefinitionResult = {
                    columnHeaders: json.columnHeaders,
                    rows: json.rows ?? [],
                    searchParams: json.searchParams,
                };
                resolve(sanitise(td));
            })
            .catch((e) => {
                if (e.name !== "AbortError") {
                    dispatch(addError(e.message));
                }
                reject(e.message);
            });
    });
    return p;
};

export const getTableTestResultList = (token: string): Promise<TTableTestResultList> => {
    const url = `${getApiEndpoint()}/TableTestResultList`;
    const p = new Promise<TTableTestResultList>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`Error fetching table test result list`);
                }
            })
            .then((json) => {
                resolve(sanitise(json));
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject(e.message);
            });
    });
    return p;
};

export const getAssetDiagrams = (token: string, uuid: string): Promise<TDetailedAssetDiagram> => {
    const url = `${getApiEndpoint()}/AssetDiagram/detailedAssetDiagrams/${uuid}`;
    const p = new Promise<TDetailedAssetDiagram>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    if (resp.status === 204) {
                        return { assetDiagrams: [], assetResults: [], assetDiagramLinks: [] };
                    } else {
                        return resp.json();
                    }
                } else {
                    if (resp.status === 404) {
                        return { assetDiagrams: [], assetResults: [], assetDiagramLinks: [] };
                    } else {
                        throw new Error("Error fetching asset diagrams");
                    }
                }
            })
            .then((json) => {
                resolve(json);
            })
            .catch((e) => {
                if (e.name !== "AbortError") {
                    dispatch(addError(e.message));
                }
                reject(e.message);
            });
    });
    return p;
};

export const getEntityNames = async (
    token: string,
    abortController: AbortController,
    uuids: Array<string>,
    entityType: TEntityType,
): Promise<Record<string, string>> => {
    const url = `${getApiEndpoint()}/${entityType}/displayText`;
    const p = new Promise<Record<string, string>>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal,
            body: JSON.stringify(uuids),
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`Error fetching names for ${entityType}`);
                }
            })
            .then((json) => {
                resolve(sanitise(json));
            })
            .catch((e) => {
                if (e.name !== "AbortError") {
                    dispatch(addError(e.message));
                }
                reject(e.message);
            });
    });
    return p;
};
