import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";


export const LogoutModal: React.FunctionComponent = () => {
    const { logout } = useAuth0();
    const { t } = useTranslation();
    return (
        <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="flex flex-shrink-0 items-center justify-between rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                            <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                                {t('Access Error')}
                            </h5>
                        </div>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div>{t('Sorry you are not authorised')}</div>
                        </div>
                        <div className="rounded-md bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button className='text-white disabled:text-hvpd-grey-700 bg-hvpd-red-500 border-hvpd-red-400 hover:bg-hvpd-red-300 text-sm rounded-md px-2 py-1 ' onClick={() => logout({ returnTo: window.location.origin })}>
                                {t('Log Out')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
