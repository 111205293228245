export interface IAsset {
    assetDiagrams?: Array<string>;
    assetType?: string;
    assetTypes?: string;
    assetLocation?: string;
    assetSubType?: string;
    kva?: string;
    numberOfPoles?: string;
    companyUuid: string;
    frame: string;
    gpsLat: number;
    gpsLong: number;
    insulationClass?: string;
    manufacturer: string;
    name: string;
    pointOfAttachmentLabelNumber: string;
    processingMaterial?: string;
    projects?: Array<string>;
    ratedPower_kW: number;
    ratedSpeedRPM: number;
    sensorStatus?: string;
    service_MAC_BAC_Steam: string;
    siteUuid: string;
    subSiteUuid?: string;
    supplyFrequency?: string;
    typeSynchronousInduction?: string;
    uuid: string;
    voltage_kV: number;
    yearOfManufacture: number;
}

export interface ICompany {
    address?: string;
    addressZIP?: string;
    companyIndex?: number;
    companyRole: string;
    country?: string;
    gpsLat?: number;
    gpsLong?: number;
    logoURL?: string;
    monetaryCurrency?: string;
    name: string;
    phoneNumber?: string;
    projects?: Array<string>;
    state?: string;
    taxReferenceID?: string;
    users?: Array<string>;
    uuid: string;
    website?: string;
};

export interface IReport {
    name: string;
}

// This is probably redundant now - SH
/*export interface IResult {
    approvalDate: number;
    approved: boolean;
    approverName: string;
    assetUuid: string;
    companyUuid: string;
    dataRevisionStatus: string;
    dataUploadStatus: string;
    endTime: number;
    resultCondition: string;
    resultConditionPhase1: string;
    resultConditionPhase2: string;
    resultConditionPhase3: string;
    resultQuantitation: number;
    resultQuantitationPhase1: number;
    resultQuantitationPhase2: number;
    resultQuantitationPhase3: number;
    resultQuantitationUnitsString: string;
    siteUuid: string;
    startTime: number;
    testEngName: string;
    testResultType: string;
    uuid: string;
}
*/
export interface ISite {
    address?: string;
    addressZIP?: string;
    companyUuid: string;
    country?: string;
    gpsLat: number;
    gpsLong: number;
    logoURL?: string;
    name?: string;
    phoneNumber?: string;
    state?: string;
    uuid: string;
    website?: string;
}
export interface ISubSite {
    companyUuid: string,
    gpsLat: number,
    gpsLong: number,
    logoURL?: string,
    name: string,
    power_kW: number,
    siteUuid: string,
    uuid: string,
    voltageIn_kV: number,
    voltageOut_kV: number
}

export type TSlideOver = '' | 'report' | 'filters' | 'projectAdmin' | 'userGroupsAdmin' | 'salesOrderAdmin';

export type TUserRole = "NoRoleToPlay" |
    "Customer" |
    "SysAdmin" |
    "TestEngineerExternal" |
    "TestEngineer" |
    "TestEngineerManagerExternal" |
    "TestEngineerManager" |
    "CustomerAndCustomerUserAdmin" |
    "SeniorTestEngineerExternal" |
    "SeniorTestEngineer" |
    "Machine2MachineBot" |
    "SalesExternal" |
    "Sales" |
    "AnalysisEngineerExternal" |
    "AnalysisEngineer" |
    "ObservationCRUD" |
    "UserCRU" |
    "UserCRUD" |
    "StorageEntityCRU" |
    "Approver" |
    "FrontEndApprover" |
    "Unknown"

export const userRoles: TUserRole[] = [
    "NoRoleToPlay",
    "Customer",
    "SysAdmin",
    "TestEngineerExternal",
    "TestEngineer",
    "TestEngineerManagerExternal",
    "TestEngineerManager",
    "CustomerAndCustomerUserAdmin",
    "SeniorTestEngineerExternal",
    "SeniorTestEngineer",
    "Machine2MachineBot",
    "SalesExternal",
    "Sales",
    "AnalysisEngineerExternal",
    "AnalysisEngineer",
    "ObservationCRUD",
    "UserCRU",
    "UserCRUD",
    "StorageEntityCRU",
    "Approver",
    "FrontEndApprover",
    "Unknown"];

export type TUserPermission =
    "EStorageList" |
    "EStorageRead" |
    "EStorageWrite" |
    "EStorageDelete" |
    "EApprove" |
    "EReadUnapproved" |
    "ECreateUser" |
    "EFrontEndSetPosition" |
    "EAdminCentreFullAccess" |
    "EStorageMove" |
    "EPatchEntity" |
    "EFrontEndApprovalControls" |
    "EViewNotifications" |
    "ECreateBaseEntityRecords" |
    "EObservationCreate" |
    "EObservationRead" |
    "EObservationUpdate" |
    "EObservationDelete" |
    "EUserRead" |
    "EUserUpdate" |
    "EUserDelete" |
    "EAccessAllCompanies" |
    "EViewHideThisFromBenchmarking" |
    "ESysAdmin" |
    "ECustomer" |
    "EMachine2MachineBot";

export const userPermissions: TUserPermission[] = [
    "EStorageList",
    "EStorageRead",
    "EStorageWrite",
    "EStorageDelete",
    "EApprove",
    "EReadUnapproved",
    "ECreateUser",
    "EFrontEndSetPosition",
    "EAdminCentreFullAccess",
    "EStorageMove",
    "EPatchEntity",
    "EFrontEndApprovalControls",
    "EViewNotifications",
    "ECreateBaseEntityRecords",
    "EObservationCreate",
    "EObservationRead",
    "EObservationUpdate",
    "EObservationDelete",
    "EUserRead",
    "EUserUpdate",
    "EUserDelete",
    "EAccessAllCompanies",
    "EViewHideThisFromBenchmarking",
    "ESysAdmin",
    "ECustomer",
    "EMachine2MachineBot"
]

export interface IUser {
    phoneNumber: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    userRoles: Array<TUserRole>;
    userPermissions: Array<TUserPermission>;
    userGroups: Array<string>;
    companies: Array<string>;
    projects: Array<string>;
    uuid: string;
}
/*export interface IUserWithPermissions extends IUser {
    userPermissions: Array<TUserPermission>;
} */
export interface IUserGroup {
    name: string;
    uuid: string;
    users: Array<string>,
    projects: Array<string>
}

export interface IProject {
    uuid: string;
    name: string;
    active: boolean;
    assets: string[];
    hvpdSONumber: string;
    isActive: boolean;
    results: string[];
    users: string[];
}

export interface IBaseProject {
    name: string,
    startDate: number,
    endDate: number,
    notifications: boolean,
    notificationTime: string,
    isActive: boolean,
    uuid: string
}

export interface ISalesOrder {
    acknowledgedDate: number;
    clientPODate: number;
    clientPONumber: string;
    projects?: Array<string>;
    dueDate: number;
    salesOrderNumber: string;
    salesOrderText: string;
    salesOrderTitle: string;
    salesOrderType: string;
    uuid: string;
}

export interface IRunningConditions {
    windingTemp1DegC: number;
    windingTempB1DegC: number;
    windingTemp2DegC: number;
    windingTemp2BDegC: number;
    windingTemp3DegC: number;
    windingTemp3BDegC: number;
    auxTempDegC: number;
    load_kW: number;
    runningSpeed_rpm: number;
    ambientTemp_DegC: number;
    ambientHumidity_PC: number;
    voltage_kV: number;
    current_Amps: number;
    currentP1_Amps: number;
    currentP2_Amps: number;
    currentP3_Amps: number;
    coolantTemp_DegC: number;
    energisationState: string,
    resultUuid: string;
    uuid: string
}

export interface IBuildInfo {
    apiVersion: string;
    atlasVersion: number;
    buildDateTime: string;
    colorStd: string;
    country: string;
    dateTime: string;
    language: string;
    locale: string;
    paperSize: string;
    timeZone: string;
    unixMillis: number;
    zoneId: string
}

export interface ILinkedEntity {
    type: TEntityType;
    name: string;
    uuid: string;
}

export interface ILinkedObservationEntity {
    type: TResultType;
    name: string;
    uuid: string;
}

export interface ILatLng {
    "lat": number;
    "lng": number;
}

export type TEntityType = "Company" | 'Asset' | 'Site' | 'SubSite' | 'User' | 'Project';

export interface IGeoPoint {
    "lat": number;
    "lng": number;
    "type": TEntityType;
    "name": string;
    "uuid": string;
}
export type TResultType = 'results' | 'companies' | 'assets' | 'sites' | 'sub-sites' | 'users' | 'one-report';
export type TTableOptions = TResultType | 'projects' | 'userGroups' | 'salesOrders';
// possibly redundant now?
//export type TReportEntityType = 'asset' | 'site' | 'company' | 'report' | 'sub-site' | 'user';

export type TReportEntityGroup = { uuid: string, type: TResultType, resultUuid?: string };

export interface IDatabaseMode {
    version: 'beta' | 'dev'
}

export interface IToast {
    id: number;
    message: string;
}

export interface IError {
    id: number;
    message: string;
    ttl: number;
}

export type TExtendedFilter = {
    propName: string,
    contains?: string,
    min?: number,
    max?: number,
    value?: number | boolean,
    matches?: string[],
}

export type TAssetAccordion = {
    Asset: boolean, Company: boolean, Site: boolean
}

export type TEntityDisplayName = {
    name: string;
    uuid: string;
}

type TTrendPoint = {
    baseUnits: string,
    condition: string,
    scaledValue: number,
    timestamp: number
}

type TSensorTrend = {
    sensorName: string,
    sensorUUID: string,
    conductorPhaseTypes: string,
    hexColor: string,
    trendPoints: Array<TTrendPoint>
}

export type TTrendField = {
    baseUnits: string,
    rangeLabel: string,
    sensorTrends: Array<TSensorTrend>
}

export type TTrendData = {
    assetName: string,
    assetUuid: string,
    timeZone: string,
    trendFields: Array<TTrendField>
}

export type TTestType = 'OnlinePD' | 'MCSAOnline' | 'OfflinePD';

export type TColumnHeaderType = "String" | "Float" | "Date" | "Integer" | "Boolean";
export interface IColumnHeader {
    title: string;
    type: TColumnHeaderType,
    propColour?: string;
    propValue: string;
    propDisplay: string;
    propIcons?: string;
    propTooltip?: string;
    isCheckbox?: boolean;
}

export interface ISortColumn {
    title: string;
    direction: 1 | -1;
}

export type TApprovalStatus = 'Approved' | 'Unapproved56days' | 'Unapproved28days' | 'Unapproved14days' | 'Unapproved';

export type TApprovalCount = Partial<Record<TApprovalStatus, number>>;


export const approveStates: Record<TApprovalStatus, string> = { 'Approved': 'Approved', Unapproved14days: 'Delayed 14 days', Unapproved28days: 'Delayed 28 days', Unapproved56days: 'Delayed 56 days', Unapproved: 'Unapproved' };

// = { Approved?: number, Unapproved56days?: number, Unapproved28days?: number, Unapproved14days?: number, Unapproved?: number };

export type TResultRow = Record<string, string | number | Array<string>> & {
    gpsLat: number;
    gpsLong: number;
    resultUuid: string;
    siteUuid: string;
    assetUuid: string;
    companyUuid: string;
    runningConditionUuid?: string;
    assetName?: string;
    siteName?: string;
    statusTooltip?: string;
    statusValue?: string;
    reportStateCounts?: TApprovalCount,
    resultStateCounts?: TApprovalCount,
    missingAssetFields?: Array<string>,
    tests: number;
}
export interface IResult {
    approvalDate: number;
    approved: boolean;
    approverName: string;
    assetUuid: string;
    companyUuid: string;
    countAllPhases: number;
    countPhase1: number;
    countPhase2: number;
    countPhase3: number;
    dataRevisionStatus: string;
    dataUploadStatus: string;
    endTime: number;
    hardwareSerialNumber: string;
    healthIndexScore_Percent: number;
    hideThisFromBenchmarking: boolean;
    legacyData: boolean;
    poaBoxType: string;
    reportUuid: string;
    reportApproverName?: string,
    reportTestEngName?: string,
    reportApprovalDate?: number,
    reportApproved?: boolean,
    resultCondition: string;
    resultConditionPhase1: string;
    resultConditionPhase2: string;
    resultConditionPhase3: string;
    resultConditionPhaseB1: string;
    resultConditionPhaseB2: string;
    resultConditionPhaseB3: string;
    resultDataSources: string;
    resultQuantitation: number;
    resultQuantitationPhase1: number;
    resultQuantitationPhase2: number;
    resultQuantitationPhase3: number;
    resultQuantitationPhaseB1: number;
    resultQuantitationPhaseB2: number;
    resultQuantitationPhaseB3: number;
    resultQuantitationUnitsBString: string;
    resultQuantitationUnitsString: string;
    resultTestable: string;
    runningConditionUuid: string;
    sensorTransferImpedance: number;
    sensorTransferImpedancePhase1: number;
    sensorTransferImpedancePhase2: number;
    sensorTransferImpedancePhase3: number;
    sensorTypeAll: string;
    sensorTypePhase1: string;
    sensorTypePhase2: string;
    sensorTypePhase3: string;
    siteUuid: string;
    startTime: number;
    subSiteUuid: string;
    syncMonitorCurrentTransformerPositions: string;
    syncMonitorSensorLocations: string;
    testEngName: string;
    testResultType: string;
    uuid: string;
}

/*Record<string, string | number> & */

export interface IResultHistory extends IResult {
    projects: string[];
    recommendationUUIDs: string[],
    resultRecommendations: string[],
}

export type TSeverity = "Low" | "Medium" | "High" | 'Unknown' | 'NotApplicable';

export interface IRecommendation {
    name: string;
    description: string;
    assetType: string;
    severity: TSeverity;
    validityPeriodDays: number;
    isActive: number;
    resultsUUIDs: string[];
    uuid: string;
}

export interface IResultRecommendation {
    name: string;
    description: string;
    severity: TSeverity,
    recommendationDate: number;
    recommendationExpiryDate: number;
    lastModifiedByEmail: string;
    lastModifiedByName: string;
    createdByEmail: string;
    createdByName: string;
    recommendationTemplateUuid: string,
    parentUuid: string,
    lastModifiedByUuid: string,
    createdByUuid: string,
    uuid: string;
}

export interface IRecommendationTemplate {
    assetType: string;
    description: string;
    isActive: boolean;
    name: string;
    severity: TSeverity;
    uuid: string;
    validityPeriodDays: number;
}

export interface IObservation {
    observationName: string;
    observationDate: number;
    severity?: TSeverity;
    isValid: boolean;
    stringValue: string;
    booleanValue: boolean;
    doubleValue: number;
    longValue: number;
    parentUuid?: string;
    unitsString?: string;
    valid: boolean;
    lastModifiedByEmail: string,
    lastModifiedByName: string,
    createdByEmail: string,
    createdByName: string,
    createdDate: number,
    lastModifiedDate: number,
    lastModifiedByUuid: string,
    createdByUuid: string
    uuid?: string;
}

export type TValueName = 'string' | 'enum' | 'int' | 'float' | 'double' | 'long' | 'boolean';

export type TSearchTemplate = {
    enumConstants?: Array<string>;
    title: string;
    type: TValueName;
    propName: string;
}

export interface ITableDefinition {
    columnHeaders: Array<IColumnHeader>;
    searchParams: Array<TSearchTemplate>;
}

export interface ITableResult {
    rows: Array<TResultRow>;
}

export interface ITableDefinitionResult extends ITableDefinition, ITableResult { }

export type TTableResultEndpoint = { name: string, endpoint: string };

export type TTableTestResultList = Array<TTableResultEndpoint>;


export type TImageDescriptor = {
    name: string,
    baseName: string,
    requestRelativeName: string,
    rootRelativeName: string,
    creationTimestamp: number,
    lastModifiedTimestamp: number,
    sizeBytes: number,
    md5Base64String: string,
    createdByUser: string,
    createdByUserUuidStr: string,
    defaultItem: boolean,
    eTag: string,
    lastAccessedTimestamp: number,
    lastModifiedByUser: string,
    lastModifiedByUserUuidStr: string,
}


export type TDocumentDescriptor = {
    baseName: string,
    creationTimestamp: number,
    createdByUser: string,
    createdByUserUuidStr: string,
    etag: string,
    lastAccessedTimestamp: number;
    lastModifiedByUser: string,
    lastModifiedByUserUuidStr: string,
    lastModifiedTimestamp: number,
    md5Base64String: string,
    name: string,
    requestRelativeName: string,
    rootRelativeName: string,
    sizeBytes: number,
}

export type TStorageDetails =
    Record<string, Array<TDocumentDescriptor>>


export type TUploadParameters = {
    chunkedUploadThresholdSize_bytes: number,
    chunkSize_bytes: number,
    maximumFileUploadSize_bytes: number
}

export type TChunkedUploadId = {
    //rootRelativeName: string,
    //uploadDestinationExists: boolean,
    state: string,
    stateLastUpdatedTimestampMillis: number,
    stateLastUpdatedDateStr: string,
    entityStoragePath: string,
    entityUuid: string,
    uploadUuid: string,
    uploadRelativeDestinationPath: string,
    uploadDestinationFileExists: boolean,
    uploadRequestTimeoutMillis: number,
    uploadRequestTimeoutDateStr: string
}

export type TChunkedUploadResult = {
    uploadUuid: string,
    chunkIndex: number,
    chunkName: string,
    chunkSize: number,
    contentRange: string,
    eTag: string,
    chunkCreatedMillis: number
}

export type TChunkedUploadCompletionResult = {
    /*  uploadUuid: string,
      uploadRelativeDestinationPath: string,
      entityStoragePath: string,
      entityUuid: string,
      eTag: string,
      chunkMergedCount: number, */
    uploadUuid: string,
    state: string,
    stateLastUpdatedTimestampMillis: number,
    stateLastUpdatedDateStr: string,
    entityStoragePath: string,
    entityUuid: string,
    uploadRelativeDestinationPath: string,
    uploadDestinationFileExists: boolean,
    uploadRequestTimeoutMillis: number,
    uploadRequestTimeoutDateStr: string,
    mergeProgress: number
}

export interface TChunkMergePayload {
    uuid: string,
    chunkMetadataMap: Record<string, TChunkedUploadResult>;
}

export type TImageDescriptorList = Array<TImageDescriptor>;

export type TAssetDiagram = {
    imageData: string,
    uuid: string,
    companyUuid: string,
    assets: Array<string>
}
export type TPhaseInfo =
    { colour: string, displayStr: string, value: number };

export type TAssetResult = {
    assetName: string,
    assetUuid: string,
    companyName: string,
    companyUuid: string,
    dateOfLatestTest: number,
    dateOfLatestTestStr: string,
    gpsLat: number,
    gpsLong: number,
    phaseInfos: Array<TPhaseInfo>,
    resultUuid: string,
    runningConditionUuid: string,
    siteName: string,
    siteUuid: string,
    testResultType: string,
    tests: number
}

export type TDetailedAssetDiagram = {
    assetDiagrams: Array<TAssetDiagram>,
    assetResults: Array<TAssetResult>
}

export type TTabId = 'summary' | 'report' | 'trends' | 'images' | 'details' | 'storage' | 'test' | 'user' | 'basic' | 'extended' | 'edit';
export type TTabName = 'Summary' | 'Report' | 'Trends' | 'Images' | 'Asset' | 'Site' | 'Storage' | 'Sub site' | 'Company' | 'Test' | 'User' | 'Basic' | 'Extended' | 'Edit';



export type IObservationList = Array<IObservation>

export type TResultObservations = { Asset: IObservationList, Result: IObservationList, Site: IObservationList, Subsite: IObservationList, Company: IObservationList };