import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Collapse, initTE } from "tw-elements";

export interface IAccordionItemProps {
    title: string;
    children: React.ReactNode;
    index: number;
    accordionId: string;
    alwaysOpen?: boolean;
    initiallyExpanded?: boolean;
    minPadding: boolean;
}

export const AccordionItem: React.FunctionComponent<IAccordionItemProps> = ({ title, children, index, accordionId, initiallyExpanded, alwaysOpen, minPadding }) => {
    return (<div className=" bg-white border border-gray-200">
        <h2 className="accordion-header mb-0" id={`accordion-heading-${index}`}>
            <button data-te-collapse-init
                data-te-target={`#accordion-item-${accordionId}-${index}`}
                data-te-collapse-collapsed={initiallyExpanded ? undefined : true}
                className={`group relative flex items-center w-full ${minPadding ? 'py-1 px-1' : 'py-4 px-5'} text-base text-hvpd-grey-700 hover:text-hvpd-grey-900 text-left bg-hvpd-malibu-100  border-b-[1px] border-b-hvpd-malibu-200 rounded-none transition focus:outline-none`}
                type="button"
                aria-expanded={initiallyExpanded ? 'true' : 'false'}
                aria-controls={`accordion-item-${accordionId}-${index}`}>
                <FontAwesomeIcon className='text-hvpd-pickled-bluewood-200 mr-2 width-[28px] transition-transform duration-200 ease-in-out rotate-[90deg] group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529]' size='sm' icon={solid('chevron-right')} />
                <span className='hover:underline'>{title}</span>
            </button>
        </h2>
        <div
            id={`accordion-item-${accordionId}-${index}`}
            className={`!visible ${initiallyExpanded === false ? 'hidden' : ''}`}
            data-te-collapse-item
            data-te-collapse-show={initiallyExpanded ? true : undefined}
            aria-labelledby={`accordion-heading-${index}`}
            data-te-parent={alwaysOpen ? undefined : `#${accordionId}`}>
            <div className={`${minPadding ? 'py-1 px-1' : 'py-2 px-3'}`}>
                {children}
            </div>
        </div>
    </div>
    )
}

export type TAccordionItemInst = {
    title: string;
    titleText?: string;
    children: React.ReactNode;
    initiallyExpanded: boolean;
    hasContent?: boolean;
}

type TAccordionOpts = {
    minPadding?: boolean;
}
export interface IAccordionProps {
    items: Array<TAccordionItemInst>;
    id: string;
    alwaysOpen?: boolean;
    options?: TAccordionOpts;
}

export const Accordion: React.FunctionComponent<IAccordionProps> = ({ items, id, alwaysOpen, options }) => {
    useEffect(() => {
        initTE({ Collapse });
    }, [items]);

    return (<div className="accordion" id={id}>
        {
            items.map((item, index) =>
                <AccordionItem key={`item-${index}`}
                    initiallyExpanded={item.initiallyExpanded}
                    title={item.titleText ?? item.title}
                    index={items.indexOf(item)}
                    accordionId={id}
                    alwaysOpen={alwaysOpen}
                    minPadding={options?.minPadding ?? false}
                >{item.children}</AccordionItem>)
        }
    </div>);
}
