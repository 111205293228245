import { CookieSetOptions } from 'universal-cookie';


export type Entries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];


export const getURLParamCookie = (urlParam: string, cookies: { mapPos?: any; urlParams?: any }, setCookie: (name: "urlParams" | "mapPos", value: any, options?: CookieSetOptions | undefined) => void) => {
    const cx: Record<string, any> = cookies['urlParams'];
    if (!cx) {
        return undefined;
    }
    if (undefined === cx[urlParam]) {
        return undefined;
    }
    const value = cx[urlParam];
    delete cx[urlParam];
    const d = new Date();
    d.setTime(d.getTime() + 1 * 60 * 1000);
    setCookie('urlParams', cx, { expires: d });
    return value;
}
/*
export const cacheFile = (fileName: string, data: string, seconds: number) => {
    try {
        sessionStorage.setItem(`${fileName}_file`, data);
        sessionStorage.setItem(`${fileName}_ts`, (Date.now() + seconds * 1000).toString());
    } catch (e) {
        console.log(e);
    }
}

export const getFileFromCache = (fileName: string): string | undefined => {
    const ts = parseInt(sessionStorage.getItem(`${fileName}_ts`) ?? '0', 10);
    if (Date.now() <= ts) {
        const data = sessionStorage.getItem(`${fileName}_file`);
        if (data) {
            return data;
        }
        return undefined;
    }
    else return undefined;
}
*/
export const base64FromArraybuffer = async (data: Uint8Array) => {
    // Use a FileReader to generate a base64 data URI
    const base64url = await new Promise((r) => {
        const reader = new FileReader()
        reader.onload = () => r(reader.result)
        reader.readAsDataURL(new Blob([data]))
    })

    /*
    The result looks like 
    "data:application/octet-stream;base64,<your base64 data>", 
    so we split off the beginning:
    */
    //@ts-ignore
    return base64url.split(",", 2)[1]
}

export const padZero = (str: string, len?: number): string => {
    len = len || 2;
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}


export const invertColor = (hexIn: string, bw: boolean): string => {
    let hex = hexIn;
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    const rS = (255 - r).toString(16);
    const gS = (255 - g).toString(16);
    const bS = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(rS) + padZero(gS) + padZero(bS);
}


export const keyToTitle = (key: string, keysToTitle: Record<string, string>) => {
    if (keysToTitle[key] !== undefined) {
        return keysToTitle[key]
    }
    const r = key.replace(/([A-Z])/g, " $1");
    const title = r.charAt(0).toUpperCase() + r.slice(1);
    return title;
}

export const sanitise = (text: string | undefined): string => {
    if (undefined === text) {
        return '';
    }
    if (/^E(\d+)Hz$/.test(text)) {
        return text.substring(1);
    }
    return text;
}

export const deepEqual = (x: Object, y: Object): boolean => {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => deepEqual(x[key as keyof (Object)], y[key as keyof (Object)]))
    ) : (x === y);
}