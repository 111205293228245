import React, { useEffect } from "react";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from './hooks';
import { initialiseStore } from './store/mainSlice';
import { useAuth0 } from "@auth0/auth0-react";
import { useCookies } from "react-cookie";
import { initI18n } from './localisation/localised';

import './App.css';

import { Grid } from './Grid';

import { LoginCard, LogoutModal, UrlUnwrapper, TokenRejected } from './Components';

// just one minute to remmember the parametes while the app initialises

export const PARAMS_COOKIE_EXPIRY = 1 * 60 * 1000;
interface IAuthUser {
  email?: string | undefined;
  email_verified?: boolean;
}

export const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const tokenRejected = useSelector(store => store.main.tokenRejected);
  const [_cookies, setCookie] = useCookies(['urlParams']);

  useEffect(() => {
    const clearCaches = async () => {
      caches.keys().then(names => {
        names.forEach(async cacheName => {
          const cache = await caches.open(cacheName);
          const keys = await cache.matchAll();
          keys.forEach(async resp => {
            await cache.delete(resp.url);
          });
        });
      });
    }
    dispatch(initialiseStore());
    initI18n();
    clearCaches();
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const d = new Date();
    d.setTime(d.getTime() + PARAMS_COOKIE_EXPIRY);
    const urlParamsObj: Record<string, string> = {};
    for (const [key, value] of urlParams) {
      urlParamsObj[key] = value;
    }
    if (Object.keys(urlParamsObj).length > 0) {
      setCookie('urlParams', urlParamsObj, { expires: d });
    }
  }, [setCookie]);

  const { isAuthenticated, user } = useAuth0();

  if (user) {
    if (!user.name) {
      user.name = user.email; // Keycloak doesn't have this field, and we check for it...
    }
  } const userAuthorised = (isAuthenticated: boolean, user: IAuthUser | undefined) =>
    isAuthenticated && user
  const userUnAuthorised = (isAuthenticated: boolean, user: IAuthUser | undefined) =>
    isAuthenticated && user

  return (<>{userAuthorised(isAuthenticated, user) ?
    <>{tokenRejected ?
      <TokenRejected /> :
      <UrlUnwrapper><Grid /></UrlUnwrapper>}</>
    : userUnAuthorised(isAuthenticated, user) ?
      <LogoutModal /> :
      <LoginCard />}</>)
}

export default App;
