import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import { setUserGroups } from "../../../store/mainSlice";
import {
    IColumnHeader, TResultRow, ISortColumn, IUser,
    IUserGroup
} from "../../../store/Interfaces";
import { getUserGroups, getUsers } from "../../../api";
import { ResultsTableHeader } from "../ResultsTableHeader";
import { ResultsTableRow } from "../ResultsTableRow";

import { getFilteredSortedResults } from "../utils";

const columnHeaders: IColumnHeader[] = [{
    title: 'Name', type: 'String', propValue: 'name', propDisplay: 'name'
}, {
    title: 'Select', type: 'Boolean', propValue: 'selected', propDisplay: 'selected'
}, {
    title: 'Users', type: 'String', propValue: 'users', propDisplay: 'users'
}, {
    title: 'Projects', type: 'String', propValue: 'projects', propDisplay: 'projects'
}];
export interface IUserTableProps {
    resultFilterText: string;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    onClickRow: (userGroup: IUserGroup) => void;
}

export const UserGroupTable: React.FunctionComponent<IUserTableProps> = ({ onClickTableColumn, resultFilterText, onClickRow, sortColumn }) => {
    const dispatch = useDispatch();
    const { token, projects, userGroups } = useSelector(state => state.main);
    const [users, setUsers] = useState<IUser[]>([]);
    const tableHeader = useRef<HTMLTableRowElement>(null);
    const [loading, setLoading] = useState(false);
    const [expandedHeaders, setExpandedHeaders] = useState<Record<string, boolean>>({});
    const [ac, setAc] = useState<AbortController>();

    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        const ac = new AbortController();
        const localGetUserGroups = async (ac: AbortController) => {
            setLoading(true);
            getUserGroups(token).then((res) => {
                dispatch(setUserGroups(res));
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                console.error(err);
            });
        };
        localGetUserGroups(ac);
    }, [token, ac]);

    useEffect(() => {
        const getUsersLocal = async () => {
            try {
                getUsers(token).then(localUserList => {
                    if (Array.isArray(localUserList)) {
                        setUsers(localUserList);
                    }
                })
            } catch (e) {
                console.error('Error');
            }
        }
        if (token) {
            getUsersLocal();
        }
    }, [token]);

    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] })
    }

    const userGroupToDisplay = (userGroup: IUserGroup): TResultRow => {
        return {
            ...userGroup,
            name: userGroup.name,
            users: (userGroup.users ?? []).map(u => users.find(us => us.uuid === u)?.emailAddress ?? '').filter(s => s.length).join(', ').substring(0, 30),
            projects: (userGroup.projects ?? []).map(p => projects.find(pr => pr.uuid === p)?.name ?? '').filter(s => s.length).join(', ').substring(0, 30),
            gpsLat: 0,
            gpsLong: 0,
            resultUuid: '', siteUuid: '', assetUuid: '', companyUuid: '', tests: 0
        }
    }



    const displayUserGroupsProjects = getFilteredSortedResults(userGroups.map(userGroupToDisplay), null, '', resultFilterText, [], sortColumn, columnHeaders);
    return <>
        {loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> :
            <div className='resultsTableClipper relative overflow-y-auto bg-hvpd-grey-50'>
                {displayUserGroupsProjects.length > 0 ? <table className='table-auto min-w-full border-slate-400 border'>
                    <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                        <tr ref={tableHeader}>
                            {columnHeaders.map((colHeader, i) => (
                                <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} expandedHeaders={expandedHeaders} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayUserGroupsProjects.map((userGroup, idx) => (
                            <ResultsTableRow key={idx} row={userGroup} columnHeaders={columnHeaders}
                                handleContextMenu={(e: MouseEvent, row: TResultRow) => { }}
                                expandedHeaders={expandedHeaders}
                                onClickRow={() => onClickRow(userGroups.find(p => p.uuid === userGroup.uuid) as IUserGroup)}
                                selected={false}
                            />
                        ))}
                    </tbody>
                </table> :
                    <div className='w-full flex mt-20'>
                        <div className='flex-grow text-center'><span>No results to display</span>
                        </div>
                    </div>}
            </div >
        }
    </>
}