import React, { useEffect, useRef, useCallback, useReducer } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { Busy } from "../Busy";
import { SlideOver } from "../SlideOver";
import { useAppSelector as useSelector, useTimeout } from '../../hooks';
import { downloadReport, downloadReportData, getReportDocument, getEntityDocument, downloadEntityDocument } from "../../api";
import { IAsset, IResultHistory, TDocumentDescriptor, TEntityType, TResultRow, ILinkedEntity, TTestType, TUserPermission } from '../../store/Interfaces';
import { base64FromArraybuffer } from '../../utils';
import { ReportHistorySelect } from './ReportHistorySelect';
import { LinkedReports } from './LinkedReports';
import { ReportApproval } from "./ReportApproval";

import './Report.css';

const COUNTS_TIMEOUT = 10;

interface IReportProps {
    resultRow?: TResultRow;
    uuid: string;
    resultEndpointName: TTestType;
    entityType: TEntityType | 'Report';
    resultHistory: { uuid: string, history: Array<IResultHistory> | Array<TDocumentDescriptor> };
    resultHistoryIndex: number;
    setResultHistoryIndex: (index: number) => void;
    loadingHistory: boolean;
    loading: boolean;
    setLoading: (loading: boolean) => void; // wrapped in useCallback
    visible: boolean;
    container: HTMLElement | null;
    linkedEntities?: ILinkedEntity[];
    setReportApproval?: (approval: boolean) => void;
    setResultApproval?: (approval: boolean) => void;
}

const TimeToString = (time: number): string => {
    const d = new Date(parseInt(time + '000'));
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
}

interface IDocumentHistorySelectProps {
    resultHistory: Array<TDocumentDescriptor>,
    historySorter: (history: TDocumentDescriptor[]) => TDocumentDescriptor[],
    resultHistoryIndex: number,
    selectHistoryPage: React.ChangeEventHandler<HTMLSelectElement>
}

const DocumentHistorySelect: React.FC<IDocumentHistorySelectProps> = ({ resultHistory, historySorter, resultHistoryIndex, selectHistoryPage }) => {
    const getEndDate = (report: TDocumentDescriptor) => {
        if (report.lastModifiedTimestamp) {
            return TimeToString(report.lastModifiedTimestamp);
        }
    }
    const getName = (report: TDocumentDescriptor): string => {
        // defend against bad data
        if (undefined === report.requestRelativeName) {
            return '';
        }
        let name: string = report.requestRelativeName;
        if (name.endsWith('.pdf')) {
            name = name.substring(0, name.length - 4);
        }
        if (name.length > 20) {
            // for some reason it wont allow unicode ellipsis
            name = name.substring(0, 12) + '...' + name.substring(name.length - 5);
        }
        name += ': ' + getEndDate(report);
        return name;
    };

    const sortedDocumentHistory = historySorter(resultHistory);
    return <select value={resultHistoryIndex !== -1 ? sortedDocumentHistory[resultHistoryIndex].requestRelativeName : ''} className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border-1 w-[60%]' onChange={selectHistoryPage}>{sortedDocumentHistory.map((a, idx) => <option key={idx} value={a.requestRelativeName}>{getName(a)}</option>)}</select>
}

type TReportError = {
    message: string;
    severity: 'error' | 'warning' | 'info';
}

interface IReportErrorProps {
    clearReportError: () => void;
    reportError: TReportError;
}

const ReportError: React.FC<IReportErrorProps> = ({ reportError, clearReportError }) => {
    return reportError.message ?
        (<div className={`min-w-80 h-14 px-7 py-4 mx-2 block rounded-md ${reportError.severity === 'error' ? ' bg-red-200 border-red-300' : reportError.severity === 'warning' ? 'bg-yellow-200 border-yellow-300' : 'bg-blue-200 border-blue-300'} `}>{reportError.message}
            <button className={`float-right hover:border-0 ${reportError.severity === 'error' ? ' text-red-400 hover:text-red-800 hover:border-red-900' : reportError.severity === 'warning' ? ' text-yellow-400 hover:text-yellow-800 hover:border-yellow-900' : ' text-blue-400 hover:text-blue-800 hover:border-blue-900'}}`} onClick={() => clearReportError()}><FontAwesomeIcon className='' icon={solid('xmark')} /></button>
        </div>) : null

}

interface IReportState {
    reportPDF: string | undefined;
    reportError: TReportError;
    numPages: number;
    pageNumber: number;
    containerWidth: number;
    showTimer: number | null;
    resizeTimeout: number | null;
    showLinkedEntities: boolean;
    countsTimeout: number;
    retry: boolean;
}
interface IReportAction {
    type: 'setReportPDF' | 'setReportError' | 'setNumPages' | 'setPageNumber' | 'setContainerWidth' | 'setShowTimer' | 'setResizeTimeout'
    | 'setShowLinkedEntities' | 'setCountsTimeout' | 'setRetry';
    payload: string | number | null | undefined | boolean | TReportError;
}

const initialState: IReportState = {
    reportPDF: undefined,
    reportError: { message: '', severity: 'error' },
    numPages: 0,
    pageNumber: 1,
    containerWidth: 500,
    showTimer: null,
    resizeTimeout: null,
    showLinkedEntities: false,
    countsTimeout: 0,
    retry: false
}

export const Report: React.FunctionComponent<IReportProps> = ({ resultRow, uuid, resultEndpointName, entityType, resultHistory, resultHistoryIndex, setResultHistoryIndex, loadingHistory, visible, loading, setLoading, container, linkedEntities, setReportApproval, setResultApproval }) => {
    const ac = useRef<AbortController>();
    const { token, assets, sites, companies, currentUser, viewBundle: { selectedResultTable } } = useSelector(store => {
        return store.main;
    });
    const reducer = (state: IReportState, action: IReportAction): IReportState => {
        switch (action.type) {
            case 'setReportPDF':
                return { ...state, reportPDF: action.payload as string };
            case 'setReportError':
                return { ...state, reportError: action.payload as TReportError };
            case 'setNumPages':
                return { ...state, numPages: action.payload as number };
            case 'setPageNumber':
                return { ...state, pageNumber: action.payload as number };
            case 'setContainerWidth':
                return { ...state, containerWidth: action.payload as number };
            case 'setShowTimer':
                return { ...state, showTimer: action.payload as number | null };
            case 'setResizeTimeout':
                return { ...state, resizeTimeout: action.payload as number | null };
            case 'setShowLinkedEntities':
                return { ...state, showLinkedEntities: action.payload as boolean };
            case 'setCountsTimeout':
                return { ...state, countsTimeout: action.payload as number };
            case 'setRetry':
                return { ...state, retry: action.payload as boolean };
            default:
                console.error('unknown action type', action);
                return state;
        }
    }
    const [{ reportPDF, reportError, numPages, pageNumber, containerWidth, showTimer, resizeTimeout, showLinkedEntities, countsTimeout, retry }, localDispatch] = useReducer(reducer, initialState);

    const setReportPDF = (reportPDF: string | undefined) => localDispatch({ type: 'setReportPDF', payload: reportPDF });
    const setReportError = (reportError: TReportError) => localDispatch({ type: 'setReportError', payload: reportError });
    const clearReportError = () => localDispatch({ type: 'setReportError', payload: { message: '', severity: 'error' } });
    const setNumPages = (numPages: number) => localDispatch({ type: 'setNumPages', payload: numPages });
    const setPageNumber = (pageNumber: number) => localDispatch({ type: 'setPageNumber', payload: pageNumber });
    const setContainerWidth = (containerWidth: number) => localDispatch({ type: 'setContainerWidth', payload: containerWidth });
    const setShowTimer = (showTimer: number | null) => localDispatch({ type: 'setShowTimer', payload: showTimer });
    const setResizeTimeout = (resizeTimeout: number | null) => localDispatch({ type: 'setResizeTimeout', payload: resizeTimeout });
    const setShowLinkedEntities = (showLinkedEntities: boolean) => localDispatch({ type: 'setShowLinkedEntities', payload: showLinkedEntities });
    const setRetry = (retry: boolean) => localDispatch({ type: 'setRetry', payload: retry });
    const reportContainer = useRef<HTMLDivElement>(null);

    useTimeout(() => {
        setResizeTimeout(null);
        setShowTimer(1);
        // setShow(false);
    }, resizeTimeout);

    useTimeout(() => {
        setContainerWidth(getContainerWidth());
        setShowTimer(null);
    }, showTimer);

    useTimeout(() => {
        localDispatch({ type: 'setCountsTimeout', payload: null });
    }, countsTimeout);

    type TLoaded = {
        numPages: number;
    }

    useEffect(() => {
        let observer: ResizeObserver;
        if (container) {
            observer = new ResizeObserver(
                (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
                    //                  setResizeTimeout(RESIZE_DELAY);
                    setContainerWidth(getContainerWidth());
                });
            observer.observe(container);
        }
        return () => {
            if (container && observer) {
                observer.unobserve(container);
            }
        }
    }, [container]);

    const onDocumentLoadSuccess = (loaded: TLoaded) => {
        setNumPages(loaded.numPages);
        clearReportError();
        setPageNumber(1);
    }

    const changePage = (offset: number) =>
        setPageNumber(pageNumber + offset);

    const previousPage = () => changePage(-1);

    const nextPage = () => changePage(1);

    useEffect(() => {
        const localAc = new AbortController();
        ac.current = localAc;
        setPageNumber(1);
        localDispatch({ type: 'setCountsTimeout', payload: COUNTS_TIMEOUT });
        setContainerWidth(getContainerWidth());
        setShowLinkedEntities(false);
        return () => {
            console.log('Aborting report fetch');
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        setContainerWidth(getContainerWidth());
        setShowLinkedEntities(false);
    }, [visible]);

    useEffect(() => {
        setReportPDF(undefined);
        setShowLinkedEntities(false);
        setPageNumber(1);
    }, [resultRow, entityType, uuid]);

    /* reset to the first item in the result history if the history changes */
    useEffect(() => {
        console.log('Result history changed');
        if (resultHistory && resultHistory.history.length > 0) {
            setResultHistoryIndex(0);
        } else {
            setResultHistoryIndex(-1);
            fetch(`/Atlas_Customer_Result_Data_Upload.pdf`).then(p => {
                const buff = p.arrayBuffer();
                buff.then(text => {
                    const report = new Uint8Array(text);
                    base64FromArraybuffer(report).then(pdf => {
                        setReportPDF(pdf);
                        setLoading(false);
                    });
                })
            }).catch(e => {
                setReportError({ message: 'Error loading report', severity: 'error' });
                setLoading(false);
            })
            setPageNumber(1);
        }
    }, [resultHistory]);

    const resetLoading = () => {
        if (ac.current) {
            ac.current.abort();
        }

        /*
        if (loading && ac.current) {
            ac.current.abort();
            console.log('Aborting on reset');
            //ac.current = new AbortController();
            setLoading(false);
        } */
    }

    // if we have selected a different item then abort the current fetch
    useEffect(() => {
        resetLoading();
    }, [uuid]);

    useEffect(() => {
        localDispatch({ type: 'setCountsTimeout', payload: COUNTS_TIMEOUT });
    }, [linkedEntities]);

    useEffect(() => {
        if (resultHistory.uuid === uuid && resultHistory.history.length > 0 && resultHistoryIndex >= 0) {
            resetLoading();
        }
    }, [resultHistory, uuid, resultHistoryIndex]);

    useEffect(() => {
        const getLatestReport = async (token: string, uuid: string, fileName: string, cacheIdentifier: string, etag: string) => {
            if (ac.current) {
                const isDocument = ["Company", 'Asset', 'Site', 'SubSite'].includes(entityType);
                setLoading(true);
                let p: Promise<{ report: Uint8Array, etag: string }>;
                if (isDocument) {
                    p = getEntityDocument(token, uuid, fileName, entityType as TEntityType, ac.current, etag);
                } else {
                    p = getReportDocument(token, uuid, true, ac.current, etag);
                }
                p.then(async reportRes => {
                    // see if the cached version will do
                    let pdf = '';
                    /*                 if (etag.length > 0 && reportRes.report.length === 0) {
                                         pdf = getFileFromCache(`Report-${cacheIdentifier}`) ?? '';
                                     } else {
                                         pdf = await base64FromArraybuffer(reportRes.report);
                                         cacheFile(`Report-${cacheIdentifier}`, pdf, REPORT_CACHE_SECONDS);
                                         cacheFile(`Report-${cacheIdentifier}-etag`, reportRes.etag, REPORT_CACHE_SECONDS)
                                     } */
                    pdf = await base64FromArraybuffer(reportRes.report);
                    setReportPDF(pdf);
                    setLoading(false);
                    setRetry(false);
                }).catch(error => {
                    setLoading(false);
                    if (ac.current && !ac.current.signal.aborted) {
                        setReportError({ message: 'Report unavailable for this result', severity: 'warning' });
                    } else {
                        if (retry === false) {
                            setRetry(true);
                        }
                    }
                })

            }
        }
        if (!loading && token && /*ac.current &&*/ resultHistory.uuid === uuid && resultHistory.history.length > 0 && resultHistoryIndex >= 0 && resultHistoryIndex < resultHistory.history.length) {
            //if (!loading) {
            if (!ac.current || ac.current.signal.aborted) {
                ac.current = new AbortController();
            }
            clearReportError();
            let identifier: string;
            let fileName = ''
            let uuidLocal = uuid;
            if (entityType === 'Report') {
                const sorted = resultSorter(resultHistory.history as IResultHistory[]);
                const reportUuid = sorted[resultHistoryIndex].reportUuid;
                if (reportUuid) {
                    uuidLocal = sorted[resultHistoryIndex].uuid;
                    identifier = uuidLocal;
                }
                else {
                    // We get the latest report using the assetUuid and a specific end-point,
                    // we could just get the report using the reportUuid we know...
                    // But we need to handle not getting anything if the latest result doesn't actually have a report
                    uuidLocal = "";
                    identifier = "";
                }
            } else {
                const sorted = documentSorter(resultHistory.history as TDocumentDescriptor[]);
                identifier = sorted[resultHistoryIndex].md5Base64String;
                fileName = sorted[resultHistoryIndex].requestRelativeName;
            }
            // console.log(`Loading report ${resultHistory ? resultHistory[resultHistoryIndex].uuid : ''} ${resultHistory?.length}`);
            //            let pdf = getFileFromCache(`Report-${identifier}`);
            //            let etag = getFileFromCache(`Report-${identifier}-etag`) ?? '';
            //           if (pdf === undefined) {
            setReportPDF(undefined);
            if (uuidLocal && identifier) {
                getLatestReport(token, uuidLocal, fileName, identifier, '');
            }
            else {
                // Do we want to display something in the UI?
                setLoading(false);
                setReportError({ message: 'No report exists for this result', severity: 'info' });
            }

        }
    }, [resultHistory, resultHistoryIndex, entityType, uuid, token, retry]);


    const getContainerWidth = () => {
        if (reportContainer.current) {
            return reportContainer.current.offsetWidth - 20;
        }
        return 500;
    }

    const downloadReportLocal = () => {
        if (uuid && token && resultHistory && resultHistoryIndex >= 0 && ac.current) {
            const asset = assets.find(a => a.uuid === uuid) as IAsset;
            const siteName = sites.find(site => site.uuid === asset?.siteUuid)?.name ?? '';
            const companyName = companies.find(company => company.uuid === asset?.companyUuid)?.name ?? '';
            const d = new Date().toDateString();
            let fileName = ''
            let uuidLocal = uuid;
            if (entityType === 'Report') {
                const sorted = resultSorter(resultHistory.history as IResultHistory[]);
                uuidLocal = sorted[resultHistoryIndex].uuid;
                fileName = asset ? `${companyName} - ${siteName} - ${asset?.name} - ${d}` : `Report`;
            } else {
                const sorted = documentSorter(resultHistory.history as TDocumentDescriptor[]);
                fileName = sorted[resultHistoryIndex].requestRelativeName;
            }

            let p: Promise<Boolean>;
            if (["Company", 'Asset', 'Site', 'SubSite'].includes(entityType)) {
                p = downloadEntityDocument(token, uuidLocal, fileName, entityType as TEntityType, ac.current);
            } else {
                p = downloadReport(token, uuidLocal, fileName, resultEndpointName);
            }
            p.then(res => {
                if (!res && ac?.current) {
                    setReportError({ message: 'Failed to collect report for this item', severity: 'error' });
                }
            });
        }
    }

    const downloadReportDataLocal = () => {
        if (uuid && token && resultHistory && resultHistoryIndex >= 0 && ac.current) {
            if (entityType === 'Report') {
                const sorted = resultSorter(resultHistory.history as IResultHistory[]);
                downloadReportData(token, sorted[resultHistoryIndex].uuid).then(res => {
                    if (!res && ac.current) {
                        setReportError({ message: 'No downloadable data for this item', severity: 'info' });
                    }
                });
            }
        }
    }
    /*   const setReportCount = (count: number) => {
           localDispatch({ type: 'setLinkedReportCount', payload: linkedReportCount + count });
       } */
    const documentSorter = (resultHistory: TDocumentDescriptor[]) => [...resultHistory ?? []].sort((a, b) => b.lastModifiedTimestamp - a.lastModifiedTimestamp);
    const resultSorter = (resultHistory: IResultHistory[]) => [...resultHistory ?? []].sort((a, b) => b.endTime - a.endTime);

    const selectHistoryPage: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        if (resultHistory && entityType === 'Report') {
            const sorted = resultSorter(resultHistory.history as IResultHistory[]);
            setResultHistoryIndex(sorted.findIndex(r => r.uuid === e.target.value)); // search on the resultUuid which will always be present
        } else if (resultHistory) {
            const sorted = documentSorter(resultHistory.history as TDocumentDescriptor[]);
            setResultHistoryIndex(sorted.findIndex(r => r.requestRelativeName === e.target.value));
        }
    }

    const canShowApproval = (): boolean => {
        const approvalPermissions: Array<TUserPermission> = ['EApprove', 'EReadUnapproved', 'EFrontEndApprovalControls'];
        return selectedResultTable === 'results' && (setReportApproval !== undefined) && !!currentUser && approvalPermissions.every(permission => currentUser.userPermissions.includes(permission)) &&
            (undefined !== resultHistory.history[resultHistoryIndex]);
    }

    const showControls = (resultHistory.history.length > 1 && !showLinkedEntities) || (linkedEntities ?? []).length > 0;

    /* <Paginator page={historyPage} setPage={setHistoryPage} pages={pages} /> : null} */
    return (visible ? (<>

        {entityType === 'Asset' && resultRow && resultRow.tests === 0 && reportPDF === undefined ?
            <div className='container mt-20 text-center'>No reports are available for this asset</div> : null}

        <div className={`grid ${showControls ? 'grid-rows-[28px_1fr_36px]' : 'grid-rows-[1fr_36px]'} h-full w-full w ${showLinkedEntities ? 'opacity-90' : ''}`} ref={reportContainer}>
            {showControls ?
                <div className="grid grid-flow-col auto-cols-1fr">
                    {(resultHistory.history.length > 1) ?
                        (entityType === 'Report') ?
                            <ReportHistorySelect resultHistory={resultHistory.history as IResultHistory[]} historySorter={resultSorter} resultHistoryIndex={resultHistoryIndex} selectHistoryPage={selectHistoryPage} /> :
                            <DocumentHistorySelect resultHistory={resultHistory.history as TDocumentDescriptor[]} historySorter={documentSorter} resultHistoryIndex={resultHistoryIndex} selectHistoryPage={selectHistoryPage} />
                        : null}

                    {
                        ((linkedEntities ?? []).length > 0 && !showLinkedEntities) ? <span><button className='text-white float-right mr-4 disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-0 border-solid border-1 text-xs font-medium' onClick={() => setShowLinkedEntities(!showLinkedEntities)}>{showLinkedEntities ? 'Hide' : 'Show'} related reports</button></span> : null
                    }
                </div> : null
            }
            {loading || loadingHistory ? <Busy /> : null}
            <ReportError clearReportError={clearReportError} reportError={reportError} />

            {reportPDF ? <>
                <div className='overflow-auto'>
                    <Document onLoadSuccess={onDocumentLoadSuccess} file={`data:application/pdf;base64,${reportPDF}`}>
                        <Page pageNumber={pageNumber} width={containerWidth} />
                    </Document>
                </div>

                <div className='pl-4 inline w-full'>
                    {numPages > 1 ?
                        <>
                            <span className="mr-2 py-2 2xl:text-sm text-xs">Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</span>
                            <button
                                type="button"
                                className='bg-gray-300 rounded-md px-2 py-1 text-black hover:bg-gray-400 border-gray-500 my-2 w-fit text-xs 2xl:text-sm'
                                disabled={pageNumber <= 1}
                                onClick={previousPage}
                            >
                                Previous
                            </button>
                            <button
                                type="button"
                                className='bg-gray-300 rounded-md ml-1 mr-1 px-2 py-1 text-black hover:bg-gray-400 border-gray-500 my-2 w-fit text-xs 2xl:text-sm'
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                            >
                                Next
                            </button>

                        </> : null
                    }
                    {resultHistory && resultHistory.history.length > 0 ? <button onClick={downloadReportLocal} className='text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 2xl:text-sm text-xs rounded-md px-2 py-1 my-2 w-fit'>Download</button> : null}
                    {entityType === 'Report' && resultHistory && resultHistory.history.length > 0 ? <button onClick={downloadReportDataLocal} disabled={reportError.message.length > 0} className='text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 2xl:text-sm text-xs rounded-md ml-1 px-2 py-1 my-2 w-fit'>Download data</button> : null}
                    {canShowApproval() ? <ReportApproval result={resultHistory.history[resultHistoryIndex] as IResultHistory} setReportApproval={setReportApproval} setResultApproval={setResultApproval} uuid={uuid} resultEndpointName={resultEndpointName} /> : null}
                </div>
            </> : null}
            {(resultHistory.history.length === 0 && reportPDF === undefined) ?
                <div className='container mt-20 text-center'>No reports are available for this asset</div> : null}

        </div>
        <SlideOver active={showLinkedEntities} onClose={() => setShowLinkedEntities(false)} expanded={false} rightOffset={true} disableClose={false}>
            <>{(countsTimeout == null && showLinkedEntities) ?
                <LinkedReports linkedEntities={linkedEntities} token={token} resultEndpointName={resultEndpointName} /> : null}
            </>
        </SlideOver>
    </>) : null);
}

