import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import { Popover, Tooltip, initTE } from "tw-elements";

import {
    faDiagramProject,
    faStethoscope,
    faThumbsUp,
    faThumbsDown,
    faFlag,
    faPersonCircleQuestion,
    faClipboardQuestion,
    faFileCircleQuestion,
    faLocationPinSlash,
    faEngineWarning,
    faFileCircleMinus,
    faFileSlash,
    faCommentPen,
    faPencilSlash,
    faArrowUpRight,
    faArrowDownRight,
    faCircle,
    faCircle1
} from "@fortawesome/pro-duotone-svg-icons";

import { useAppSelector as useSelector } from '../../hooks';
import { IColumnHeader, TResultRow, ILatLng, TApprovalCount, TApprovalStatus, approveStates } from "../../store/Interfaces";
import { invertColor } from '../../utils';

import { translateField } from './utils';

initTE({ Popover, Tooltip });

export type TColour = 'Red' | 'Yellow' | 'Green' | 'Amber';
type TCellColours = Record<TColour, CSSProperties>

export const cellColours: TCellColours = {
    Red: { backgroundColor: '#fc3b3b', color: '0xe0e0e0' },
    Green: { backgroundColor: '#12d313', color: '0xe0e0e0' },
    Yellow: { backgroundColor: '#e8e816', color: '0xe0e0e0' },
    Amber: { backgroundColor: '#f9b103', color: '0xe0e0e0' }
}

interface IResultsTableRowProps {
    columnHeaders: IColumnHeader[];
    row: TResultRow;
    handleContextMenu: (e: MouseEvent, row: TResultRow) => void;
    onClickRow: (e: React.MouseEvent, row: TResultRow) => void;
    expandedHeaders: Record<string, boolean>;
    setHighlightLatLng?: (ll: ILatLng | undefined) => void;
    selected: boolean;
    forReporting?: boolean;
    onMouseOut?: (e: React.MouseEvent, row: TResultRow) => void;
    onMouseOver?: (e: React.MouseEvent, row: TResultRow) => void;
    showModal?: (row: TResultRow) => void;
    popover?: typeof Popover | null;
    setPopover?: (popover: typeof Popover) => void;
}

const iconMaps: Record<string, IconDefinition> = {
    'stethescope': faStethoscope,
    'diagram-project': faDiagramProject,
    'thumbs-up': faThumbsUp,
    'thumbs-down': faThumbsDown,
    'person-circle-question': faPersonCircleQuestion,
    'clipboard-question': faClipboardQuestion,
    'file-circle-question': faFileCircleQuestion,
    'location-pin-slash': faLocationPinSlash,
    'engine-warning': faEngineWarning,
    'file-circle-minus': faFileCircleMinus,
    'file-slash': faFileSlash,
    'comment-pen': faCommentPen,
    'pencil-slash': faPencilSlash,
    'flag': faFlag,
    'circle': faCircle,
    'arrow-up-right': faArrowUpRight,
    'arrow-down-right': faArrowDownRight,
    'circle1': faCircle1
};
const popOverTemplate = '<div class="opacity-0 transition-opacity duration-150 ease-in-out absolute top-0 left-0 z-[3000] block max-w-[267px] break-words bg-white bg-clip-padding border border-neutral-100 rounded-lg shadow-[0_0px_3px_0_rgba(0,0,0,0.07),0_2px_2px_0_rgba(0,0,0,0.04)] text-sm not-italic font-normal text-left no-underline underline-offset-auto normal-case leading-6 tracking-normal break-normal whitespace-normal dark:bg-neutral-700 dark:border-0 dark:text-white data-[popper-reference-hidden]:hidden" role="tooltip"> <h3 class="popover-header py-2 px-4 mb-0 border-b-2 border-neutral-100 rounded-t-lg font-medium empty:hidden dark:border-neutral-500"></h3>' + '<div class="popover-body p-4 text-[#212529] dark:text-white"></div> </div>'

interface IRowIconProps {
    icons: string[];
    className?: string;
    html: string;
    row?: TResultRow;
    tooltip?: string;
    showDetails?: () => void;
    popover?: typeof Popover | null;
    setPopover?: (popover: typeof Popover) => void;
}

const RowIcon: React.FunctionComponent<IRowIconProps> = (props) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const renderIcons = props.icons.filter(icon => undefined !== iconMaps[icon]);
    const [uid, setUid] = useState('');

    useEffect(() => {
        setUid(`${Math.random()}`);
    }, []);

    const clickButton = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (props.row && props.tooltip && props.setPopover) {
            if (props.popover) {
                props.setPopover(null);
            }

            const div = document.createElement('div');
            const root = createRoot(div);

            flushSync(() => {
                if (props.row && props.tooltip) {
                    root.render(<ApprovalReport row={props.row} tooltip={props.tooltip} uid={uid} />);
                }
            });
            const content = div.innerHTML;

            const p = new Popover(buttonRef.current, { trigger: 'manual', title: 'Additional info - ' + props?.row?.['assetName'], html: true, template: popOverTemplate, content: content ?? '', sanitize: false });
            props.setPopover && props.setPopover(p);
            div.remove();

            if (p) {
                p.show();
                // find the 'Details' button, set focus and on click
                const tooltipButton = document.getElementById(`tooltip-button-${uid}`);
                if (tooltipButton) {
                    tooltipButton.focus();
                    tooltipButton.onclick = () => {
                        props.setPopover && props.setPopover(null);
                        if (props.showDetails) {
                            props.showDetails();
                        }
                    }
                }
            }
        }
    }

    return (renderIcons.length > 0) ? (<div className="group inline-block">
        {props.row ?
            <button type='button'
                ref={buttonRef}
                onClick={clickButton}
            >
                {renderIcons.map((icon, idx) => <FontAwesomeIcon key={`icon-${idx}`} className={props.className} icon={iconMaps[icon]} />)}
            </button> : <span data-te-toggle="tooltip" title={props.html}>{renderIcons.map((icon, idx) => <FontAwesomeIcon key={`icon-${idx}`} className={props.className} icon={iconMaps[icon]} />)}</span>}
    </div>) : null;
}

const canShowApprovalReport = (row: TResultRow) => (
    (row['reportStateCounts'] && Object.values(row['reportStateCounts']).some(v => v > 0)) ||
    (row['resultStateCounts'] && Object.values(row['resultStateCounts']).some(v => v > 0)) ||
    (row['missingAssetFields'] && row['missingAssetFields'].length > 0));


interface IApprovalReportProps {
    row: TResultRow;
    tooltip: string;
    uid: string;
}

const ApprovalReport: React.FunctionComponent<IApprovalReportProps> = ({ row, tooltip, uid }) => {
    const report = (row['reportStateCounts'] || row['resultStateCounts'] || row['missingAssetFields'] || tooltip?.includes('SLD') ? <div className="w-[240px]">
        <div className='grid grid-cols-1'>
            <button id={`tooltip-button-${uid}`} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium">Details</button>
            {tooltip?.includes('SLD') ? <div className='text-center'>{tooltip}</div> : null}
            {(row['resultStateCounts'] || row['resultStateCounts']) ?
                <div className='grid grid-cols-2'>
                    {['reportStateCounts', 'resultStateCounts'].map((reportGroup, reportIdx) => {
                        const currentRow: TApprovalCount = row[reportGroup] as TApprovalCount;
                        return currentRow ? (
                            <React.Fragment key={reportGroup} >
                                <div className='col-span-2 text-center font-bold my-1'>{!reportIdx ? 'Report' : 'Result'} state</div>
                                {Object.keys(approveStates).map((key, idx) => {
                                    const value = currentRow[key as TApprovalStatus];
                                    return value ? (<React.Fragment key={`${key} -${idx} `}><div className='text-right'>{approveStates[key as TApprovalStatus]}</div>
                                        <div className='ml-1 text-left'>{currentRow[key as TApprovalStatus]}</div></React.Fragment>) : null
                                })
                                }
                            </React.Fragment>
                        ) : null
                    })} </div> : null}
            {
                (row['missingAssetFields'] ?? []).length > 0 ? <div className="grid grid-cols-1">
                    <div className='text-center font-bold my-1'>Missing fields</div>
                    {
                        row['missingAssetFields']?.map((field, idx) => <div key={`missing-${idx} `} className='ml-1 text-center whitespace-break-spaces'>{translateField(field)}</div>)
                    }
                </div> : null
            }
        </div>
    </div > : null);
    return report;
}


export const ResultsTableRow: React.FunctionComponent<IResultsTableRowProps> = ({ columnHeaders, row, handleContextMenu, onClickRow, expandedHeaders, setHighlightLatLng, selected, forReporting, onMouseOut, onMouseOver, showModal, popover, setPopover }) => {
    const rowRef = useRef<HTMLTableRowElement>(null);
    const defeatScroll = useRef<boolean>(false);
    const { selectedAssets } = useSelector(state => state.main);

    const localSetHightlightLatLng = (ll: ILatLng | undefined) => {
        if (setHighlightLatLng) {
            setHighlightLatLng(ll);
        }
    }

    useEffect(() => {
        if (selected && rowRef.current && selectedAssets.length === 1 && selectedAssets[0] === row.assetUuid) {
            if (!defeatScroll.current) {
                if (rowRef.current) {
                    //@ts-ignore
                    rowRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                }
            }
            defeatScroll.current = false;
        }
    }, [selected, selectedAssets, rowRef, row.assetUuid]);

    const localOnClickRow = (e: React.MouseEvent, row: TResultRow) => {
        if (onClickRow) {
            defeatScroll.current = true;
            onClickRow(e, row);
        }
    }

    useEffect(() => {
        const lContext = (e: MouseEvent) => handleContextMenu(e, row);

        if (rowRef.current) {
            const localRef = rowRef.current;
            localRef.addEventListener('contextmenu', lContext);
            return () => {
                localRef.removeEventListener('contextmenu', lContext);
            }
        }
    }, [rowRef, row, handleContextMenu]);

    const localOnMouseOver = (e: React.MouseEvent) => {
        localSetHightlightLatLng({ lat: row.gpsLat, lng: row.gpsLong });
        if (onMouseOver) {
            onMouseOver(e, row);
        }
    }

    const localOnMouseOut = (e: React.MouseEvent) => {
        localSetHightlightLatLng(undefined);
        if (onMouseOut) {
            onMouseOut(e, row);
        }
    }
    //() => localSetHightlightLatLng({lat: row.gpsLat, lng: row.gpsLong })

    return <tr ref={rowRef} tabIndex={0} onClick={(e) => localOnClickRow(e, row)} onMouseOut={localOnMouseOut} onMouseOver={localOnMouseOver} role='button' className={`hover:bg-hvpd-grey-200 bg-hvpd-grey-50 text-sm  ${selected ? 'outline outline-1 outline-sky-300 bg-sky-100' : ''} `}>{
        columnHeaders.map((colHeader, idx) => {
            let style: CSSProperties = {};
            const propColourValue: string = colHeader.propColour ? row[colHeader.propColour] as string : '';

            if (propColourValue && (propColourValue.length > 0)) {
                if (propColourValue.startsWith('#')) {
                    style = { backgroundColor: propColourValue, color: invertColor(propColourValue, true) };
                }
                else {
                    style = cellColours[propColourValue as TColour];
                }
            }

            const tooltip = colHeader.propTooltip && row[colHeader.propTooltip] ? row[colHeader.propTooltip] : undefined;

            let errorSummary: string = ''
            if (colHeader.propValue === 'tests') {
                errorSummary = tooltip?.toString() ?? '';
            }

            return (<td key={`col-${idx} `} className={`text-sm text-gray-900 font-light px-1 py-0 text-left whitespace-nowrap border border-grey-900 ${expandedHeaders[colHeader.title] === false ? 'max-w-[2rem] overflow-hidden text-ellipsis whitespace-nowrap' : ''} `} style={style}>
                {colHeader.isCheckbox ? <input type='checkbox' checked={!!row[colHeader.propValue] as boolean} readOnly /> :
                    <>{typeof row[colHeader.propDisplay] === 'object' ? Object.values(row[colHeader.propDisplay]).join(', ') : row[colHeader.propDisplay] ?? ''}
                        {colHeader.propIcons && row[colHeader.propIcons] ?
                            canShowApprovalReport(row) ?
                                <RowIcon key={`icon-${idx} `} icons={row[colHeader.propIcons] as string[]} html={errorSummary} row={row} tooltip={tooltip?.toString()} showDetails={showModal ? () => showModal(row) : undefined} popover={popover} setPopover={setPopover} />
                                : <RowIcon key={`icon-${idx} `} icons={row[colHeader.propIcons] as string[]} html={errorSummary} popover={popover} setPopover={setPopover} />
                            : null
                        }
                        {idx === 0 && forReporting ? <span className="float-right">
                            <RowIcon className='mr-2' key={`icon-${idx} `} icons={['circle1']} html='Selected for One Report' />
                        </span> : null}</>
                }
            </td>)
        })
    }</tr>
}