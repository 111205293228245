import React, { useEffect, useState } from "react";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../hooks';

import {
    setExtendedFilter,
    setResultFilterText
} from "../../store/mainSlice";

interface IUrlUnwrapperProps {
    children: React.ReactNode;
}

export const UrlUnwrapper: React.FunctionComponent<IUrlUnwrapperProps> = (props) => {
    const dispatch = useDispatch();
    const [initialised, setInitialised] = useState(false);
    const extendedFilter = useSelector(state => state.main.extendedFilter);
    const resultFilter = useSelector(state => state.main.viewBundle.tableFilterText);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const f = urlParams.get('filter');
        if (f && f !== resultFilter) {
            dispatch(setResultFilterText(f));
        }
        const extendedFilterLocal = urlParams.get('extendedFilter');
        if (extendedFilterLocal) {
            try {
                const ef = JSON.parse(extendedFilterLocal);
                if (JSON.stringify(ef) !== JSON.stringify(extendedFilter)) {
                    dispatch(setExtendedFilter(ef));
                }
            } catch (e) {
                console.error('Cannot parse extendedFilter', extendedFilterLocal, e);
            }
        }
        setInitialised(true);
    }, []);

    return <>{initialised ? props.children : null}</>

}