import { IFormField, TEntity } from './FormTemplate';

export const isBlank = (form: IFormField[], wipEntity: TEntity) => {
    return form.every(field => {
        if (Array.isArray(field.propValue)) {
            return !wipEntity?.[field.propValue[0]] && !wipEntity?.[field.propValue[1]];
        }
        else return !wipEntity?.[field.propValue];
    });
}

export const hasChanged = (entity: TEntity, form: IFormField[], wipEntity: TEntity): boolean => {
    return (entity !== null && !isBlank(form, entity) && form.some(field => {
        if (Array.isArray(field.propValue)) {
            return entity[field.propValue[0]] !== wipEntity?.[field.propValue[0]] || entity[field.propValue[1]] !== wipEntity?.[field.propValue[1]];
        }
        return entity?.[field.propValue] !== wipEntity?.[field.propValue];
    }));
}

export const createEntity = (form: IFormField[]): TEntity => {
    const baseEntity = form.reduce((acc, field) => {
        switch (field.type) {
            case 'Checkbox':
                return ({ ...acc, [field.propValue.toString()]: false }) as TEntity;
            case 'Date':
                return ({ ...acc, [field.propValue.toString()]: 0 }) as TEntity;
            case 'Number':
                return ({ ...acc, propValue: 0 }) as TEntity;
            case "String":
                return ({ ...acc, [field.propValue.toString()]: '' }) as TEntity;
            case "NumberPair":
                return ({ ...acc, [field.propValue[0].toString()]: '', [field.propValue[1].toString()]: '' }) as TEntity;
            case 'Select':
            case "MultiSelect":
            case "MultiSelectPair":
                return ({ ...acc, [field.propValue.toString()]: [] }) as TEntity;
            case 'UserSelect':
                return ({ ...acc, [field.propValue.toString()]: [] }) as TEntity;
            default:
                return ({ ...acc, [field.propValue.toString()]: '' }) as TEntity;
        }
    }, {});
    return { ...baseEntity, uuid: '' };
}