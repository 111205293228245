import React, { useEffect, useState } from "react";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setProjects } from "../../../store/mainSlice";

import { createProject, updateProject, deleteProject } from "../../../api";
import { FormTemplate, IFormField, TEntity, FormContext } from "../FormTemplate";
import { Busy } from "../../Busy";
import { projectEditForm, mapEnumsToForm } from "../../../templates/formTemplates";
import { IBaseProject } from "../../../store/Interfaces";
const TABLENAME = 'TableProject';

interface IProjectEditProps {
    project?: IBaseProject;
    endEdit: () => void;
    clearFocus: () => void;
}

export const ProjectEdit: React.FunctionComponent<IProjectEditProps> = ({ project, endEdit, clearFocus }) => {
    const dispatch = useDispatch();
    const { projects, token, tableDefinitionsComplete } = useSelector(state => state.main);
    const [wipProject, setWipProject] = useState<IBaseProject>();
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(projectEditForm);
    const [busy, setBusy] = useState(false);
    const [entityChanged, setEntityChanged] = useState(false);
    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        setWipProject({ ...project as IBaseProject });
    }, [project]);


    useEffect(() => {
        if (tableDefinitionsComplete[TABLENAME]?.searchParams) {
            const localFormWithOptions = mapEnumsToForm(projectEditForm, tableDefinitionsComplete[TABLENAME]?.searchParams);
            setFormWithOptions(localFormWithOptions);
        }
    }, [tableDefinitionsComplete])

    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createProject(token, entity as IBaseProject).then((project) => {
                dispatch(addToast('Project created'));
                setBusy(false);
                setWipProject(project);
                dispatch(setProjects([...projects, project as IBaseProject]));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            })
        } else {
            updateProject(token, entity as IBaseProject).then((project) => {
                dispatch(addToast('Project updated'));
                setBusy(false);
                setWipProject(project);
                dispatch(setProjects(projects.map(p => p.uuid === project.uuid ? project as IBaseProject : p)));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            });
        }
    }
    const onDelete = () => {
        setBusy(true);
        deleteProject(token, wipProject?.uuid as string).then(() => {
            dispatch(setProjects(projects.filter(p => p.uuid !== wipProject?.uuid)));
            clearFocus();
            dispatch(addToast('Porject deleted'));
            setWipProject(undefined);
            setBusy(false);
        }).catch((err) => {
            setBusy(false);
            dispatch(addError(err.message));
            console.error(err);
        });
    }

    return (
        <div className='m-2 overflow-auto max-h-full pb-4'>
            <div className="relative z-30  w-full h-full">
                {busy ? <Busy /> :
                    <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                        <FormTemplate entityName='Project' form={formWithOptions} entity={wipProject as IBaseProject} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={onDelete} onEndEdit={endEdit} />
                    </FormContext.Provider>}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}
        </div>
    )
}