import React from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { showModal } from '../../store/mainSlice';

import { FileUpload } from '../FileUpload';

export const Modal: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { modal } = useSelector(store => store.main);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(showModal(false));
    }
    return (<>{modal.show ? <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        {/* <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
    From: "opacity-0"
    To: "opacity-100"
    Leaving: "ease-in duration-200"
    From: "opacity-100"
    To: "opacity-0"
--> */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                {/*    <!--
            Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
--> */}
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <FileUpload type='zip' entityType='Asset' uuid='' />
                    </div>
                    <div className="rounded-md bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" onClick={onClose} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium">{t('Close')}</button>
                    </div>
                </div>
            </div>
        </div>
    </div> : null}</>)
}