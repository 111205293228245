import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../hooks';
import { addError, addToast } from "../../store/mainSlice";
import { TEntityType, TDocumentDescriptor, TStorageDetails } from "../../store/Interfaces";
import { Busy } from "../Busy";
import { FileUpload } from "../FileUpload";
import { getEntityStorage, downloadFile, TFileType, TUploadType } from "../../api";
export interface IStorageProps {
    uuid: string;
    entityType: TEntityType;
}

export const Storage: React.FunctionComponent<IStorageProps> = ({ uuid, entityType }) => {
    const dispatch = useDispatch();
    const { token } = useSelector(state => state.main);
    const [busy, setBusy] = useState(false);

    const [ac, setAc] = useState<AbortController>(new AbortController());
    const [uploadType, setUploadType] = useState<TUploadType>();
    /*  const [images, setImages] = useState<TDocumentDescriptor[]>([]);
      const [docs, setDocs] = useState<TDocumentDescriptor[]>([]);
      const [zips, setZips] = useState<TDocumentDescriptor[]>([]);
  */
    const [uploadTrigger, setUploadtTrigger] = useState(0);
    const [entityStorage, setEntityStorage] = useState<TStorageDetails>({});

    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, []);

    const upload = (type: TUploadType) => {
        setUploadType(type);
    }

    useEffect(() => {
        async function getStorage() {
            try {
                const es = await getEntityStorage(token, uuid, entityType);
                setEntityStorage(es);
                setBusy(false);
            } catch (e) {
                console.error(e);
                setBusy(false);
            }
        }
        if (entityType && token && uuid) {
            setBusy(true);
            getStorage();
        }
        getStorage();
    }, [entityType, token, uuid, uploadTrigger]);

    const onClickDownload = (file: TDocumentDescriptor, fileType: TFileType) => {
        setBusy(true);
        downloadFile(token, uuid, entityType, fileType, file.requestRelativeName, ac).then((res) => {
            if (res === 200) {
                dispatch(addToast(`File ${file.requestRelativeName} downloaded successfully`));
            } else {
                dispatch(addError(`File ${file.requestRelativeName} download failed [${res}]`));
            }
            setBusy(false);
        })
    }

    const onSuccessfulUpload = (file: File) => {
        dispatch(addToast(`File ${file.name} uploaded successfully`));
        setUploadType(undefined);
        setUploadtTrigger(uploadTrigger + 1);
    }

    const onError = (error: string) => {
        dispatch(addError(error));
        setUploadType(undefined);
    }

    /*useEffect(() => {
        const getFiles = async () => {
            setBusy(true);
            const promises = [getEntityFileList(token, uuid, entityType, 'Image', ac)
                .then(response => {
                    setImages(response)
                })
                .catch(error => {
                    console.log(error);
                }),
            getEntityFileList(token, uuid, entityType, 'Doc', ac)
                .then(response => {
                    setDocs(response)
                })
                .catch(error => {
                    console.log(error);
                }),
            getEntityFileList(token, uuid, entityType, 'Zip', ac)
                .then(response => {
                    setZips(response)
                })
                .catch(error => {
                    console.log(error);
                })];
            Promise.all(promises).then(() => {
                setBusy(false);
            });
        }

        if (token && uuid && entityType && ac) {
            getFiles();
        }
    }, [token, uuid, entityType, ac, uploadTrigger]);
*/


    return (
        <div>
            <div className="grid grid-cols-4 grid-flow-col-dense border-hvpd-grey-100 bg-hvpd-grey-50 border" >
                <button onClick={() => upload('zip')} disabled={uploadType === 'zip'} className='text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md m-2'>Upload Zip</button>

                <button onClick={() => upload('doc')} disabled={uploadType === 'doc'} className='text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md m-2'>Upload Document</button>

                <button onClick={() => upload('image')} disabled={uploadType === 'image'} className='text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md m-2'>Upload Image</button>

                <button onClick={() => setUploadType(undefined)} disabled={uploadType == null} className='text-white disabled:text-hvpd-grey-400 bg-hvpd-malibu-500 border-hvpd-malibu-200/40 hover:bg-hvpd-malibu-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md m-2'>Cancel</button>
            </div>
            {Object.values(entityStorage).flat().length === 0 ? <div className='text-hvpd-grey-400'>No files stored</div> :
                <div className="border-hvpd-grey-100 border mt-2 p-1">
                    <div className="w-full flex flex-row justify-between border-hvpd-grey-100 border-b ">
                        <div className='text-hvpd-grey-800 inline py-2 font-bold'>Files stored</div>
                        <button title='Refresh file list' onClick={() => setUploadtTrigger(uploadTrigger + 1)} className='text-white disabled:text-hvpd-grey-700 bg-hvpd-jungle-green-500 border-hvpd-jungle-green-200/40 hover:bg-hvpd-jungle-green-600 px-2 py-1 border-solid border-1 border-l-0 text-sm font-medium'><FontAwesomeIcon className='text-hvpd-' icon={solid('redo')} /></button>
                    </div>
                    {busy ? <Busy /> : null}
                    <div className={`grid grid-cols-${Object.keys(entityStorage).length}`}>
                        {Object.keys(entityStorage).map((key) => (
                            <div key={key} className="grid-rows-1">
                                <div className="text-left capitalize text-lg">{key}</div>
                                {Object.values(entityStorage[key]).map(file => (
                                    <div className="text-left" key={file.baseName}>
                                        <button className='hover:underline' title={`Click to download ${file.requestRelativeName}`} onClick={() => onClickDownload(file, key === 'doc' ? 'Doc' : key === 'image' ? 'Image' : 'Zip')} >{file.baseName}</button>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            }
            {
                uploadType != null ? <div className="mt-2">
                    {uploadType === 'zip' ?
                        <FileUpload uuid={uuid} entityType={entityType} type={uploadType} onError={onError} onSuccessfulUpload={onSuccessfulUpload} />
                        : null}
                    {uploadType === 'doc' ?
                        <FileUpload uuid={uuid} entityType={entityType} type={uploadType} onError={onError} onSuccessfulUpload={onSuccessfulUpload} />
                        : null}
                    {uploadType === 'image' ?
                        <FileUpload uuid={uuid} entityType={entityType} type={uploadType} onError={onError} onSuccessfulUpload={onSuccessfulUpload} />
                        : null}

                </div> : null
            }

        </div >
    );
}