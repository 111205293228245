import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';

import { ResultsTableHeader } from "../ResultsTableHeader";
import { ResultsTableRow } from "../ResultsTableRow";
import { getFilteredSortedResults } from "../utils";

import { IColumnHeader, TResultRow, ISortColumn, ISalesOrder } from '../../../store/Interfaces';
import { getSalesOrders } from "../../../api";
import { setSalesOrders } from "../../../store/mainSlice";

const columnHeaders: IColumnHeader[] = [{
    title: 'Number', type: 'String', propValue: 'salesOrderNumber', propDisplay: 'salesOrderNumber'
}, {
    title: 'Type', type: 'String', propValue: 'salesOrderType', propDisplay: 'salesOrderType'
}, {
    title: 'Title', type: 'String', propValue: 'salesOrderTitle', propDisplay: 'salesOrderTitle'
}, {
    title: 'Text', type: 'String', propValue: 'salesOrderText', propDisplay: 'salesOrderText'
}, {
    title: 'Acknowledged date', type: 'Date', propValue: 'acknowledgedDate', propDisplay: 'acknowledgedDate'
}, {
    title: 'Due date', type: 'Date', propValue: 'dueDate', propDisplay: 'dueDate'
}, {
    title: 'Client PO number', type: 'String', propValue: 'clientPONumber', propDisplay: 'clientPONumber'
}, {
    title: 'Client PO  date', type: 'Date', propValue: 'clientPODate', propDisplay: 'clientPODate'
}, {
    title: 'Projects', type: 'String', propValue: 'projects', propDisplay: 'projects'
}, {
    title: 'UUID', type: 'String', propValue: 'uuid', propDisplay: 'uuid'
}];

interface ISalesOrderTableProps {
    resultFilterText: string;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    onClickRow: (e: React.MouseEvent, salesOrder?: ISalesOrder) => void;
}

export const SalesOrderTable: React.FunctionComponent<ISalesOrderTableProps> = ({ onClickTableColumn, resultFilterText, onClickRow, sortColumn }) => {
    const dispatch = useDispatch();
    const { token, salesOrders, projects } = useSelector(state => state.main);
    const tableHeader = useRef<HTMLTableRowElement>(null);
    const [expandedHeaders, setExpandedHeaders] = useState<Record<string, boolean>>({});
    const [ac, setAc] = useState<AbortController>();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        const ac = new AbortController();
        const localGetSalesOrders = async (ac: AbortController) => {
            setLoading(true);
            getSalesOrders(token).then((res) => {
                // dispatch(setUserGroups(res));
                if (Array.isArray(res)) {
                    dispatch(setSalesOrders(res));
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                console.error(err);
            });
        };
        localGetSalesOrders(ac);
    }, [token, ac]);


    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] })
    }

    const salesOrderToDisplay = (salesOrder: ISalesOrder): TResultRow => {
        return {
            salesOrderNumber: salesOrder.salesOrderNumber,
            salesOrderType: salesOrder.salesOrderType,
            salesOrderTitle: salesOrder.salesOrderTitle,
            salesOrderText: salesOrder.salesOrderText,
            acknowledgedDate: new Date(salesOrder.acknowledgedDate * 1000).toLocaleDateString(),
            dueDate: new Date(salesOrder.dueDate * 1000).toLocaleDateString(),
            clientPONumber: salesOrder.clientPONumber,
            clientPODate: new Date(salesOrder.clientPODate * 1000).toLocaleDateString(),
            projects: (salesOrder.projects ?? []).map(p => projects.find(pr => pr.uuid === p)?.name ?? '').filter(s => s.length).join(', ').substring(0, 30),
            uuid: salesOrder.uuid,
            gpsLat: 0, gpsLong: 0, resultUuid: '', siteUuid: '', assetUuid: '', companyUuid: '', tests: 0
        }
    }

    const displaySalesOrders = getFilteredSortedResults(salesOrders.map(salesOrderToDisplay), null, '', resultFilterText, [], sortColumn, columnHeaders);

    return (
        <>{loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div > :
            (<div className='resultsTableClipper relative overflow-y-auto bg-hvpd-grey-50'>
                {displaySalesOrders.length > 0 ? <table className='table-auto min-w-full border-slate-400 border'>
                    <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                        <tr ref={tableHeader}>
                            {columnHeaders.map((colHeader, i) => (
                                <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} expandedHeaders={expandedHeaders} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displaySalesOrders.map((salesOrder, idx) => (
                            <ResultsTableRow key={idx} row={salesOrder} columnHeaders={columnHeaders}
                                handleContextMenu={(e: MouseEvent, row: TResultRow) => { }}
                                expandedHeaders={expandedHeaders}
                                onClickRow={(e) => onClickRow(e, salesOrders.find(so => so.uuid === salesOrder.uuid) as ISalesOrder)}
                                selected={false}
                            />
                        ))}
                    </tbody>
                </table> :
                    <div className='w-full flex mt-20'>
                        <div className='w-full flex mt-20'>
                            <div className='flex-grow text-center'><span>No results to display</span>
                                <button onClick={onClickRow} className='ms-2 flex-grow h-7 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md'>Show panel</button>
                            </div>
                        </div>
                    </div>}
            </div>)
        }</>);

}