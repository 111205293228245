import React, { useEffect, useState } from "react";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setSalesOrders } from "../../../store/mainSlice";
import { FormTemplate, TEntity, IFormField, FormContext } from "../FormTemplate";
import { createSalesOrder, deleteSalesOrder, updateSalesOrder } from "../../../api";

import { Busy } from "../../Busy";
import { salesOrderEditForm } from "../../../templates/formTemplates";

import { ISalesOrder, IBaseProject } from "../../../store/Interfaces";

interface ISalesOrderEditProps {
    salesOrder?: ISalesOrder;
    endEdit: () => void;
    clearFocus: () => void;
}

export const SalesOrderEdit: React.FunctionComponent<ISalesOrderEditProps> = ({ salesOrder, endEdit, clearFocus }) => {
    const dispatch = useDispatch();
    const { token, projects, salesOrders } = useSelector(state => state.main);

    const [wipSalesOrder, setWipSalesOrder] = useState<ISalesOrder>();
    const [busy, setBusy] = useState(false);
    const [entityChanged, setEntityChanged] = useState(false);
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(salesOrderEditForm);

    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        setWipSalesOrder({ ...salesOrder as ISalesOrder });
    }, [salesOrder]);

    const projectSort = (a: IBaseProject, b: IBaseProject) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }

    useEffect(() => {
        if (projects.length) {
            const formWithOptions = salesOrderEditForm.map(field => {
                if (field.propValue === 'projects') {
                    return { ...field, options: ([...projects] ?? []).sort(projectSort).map(project => ({ name: project?.name ?? '', value: project.uuid })) }
                }
                else {
                    return field
                }
            })
            setFormWithOptions(formWithOptions);
        }
    }, [projects])



    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createSalesOrder(token, entity as ISalesOrder).then((salesOrder: ISalesOrder) => {
                dispatch(addToast('Sales order created'));
                setBusy(false);
                setWipSalesOrder(salesOrder);
                dispatch(setSalesOrders([...salesOrders, salesOrder]));
            }).catch((err) => {
                console.error(err);
                setBusy(false);
                dispatch(addError('Error creating sales order'));
            })
        } else {
            updateSalesOrder(token, entity as ISalesOrder).then((salesOrder: ISalesOrder) => {
                dispatch(addToast('User group updated'));
                setBusy(false);
                setWipSalesOrder(salesOrder);
                dispatch(setSalesOrders(salesOrders.map(so => so.uuid === entity.uuid ? salesOrder as ISalesOrder : so)));
            }).catch((err) => {
                console.error(err);
                setBusy(false);
                dispatch(addError('Error updating sales order'));
            });
        }
    }
    const onDelete = () => {
        setBusy(true);
        deleteSalesOrder(token, wipSalesOrder?.uuid as string).then(() => {
            dispatch(addToast('User group deleted'));
            clearFocus();
            setBusy(false);
            setWipSalesOrder(undefined);
            dispatch(setSalesOrders(salesOrders.filter(ug => ug.uuid !== wipSalesOrder?.uuid)));
        }).catch((err) => {
            setBusy(false);
            dispatch(addError(err.message));
            console.error(err);
        });
    }

    return (
        <div className='m-2 overflow-auto max-h-full pb-4'>
            <div className="relative z-30 w-full h-full">
                {busy ? <Busy /> :
                    <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                        <FormTemplate entityName='Sales order' form={formWithOptions} setForm={setFormWithOptions} entity={wipSalesOrder as ISalesOrder} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={onDelete} onEndEdit={endEdit} />
                    </FormContext.Provider>}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}
        </div >)
}