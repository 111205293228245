import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    IAsset, ICompany, IDatabaseMode, IGeoPoint, TResultRow,
    IReport, IResult, ISite, ISubSite, IBaseProject, IUserGroup, TTableTestResultList,
    ITableDefinition, ITableDefinitionResult, ITableResult, TExtendedFilter,
    TTableResultEndpoint, TTabId, TAssetAccordion, IObservation, IUser,
    TDetailedAssetDiagram, TEntityDisplayName, TEntityType, IBuildInfo, IToast, IError, TTestType,
    TTableOptions, TResultObservations, TSlideOver,
    IResultRecommendation,
    ISalesOrder
} from './Interfaces';

interface IMainStore {
    assets: Array<IAsset>;
    companies: Array<ICompany>;
    database: IDatabaseMode;
    filterTemplate: TResultRow | undefined;
    geoPoints: Array<IGeoPoint>;
    reports: Array<IReport>;
    results: Array<IResult>;  // Probably unused now -- SH
    sites: Array<ISite>;
    subSites: Array<ISubSite>;
    projects: Array<IBaseProject>;
    userGroups: Array<IUserGroup>;
    salesOrders: Array<ISalesOrder>;
    assetDiagrams: Record<string, TDetailedAssetDiagram>,
    entityDisplayNames: Record<TEntityType, Array<TEntityDisplayName>>;
    buildInfo: IBuildInfo;
    token: string;
    tokenRejected: boolean;
    tableEndPoints: TTableTestResultList;
    tableDefinitions: Record<string, ITableDefinition>; // deprecated
    tableDefinitionsComplete: Record<string, ITableDefinitionResult | null>;
    tableResults: Record<TTestType, ITableResult>; // deprecated
    extendedFilter: TExtendedFilter[];
    enableResultFilter: boolean;
    latestResultFilter: boolean;
    resultFilter: TTableResultEndpoint | undefined;
    currentUser: IUser | undefined;
    toastList: Array<IToast>;
    errorList: Array<IError>;
    modal: { show: boolean, name: '' },
    assetAccordion: TAssetAccordion;
    sldViewUUIDs: Record<string, Array<string>>;
    selectedAssets: Array<string>,
    assetsForReporting: Array<{ assetUuid: string, resultUuid: string }>,
    oneReportResults: ITableDefinitionResult,
    reportTab: TTabId,
    dashboardMode: boolean,
    editMode: boolean,

    viewBundle: {
        selectedResultTable: TTableOptions,
        selectedResultRow: TResultRow | undefined,
        mapView: 'map' | 'sld',
        mapPanelExpanded: boolean,
        resultFilterText: string,
        tableFilterText: string,
        mapFilterText: string,
        reportsDisabled: boolean,
        boundsFiltered: boolean,
        includeNoTests: boolean,
        slideOver: TSlideOver;
    }
    recommendations: Array<string>;
    observations: TResultObservations;
    clipBoardObservation: { type: 'Result' | 'Asset', observation: IObservation } | null;
    clipBoardRecommendation: { type: 'Result' | 'Asset', recommendation: IResultRecommendation } | null;
}

const initialState: IMainStore = {
    assets: [], companies: [], currentUser: undefined,
    database: { version: 'dev' }, geoPoints: [],
    filterTemplate: undefined,
    extendedFilter: [],
    projects: [],
    userGroups: [],
    salesOrders: [],
    reports: [],
    results: [],
    sites: [],
    subSites: [],
    assetDiagrams: {},
    entityDisplayNames: { 'Asset': [], 'Company': [], 'Site': [], 'SubSite': [], 'User': [], 'Project': [] },
    buildInfo: {
        apiVersion: '',
        atlasVersion: 0,
        buildDateTime: '',
        colorStd: '',
        country: '',
        dateTime: '',
        language: '',
        locale: '',
        paperSize: '',
        timeZone: '',
        unixMillis: 0,
        zoneId: ''
    },
    tableEndPoints: [],
    tableDefinitions: { 'MCSAOnline': { columnHeaders: [], searchParams: [] }, 'OfflinePD': { columnHeaders: [], searchParams: [] }, 'OnlinePD': { columnHeaders: [], searchParams: [] } },
    tableDefinitionsComplete: { 'MCSAOnline': { columnHeaders: [], searchParams: [], rows: [] }, 'OfflinePD': { columnHeaders: [], searchParams: [], rows: [] }, 'OnlinePD': { columnHeaders: [], searchParams: [], rows: [] } },
    tableResults: { 'MCSAOnline': { rows: [] }, 'OfflinePD': { rows: [] }, 'OnlinePD': { rows: [], } },
    enableResultFilter: true,
    latestResultFilter: true,
    resultFilter: undefined,
    toastList: [],
    errorList: [],
    modal: {
        show: false, name: '',
    },
    token: '',
    tokenRejected: false,
    assetAccordion: { Asset: false, Company: true, Site: true },
    sldViewUUIDs: {},
    selectedAssets: [],
    assetsForReporting: [],
    oneReportResults: { columnHeaders: [], searchParams: [], rows: [] },
    reportTab: 'report',
    dashboardMode: false,
    editMode: false,
    viewBundle: {
        selectedResultTable: 'results',
        selectedResultRow: undefined,
        mapView: 'map',
        mapPanelExpanded: false,
        resultFilterText: '',
        tableFilterText: '',
        mapFilterText: '',
        reportsDisabled: false,
        boundsFiltered: false,
        includeNoTests: false,
        slideOver: ''
    },
    recommendations: [],
    observations: { Asset: [], Result: [], Site: [], Subsite: [], Company: [] },
    clipBoardObservation: null,
    clipBoardRecommendation: null
};

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        initialiseStore: (state) => {
            state = { ...initialState }
        },
        addSldViewUUID: (state, action: PayloadAction<{ uuid: string, fileName: string }>) => {
            const { uuid, fileName } = action.payload;
            if (!(state.sldViewUUIDs[fileName] ?? []).includes(uuid)) {
                state.sldViewUUIDs[fileName] = [...(state.sldViewUUIDs[fileName] ?? []), uuid];
            }
        },
        addSldViewUUIDs: (state, action: PayloadAction<{ fileName: string, uuids: Array<string> }>) => {
            const { fileName, uuids } = action.payload;
            let sldViewUUIDs = [...state.sldViewUUIDs[fileName] ?? []];
            uuids.forEach(uuid => {
                if (!sldViewUUIDs.includes(uuid)) {
                    sldViewUUIDs.push(uuid);
                }
            })
            state.sldViewUUIDs[fileName] = sldViewUUIDs;
        },
        resetSldViewUUIDs: (state) => {
            state.sldViewUUIDs = {};
        },
        setAssets: ((state, action: PayloadAction<Array<IAsset>>) => {
            state.assets = [...action.payload]
        }),
        setCompanies: ((state, action: PayloadAction<Array<ICompany>>) => {
            state.companies = [...action.payload]
        }),
        setDatabaseMode: ((state, action: PayloadAction<"beta" | "dev">) => {
            state.database = { version: action.payload }
        }),
        setEnableResultFilter: (state, action: PayloadAction<boolean>) => {
            state.enableResultFilter = action.payload
        },
        setExtendedFilter: (state, action: PayloadAction<TExtendedFilter[]>) => {
            state.extendedFilter = [...action.payload]
        },
        setLatestResultFilter: (state, action: PayloadAction<boolean>) => {
            state.latestResultFilter = action.payload
        },
        setGeoPoints: ((state, action: PayloadAction<Array<IGeoPoint>>) => {
            state.geoPoints = [...action.payload]
        }),
        addProjects: ((state, action: PayloadAction<Array<IBaseProject>>) => {
            state.projects = [...action.payload, ...state.projects.filter(p => !action.payload.some(p2 => p2.uuid === p.uuid))]
        }),
        setProjects: ((state, action: PayloadAction<Array<IBaseProject>>) => {
            state.projects = [...action.payload];
        }),
        addEntityDisplayNames: ((state, action: PayloadAction<{ entityType: TEntityType, names: Array<TEntityDisplayName> }>) => {
            state.entityDisplayNames = { ...state.entityDisplayNames, [action.payload.entityType]: [...action.payload.names, ...state.entityDisplayNames[action.payload.entityType].filter(n => !action.payload.names.some(n2 => n2.uuid === n.uuid))] }
        }),
        setReports: ((state, action: PayloadAction<Array<IReport>>) => {
            state.reports = [...action.payload]
        }),
        setSites: ((state, action: PayloadAction<Array<ISite>>) => {
            state.sites = [...action.payload]
        }),
        setSubSites: ((state, action: PayloadAction<Array<ISubSite>>) => {
            state.subSites = [...action.payload]
        }),
        setUserGroups: ((state, action: PayloadAction<Array<IUserGroup>>) => {
            state.userGroups = [...action.payload]
        }),
        setSalesOrders: ((state, action: PayloadAction<Array<ISalesOrder>>) => {
            state.salesOrders = [...action.payload]
        }),
        setBuildInfo: ((state, action: PayloadAction<IBuildInfo>) => {
            state.buildInfo = { ...action.payload }
        }),
        setFilterTemplate: ((state, action: PayloadAction<TResultRow | undefined>) => {
            state.filterTemplate = action.payload
        }),
        setToken: ((state, action: PayloadAction<string>) => {
            state.token = action.payload
        }),
        setTokenRejected: ((state, action: PayloadAction<boolean>) => {
            state.tokenRejected = action.payload
        }),
        setTableEndPoints: ((state, action: PayloadAction<TTableTestResultList>) => {
            state.tableEndPoints = action.payload
        }),
        setTableDefinitionComplete: ((state, action: PayloadAction<[string, ITableDefinitionResult | null]>) => {
            state.tableDefinitionsComplete = {
                ...state.tableDefinitionsComplete, [action.payload[0]]: action.payload[1]
            }
        }),
        setTableDefinition: ((state, action: PayloadAction<[string, ITableDefinition]>) => {
            state.tableDefinitions = {
                ...state.tableDefinitions, [action.payload[0]]: action.payload[1]
            }
        }),
        setTableResult: ((state, action: PayloadAction<[TTestType, ITableResult]>) => {
            state.tableResults = {
                ...state.tableResults, [action.payload[0]]: action.payload[1]
            }
        }),
        setResultFilter: ((state, action: PayloadAction<TTableResultEndpoint | undefined>) => {
            state.resultFilter =
                action.payload
        }),
        setCurrentUser: ((state, action: PayloadAction<IUser>) => {
            state.currentUser = action.payload
        }),
        setAssetDiagram: ((state, action: PayloadAction<{ uuid: string, assetDiagram: TDetailedAssetDiagram }>) => {
            state.assetDiagrams = { ...state.assetDiagrams, [action.payload.uuid]: action.payload.assetDiagram }
        }),
        addToast: (state, action: PayloadAction<string>) => {
            const id = state.toastList.reduce((acc, current) => current.id >= acc ? acc = current.id + 1 : acc, 1);
            state.toastList = [...state.toastList, { id, message: action.payload }]
        },
        removeToast: (state, action: PayloadAction<number>) => {
            state.toastList = state.toastList.filter(toast => toast.id !== action.payload);
        },
        addError: (state, action: PayloadAction<string>) => {
            if (!action.payload) return;
            const id = state.errorList.reduce((acc, current) => current.id >= acc ? acc = current.id + 1 : acc, 1);
            state.errorList = [...state.errorList, { id, message: action.payload, ttl: Date.now() + 10000 }]
        },
        removeError: (state, action: PayloadAction<number>) => {
            state.errorList = state.errorList.filter(error => error.id !== action.payload);
        },
        showModal: (state, action: PayloadAction<boolean>) => {
            state.modal.show = action.payload;
        },
        setAssetAccordion: (state, action: PayloadAction<TAssetAccordion>) => {
            state.assetAccordion = { ...action.payload };
        },
        setSelectedAssets: (state, action: PayloadAction<Array<string>>) => {
            state.selectedAssets = [...action.payload];
        },
        setAssetsForReporting: (state, action: PayloadAction<Array<{ assetUuid: string, resultUuid: string }>>) => {
            state.assetsForReporting = [...action.payload];
        },
        setReportTab: (state, action: PayloadAction<TTabId>) => {
            state.reportTab = action.payload;
        },
        setOneReportResults: (state, action: PayloadAction<ITableDefinitionResult>) => {
            state.oneReportResults = action.payload;
        },
        setDashboardMode: (state, action: PayloadAction<boolean>) => {
            state.dashboardMode = action.payload;
        },
        setEditMode: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        setSelectedResultTable: (state, action: PayloadAction<TTableOptions>) => {
            state.viewBundle.selectedResultTable = action.payload;
        },
        setSelectedResultRow: (state, action: PayloadAction<TResultRow | undefined>) => {
            state.viewBundle.selectedResultRow = action.payload;
        },
        setMapView: (state, action: PayloadAction<'map' | 'sld'>) => {
            state.viewBundle.mapView = action.payload;
        },
        setMapPanelExpanded: (state, action: PayloadAction<boolean>) => {
            state.viewBundle.mapPanelExpanded = action.payload;
        },
        setResultFilterText: (state, action: PayloadAction<string>) => {
            state.viewBundle.resultFilterText = action.payload;
        },
        setTableFilterText: (state, action: PayloadAction<string>) => {
            state.viewBundle.tableFilterText = action.payload;
        },
        setMapFilterText: (state, action: PayloadAction<string>) => {
            state.viewBundle.mapFilterText = action.payload;
        },
        setReportsDisabled: (state, action: PayloadAction<boolean>) => {
            state.viewBundle.reportsDisabled = action.payload;
        },
        setBoundsFiltered: (state, action: PayloadAction<boolean>) => {
            state.viewBundle.boundsFiltered = action.payload;
        },
        setIncludeNoTests: (state, action: PayloadAction<boolean>) => {
            state.viewBundle.includeNoTests = action.payload;
        },
        setSlideOver: (state, action: PayloadAction<TSlideOver>) => {
            state.viewBundle.slideOver = action.payload;
        },
        setClipBoardObservation: (state, action: PayloadAction<{ type: 'Result' | 'Asset', observation: IObservation } | null>) => {
            state.clipBoardObservation = action.payload;
        },
        setClipBoardRecommendation: (state, action: PayloadAction<{ type: 'Result' | 'Asset', recommendation: IResultRecommendation } | null>) => {
            state.clipBoardRecommendation = action.payload;
        },
        setRecommendations(state, action: PayloadAction<Array<string>>) {
            state.recommendations = action.payload;
        },
        setObservations(state, action: PayloadAction<TResultObservations>) {
            state.observations = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { initialiseStore,
    addSldViewUUID, addSldViewUUIDs, resetSldViewUUIDs, addToast, removeToast, addError, removeError,
    addEntityDisplayNames,
    setAssets, setAssetAccordion, setAssetDiagram, setBuildInfo, setCompanies, setCurrentUser,
    setDatabaseMode, setEnableResultFilter, setExtendedFilter, setFilterTemplate, setLatestResultFilter,
    setGeoPoints, addProjects, setProjects, setReports, setReportTab,
    setResultFilter, setSalesOrders,
    setSites, setSubSites, setTableEndPoints,
    setTableDefinitionComplete,
    setToken, setTokenRejected, setTableDefinition, setTableResult,
    setUserGroups,
    showModal, setSelectedAssets, setAssetsForReporting,
    setOneReportResults, setDashboardMode, setEditMode,

    setSelectedResultTable, setSelectedResultRow, setMapView, setMapPanelExpanded,
    setResultFilterText, setTableFilterText, setMapFilterText, setReportsDisabled, setBoundsFiltered,
    setIncludeNoTests, setSlideOver, setClipBoardObservation, setClipBoardRecommendation,
    setRecommendations, setObservations
} = mainSlice.actions

export default mainSlice.reducer