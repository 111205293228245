import React, { useCallback, useState, useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchHandlers } from "react-zoom-pan-pinch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { TAssetDiagram, TAssetResult } from '../../store/Interfaces';
import { convertDocEleToReact } from './utils';
import { useTimeout } from '../../hooks';
import { ContextMenu } from '../ContextMenu';
import './SvgUrl.css';

export interface ISVGUrlProps {
    assetUuid: string;
    assetDiagram: TAssetDiagram | undefined;
    zoom: boolean;
    width?: number;
    onClick?: (e: React.MouseEvent) => void;
    selectAssets: (uuid: string[]) => void;
    assetResults: Array<TAssetResult>;
}

export const SVGUrl: React.FunctionComponent<ISVGUrlProps> = ({ assetDiagram, zoom, width, onClick, selectAssets, assetResults }) => {
    const svgHolder = useRef<HTMLDivElement>(null);
    const svgRef = useRef<HTMLDivElement>(null);
    const [scaleTimeout, setScaleTimeout] = useState<number | null>(null);
    const setTransform = useRef<ReactZoomPanPinchHandlers['setTransform']>();
    const [viewBox, setViewBox] = useState<[number, number, number, number]>([0, 0, 0, 0]);
    const [initialScale, setInitialScale] = useState(-1);
    const [convertedSvg, setConvertedSvg] = useState<React.ReactElement>();
    const [menuShown, setMenuShown] = useState<boolean>(false);
    const [contextPos, setContextPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [assetsInView, setAssetsInView] = useState<string[]>([]);

    useEffect(() => {
        const onLocalClick = (v: string) => {
            const match = v.match(/^asset\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/);
            if (match && zoom) {
                selectAssets([match[1]]);
            }
        }

        if (assetDiagram && assetDiagram.imageData.trim().length > 0) {
            const domParser = new DOMParser();

            const ele = domParser.parseFromString(assetDiagram.imageData, "image/svg+xml");
            const { e: CompX, v: viewBoxIn, assets } = convertDocEleToReact(ele.documentElement as unknown as SVGElement, assetDiagram.uuid.slice(0, 8), assetResults, {}, zoom, onLocalClick);
            setConvertedSvg(CompX);
            setAssetsInView(assets);
            const vbx = viewBoxIn.split(' ').map(v => parseInt(v, 10));
            setViewBox(vbx.slice(0, 4) as [number, number, number, number]);
            const vs = (width ?? 500) / (vbx[2] - vbx[0]);
            setInitialScale(vs);
        }
    }, [assetDiagram, zoom, width, selectAssets, assetResults]);

    const resetTransform = useCallback((animationTime = 0) => {
        setScaleTimeout(null);
        if (setTransform.current && viewBox[2] > 0) {
            const vs = (width ?? 500) / (viewBox[2] - viewBox[0]);
            setTransform.current(0, 0, vs, animationTime);
        }
    }, [viewBox, width]);

    useTimeout(resetTransform, scaleTimeout);

    // cant use the useTimeout hook as it doesnt work with the 'p' value from the TransformWrapper
    useEffect(() => {
        setScaleTimeout(10);
    }, [viewBox]);

    useEffect(() => {
        resetTransform(200);
    }, [width, resetTransform]);

    const handleContextMenu: React.MouseEventHandler<HTMLDivElement> = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuShown(true);
        setContextPos({ x: e.pageX, y: e.pageY });
        return null;
    }
    /*
        useEffect(() => {
            const lContext = (e: MouseEvent) => handleContextMenu(e);
            if (svgRef.current) {
                const localRef = svgRef.current;
                localRef.addEventListener('contextmenu', lContext);
                return () => {
                    localRef.removeEventListener('contextmenu', lContext);
                }
            }
        }, []);
    */
    return <div ref={svgHolder} onClick={onClick}>
        {(zoom) ?
            <>
                {initialScale !== -1 ? <TransformWrapper initialScale={1} limitToBounds={false}
                    initialPositionX={0}
                    initialPositionY={0} minScale={0.5} maxScale={6} wheel={{ step: 0.06 }}
                    pinch={{ step: 0.02 }}>
                    {(p) => {
                        setTransform.current = p.setTransform;
                        return (<React.Fragment>
                            <div className="p-4 text-right bg-hvpd-malibu-900">
                                <button type='button' className='text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1' onClick={() => p.zoomIn()}><FontAwesomeIcon icon={solid('circle-plus')} /></button>
                                <button type='button' className='text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1' onClick={() => p.zoomOut()}><FontAwesomeIcon icon={solid('circle-minus')} /></button>
                                <button type='button' className='text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1' onClick={() => resetTransform(300)}><FontAwesomeIcon icon={solid('arrows-to-circle')} /></button>
                            </div>
                            <div ref={svgRef} onContextMenu={handleContextMenu} className='bg-hvpd-malibu-800 overflow-y-auto' style={{ maxHeight: 'calc(100vh - 176px)' }}>
                                <TransformComponent>{convertedSvg}</TransformComponent>
                            </div>
                        </React.Fragment>)
                    }}
                </TransformWrapper> : null}</> : null}
        {!zoom ? <div className='m-1 bg-hvpd-malibu-800'>{convertedSvg}</div> : null}
        <ContextMenu pos={contextPos} menuShown={menuShown} closeMenu={() => setMenuShown(false)} >
            <li className='w-full'><button onClick={() => selectAssets(assetsInView)} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>Select all assets in view</button></li>
        </ContextMenu >

    </div >
}
