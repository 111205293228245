import React from 'react';
import { useTranslation } from 'react-i18next';
interface IConfirmModalProps {
    onCancel: () => void;
    onConfirm: () => void;
    type: 'recommendation' | 'observation';
}

export const ConfirmModal: React.FunctionComponent<IConfirmModalProps> = ({ onConfirm, onCancel, type }) => {
    const { t } = useTranslation();

    const getWarningMessage = (type: 'recommendation' | 'observation') => {
        if (type === 'recommendation') {
            return t('Warning: This recommendation will be permenantly removed');
        }
        return t('Warning: This observation will be permenantly removed');
    }

    return (<div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        {getWarningMessage(type)}
                    </div>
                    <div className="rounded-md bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" onClick={onConfirm} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2">{t('Delete')}</button>
                        <button type="button" onClick={onCancel} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1" >{t('Cancel')}</button>
                    </div>
                </div>
            </div>
        </div>
    </div >);
}