import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CopyToClipboard from "react-copy-to-clipboard";
import { Popover, Tooltip, initTE } from "tw-elements";

import { useAppDispatch as useDispatch, useAppSelector as useSelector, useTimeout } from '../../hooks';
import {
    addToast,
    setTableDefinition,
    setTableResult, setAssetsForReporting,
    setSelectedAssets,
} from "../../store/mainSlice";
import {
    IColumnHeader, TResultRow, ISortColumn, TTableResultEndpoint, ILatLng, TTestType, TSlideOver,
} from "../../store/Interfaces";
import { getTableDefinition } from '../../api';
import { ContextMenu } from '../';
import { TBounds } from "../../Grid/Grid";
import { ResultsTableRow } from "./ResultsTableRow";
import { ResultsTableHeader } from "./ResultsTableHeader";
import { ConfirmApplyObservationModal } from "./ConfirmApplyObservationModal";
import { ProblemReportModal } from "./ProblemReportModal";

import { getFilteredSortedResults, jsonToCsv, downloadCSV } from './utils';

initTE({ Popover, Tooltip });

const POPOVER_HIDE_TIMEOUT = 4000;


export interface IResultsTableProps {
    tableEndPoint: TTableResultEndpoint | undefined;
    bounds: TBounds | null;
    boundsFiltered: 'map' | 'sld' | '';
    allowedAssets: string[];
    getReport: (e: React.MouseEvent, row: TResultRow) => void;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    resultFilterText: string;
    setHighlightLatLng: (ll: ILatLng | undefined) => void;
    refresh: number;
}

export const ResultsTable: React.FunctionComponent<IResultsTableProps> = ({ tableEndPoint, bounds, boundsFiltered, allowedAssets, getReport, onClickTableColumn, sortColumn, resultFilterText, setHighlightLatLng, refresh }) => {
    const dispatch = useDispatch();
    const { currentUser, extendedFilter, selectedAssets, assetsForReporting, token, tableDefinitions, tableResults, viewBundle: { includeNoTests }, clipBoardObservation } = useSelector(state => state.main);
    const definitions = tableEndPoint ? tableDefinitions[tableEndPoint.name] : { columnHeaders: [], searchParams: [] }
    const results = tableEndPoint ? tableResults[tableEndPoint.name as TTestType] : { rows: [] }

    const tableHeader = useRef<HTMLTableRowElement>(null);
    const rowRef = useRef<HTMLTableSectionElement>(null);
    const localRefresh = useRef(refresh);
    const localExtendedFilter = useRef<string>(JSON.stringify(extendedFilter));

    //  const [cachedTResultRow, setCachedTResultRow] = useState<TResultRow>();
    const [menuShown, setMenuShown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contextPos, setContextPos] = useState({ x: 0, y: 0 });
    const [contextRow, setContextRow] = useState<TResultRow | undefined>(undefined);

    const [expandedHeaders, setExpandedHeaders] = useState<Record<string, boolean>>({});
    const [ac, setAc] = useState<AbortController>();
    const [lastSelectedAssetUuid, setLastSelectedAssetUuid] = useState<string>('');
    const [lastRangeAdded, setLastRangeAdded] = useState<string[]>([]);

    const [filteredSortedResults, setFilteredSortedResults] = useState<TResultRow[]>([])

    const [showModal, setShowModal] = useState<TResultRow>();
    const [showApplyObservationModal, setShowApplyObservationModal] = useState(false);
    const [popover, setPopover] = useState<typeof Popover>(null);
    const [popoverHideTimeOut, setPopoverHideTimeOut] = useState<number | null>(null);

    /* the following are variables associated with table virtualisation */
    const [itemRowHeight, setItemRowHeight] = useState(22); // same height as each row
    const [headerWidths, setHeaderWidths] = useState<number[]>([]);
    const [displayStart, setDisplayStart] = useState(0);
    const [displayEnd, setDisplayEnd] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const localAc = new AbortController();
        localExtendedFilter.current = JSON.stringify(extendedFilter);
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, []);

    const loadTable = async () => {
        if (tableEndPoint && token && ac && !loading) {
            setContextRow(undefined);
            setLoading(true);
            getTableDefinition(token, tableEndPoint, extendedFilter, includeNoTests, ac).then(tableDefinition => {
                dispatch(setTableResult([tableEndPoint.name as TTestType, { rows: tableDefinition.rows }]));
                setLoading(false);
            }).catch(e => {
                console.error(e);
                setLoading(false);
            })
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // for some reason the extendedFilter is not being updated in the useEffect below, so we need to use a ref and compare with the previous value   
        const stringExtendedFilter = JSON.stringify(extendedFilter);
        if (stringExtendedFilter !== localExtendedFilter.current && tableEndPoint && token && ac && !loading) {
            localExtendedFilter.current = stringExtendedFilter;
            loadTable();
        }
    });

    useEffect(() => {
        if (tableEndPoint && token && (localRefresh.current !== refresh /*|| localExtendedFilter.current !== JSON.stringify(extendedFilter) */) && ac && !loading) {
            localRefresh.current = refresh;
            //   localExtendedFilter.current = JSON.stringify(extendedFilter);
            loadTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extendedFilter, localRefresh, tableEndPoint, token, dispatch, refresh, ac, loading])

    useEffect(() => {
        if (tableEndPoint && token && ac && !loading) {
            //   localExtendedFilter.current = JSON.stringify(extendedFilter);
            loadTable();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [includeNoTests])

    useEffect(() => {
        if (tableEndPoint && definitions.columnHeaders.length === 0 && token && ac) {
            setLoading(true);
            setContextRow(undefined);
            getTableDefinition(token, tableEndPoint, extendedFilter, includeNoTests, ac).then(tableDefinition => {
                dispatch(setTableDefinition([tableEndPoint.name, { columnHeaders: tableDefinition.columnHeaders, searchParams: tableDefinition.searchParams }]));
                dispatch(setTableResult([tableEndPoint.name as TTestType, { rows: tableDefinition.rows }]));
                setLoading(false);
            }).catch(e => {
                console.error(e);
                setLoading(false);
            })
        }
        if (definitions.columnHeaders.length > 0) {
            setExpandedHeaders(definitions.columnHeaders.reduce((acc: Record<string, boolean>, ch) => {
                acc[ch.title] = true;
                return acc;
            }, {}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results, tableEndPoint, token, extendedFilter, includeNoTests, ac])

    useTimeout(() => {
        if (popover) {
            popover.dispose();
            setPopover(null);
            setPopoverHideTimeOut(null);
        }
    }, popoverHideTimeOut);

    const setPopoverFromRow = (popoverIn: typeof Popover) => {
        if (popover) {
            popover.hide();
        }
        if (popoverIn) {
            setPopoverHideTimeOut(POPOVER_HIDE_TIMEOUT);
            setPopover(popoverIn);
        } else {
            setPopoverHideTimeOut(1);
        }
    }
    /******* TABLE VIRTUALISATION */

    const screenHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
    ); // get the height of the screen
    const offset = screenHeight; // We want to render more than we see, or else we will see nothing when scrolling fast
    const rowsToRender = Math.floor((screenHeight + offset) / itemRowHeight);

    /*******************/

    const setDisplayPositions = useCallback(
        (scroll: number) => {
            // we want to start rendering a bit above the visible screen
            const scrollWithOffset = Math.floor(scroll - rowsToRender - offset / 2);
            // start position should never be less than 0
            const displayStartPosition = Math.round(
                Math.max(0, Math.floor(scrollWithOffset / itemRowHeight))
            );

            // end position should never be larger than our data array
            const displayEndPosition = Math.round(
                Math.min(displayStartPosition + rowsToRender, filteredSortedResults.length)
            );
            setDisplayStart(() => displayStartPosition);
            setDisplayEnd(() => displayEndPosition);
        },
        [filteredSortedResults.length, offset, rowsToRender, itemRowHeight]
    );

    const onTableScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (filteredSortedResults.length !== 0 && e.currentTarget.scrollTop !== scrollPosition) {
            const st = e.currentTarget.scrollTop;
            setTimeout(() => setScrollPosition(st), 5);
            // setDisplayPositions(e.currentTarget.scrollTop);
        }
    }

    // We want to set the display positions on rendering
    useEffect(() => {
        setDisplayPositions(scrollPosition);
    }, [scrollPosition, setDisplayPositions]);

    /*************************************/

    const onExport = (selection: 'all' | 'selected') => {
        setMenuShown(false);
        let filteredSortedResults: TResultRow[] = (undefined === results.rows || 0 === results.rows.length) ? [] :
            getFilteredSortedResults(results.rows, bounds, boundsFiltered, resultFilterText, allowedAssets, sortColumn, definitions.columnHeaders);

        if (selection === 'selected') {
            filteredSortedResults = filteredSortedResults.filter(r => selectedAssets.includes(r.assetUuid));
        }

        let columns: string[][] = [];
        columns.push(definitions.columnHeaders.map(ch => ch.title));
        columns = columns.concat(filteredSortedResults.map(row =>
            definitions.columnHeaders.map((ch, idx) => {
                if (row[ch.propDisplay] === undefined) {
                    console.log(`Results table - Missing ${ch.propDisplay} in row ${idx}`)
                }
                return (row[ch.propDisplay] ?? '').toString();
            })
        ));
        const csvString = jsonToCsv(columns);
        downloadCSV(csvString, `Report export - ${new Date().toLocaleString()}.csv`);
    }

    const onMarkForReporting = (mark: boolean, all?: boolean) => {
        setMenuShown(false);
        if (!mark) {
            dispatch(setAssetsForReporting([]));
        } else if (all) {
            const r = filteredSortedResults.map(r => ({ assetUuid: r.assetUuid, resultUuid: (r.resultUuid ?? '').toString() }));
            dispatch(setAssetsForReporting(r));
        }
        else {
            let newAssetsForReporting = filteredSortedResults.filter(row => selectedAssets.includes(row.assetUuid)).map(r => ({ assetUuid: r!.assetUuid, resultUuid: (r!.resultUuid ?? '').toString() }));
            newAssetsForReporting = newAssetsForReporting.filter(na => assetsForReporting.every(ar => ar.assetUuid !== na.assetUuid));
            dispatch(setAssetsForReporting([...newAssetsForReporting, ...assetsForReporting]));
        }
    }

    const canRemoveSelectedAssets = () => selectedAssets.length > 0 && assetsForReporting.length > 0 && assetsForReporting.some(a4r => selectedAssets.includes(a4r.assetUuid));

    const onRemoveSelectedAssets = () => {
        setMenuShown(false);
        dispatch(setAssetsForReporting(assetsForReporting.filter(a4r => !selectedAssets.includes(a4r.assetUuid))));
    }

    const onMouseOver = useMemo(() => (e: React.MouseEvent, res: TResultRow) => {
        if (e.buttons === 1) {
            if (!selectedAssets.includes(res.assetUuid)) {
                dispatch(setSelectedAssets([...selectedAssets, res.assetUuid]));
            }
            const s = document.getSelection();
            if (s) {
                s.removeAllRanges();
            }
        }
    }, [dispatch, selectedAssets]);

    const handleContextMenu = useMemo(() => (e: MouseEvent, res: TResultRow) => {
        e.preventDefault();

        //        setContextRow(res);
        if (currentUser?.userPermissions.some(permission => permission === 'EFrontEndSetPosition')) {
            setContextRow(res);
            setContextPos({ x: e.pageX, y: e.pageY });
            setMenuShown(true);
        }
    }, [currentUser]);


    const onLatestResultToClipboard = (all: boolean, resultUuid: boolean) => {
        setMenuShown(false);
        dispatch(addToast(`Result GUID ${contextRow?.resultUuid.toString() ?? 'Error'} copied to clipboard`));
    }

    const onAssetToClipboard = () => {
        setMenuShown(false);
        dispatch(addToast(`Asset GUID ${contextRow?.assetUuid.toString() ?? 'Error'} copied to clipboard`));
    }


    const onAllResultToClipboard = (all: boolean, resultUuid: boolean) => {
        setMenuShown(false);
        dispatch(addToast('Result information copied to clipboard'));
    }

    const getContextRowText = () => {
        if (contextRow) {
            const rows = results.rows.filter(r => r.assetUuid === contextRow.assetUuid);
            if (rows.length > 0) {
                const rowForText: Array<Record<string, string>> = rows.map(row => ({
                    'Result approved': (row['resultStateCounts'] && Object.values(row['resultStateCounts']).some(v => v > 0)) ? 'Yes' : 'No',
                    'Report approved': (row['reportStateCounts'] && Object.values(row['reportStateCounts']).some(v => v > 0)) ? 'Yes' : 'No',
                    'Date of latest test': (row['dateOfLatestTest'] ?? '').toString(),
                    'GUID': row['resultUuid'] ?? '',
                }));
                return jsonToCsv([Object.keys(rowForText[0]), ...rowForText.map(r4t => Object.values(r4t))]);
            }
            return '';
        }

        return 'Error';
    }

    const closeContextMenu = () => {
        setMenuShown(false);
    }

    useEffect(() => {
        const handleGeneralClick = () => {
            setMenuShown(false);
        }
        if (rowRef.current) {
            document.addEventListener('click', handleGeneralClick);
            return () => {
                document.removeEventListener('click', handleGeneralClick);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowRef]);

    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] })
    }

    const WIDTH_PADDING = 8;
    // recalculate the header widths when the table contentts are changed
    useEffect(() => {
        if (tableHeader.current && headerWidths.length === 0 && filteredSortedResults.length > 0) {
            const headerWidthsLocal = Array.from(tableHeader.current.childNodes as NodeListOf<HTMLTableCellElement>).map(tableHead => tableHead.getBoundingClientRect());
            if (headerWidthsLocal.length) {
                setItemRowHeight(headerWidthsLocal[0].height);
                setHeaderWidths(headerWidthsLocal.map(h => h.width + WIDTH_PADDING));
            }
        };
    }, [tableHeader, headerWidths, filteredSortedResults]);

    useEffect(() => {
        setHeaderWidths([]);
    }, [filteredSortedResults, expandedHeaders, resultFilterText]);


    // we need to store the results in state so we can use it in the useEffect below
    useEffect(() => {
        const localFilteredSortedResults: TResultRow[] = (undefined === results.rows || 0 === results.rows.length) ? [] :
            getFilteredSortedResults(results.rows, bounds, boundsFiltered, resultFilterText, allowedAssets, sortColumn, definitions.columnHeaders);
        if (localFilteredSortedResults.length !== filteredSortedResults.length || localFilteredSortedResults.some((r, idx) => r.assetUuid !== filteredSortedResults[idx].assetUuid)) {
            setFilteredSortedResults(localFilteredSortedResults);
        } else if (results.rows.length === 0 && filteredSortedResults.length > 0) {
            setFilteredSortedResults([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bounds, boundsFiltered, resultFilterText, allowedAssets, sortColumn, definitions.columnHeaders, results.rows]);

    // add a filler row at the top. The further down we scroll the taller this will be

    let rows: React.JSX.Element[];
    if (headerWidths.length > 0) {
        rows =
            [<tr
                key="startRowFiller"
                style={{ height: displayStart * itemRowHeight }}
            ></tr>];

        // add the rows to actually render
        for (let i = displayStart; i < displayEnd; ++i) {
            const resultRow = filteredSortedResults[i];
            if (resultRow !== undefined) {
                rows.push(
                    <ResultsTableRow key={`row-${i}`} columnHeaders={definitions.columnHeaders} row={resultRow} showModal={(row) => setShowModal(row)}
                        handleContextMenu={handleContextMenu}
                        onClickRow={(e) => onClickRow(e, resultRow)} onMouseOver={onMouseOver} expandedHeaders={expandedHeaders} setHighlightLatLng={setHighlightLatLng}
                        selected={selectedAssets.some(sa => sa === resultRow.assetUuid)} forReporting={assetsForReporting.some(a4r => a4r.assetUuid === resultRow.assetUuid)}
                        popover={popover} setPopover={setPopoverFromRow}
                    />
                );
            }
        }

        // add a filler row at the end. The further up we scroll the taller this will be
        rows.push(
            <tr
                key="endRowFiller"
                style={{ height: (filteredSortedResults.length - displayEnd) * itemRowHeight }}
            ></tr>
        );
    } else {
        // Create small sample table consisting of the widest rows from each column and use that to calculate the widths
        rows = [];
        if (filteredSortedResults.length > 0) {
            // sampling up to 10 rows and pack them with strings of the longest length
            let initialRows = filteredSortedResults.slice(0, filteredSortedResults.length > 10 ? 10 : filteredSortedResults.length);
            definitions.columnHeaders.forEach(colHeader => { initialRows = initialRows.map(ir => ({ ...ir, [colHeader.propDisplay]: colHeader.title })) });
            filteredSortedResults.forEach((row, i) => {
                definitions.columnHeaders.forEach(colHeader => {
                    const text = row[colHeader.propDisplay]?.toString() ?? '';
                    const slot = initialRows.find(ir => ir[colHeader.propDisplay].toString().length < text.length);
                    if (slot) {
                        slot[colHeader.propDisplay] = text;
                    }
                });
            });
            rows = initialRows.map((resultRow, i) => <ResultsTableRow key={`row-${i}`} columnHeaders={definitions.columnHeaders} row={resultRow} showModal={(row) => setShowModal(row)}
                handleContextMenu={handleContextMenu}
                onClickRow={(e) => onClickRow(e, resultRow)} onMouseOver={onMouseOver} expandedHeaders={expandedHeaders} setHighlightLatLng={setHighlightLatLng}
                selected={selectedAssets.some(sa => sa === resultRow.assetUuid)} forReporting={assetsForReporting.some(a4r => a4r.assetUuid === resultRow.assetUuid)}
                popover={popover} setPopover={setPopover}
            />)
        }

    }

    const onClickRow = useMemo(() => (e: React.MouseEvent, row: TResultRow) => {
        //const chosenAsset = assets.find(a => a.uuid === row.assetUuid);
        if (e.shiftKey) {
            //    if (chosenAsset) { // is this necessary?
            if (lastSelectedAssetUuid === '') {
                dispatch(setSelectedAssets([row.assetUuid]));
                setLastSelectedAssetUuid(row.assetUuid);
                setLastRangeAdded([row.assetUuid]);
            }
            else {
                const r = getFilteredSortedResults(results.rows, bounds, boundsFiltered, resultFilterText, allowedAssets, sortColumn, definitions.columnHeaders);
                const idx1 = r.findIndex(r => r.assetUuid === lastSelectedAssetUuid);
                const idx2 = r.findIndex(r => r.assetUuid === row.assetUuid);
                if (idx1 !== -1 && idx2 !== -1) {
                    const newSelectedAssets = r.slice(Math.min(idx1, idx2), Math.max(idx1, idx2) + 1).map(r => r.assetUuid);
                    const prevSelectedAssets = selectedAssets.filter(sa => !lastRangeAdded.includes(sa));
                    dispatch(setSelectedAssets([...new Set([...newSelectedAssets, ...prevSelectedAssets])]));
                    setLastRangeAdded(newSelectedAssets);
                }
            }
            //   }
        } else if ((e.ctrlKey && navigator.platform.indexOf('Mac') === -1) ||
            (e.metaKey && navigator.platform.indexOf('Mac') !== -1)) {
            if (selectedAssets.includes(row.assetUuid)) {
                dispatch(setSelectedAssets(selectedAssets.filter(sa => sa !== row.assetUuid)));
                setLastRangeAdded([]);
            } else {
                dispatch(setSelectedAssets([...selectedAssets, row.assetUuid]));
                setLastSelectedAssetUuid(row.assetUuid);
                setLastRangeAdded([]);
            }
        } else {
            setLastSelectedAssetUuid(row.assetUuid);
            dispatch(setSelectedAssets([row.assetUuid]));
            setLastRangeAdded([]);
            getReport(e, row);
        }
        const s = document.getSelection();
        if (s) {
            s.removeAllRanges();
        }
    }, [allowedAssets, bounds, boundsFiltered, definitions.columnHeaders, dispatch, getReport, lastRangeAdded, lastSelectedAssetUuid, resultFilterText, results.rows, selectedAssets, sortColumn]);

    return <>
        {loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> :

            <div className='resultsTableClipper relative overflow-y-auto bg-hvpd-grey-50' onScroll={onTableScroll}>
                {rows.length > 0 ? <table className={`${headerWidths.length === 0 ? 'table-auto' : 'table-fixed'} min-w-full w-full border-slate-400 border`}>
                    <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                        <tr ref={tableHeader}>
                            {definitions.columnHeaders.map((colHeader, i) =>
                                <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} headerWidth={headerWidths.length > 0 ? headerWidths[i] : undefined} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                            )}
                        </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                        {rows}
                        {/*filteredSortedResults.map((resultRow, idx) => (
                            <ResultsTableRow key={`row-${idx}`} columnHeaders={definitions.columnHeaders} row={resultRow} handleContextMenu={handleContextMenu} onClickRow={(e) => onClickRow(e, resultRow)} onMouseOver={onMouseOver} expandedHeaders={expandedHeaders} setHighlightLatLng={setHighlightLatLng}
                                selected={selectedAssets.some(sa => sa === resultRow.assetUuid)} forReporting={assetsForReporting.some(a4r => a4r.assetUuid === resultRow.assetUuid)} />
                        ))*/}
                    </tbody>
                </table> : <div className='container mt-20 text-center'>No results found</div>}
            </div>
        }
        {loading ? null : <ContextMenu pos={contextPos} menuShown={menuShown} closeMenu={closeContextMenu} >
            <li className='w-full'><button onClick={() => onExport('all')} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>Export all</button></li>
            {selectedAssets.length > 0 ? <li className='w-full'><button onClick={() => onExport('selected')} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>Export selected</button></li> : null}
            <li><hr /></li>
            {selectedAssets.length > 0 ? <li className='w-full'><button onClick={() => onMarkForReporting(true)} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>Add selected to <span className='italic'>One Report</span></button></li> : null}
            {canRemoveSelectedAssets() ? <li className='w-full'><button onClick={onRemoveSelectedAssets} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>Remove selected from <span className='italic'>One Report</span></button></li> : null}
            <li className='w-full'><button onClick={() => onMarkForReporting(true, true)} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>Add all to <span className='italic'>One Report</span></button></li>
            {assetsForReporting.length > 0 ? <li className='w-full'><button onClick={() => onMarkForReporting(false)} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>Reset <span className='italic'>One Report</span> selection</button></li> : null}
            <li><hr /></li>
            {contextRow?.resultUuid ? <li className='w-full'><CopyToClipboard text={(contextRow?.resultUuid ?? '').toString() ?? 'Error'} ><button onClick={() => onLatestResultToClipboard(true, true)} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>Copy latest result GUID to clipboard</button></CopyToClipboard></li> : null}
            <li className='w-full'><CopyToClipboard text={(contextRow?.assetUuid ?? '').toString() ?? 'Error'} ><button onClick={() => onAssetToClipboard()} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>Copy asset GUID to clipboard</button></CopyToClipboard></li>
            <li className='w-full'><CopyToClipboard text={getContextRowText()} ><button onClick={() => onAllResultToClipboard(true, true)} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>Copy all result GUIDs to clipboard</button></CopyToClipboard></li>
            {clipBoardObservation ? <><li><hr /></li>
                <li className='w-full'><button onClick={() => setShowApplyObservationModal(true)} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left whitespace-nowrap'>{`Apply clipboard ${clipBoardObservation.type.toLowerCase()} observation to selected entries`}</button></li></> : null}
        </ContextMenu>}

        {
            showApplyObservationModal ? <ConfirmApplyObservationModal onCancel={() => setShowApplyObservationModal(false)} onConfirm={() => setShowApplyObservationModal(false)} rows={results.rows.filter(r => selectedAssets.includes(r.assetUuid))} /> : null
        }

        <ProblemReportModal show={!!showModal} row={showModal} onCancel={() => setShowModal(undefined)} />
    </>
}