import React, { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { useAppSelector as useSelector } from '../../hooks';

import {
    ILinkedObservationEntity, IObservation, IResultRecommendation,
    TReportEntityGroup, TResultType, TSeverity, TResultObservations,
} from "../../store/Interfaces";
import { searchRecommendations, getObservations, getRecommendations } from "../../api";
import { TAssetType } from '../../api/recommendationApi';
import { Accordion, TAccordionItemInst } from "../Accordion";

import { feToEt } from "../ReportGroup";
import { RecommendationCount } from "./RecommendationCount";
import { RecommendationGroup } from "./RecommendationGroup";
import { Recommendation } from "./Recommendation";
import { CreateModal } from "./CreateModal";
import { CreateRecommendationModal } from "./CreateRecommendationModal";

export interface IRecommendationsProps {
    recommendationUUids: string[];
    observations: TResultObservations;
    setObservations?: (observations: TResultObservations) => void;
    loadingObservations: boolean;
    linkedEntities?: ILinkedObservationEntity[];
    focusEntity: TReportEntityGroup;
    showLinked?: boolean;
}

export type TRecommendationRow = {
    name: string, value: string, uuid: string, date: string,
    updatedBy: string, updatedDate: string, createdBy: string, createdDate: string
};


const SHOW_AS_FLAT = true;

const observationKeys: Array<keyof TResultObservations> = ['Result', 'Asset', 'Site', 'Subsite', 'Company'];

const Spinner: React.FC = () => <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div>

const BaseResultType: Record<TResultType, IObservation[]> = { assets: [], sites: [], 'sub-sites': [], companies: [], results: [], users: [], 'one-report': [] };
interface IRecommendationWithLoaderProps {
    loading: boolean;
    fullRecommendations: IResultRecommendation[];
}

const RecommendationWithLoader: React.FunctionComponent<IRecommendationWithLoaderProps> = ({ loading, fullRecommendations }) => {
    const recommendationToRecommendationRow = (recommendation: IResultRecommendation): TRecommendationRow => {
        const resultRow: TRecommendationRow = {
            name: recommendation.name,
            value: recommendation.description,
            uuid: recommendation.uuid,
            date: '',
            updatedBy: '', createdBy: '', updatedDate: '', createdDate: ''
        }
        return resultRow;
    }
    return loading || fullRecommendations.length > 0 ? (<div className='grid-flow-row'>
        {loading ? <Spinner /> :
            <div className="overflow-auto">
                <RecommendationGroup isRecommendation={true} type='Recommendations' recommendations={fullRecommendations.map(recommendationToRecommendationRow)} />
            </div>
        }
    </div>) : null
}

interface IObservationWithLoaderProps {
    loading: boolean;
    observations: TResultObservations;
}

const ObservationWithLoader: React.FunctionComponent<IObservationWithLoaderProps> = ({ loading, observations }) => {
    const observationToRecommendationRow = (observation: IObservation): TRecommendationRow => {
        const resultRow: TRecommendationRow = {
            name: observation.observationName,
            value: observation.stringValue,
            uuid: observation.uuid ?? '',
            date: observation.observationDate ? new Date(observation.observationDate * 1000).toLocaleDateString() : '',
            createdBy: observation.createdByName ?? '',
            createdDate: observation.createdDate ? new Date(observation.createdDate * 1000).toLocaleDateString() : '',
            updatedBy: observation.lastModifiedByName ?? '',
            updatedDate: observation.lastModifiedDate ? new Date(observation.lastModifiedDate * 1000).toLocaleDateString() : '',

        }
        return resultRow;
    }

    return loading ? <Spinner /> :
        <>{observationKeys.map(key => (observations[key].length > 0) ? (<div key={`observation-${key}`} className="overflow-auto">
            <RecommendationGroup isRecommendation={false} type={`${key} observation`} recommendations={observations[key].map(observationToRecommendationRow)} />
        </div>) : null)}</>
}

interface ILinkedEntitiesProps {
    linkedEntities?: ILinkedObservationEntity[];
    token: string;
    linkedRecommendations: Record<TResultType, IObservation[]>;
}

const LinkedEntities: React.FunctionComponent<ILinkedEntitiesProps> = ({ linkedEntities, token, linkedRecommendations }) => {
    return (((linkedEntities ?? []).length > 0) ? <div>
        <div className='w-full border-slate-400 border'>
            <div className="overflow-y-auto">
                {((linkedEntities ?? []).map((entity, idx) => (
                    <React.Fragment key={entity.uuid}>
                        <RecommendationCount key={`${idx}-${entity.uuid}`} uuid={entity.uuid} token={token} assetType={entity.type} assetName={entity.name} recommendations={linkedRecommendations[entity.type]} showAsFlat={SHOW_AS_FLAT} />
                    </React.Fragment>)
                ))
                }
            </div>
        </div>
    </div> : <div>No linked entities</div>)
}

type TPanelCount = {
    all: number;
    recommendations: number;
    observations: number;
    relatedObservations: number;
}

export const Recommendations: React.FunctionComponent<IRecommendationsProps> = ({ recommendationUUids, observations, setObservations, loadingObservations, linkedEntities, focusEntity: { uuid, type, resultUuid }, showLinked, focusEntity }) => {
    const { token, currentUser, resultFilter, tableResults } = useSelector(state => state.main);
    const linkedEntitiesRecord = useRef<ILinkedObservationEntity[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingLinked, setLoadingLinked] = useState(false);
    const [fullRecommendations, setFullRecommendations] = useState<IResultRecommendation[]>([]);
    const [linkedRecommendations, setLinkedRecommendations] = useState<Record<TResultType, IObservation[]>>({ ...BaseResultType });
    const [panels, setPanels] = useState<TPanelCount>({ all: 0, recommendations: 0, observations: 0, relatedObservations: 0 });
    const [ac, setAc] = useState<AbortController>();
    const [showCreate, setShowCreate] = useState<{ parentUuid: string, assetType: keyof TResultObservations, show: boolean }>({ parentUuid: '', assetType: 'Result', show: false });
    const [showCreateRecommendation, setShowCreateRecommendation] = useState<{ parentUuid: string, assetType: TAssetType, show: boolean }>({ parentUuid: '', assetType: 'Result', show: false });

    useEffect(() => {
        setFullRecommendations([]);
        setLinkedRecommendations({ ...BaseResultType });
        setPanels({ all: 0, recommendations: 0, observations: 0, relatedObservations: 0 });
        setLoading(false);
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        setLinkedRecommendations({ ...BaseResultType });
        setPanels({ all: 0, recommendations: 0, observations: 0, relatedObservations: 0 });
        setFullRecommendations([]);
    }, [uuid]);

    // if we are looking at the asset list trigger a recommendation fetch

    useEffect(() => {
        const getLocalRecommendations = async (): Promise<IResultRecommendation[] | string> => {
            return getRecommendations(token, 'Asset', focusEntity.uuid).then((res) => {
                return res;
            }).catch((e: Error) => {
                console.error(e);
                return 'Error';
            });
        }

        setFullRecommendations([]);

        //
        if (token && type === 'assets' && focusEntity.uuid && ac && !ac.signal.aborted) {
            setLoading(true);

            getLocalRecommendations().then((res) => {
                if (res instanceof Array) {
                    setFullRecommendations(res);
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                console.error(e);
            });
        }
    }, [token, focusEntity.uuid, type, ac]);


    // if we are given a list of recommendationUUIds we must be on the report list 
    useEffect(() => {
        const getLocalRecommendations = async (uuids: string[], ac: AbortController): Promise<IResultRecommendation[] | string> => {
            return searchRecommendations(token, 'Result', uuids, ac).then((res) => {
                return res;
            }).catch((e: Error) => {
                console.error(e);
                return 'Error';
            });
        }

        setFullRecommendations([]);
        //
        if (token && recommendationUUids && recommendationUUids.length > 0 && ac && !ac.signal.aborted) {
            setLoading(true);

            getLocalRecommendations(recommendationUUids, ac).then((res) => {
                if (res instanceof Array) {
                    setFullRecommendations(res);
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                console.error(e);
            });
        }
    }, [token, recommendationUUids, ac]);

    useEffect(() => {
        const getLinkedObservations = async (entity: ILinkedObservationEntity): Promise<[ILinkedObservationEntity, IObservation[]]> => {
            const { type: assetType, uuid } = entity;
            const ac = new AbortController();
            // let recommendations: IResultRecommendation[] = [];
            //console.log('getLinkedObservations', assetType, uuid, ac);
            return getObservations(token, assetType, uuid, ac).then(observations => {
                if (typeof observations !== 'string') {
                    return [entity, observations] as [ILinkedObservationEntity, IObservation[]];
                }
                return [entity, []] as [ILinkedObservationEntity, IObservation[]];
            }).catch(e => {
                return [entity, []] as [ILinkedObservationEntity, IObservation[]];
            })
        }
        const getAllObservations = async () => {
            setLoadingLinked(true);
            const promises: Array<Promise<[ILinkedObservationEntity, IObservation[]]>> = (linkedEntities ?? []).map((entity, idx) => {
                return getLinkedObservations(entity);
            });
            Promise.all(promises).then((recc) => {
                const lr: Record<TResultType, IObservation[]> = recc.reduce((acc, [entity, recommendations]) => ({ ...acc, [entity.type]: recommendations }), { ...BaseResultType });
                setLinkedRecommendations(lr);
                setLoadingLinked(false);
            });
        }
        if (linkedEntities && linkedEntities.length > 0 && (
            linkedEntitiesRecord.current.length === 0 ||
            linkedEntitiesRecord.current.some((entity, idx) => entity.uuid !== linkedEntities[idx].uuid))) {
            linkedEntitiesRecord.current = linkedEntities;
            getAllObservations();
        }
    }, [linkedEntities, token]);

    useEffect(() => {
        const panelCount = (): TPanelCount => {
            const tp: TPanelCount = {
                all: 0, recommendations: fullRecommendations.length, observations: observationKeys.reduce((acc, key) => acc + observations[key].length, 0),
                relatedObservations: linkedRecommendations ? Object.values(linkedRecommendations).reduce((acc, obs) => acc + obs.length, 0) : 0
            };
            tp.all = (tp.recommendations > 0 ? 1 : 0) + (tp.observations > 0 ? 1 : 0) + (tp.relatedObservations > 0 ? 1 : 0);
            return tp;
        }
        setPanels(panelCount());
    }, [linkedRecommendations, fullRecommendations, observations]);

    const addObservation = (assetType: keyof TResultObservations, parentUuid: string) => {
        setShowCreate({ assetType, parentUuid, show: true });
    }
    const addRecommendation = (assetType: TAssetType, parentUuid: string) => {
        setShowCreateRecommendation({ assetType, parentUuid, show: true });
    }
    const updateObservationText = async (uuid: string, observation: IObservation, key: keyof TResultObservations) => {
        if (setObservations !== undefined) {
            setObservations({
                ...observations,
                [key]: observations[key].map(obs => obs.uuid === uuid ? { ...obs, stringValue: observation.stringValue, severity: observation.severity, observationName: observation.observationName } : obs)
            });
        }
        if (linkedRecommendations !== undefined) {
            const etTofe: Record<keyof TResultObservations, TResultType> = { Asset: 'assets', Result: 'results', Company: 'companies', Site: 'sites', Subsite: 'sub-sites' };
            setLinkedRecommendations({
                ...linkedRecommendations,
                [etTofe[key]]: linkedRecommendations[etTofe[key]].map(obs => obs.uuid === uuid ? { ...obs, stringValue: observation.stringValue, severity: observation.severity, observationName: observation.observationName } : obs)
            });
        }
    }

    const updateRecommendationText = async (recommendationIn: IResultRecommendation) => {
        const updatedRecommendations = fullRecommendations.map(recommendation => recommendation.uuid === recommendationIn.uuid ?
            { ...recommendationIn } : recommendation);
        setFullRecommendations(updatedRecommendations);
    }

    const recommendationCreated = (recommendation: IResultRecommendation) => {
        setFullRecommendations([...fullRecommendations, recommendation]);
        setShowCreateRecommendation({ ...showCreateRecommendation, show: false });
    }

    const observationCreated = (observation: IObservation) => {
        if (setObservations !== undefined) {
            if (resultUuid) {
                // we are on the result table
                if ('Result' === showCreate.assetType.toString()) {
                    setObservations({
                        ...observations,
                        Result: [...observations.Result, observation]
                    });
                } else if ('Asset' === showCreate.assetType.toString()) {
                    setObservations({
                        ...observations,
                        Asset: [...observations.Asset, observation]
                    });
                    setLinkedRecommendations({
                        ...linkedRecommendations,
                        assets: [...linkedRecommendations.assets, observation]
                    });
                }
            } else {
                setObservations({
                    ...observations,
                    [showCreate.assetType]: [...observations[showCreate.assetType], observation]
                });
            }
            setShowCreate({ ...showCreate, show: false });
        }
    }

    const deleteObservation = (uuid: string, key: keyof TResultObservations) => {
        if (setObservations !== undefined) {
            setObservations({
                ...observations,
                [key]: observations[key].filter(obs => obs.uuid !== uuid)
            });
        }
        if (linkedRecommendations !== undefined) {
            const etTofe: Record<keyof TResultObservations, TResultType> = { Asset: 'assets', Result: 'results', Company: 'companies', Site: 'sites', Subsite: 'sub-sites' };
            setLinkedRecommendations({
                ...linkedRecommendations,
                [etTofe[key]]: linkedRecommendations[etTofe[key]].filter(obs => obs.uuid !== uuid)
            });
        }
    }

    const getRowColour = (severity: TSeverity | ''): string => {
        switch (severity) {
            case 'High':
                return 'bg-red-200 hover:bg-red-300';
            case 'Medium':
                return 'bg-amber-400 hover:bg-amber-500';
            case 'Low':
                return 'bg-green-200 hover:bg-green-300';
            default:
                return 'bg-color-background hover:bg-hvpd-grey-200 ';
        }
    }

    const accordionItems: TAccordionItemInst[] = showLinked === false ? [
        { initiallyExpanded: true, title: 'Recommendations', titleText: `Recommendations`, hasContent: panels.recommendations > 0, children: <RecommendationWithLoader loading={loading} fullRecommendations={fullRecommendations} /> },
        { initiallyExpanded: true, title: 'Observations', hasContent: panels.observations > 0, children: <ObservationWithLoader loading={loadingObservations} observations={observations} /> }]
        :
        [
            { initiallyExpanded: true, title: 'Recommendations', titleText: `Recommendations`, hasContent: panels.recommendations > 0, children: <RecommendationWithLoader loading={loading} fullRecommendations={fullRecommendations} /> },
            { initiallyExpanded: true, title: 'Observations', hasContent: panels.observations > 0, children: <ObservationWithLoader loading={loadingObservations} observations={observations} /> },
            {
                initiallyExpanded: true, title: 'Related observations', hasContent: panels.relatedObservations > 0, children: <LinkedEntities linkedEntities={linkedEntities} token={token} linkedRecommendations={linkedRecommendations} />
            }
        ];


    const allObservations = observationKeys.reduce((acc, key) => acc.concat(observations[key]), [] as IObservation[]);

    const hasLinkedEntities = (): boolean => {
        return !!linkedEntities && linkedEntities.some(entity =>
            linkedRecommendations[entity.type].filter(le => (allObservations.every(obs => obs.uuid !== le.uuid))).length > 0);
    }

    const countLinkedEntities = (): number =>
        (linkedEntities ?? []).reduce((acc, entity) => acc + linkedRecommendations[entity.type].filter(le => (allObservations.every(obs => obs.uuid !== le.uuid))).length, 0);

    const relatedObservations = countLinkedEntities();

    const deleteRecommendation = (uuid: string) => {
        setFullRecommendations(fullRecommendations.filter(recommendation => recommendation.uuid !== uuid));
    }

    return (
        <div className="overflow-y-auto h-full">
            {(panels.all === 0) ?
                <div>{(loading || loadingObservations) ? <Spinner /> : <div className="text-center mt-10 mx-8 font text-lg">No recommendations, observations or related observations</div>}</div>
                :
                SHOW_AS_FLAT ?
                    <div>
                        {fullRecommendations.length > 0 ?
                            <>
                                <h1 className="px-2 pt-4 pb-3 border-b-[1px] border-b-hvpd-grey-300 text-hvpd-pickled-bluewood-500 text-xl font-bold">Recommendation{fullRecommendations.length !== 1 ? 's' : ''}</h1>
                                <Recommendation isRecommendation={true} type={type} recommendations={fullRecommendations.map(recommendation => ({
                                    name: recommendation.name, value: recommendation.description, uuid: recommendation.uuid,
                                    date: new Date(recommendation.recommendationDate * 1000).toLocaleDateString(),
                                    createdBy: '',
                                    createdDate: '',
                                    updatedBy: '',
                                    updatedDate: '',

                                }))}
                                    recommendationsRaw={fullRecommendations}
                                    canEdit={currentUser?.userPermissions?.includes('EObservationUpdate') ?? false}
                                    canCreate={currentUser?.userPermissions?.includes('EObservationCreate') ?? false}
                                    canDelete={currentUser?.userPermissions?.includes('EObservationDelete') ?? false}
                                    updateRecommendation={updateRecommendationText}
                                    observationDeleted={(uuid: string) => deleteRecommendation(uuid)} />
                            </> : null}

                        {observationKeys.map(key => (observations[key].length > 0) ? (
                            <React.Fragment key={`observation-${key}`}>
                                <div className="grid grid-cols-[6fr_auto]  bg-slate-600">
                                    <h1 className="px-2 pt-4 pb-3  text-hvpd-grey-50 font-bold text-xl">{`${key} observation${observations[key].length !== 1 ? 's' : ''}`}</h1>
                                </div>
                                <div className="overflow-auto">
                                    <Recommendation key={`observation-${key.toString()}`}
                                        type=''
                                        isRecommendation={false}
                                        canEdit={currentUser?.userPermissions?.includes('EObservationUpdate') ?? false}
                                        canCreate={currentUser?.userPermissions?.includes('EObservationCreate') ?? false}
                                        canDelete={currentUser?.userPermissions?.includes('EObservationDelete') ?? false}
                                        updateObservation={(uuid: string, value: IObservation) => updateObservationText(uuid, value, key)}
                                        observationType={key}
                                        observationDeleted={(uuid: string) => deleteObservation(uuid, key)}
                                        observations={observations[key]}
                                        recommendations={observations[key].map(observation => {
                                            /*  let observationName: string = `${observation.observationName} (${observation.createdByName}) ${new Date(observation.observationDate * 1000).toLocaleDateString()}`;
                                              if (observation.lastModifiedDate !== observation.createdDate) {
                                                  observationName = `${observationName}} Updated ${new Date(observation.lastModifiedDate * 1000).toLocaleDateString()} (${observation.lastModifiedByName})`;
                                              } */
                                            return {
                                                name: observation.observationName,
                                                value: observation.stringValue,
                                                uuid: observation.uuid ?? '',
                                                date: observation.observationDate ? new Date(observation.observationDate).toLocaleDateString() : '',
                                                createdBy: observation.createdByName ?? '',
                                                createdDate: observation.createdDate ? new Date(observation.createdDate * 1000).toLocaleDateString() : '',
                                                updatedBy: observation.lastModifiedByName ?? '',
                                                updatedDate: observation.lastModifiedDate && (observation.createdDate !== observation.lastModifiedDate) ? new Date(observation.lastModifiedDate * 1000).toLocaleDateString() : '',
                                            }
                                        })} />
                                </div>
                            </React.Fragment>)
                            : null)}

                        {(loadingLinked) ? <Spinner /> : null}
                        {panels.relatedObservations && panels.relatedObservations > 0 && hasLinkedEntities() ? <>
                            <div className='bg-slate-600 border-b-hvpd-grey-600'><h2 className="px-2 pt-4 pb-3 text-hvpd-grey-50">{`${relatedObservations === 1 ? 'Related observation' : `${relatedObservations} related observations`} `}</h2></div>
                            {((linkedEntities ?? []).map((entity, idx) => (
                                <React.Fragment key={entity.uuid}>
                                    <Recommendation type={`${feToEt[entity.type]} observation${entity.type === 'sub-sites' || entity.type === 'sites' ? ` ${entity.name}` : ''} `} isRecommendation={false} canEdit={false}
                                        recommendations={linkedRecommendations[entity.type]
                                            .filter(le => (allObservations.every(obs => obs.uuid !== le.uuid)))
                                            .map(observation => ({
                                                name: observation.observationName,
                                                value: observation.stringValue,
                                                uuid: observation.uuid ?? '',
                                                createdBy: observation.createdByName ?? '',
                                                createdDate: observation.createdDate ? new Date(observation.createdDate * 1000).toLocaleDateString() : '',
                                                updatedBy: observation.lastModifiedByName ?? '',
                                                updatedDate: observation.lastModifiedDate && (observation.createdDate !== observation.lastModifiedDate) ? new Date(observation.lastModifiedDate * 1000).toLocaleDateString() : '',
                                                date: observation.observationDate ? new Date(observation.observationDate * 1000).toLocaleDateString() : '',
                                            }))} />
                                </React.Fragment>)
                            ))}
                        </> : null}
                    </div>
                    : /* the code below is not used */
                    (panels.all > 1) ? <Accordion id='observation-accordion' items={accordionItems.filter(a => a.hasContent)} alwaysOpen={true} />
                        :
                        <>
                            {accordionItems.filter(item => item.hasContent).map((item, idx) => (
                                <div key={`${item.title} -${idx} `}>
                                    <h2 className='py-4 px-2 text-lg text-gray-800 font-semibold text-left bg-hvpd-malibu-100  border-b-[1px] border-b-hvpd-malibu-200'>{item.title}</h2>
                                    {item.children}
                                </div>)

                            )}
                        </>
            }

            {currentUser?.userPermissions?.includes('EObservationCreate') ? (<>
                {type === 'assets' ? <button className="float-right mt-4 mb-3 mr-2 rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2  border-solid border-1 text-sm font-sm" onClick={() => addObservation('Asset', uuid)}>Add asset observation</button> : null}
                {type === 'companies' ? <button className="float-right mt-4 mb-3 mr-2 rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2  border-solid border-1 text-sm font-sm" onClick={() => addObservation('Company', uuid)}>Add company observation</button> : null}
                {type === 'sites' ? <button className="float-right mt-4 mb-3 mr-2 rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2  border-solid border-1 text-sm font-sm" onClick={() => addObservation('Site', uuid)}>Add site observation</button> : null}
                {type === 'sub-sites' ? <button className="float-right mt-4 mb-3 mr-2 rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2  border-solid border-1 text-sm font-sm" onClick={() => addObservation('Subsite', uuid)}>Add sub site observation</button> : null}

                {resultUuid ? <button className="float-right mt-4 mb-3 mr-2 rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2  border-solid border-1 text-sm font-sm" onClick={() => addObservation('Result', resultUuid)}>Add result observation</button> : null}
                {resultUuid ? <button className="float-right mt-4 mb-3 mr-2 rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2  border-solid border-1 text-sm font-sm" onClick={() => addRecommendation('Result', resultUuid)}>Add result recommendation</button> : null}
                {type === 'assets' && uuid ? <button className="float-right mt-4 mb-3 mr-2 rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2  border-solid border-1 text-sm font-sm" onClick={() => addRecommendation('Asset', uuid)}>Add asset recommendation</button> : null}
                {linkedEntities?.filter(t => t.type === 'assets').map(f => <button key={f.uuid} className="float-right mt-4 mb-3 mr-2 rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2  border-solid border-1 text-sm font-sm" onClick={() => addObservation('Asset', f.uuid)}>Add asset observation</button>)}</>) : null
            }
            {
                showCreate.show ?
                    <CreateModal onCreate={(observation) => observationCreated(observation)} onCancel={() => setShowCreate({ ...showCreate, show: false })} parentUuid={showCreate.parentUuid} assetType={showCreate.assetType} /> : null
            }

            <CreateRecommendationModal show={showCreateRecommendation.show} onCreate={(recommendation) => recommendationCreated(recommendation)} onCancel={() => setShowCreateRecommendation({ ...showCreateRecommendation, show: false })} parentUuid={showCreateRecommendation.parentUuid} assetType={showCreateRecommendation.assetType} />

        </div >
    );
};
