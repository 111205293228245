import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTranslation } from 'react-i18next';

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../hooks';
import { setClipBoardObservation, setClipBoardRecommendation } from '../../store/mainSlice';

import { DropdownMenu } from '../DropdownMenu';
import { TResultType, TTableOptions } from '../../store/Interfaces';
import { ViewObservationModal } from '../Recommendations/ViewObservationModal';
import { ViewRecommendationModal } from '../Recommendations/ViewRecommendationModal';

export interface IMainNavProps {
    setResultTable: (resultType: TTableOptions) => void;
    resultType: TTableOptions;
}

export const MainNav: React.FunctionComponent<IMainNavProps> = ({ setResultTable, resultType }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { assetsForReporting, editMode } = useSelector(store => store.main);
    const { clipBoardObservation, clipBoardRecommendation } = useSelector(store => store.main);
    const [showObservationModal, setShowObservationModal] = useState(false);
    const [showRecommendationModal, setShowRecommendationModal] = useState(false);

    const removeObservation = () => {
        dispatch(setClipBoardObservation(null));
    }
    const removeRecommendation = () => {
        dispatch(setClipBoardRecommendation(null));
    }

    useEffect(() => {
        if (!clipBoardObservation) {
            setShowObservationModal(false);
        }
    }, [clipBoardObservation]);

    useEffect(() => {
        if (!clipBoardRecommendation) {
            setShowRecommendationModal(false);
        }
    }, [clipBoardRecommendation]);

    const navNames = [{ label: t('Results'), value: 'results' }, { label: t('Companies'), value: 'companies' }, { label: t('Assets'), value: 'assets' },
    { label: t('Sites'), value: 'sites' },
    { label: t('Sub sites'), value: 'sub-sites' },
    { label: t('Users'), value: 'users' },
    {
        label: t('One report'), value: 'one-report', disabled: !assetsForReporting || assetsForReporting.length === 0,
    }].concat(editMode ? [{ label: t('Projects'), value: 'projects' },
    { label: t('User groups'), value: 'userGroups' },
    { label: t('Sales orders'), value: 'salesOrders' }] : []);

    return <div className="float-left max-w-7xl mx-auto px-2 sm:px-3 lg:px-4">
        <div className="relative flex items-center justify-between h-16 mt-2" >
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                        <DropdownMenu selected={resultType} options={navNames} setOption={(option) => setResultTable(option.value as TResultType)} />
                    </div>
                </div>
                {clipBoardObservation ? (<div className="flex-1 flex items-center justify-center  sm:items-stretch sm:justify-start">
                    <div className="flex space-x-4  ml-2">
                        <button onClick={() => setShowObservationModal(true)} title='View observation' className='rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 p-2 mr-1 border-solid border-1 text-sm' >
                            <FontAwesomeIcon className=' text-hvpd-malibu-300' icon={solid('clipboard-list')} />
                        </button>
                    </div>
                </div>) : null}
                {clipBoardRecommendation ? (<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex space-x-4  ml-2">
                        <button onClick={() => setShowRecommendationModal(true)} title={t('View recommendation')} className='rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 p-2 mr-1 border-solid border-1 text-sm' >
                            <FontAwesomeIcon className=' text-hvpd-malibu-300' icon={solid('clipboard-list')} />
                        </button>
                    </div>
                </div>) : null}
            </div>
        </div>
        {showObservationModal && clipBoardObservation ?
            <ViewObservationModal onClose={() => setShowObservationModal(false)} observationType={clipBoardObservation.type} observation={clipBoardObservation.observation} removeObservation={removeObservation} /> : null}
        {showRecommendationModal && clipBoardRecommendation ?
            <ViewRecommendationModal onClose={() => setShowRecommendationModal(false)} recommendationType={clipBoardRecommendation.type} recommendation={clipBoardRecommendation.recommendation} removeRecommendation={removeRecommendation} /> : null}
    </div>
}