import {
    ICompany
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;

export const getCompanies = async (token: string): Promise<Array<ICompany> | string> => {
    const url = `${getApiEndpoint()}/Company`;
    const p = new Promise<ICompany[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new Error(`Error getting the company list [${resp.status}]`);
                }
            })
            .then((json) => {
                resolve(sanitise(json) as Array<ICompany>);
            })
            .catch((e) => {
                dispatch(addError(e.message));
                reject("Error fetching...");
            });
    });
    return p;
};


export const createCompany = async (token: string, company: ICompany): Promise<ICompany> => {
    const url = `${getApiEndpoint()}/Company`;
    const p = new Promise<ICompany>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...company, uuid: undefined })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error creating company [${resp.status}]`));
            }
        }).then(json => {
            const companyReturned: ICompany = json as ICompany;
            resolve(sanitise(companyReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error creating');
        });
    })
    return p;
}

export const updateCompany = async (token: string, company: ICompany): Promise<ICompany> => {
    const url = `${getApiEndpoint()}/Company`;
    const p = new Promise<ICompany>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(company)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error updating company [${resp.status}]`));
            }
        }).then(json => {
            const companyReturned: ICompany = json as ICompany;
            resolve(sanitise(companyReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error updating');
        });
    })
    return p;
}


export const deleteCompany = async (token: string, uuid: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Company/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Error deleting company [${resp.status}]`));
            }
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error deleting.');
        });
    })
    return p;
}