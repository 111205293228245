import { TBounds, TBoundsX } from "../../Grid/Grid";
import { IColumnHeader, TResultRow, ISortColumn } from "../../store/Interfaces";

export const boundsContains = (bounds: TBounds, lat: number, lng: number): boolean => {
    if (bounds.ne.lat === 0 && bounds.ne.lng === 0 && bounds.sw.lat === 0 && bounds.sw.lng === 0) {
        return true;
    }
    const llb = new google.maps.LatLngBounds({ ...bounds.sw }, { ...bounds.ne });
    const latlng = new google.maps.LatLng(lat, lng);
    // code very explicit for debugging
    const v = llb.contains(latlng);
    return v;
    //const inlat = lat < bounds.ne.lat && lat > bounds.sw.lat
    //const inlng = lng > bounds.ne.lng && lng < bounds.sw.lng;
    //return inlat && inlng;
}
export const boundsXContains = (boundsIn: TBoundsX, lat: number, lng: number): boolean => {
    const { bounds } = boundsIn;
    if (bounds.west === 0 && bounds.east === 0 && bounds.north === 0 && bounds.south === 0) {
        return true;
    }
    const llb = new google.maps.LatLngBounds(new google.maps.LatLng(bounds.west, bounds.south), new google.maps.LatLng(bounds.east, bounds.north));
    return llb.contains(new google.maps.LatLng(lat, lng));
    // code very explicit for debugging
    /*const inlat = lat < bounds.east && lat > bounds.west;
    const inlng = lng > bounds.north && lng < bounds.south;
    return inlat && inlng; */
}
export const boundsNSEW2BoundsLatLong = (bounds: TBoundsX): TBounds => {
    return {
        ne: { lat: bounds.bounds.north, lng: bounds.bounds.east },
        sw: { lat: bounds.bounds.south, lng: bounds.bounds.west }
    }
}
export const posFilter = (row: TResultRow | undefined, bounds: TBounds | null, boundsFiltered: 'map' | 'sld' | ''): boolean => {
    if (row && bounds && boundsFiltered === 'map') {
        return boundsContains(bounds, row.gpsLat, row.gpsLong);
    }
    return true;
}

export const textFilter = (row: TResultRow | undefined, resultFilterText: string, columnHeaders: IColumnHeader[]): boolean => {
    if (resultFilterText === '') {
        return true;
    }
    // const mustHaves = resultFilterText.split('&').map(t => t.split('+')[0].trim().toLowerCase()).filter(t => t !== '');

    const filterList = resultFilterText.split('+').map(t => t.trim().toLowerCase()).filter(t => t !== '');

    if (row && columnHeaders) {
        return (filterList.some(filterText => {
            if (parseFloat(filterText).toString() === filterText) {
                if (columnHeaders.filter(header => header.type === 'Float' || header.type === 'Integer').some(ch => (row[ch.propValue] ?? '').toString() === filterText)) {
                    return true;
                }
            }
            return columnHeaders.filter(header => header.type === 'String' || header.type === 'Date').some(ch => (row[ch.propDisplay] ?? '').toString().toLowerCase().includes(filterText));
        }));
    }
    return true;
}

export const assetFilter = (row: TResultRow | undefined, allowedAssets: string[], boundsFiltered: 'map' | 'sld' | ''): boolean => {
    if (allowedAssets.length === 0 || boundsFiltered !== 'sld') {
        return true;
    }
    if (row) {
        return allowedAssets.includes(row.assetUuid);
    }
    return true;
}

export const getFilteredSortedResults = (results: TResultRow[], bounds: TBounds | null, boundsFiltered: 'map' | 'sld' | '', resultFilterText: string, allowedAssets: string[], sortColumn: ISortColumn | null, columnHeaders: IColumnHeader[]): TResultRow[] => {
    const sortColHeader = sortColumn ? columnHeaders.find(ch => ch.title === sortColumn.title) ?? null : null;

    const sorter = (a: TResultRow, b: TResultRow): number => {

        if (sortColHeader === null) {
            return 0;
        }

        const sortDirection = sortColumn?.direction ?? 1;
        const sortType = sortColHeader.type;
        const prop = sortColHeader.propValue ?? sortColHeader.propDisplay;

        // Put the undefined values at the bottom of the list always...
        if (sortType === 'Float' || sortType === 'Integer' || sortType === 'Date') {
            const BIG_VALUE = Number.MAX_VALUE * sortDirection;
            const av = a[prop] ?? BIG_VALUE;
            const bv = b[prop] ?? BIG_VALUE;
            return ((av as number) - (bv as number)) * sortDirection;
        }
        else {
            const BIG_VALUE = sortDirection < 0 ? '' : '\x10FFFF';
            const av = a[prop] ?? BIG_VALUE;
            const bv = b[prop] ?? BIG_VALUE;
            return sortDirection * (av.toString()).localeCompare(bv.toString());
        }
    }

    const filteredSortedResults = results.filter(r => textFilter(r, resultFilterText, columnHeaders))
        .filter((r) => posFilter(r, bounds, boundsFiltered))
        .filter(r => assetFilter(r, allowedAssets, boundsFiltered))
        .slice()
        .sort(sorter);
    return filteredSortedResults;
}


export const jsonToCsv = (jsonArray: string[][]): string => {
    let csv = '';

    // Function to escape double quotes
    const escapeQuotes = (str: string): string => {
        return str.replace(/"/g, '""');
    };

    const csvDataLine = (value: string, index: number, row: string[]): string => {
        let csv = `"${escapeQuotes(value)}"`;
        csv += index < row.length - 1 ? ',' : '\n';
        return csv;
    }

    // Loop through the header (first string array)
    jsonArray[0].forEach((header, index) => {
        csv += `"${escapeQuotes(header)}"`;
        csv += index < jsonArray[0].length - 1 ? ',' : '\n';
    });

    // Loop through the data rows (remaining string arrays)
    for (let i = 1; i < jsonArray.length; i++) {
        const row = jsonArray[i];
        csv += row.map((value, index, row) => csvDataLine(value, index, row)).join('');
    }

    return csv;
}

export const downloadCSV = (csvString: string, filename: string): void => {
    // Create a Blob with the CSV string
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;

    // Append the link to the DOM, click it, and remove it
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}


export const translateField = (field: string) => {
    if (field === 'Voltage_kV') {
        return 'Voltage(kV)';
    }
    return field;
}