import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import exifr from 'exifr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IFormField } from './FormTemplate';

interface ILocationModalProps {
    close: () => void;
    save: (coordinates: { x: number, y: number }) => void;
    locationField: IFormField;
    original: { x: number, y: number };
    current: { x: number, y: number };
}
const PRECISION = 6;

export const LocationModal: React.FunctionComponent<ILocationModalProps> = ({ close, save, original, current, locationField }) => {
    const { t } = useTranslation();
    const [imageError, setImageError] = useState<string>('');
    const [coordinates, setCoordinates] = useState({ x: '', y: '' });

    useEffect(() => {
        setCoordinates({ x: current.x.toFixed(PRECISION), y: current.y.toFixed(PRECISION) })
    }, [current])

    const setXCoordinate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates({ x: Number(e.target.value).toFixed(PRECISION), y: coordinates.y });
    }
    const setYCoordinate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates({ y: Number(e.target.value).toFixed(PRECISION), x: coordinates.x });
    }

    const setCoordinatesFromImage = (list: DataTransferItemList) => {
        const items: DataTransferItem[] = [].slice.call(list).filter(function (item: DataTransferItem) {
            // Filter the image items only
            return /^image\//.test(item.type);
        });
        if (items.length > 0) {
            const item = items[0];
            const blob = item.getAsFile();
            if (blob) {
                //   imageRef.current.src = URL.createObjectURL(blob);
                let file = new File([blob], t("file name"), { type: "image/jpeg", lastModified: new Date().getTime() });
                exifr.gps(file).then(gps => {
                    if (undefined !== gps?.latitude && undefined !== gps?.longitude) {
                        setImageError('');
                        setCoordinates({ x: gps.latitude.toFixed(PRECISION), y: gps.longitude.toFixed(PRECISION) });
                    } else {
                        setImageError(t('No GPS data found in image'))
                    }
                });
            }
        }
    }

    const onImagePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const clipboardItems = e.clipboardData.items;
        setCoordinatesFromImage(clipboardItems);
    }

    const onImageDrop = (e: React.DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        const dropItems = e.dataTransfer.items;
        setCoordinatesFromImage(dropItems);
    }

    const onNPPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const data = e.clipboardData.getData('Text').split(',').map(v => v.trim());
        if (data.length >= 2) {
            e.preventDefault();
            setCoordinates({ x: data[0], y: data[1] });
        }
    }

    return (
        <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

            <div className="fixed inset-0 z-[3000] overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-5xl">
                        <div className="flex flex-shrink-0 items-center justify-between rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                            <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                                Location helper
                            </h5>
                            <button type="button" onClick={close} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label="Close">
                                <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                            </button>
                        </div>

                        <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 py-1">
                            <div className='grid grid-cols-[1fr_3fr] gap-1 overflow-auto mt-2'>
                                <>
                                    <span className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2" >Original coordinates</span>
                                    <div className='flex'>
                                        <label className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2" htmlFor='originalX'>{(locationField.propLabels ?? [])?.[0] ?? 'np0'}</label>
                                        <input className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                            id='originalX' readOnly={true} type='number' defaultValue={original.x.toString() ?? ''} />
                                        <label className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2" htmlFor='originalY'>{(locationField.propLabels ?? [])?.[1] ?? 'np0'}</label>
                                        <input className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                            id='originalY' readOnly={true} type='number' defaultValue={original.y.toString() ?? ''} />

                                    </div>
                                </>
                                <>
                                    <span className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2" >Current coordinates</span>
                                    <div className='flex'>
                                        <label className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2" htmlFor='currentX'>{(locationField.propLabels ?? [])?.[0] ?? 'np0'}</label>
                                        <input className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                            id='currentX' type='number' onPaste={e => onNPPaste(e)} onChange={setXCoordinate} value={coordinates.x.toString() ?? ''} />
                                        <label className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2" htmlFor='currentY'>{(locationField.propLabels ?? [])?.[1] ?? 'np0'}</label>
                                        <input className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                            id='currentY' type='number' onPaste={e => onNPPaste(e)} onChange={setYCoordinate} value={coordinates.y.toString() ?? ''} />

                                    </div>
                                </>

                                <label htmlFor='googleUrl' className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2">Google url</label>
                                <input id='googleUrl' readOnly name='googleUrl' className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500' value={`https://www.google.com/maps/@?api=1&map_action=map&center=${coordinates.x},${coordinates.y}`} />
                                <label htmlFor='imagePaste' className="mx-1 font-semibold text-right text-sm pr-2 mt-1 border-r-2">Paste or drop an image here</label>
                                <input id='imagePaste' name='imagePaste' className='px-1 bg-white border border-hvpd-grey-300 rounded-md text-sm shadow-sm placeholder-hvpd-grey-400
focus:outline-none focus:border-hvpd-pickled-bluewood-500 focus:ring-1 focus:ring-hvpd-pickled-bluewood-500
disabled:bg-hvpd-grey-50 disabled:text-hvpd-grey-500 disabled:border-hvpd-grey-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-500
focus:invalid:border-pink-500 focus:invalid:ring-pink-500 w-full' onDrop={onImageDrop} onPaste={onImagePaste} />
                                {imageError && <div className='text-red-500'>{imageError}</div>}
                            </div>
                        </div>
                        <div className="rounded-md bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse">
                            <button type="button" onClick={() => save({ x: parseFloat(coordinates.x), y: parseFloat(coordinates.y) })} disabled={false} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2">Update</button>
                            <button type="button" onClick={close} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}