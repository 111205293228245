import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReportGroupContext } from '../../ReportGroup/ReportGroupContext';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import {
    addToast,
} from "../../../store/mainSlice";

import { Busy } from '../../Busy';
import { ImagePicker } from '../ImagePicker';
import { updateObservation } from '../../../api';
import { IObservation, TSeverity, TResultObservations } from '../../../store/Interfaces';


interface IEditModalProps {
    onSave: (observation: IObservation) => void;
    onCancel: () => void;
    observation: IObservation | undefined;
    assetType: keyof TResultObservations;
}

export const EditModal: React.FunctionComponent<IEditModalProps> = ({ onSave, onCancel, observation, assetType }) => {
    const dispatch = useDispatch();
    const { imageListAsset, imageBlobs } = useContext(ReportGroupContext);
    const { token } = useSelector(state => state.main);
    const editorRef = useRef<Editor>(null);
    const [observationEdit, setObservationEdit] = useState<IObservation>({
        observationName: '',
        observationDate: 0,
        severity: 'Medium',
        isValid: true,
        stringValue: '',
        booleanValue: true,
        doubleValue: 0,
        longValue: 0,
        parentUuid: '',
        unitsString: '',
        valid: true,
        lastModifiedByEmail: '',
        lastModifiedByUuid: '',
        lastModifiedDate: 0,
        lastModifiedByName: '',
        createdByEmail: '',
        createdByName: '',
        createdByUuid: '',
        createdDate: 0,
        uuid: ''
    });

    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if (observation) {
            setObservationEdit({ ...observation });
        }
    }, [observation]);

    const observationIsValid = () => observationEdit.observationName !== '' && observationEdit.stringValue !== '';
    const observationHasChanged = () => {
        return observation?.observationName !== observationEdit.observationName || observation?.stringValue !== observationEdit.stringValue || observation?.severity !== observationEdit.severity;
    }

    const save = async () => {
        setBusy(true);
        try {
            const newObservation = await updateObservation(token, assetType, observationEdit);
            dispatch(addToast(`Updated observation ${observationEdit.observationName}`));
            setBusy(false);
            onSave(newObservation);
        } catch (e) {
            console.error(e);
            setBusy(false);
            onCancel();
        }
    }

    /*  const onClickImage = (image: TImageDescriptor) => {
          const markdown = editorRef.current?.getInstance().getMarkdown();
          editorRef.current?.getInstance().setMarkdown(`${markdown} ![${image.name}](${image.name})`);
      } */
    const onClickImage = (base64: string, metadata: string) => {
        const markdown = editorRef.current?.getInstance().getMarkdown();
        editorRef.current?.getInstance().setMarkdown(`${markdown} ![${metadata}](${base64})`);
    }

    const hasImages = imageListAsset.images.some(image => (imageBlobs[image.name] ?? '').length > 0);

    return (<div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-[3000] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-4xl">

                    <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 bg-hvpd-pickled-bluewood-300  border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                            Edit observation
                        </h5>
                        <button type="button" onClick={onCancel} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label="Close">
                            <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                        </button>
                    </div>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 py-1">
                        {busy ? <div className='mb-20 mx-[12rem]'><Busy /></div> :
                            <div className={`grid ${hasImages ? 'grid-cols-[480px_320px]' : 'grid-cols-1'}`}>
                                <div>
                                    <div className='grid grid-cols-2 mb-3'>
                                        <div className='inline-flex'>
                                            <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="observationName">Name</label>
                                            <input type="text" id='observationName' name='observationName' className="w-40 p-1 text-sm rounded-sm border-hvpd-grey-700 focus:hvpd-grey-500 active:hvpd-grey-500 border inline" value={observationEdit?.observationName ?? ''} onChange={e => setObservationEdit({ ...observationEdit, observationName: e.target.value })} />
                                        </div>
                                        <div className='inline-flex'>
                                            <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="observationSeverity">Severity</label>
                                            <select id='observationSeverity' name='observationSeverity' className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border' value={observationEdit?.severity} onChange={e => setObservationEdit({ ...observationEdit, severity: e.target.value as TSeverity })}>
                                                <option>Low</option><option>Medium</option><option>High</option>
                                            </select>
                                        </div>
                                    </div>
                                    <Editor
                                        initialValue={observation?.stringValue ?? ' '}
                                        previewStyle="vertical"
                                        height="460px"
                                        onChange={(e) => {
                                            const markDown = editorRef.current?.getInstance().getMarkdown();
                                            setObservationEdit({
                                                ...observationEdit, stringValue: (markDown ? markDown : '')

                                            })
                                        }}
                                        ref={editorRef}
                                        initialEditType="wysiwyg"
                                        useCommandShortcut={true}
                                    />
                                </div>
                                {hasImages ? (<div>
                                    <ImagePicker onClickImage={onClickImage} />
                                </div>) : null}
                            </div>}
                    </div>

                    <div className="rounded-md bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse">
                        <button type="button" disabled={!observationHasChanged() || !observationIsValid() || busy} onClick={save} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2">Save</button>
                        <button type="button" onClick={onCancel} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">Cancel</button>
                    </div>
                </div>
            </div>
        </div >
    </div >);
}
