import React, { createElement } from "react";

import { TAssetResult, TPhaseInfo } from '../../store/Interfaces';


const parseInlineStyle = (style: string): Record<string, string> => {
    let styleArray = style.split(';');
    const styleObject: Record<string, string> = styleArray.reduce((acc, item) => {
        const [key, value] = item.split(':');
        if (key && value) {
            const keyX = key.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
            acc[keyX] = value.trim();
        }
        return acc;
    }, {} as Record<string, string>);
    return styleObject;
    /*
    OLD Style - didn't work on Firefox
        const template = document.createElement('template');
        template.setAttribute('style', style)
        return Object.entries(template.style)
            .filter(([key]) => !/^[0-9]+$/.test(key))
            .filter(([, value]) => Boolean(value))
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    */
}

const getPhaseInfos = (element: SVGElement, assetResults: Array<TAssetResult>): { match: Array<string>, phaseInfos: Array<TPhaseInfo>, asset?: TAssetResult } => {
    const match = (element.getAttribute('atlas-node') ?? '').match(/^asset\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/L(\d+)$/) ?? [];
    if (match.length > 0) {
        const assetUuid = match[1];
        const assetResult = assetResults.find(ar => ar.assetUuid === assetUuid);
        if (assetResult && assetResult.phaseInfos) {
            return { match, phaseInfos: assetResult.phaseInfos, asset: assetResult };
        }
    }
    return { match: [], phaseInfos: [] };
}

const convertElement = (element: SVGElement, uuid: string, assetResults: Array<TAssetResult>, assetUUIDs: string[], zoom: boolean, onClick: (v: string) => void,
    setViewBox?: (vp: string) => void, props?: {
        [x: string]: any;
    },): React.ReactElement => {
    const tagName = element.tagName;
    if (tagName === 'sodipodi:namedview') {
        return createElement('div', { key: Math.random().toString() });
    }

    let _props = props || {};
    for (let i = 0; i < element.attributes.length; i++) {
        let nodeName = element.attributes[i].nodeName;

        if (nodeName.startsWith('xmlns:')) {
            continue;
        }
        if (nodeName.includes(':')) {
            nodeName = nodeName.split(':').map((n, idx) => idx === 0 ? n : n.substring(0, 1) + n.substring(1)).join('');
            if (nodeName === 'xmlspace') {
                nodeName = 'xmlSpace';
            }
        }
        let val: string | object | null = element.attributes[i].nodeValue;
        if (nodeName === 'viewBox' && setViewBox) {
            setViewBox(val as string);
        }
        if (nodeName === "style" && (val !== "none" && val !== null)) {
            val = parseInlineStyle(val);
        }
        if (nodeName === "class") {
            nodeName = "className";
            val = `${val}-${uuid}`;
        }
        if (nodeName === "atlas-node") {
            const { match, phaseInfos } = getPhaseInfos(element, assetResults);
            if (phaseInfos.length > 0 && match.length > 2) {
                const index = parseInt(match[2], 10);
                const colour = phaseInfos[index - 1]?.colour ?? '';
                const tagNames = ['circle', 'ellipse', 'line', 'path', 'polygon', 'polyline', 'rect'];

                tagNames.forEach(tagName => {
                    const elements = element.getElementsByTagName(tagName);
                    for (const element of elements) {
                        (element as SVGAElement).style['fill'] = colour;
                        element.setAttribute("fill", colour);
                    }
                });

                tagNames.forEach(tagName => {
                    if (element.tagName === tagName) {
                        element.style['fill'] = colour;
                        element.setAttribute("fill", colour);
                    }
                });

            }
            _props['onClick'] = (() => onClick(element.attributes[i].nodeValue ?? ''));
        }
        if (tagName === 'svg' && nodeName === 'viewBox' && zoom) {
            const vb = element.attributes[i].value.split(' ');
            if (vb.length >= 4) {
                _props['style'] = { width: `${vb[2]}px`, height: `${vb[3]}px` };
            }
        }
        _props[nodeName] = val;
    }

    if (tagName === "style") {
        let styleString = element.textContent ?? '';
        styleString = styleString.replaceAll(/(\.-?[_a-zA-Z]+[_a-zA-Z0-9-]*)/g, `$1-${uuid}`);
        /* for the time being simply remove 'fill:none; from the style,
        this is too brute force though - in future we need to inject the fill into the 
        elements class defintion */
        styleString = styleString.replaceAll(/fill:none;/g, '');
        appendStyleSheet('svg-style', styleString);
    }
    let unique = Math.floor(Math.random() * 10000);
    let children: Array<React.ReactElement | string> = Array.from(element.children).map((item, idx) => {
        if (element.children.length > 0) {
            item.setAttribute("key", `${tagName}-${unique}-${idx.toString()}`);
        }
        return convertElement(item as SVGElement, uuid, assetResults, assetUUIDs, zoom, onClick);
    });

    //   children.push(element.textContent ?? '');
    const newProps = { ..._props }
    if (children.length === 0 && (tagName === 'text' || tagName === 'tspan' || tagName === 'textPath')) {
        return createElement(tagName, newProps, element.textContent);
    }
    if (element.getAttribute('atlas-node') !== null) {
        const { match, phaseInfos, asset } = getPhaseInfos(element, assetResults);
        if (asset && assetUUIDs.indexOf(asset.assetUuid) === -1) {
            assetUUIDs.push(asset.assetUuid);
        }
        if (phaseInfos.length > 0 && match.length > 2) {
            const index = parseInt(match[2], 10);
            const colour = phaseInfos[index - 1]?.colour ?? '';
            const displayStr = phaseInfos[index - 1]?.displayStr ?? '';
            //   hasCreatedTooltip = true;
            const inner = createElement(tagName, newProps, children);
            //   console.log(tagName);
            return createElement('a', {
                id: "my-anchor-element",
                key: Math.random().toString(),
                onMouseMove: (e: any) => {
                    const tooltip = document.getElementById("tooltip");
                    if (tooltip) {
                        tooltip.style.left = `${e.clientX - 80}px`;
                        tooltip.style.top = `${e.clientY - 120}px`;
                        tooltip.style.opacity = "1";
                        const d = asset?.dateOfLatestTestStr ? `Latest test: ${asset?.dateOfLatestTestStr.toString()}` : 'No test date';
                        tooltip.innerHTML = `<div style='font-size:0.8em'><div style='font-weight:600'>${asset?.siteName}</div><div style='font-weight:400'>${asset?.assetName}</div><div style='color:${colour}'>${displayStr}</div><div>${d}</div></div>`;
                    }
                },
                onMouseLeave: () => {
                    const tooltip = document.getElementById("tooltip");
                    if (tooltip) {
                        tooltip.style.opacity = "0";
                    }
                }
            }, inner);

        }
    };
    return createElement(tagName, newProps, children);
}

export const convertDocEleToReact = (element: SVGElement, uuid: string, assetResults: Array<TAssetResult>, props: {
    [x: string]: any;
}, zoom: boolean, onClick: (v: string) => void): { e: React.ReactElement, v: string, assets: string[] } => {
    let viewBox = "0 0 200 200";
    const setViewBox = (vp: string) => {
        viewBox = vp;
    }
    const tooltip = document.getElementById("tooltip");
    if (tooltip) {
        tooltip.style.opacity = "0";
    }
    try {
        const assetUUIDs: Array<string> = [];
        const elementOut = convertElement(element, uuid, assetResults, assetUUIDs, zoom, onClick, setViewBox, props);
        return { e: elementOut, v: viewBox, assets: assetUUIDs };
    }
    catch (ex) {
        return { e: createElement("div", { width: 200, 'min-height': 200 }, "No image available"), v: viewBox, assets: [] };
    }
};

// Appends CSS content to the head of the site
const appendStyleSheet = (id: string, content: string) => {
    if (!document.querySelector("#" + id)) {
        const head = document.head || document.getElementsByTagName("head")[0];
        head.appendChild(createStyleElement(id, content));
    } else {
        const style = document.getElementById(id);
        if (style && style.hasAttribute('type') && style.getAttribute('type') === 'text/css') {
            if (!Array.from(style.childNodes).some(child => child.textContent === content)) {
                style.appendChild(document.createTextNode(content));
            }
        }
    }
}

// Creates the style element
const createStyleElement = (id: string, content: string) => {
    const style = document.createElement("style");
    style.setAttribute('type', "text/css");
    style.id = id;

    if (style.hasAttribute('styleSheet')) {
        //@ts-ignore
        style.styleSheet.cssText = content;
    } else {
        style.appendChild(document.createTextNode(content));
    }
    return style;
}