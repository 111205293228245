import {
    IResultRecommendation,
    IRecommendationTemplate
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;

const API_CLASS = 'Recommendation';

export type TAssetType = 'Result' | 'Asset';

export const getRecommendation = async (token: string, assetType: TAssetType, uuid: string): Promise<IResultRecommendation | string> => {
    const url = `${getApiEndpoint()}/${assetType}${API_CLASS}/${uuid}`;
    const p = new Promise<IResultRecommendation>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error getting the recommendation ${uuid} [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IResultRecommendation);
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    });
    return p;
}


export const addRecommendation = async (token: string, assetType: TAssetType, recommendation: IResultRecommendation): Promise<IResultRecommendation> => {
    const url = `${getApiEndpoint()}/${assetType}${API_CLASS}`;
    const p = new Promise<IResultRecommendation>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(recommendation)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error adding the recommendation [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IResultRecommendation);
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    });
    return p;
}

export const updateRecommendation = async (
    token: string,
    assetType: TAssetType,
    recommendation: IResultRecommendation,
): Promise<IResultRecommendation> => {
    const url = `${getApiEndpoint()}/${assetType}${API_CLASS}`;
    const p = new Promise<IResultRecommendation>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(recommendation)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error adding the recommendation [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IResultRecommendation);
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    });
    return p;
};
export const deleteRecommendation = async (token: string, uuid: string, assetType: TAssetType): Promise<boolean> => {
    const url = `${getApiEndpoint()}/${assetType}${API_CLASS}/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error deleting the recommendation ${uuid} [${resp.status}]`));
            }
        }).then(json => {
            resolve(true);
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    });
    return p;
}

export const getRecommendations = async (token: string, assetType: TAssetType, parentUUid?: string): Promise<IResultRecommendation[]> => {
    let url: string;
    if (assetType === 'Asset') {
        url = `${getApiEndpoint()}/${assetType}Recommendation/parent/${parentUUid}`;
    } else {
        url = `${getApiEndpoint()}/${assetType}${API_CLASS}`;
    }
    const p = new Promise<IResultRecommendation[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error getting the recommendations [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IResultRecommendation[]);
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    });
    return p;
}


export const searchRecommendations = async (token: string, assetType: TAssetType, uuids: string[], abortController: AbortController): Promise<IResultRecommendation[] | string> => {
    const url = `${getApiEndpoint()}/${assetType}${API_CLASS}/searchMinimal`;
    const p = new Promise<IResultRecommendation[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal,
            method: 'POST',
            body: JSON.stringify({
                propName: "uuidStr",
                matches: uuids
            })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error getting the recommendations [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IResultRecommendation[]);
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.message);
        });
    });
    return p;
}

//[{"propName":"assetType","matches":["RotatingMachine"]}]
export const searchRecommendationTemplates = async (token: string, assetType: string, abortController: AbortController): Promise<Array<IRecommendationTemplate>> => {
    const url = `${getApiEndpoint()}/Recommendation/search`;
    const p = new Promise<IRecommendationTemplate[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal,
            method: 'POST',
            body: JSON.stringify({
                propName: "assetType",
                matches: [assetType]
            })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error getting the recommendation template [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IRecommendationTemplate[]);
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.message);
        });
    });
    return p;
}

//export cons