
import {
    IReport
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;


export const getReports = async (token: string): Promise<Array<IReport> | string> => {
    const url = `${getApiEndpoint()}/Report`;
    const p = new Promise<IReport[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the report list [${resp.status}]`));

            }
        }).then(json => {
            resolve(sanitise(json) as Array<IReport>);
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    })
    return p;
}

export const getReport = async (token: string, uuid: string): Promise<IReport> => {
    const url = `${getApiEndpoint()}/Report/${uuid}}`;
    const p = new Promise<IReport>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the report [${resp.status}]`));

            }
        }).then(json => {
            resolve(sanitise(json) as IReport);
        }).catch(e => {
            dispatch(addError(e.message));
            reject(e.message);
        });
    })
    return p;
}

export const changeReportApprovalStatus = async (token: string, reportUuid: string, status: 'Approve' | 'Disapprove'): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Report/${reportUuid}/${status}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Failed to ${status} report`));
            }
        }).catch(e => {
            reject(e.message);
        });
    });
    return p;
}
