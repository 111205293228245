import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setCompanies, setTableDefinitionComplete } from "../../../store/mainSlice";
import { ICompany, IUser, IBaseProject } from "../../../store/Interfaces";
import { FormTemplate, IFormField, TEntity, FormContext } from "../FormTemplate";
import { getUsers, createCompany, updateCompany, deleteCompany } from "../../../api";
import { Busy } from "../../Busy";
import { companyEditForm, mapEnumsToForm } from "../../../templates/formTemplates";

const TABLENAME = 'TableCompany';

interface ICompanyEditProps {
    companyUuid?: string;
    endEdit: () => void;
    clearFocus: () => void;
}

export const CompanyEdit: React.FunctionComponent<ICompanyEditProps> = ({ companyUuid, endEdit, clearFocus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companies, projects, token, tableDefinitionsComplete } = useSelector(store => store.main);
    const [wipCompany, setWipCompany] = useState<ICompany>();
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(companyEditForm);
    const [busy, setBusy] = useState(false);
    const [entityChanged, setEntityChanged] = useState(false);
    const [users, setUsers] = useState<IUser[]>([]);
    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);


    useEffect(() => {
        const getUsersLocal = async () => {
            try {
                setBusy(true);
                getUsers(token).then(localUserList => {
                    setBusy(false);
                    if (Array.isArray(localUserList)) {
                        setUsers(localUserList);
                    }
                })
            } catch (e) {
                setBusy(false);
                dispatch(addError(t('Failed to get users')));
                console.error('Error');
            }
        }
        if (token) {
            getUsersLocal();
        }
    }, [token]);

    useEffect(() => {
        if (companyUuid && companies) {
            //const tx = tableDefinitionsComplete.TableSubSite.rows.find(r => r.uuid === subSiteUuid);;
            //const s = subSites.find(s => s.name === tx?.name);
            const s1 = companies.find(c => c.uuid === companyUuid);
            setWipCompany(s1);
        }
    }, [companyUuid])

    const projectSort = (a: IBaseProject, b: IBaseProject) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }
    const userSort = (a: IUser, b: IUser) => {
        return (a?.emailAddress ?? '').localeCompare(b?.emailAddress ?? '');
    }

    useEffect(() => {
        if (projects && users && tableDefinitionsComplete[TABLENAME]?.searchParams) {
            let localFormWithOptions = mapEnumsToForm(companyEditForm, tableDefinitionsComplete[TABLENAME]?.searchParams);

            localFormWithOptions = localFormWithOptions.map(field => {
                if (field.propValue === 'projects') {
                    return { ...field, options: ([...projects] ?? []).sort(projectSort).map(project => ({ name: project?.name ?? '', value: project.uuid })) }
                } else if (field.propValue === 'users') {
                    return { ...field, options: ([...users] ?? []).sort(userSort).map(user => ({ name: user?.emailAddress ?? '', value: user.uuid })) }
                } else {
                    return field
                }
            })
            setFormWithOptions(localFormWithOptions);
        }
    }, [projects, users, tableDefinitionsComplete])

    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createCompany(token, entity as ICompany).then((company) => {
                dispatch(addToast(t('Company created')));
                setBusy(false);
                setWipCompany(company);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
                dispatch(setCompanies([...companies, company as ICompany]));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            })
        } else {
            updateCompany(token, entity as ICompany).then((company) => {
                dispatch(addToast(t('Company updated')));
                setBusy(false);
                setWipCompany(company);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
                dispatch(setCompanies(companies.map(c => c.uuid === company.uuid ? company as ICompany : c)));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            });
        }
    }

    const onDelete = () => {
        setBusy(true);
        deleteCompany(token, wipCompany?.uuid as string).then(() => {
            dispatch(setCompanies(companies.filter(c => c.uuid !== wipCompany?.uuid)));
            dispatch(setTableDefinitionComplete([TABLENAME, null]))
            clearFocus();
            dispatch(addToast(t('Company deleted')));
            setWipCompany(undefined);
            setBusy(false);
        }).catch((err) => {
            setBusy(false);
            dispatch(addError(err.message));
            console.error(err);
        });
    }
    const setForm = (form: Array<IFormField>) => {
        setFormWithOptions(form);
    }
    return (
        <div className='m-2 overflow-auto max-h-full pb-4'>
            <div className="relative z-30 w-full h-full" >
                {busy ? <Busy /> :
                    <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                        <FormTemplate entityName={t('Company')} form={formWithOptions} setForm={setForm} entity={wipCompany as TEntity} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={onDelete} onEndEdit={endEdit} />
                    </FormContext.Provider>}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}
        </div >
    )
}